import React from "react";
import { Button, Typography } from "antd";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { clearMeetingPlanReducerAction } from "../../redux/actions/meetingsPlanActions";

function ConfigMeetingsPanel() {
  const history = useHistory();
  const dispatch = useDispatch();

  const redirectToMeetingPlanAndClearStore = () => {
    dispatch(clearMeetingPlanReducerAction());
    history.push("/meeting-plan");
  };

  return (
    <div className="meetingConfigPanel">
      <Typography.Title className="title" level={2}>
        Meetings
      </Typography.Title>
      <Button type="primary" onClick={redirectToMeetingPlanAndClearStore}>
        Add meetings plan
      </Button>
    </div>
  );
}

export default ConfigMeetingsPanel;
