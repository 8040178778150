import React, { useState, useMemo } from "react";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewCompanyAction,
  setChosenCompanyAction,
  deleteCurrentCompanyAction,
  editCompanyAction,
  setCompanyUsersAction,
  setAllAttributesAction,
  setCurrentFunnelAction,
} from "../../../redux/actions/adminPageActions";
import { Switch } from "antd";
import API from "../../../services/apiService";
import notificationAlert from "../../../utils/notificationAlert";
import ModalGroup from "../../../components/ModalGroup/ModalGroup";
import PropTypes from "prop-types";
import CompanyDropdown from "../../../components/CompanyDropdown/CompanyDropdown";
import { isHavePermission } from "../../../utils/helpers";
import { applicationRoles } from "../../../utils/mocks";
import ModalComponent from "../../../components/Modal/Modal";
import "./_configCompanyPanel.less";

const { ADMIN } = applicationRoles;

const ConfigCompanyPanel = React.memo(({ allCompanies, currentCompany, chooseCompanyTabHandler }) => {
  const dispatch = useDispatch();
  const [modalMode, setModalMode] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const [isConfirmDelete, setConfirmDelete] = useState(false);
  const loggedUser = useSelector((state) => state.common.user);
  const companyUsers = useSelector((state) => state.setting.companyUsers);

  const chooseCompanyHandler = (selectedCompany) => {
    const chosenCompany = allCompanies.find((company) => company.name === selectedCompany);
    dispatch(setCurrentFunnelAction({}));
    dispatch(setChosenCompanyAction({ chosenCompany }));
    dispatch(setCompanyUsersAction(chosenCompany.id));
    const allAttributesData = allCompanies.reduce((acc, company) => acc.concat(company.attributes), []);
    dispatch(setAllAttributesAction(setDataExceptCurrentCompany(allAttributesData, chosenCompany)));
    chooseCompanyTabHandler("General");
  };

  const setDataExceptCurrentCompany = (attributes, newCompanyData) => {
    return attributes.filter(
      (attribute) => !newCompanyData.attributes.some((currentAttribute) => currentAttribute.id === attribute.id)
    );
  };

  const createNewCompany = async ({ name, copied_company, copied_questions, licenseeCount, isActive }) => {
    const newCompanyData = await API.post("company/create", {
      name,
      isActive,
      type: "",
      licenseeCount,
    });
    if (copied_company && copied_questions) {
      const copiedCompany = allCompanies.find((company) => company.name === copied_company);
      const copiedQuestions = copiedCompany.questions.filter((question) => {
        return copied_questions.some((copiedQuestionName) => question.name === copiedQuestionName);
      });
      await API.post(`question/copy/${newCompanyData.id}`, {
        questionsArray: copiedQuestions,
      });
    }

    notificationAlert({
      description: "Company has been created",
    });
    dispatch(setCurrentFunnelAction({}));
    dispatch(createNewCompanyAction({ newCompanyData }));
    chooseCompanyTabHandler("General");
  };

  const editCompany = async ({ name, copied_company, copied_questions, licenseeCount, isActive }) => {
    const companyIndex = allCompanies.findIndex((company) => company.name === currentCompany.name);
    const updatedCompany = await API.patch(`company/${currentCompany.id}`, {
      ...currentCompany,
      name,
      isActive,
      licenseeCount,
    });
    if (copied_company && copied_questions) {
      const copiedCompany = allCompanies.find((company) => company.name === copied_company);
      const copiedQuestions = copiedCompany.questions.filter((question) => {
        return copied_questions.some((copiedQuestionName) => question.name === copiedQuestionName);
      });
      await API.post(`question/copy/${updatedCompany.id}`, {
        questionsArray: copiedQuestions,
      });
    }

    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
    notificationAlert({
      description: "Company name has been changed",
    });
  };

  const deleteCompany = async () => {
    await API.delete(`company/${currentCompany.id}`);
    localStorage.removeItem("currentCompanyNameSetting");
    notificationAlert({
      description: "Company has been deleted",
    });
    dispatch(deleteCurrentCompanyAction({ id: currentCompany.id }));
  };

  const modalSubmitOk = async (value) => {
    if (modalMode === "add") {
      await createNewCompany(value);
    } else if (modalMode === "edit") {
      await editCompany(value);
    } else if (modalMode === "delete") {
      if (companyUsers && companyUsers.length) {
        return setConfirmDelete(true);
      }
      await deleteCompany();
    }
    setModalVisible(false);
  };

  const onModalCancel = () => {
    setModalVisible(false);
    setModalMode("");
  };

  const onModalOpen = (mode) => {
    setModalVisible(true);
    setModalMode(mode);
  };

  const isBtnVisible = !!(currentCompany && currentCompany.name);

  const toggleCompanyActive = async (value) => {
    const companyIndex = allCompanies.findIndex((el) => el.name === currentCompany.name);
    const updatedCompany = await API.patch(`company/${currentCompany.id}`, {
      ...currentCompany,
      isActive: value,
    });
    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
  };

  const buttons = [
    { name: "Add company", action: () => onModalOpen("add"), visible: true },
    { name: "Edit company", action: () => onModalOpen("edit"), visible: isBtnVisible },
    { name: "Delete company", action: () => onModalOpen("delete"), visible: isBtnVisible },
  ];

  const modals = [
    {
      title: "Delete",
      text: `Delete «${currentCompany && currentCompany.name}» company?`,
      type: "delete",
      isVisible: isModalVisible,
    },
    { title: "Edit", text: "Edit company fields", type: "edit", isVisible: isModalVisible },
    { title: "Add", text: "Add new company", type: "add", isVisible: isModalVisible },
  ];

  const modalComponentsData = useMemo(
    () => () => {
      return [
        { name: "name", elementType: "input", placeholder: "Company name", checkDuplicate: true },
        {
          name: "copied_company",
          placeholder: "Choose company",
          elementType: "main_select",
          elementList: allCompanies && allCompanies.length ? allCompanies.filter((company) => company.isActive) : [],
          notRequired: true,
          dependentSelectName: "questions",
          notEditable: true,
          nestedField: "name",
        },
        {
          name: "copied_questions",
          placeholder: "Choose company question",
          elementType: "dependent_select",
          elementList: [],
          mode: "multiple",
          notRequired: true,
        },
        {
          name: "licenseeCount",
          placeholder: "Licensee count",
          elementType: "input_number",
        },
        {
          name: "isActive",
          elementType: "switcher",
          notRequired: true,
        },
      ];
    },
    [allCompanies]
  );

  const getCurrentItem = (currentCompany) => {
    return modalMode === "edit" ? { ...currentCompany, isActive: currentCompany.isActive } : currentCompany;
  };

  const confirmCompanyDeleteHandler = async () => {
    setConfirmDelete(false);
    await deleteCompany();
    setModalVisible(false);
    setModalMode("");
  };

  const closeCompanyDeleteHandler = () => {
    setModalVisible(false);
    setModalMode("");
    setConfirmDelete(false);
  };

  return (
    <div className="company-config">
      {isModalVisible ? (
        <ModalGroup
          editableItemIdx={""}
          mainField="name"
          allItems={allCompanies}
          mode={modalMode}
          modals={modals}
          modalComponentsData={modalComponentsData()}
          onModalCancel={onModalCancel}
          modalSubmitOk={modalSubmitOk}
          currentItem={getCurrentItem(currentCompany)}
        />
      ) : null}
      {isConfirmDelete ? (
        <ModalComponent
          title="Confirm delete"
          text="Company contains users. Are you sure you want to delete company? (users will be available in the system)"
          isModalVisible={isConfirmDelete}
          modalSubmitOk={confirmCompanyDeleteHandler}
          onModalCancelHandler={closeCompanyDeleteHandler}
        />
      ) : null}
      <div className="block__title">All companies:</div>
      <div className="select_container">
        <CompanyDropdown
          optionField="name"
          className="admin_page"
          onSelect={(chosenCompany) => currentCompany?.name !== chosenCompany && chooseCompanyHandler(chosenCompany)}
          value={currentCompany && currentCompany.name}
          allData={allCompanies}
        />
      </div>
      {isHavePermission(loggedUser.role, [ADMIN]) ? (
        <>
          <ButtonGroup data={buttons} />
          {!!(currentCompany && currentCompany.name) && (
            <Switch
              checkedChildren={"Active"}
              disabled={String(currentCompany.id) === String(loggedUser.companyId)}
              unCheckedChildren={"Inactive"}
              onChange={toggleCompanyActive}
              checked={currentCompany.isActive}
            />
          )}
        </>
      ) : null}
    </div>
  );
});

ConfigCompanyPanel.propTypes = {
  currentCompany: PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.number,
    name: PropTypes.string,
    attributes: PropTypes.array,
    consultants: PropTypes.array,
    funnels: PropTypes.array,
    performanceIndicators: PropTypes.array,
    questions: PropTypes.array,
    reportSettings: PropTypes.object,
    scripts: PropTypes.array,
    timezone: PropTypes.string,
    timezoneName: PropTypes.string,
    webhooks: PropTypes.array,
  }),
  allCompanies: PropTypes.array,
  chooseCompanyTabHandler: PropTypes.func,
};

export default ConfigCompanyPanel;
