import React, { useState } from "react";
import ScriptTextSection from "./ScriptTextSection";
import ScriptObjectionSection from "./ScriptObjectionSection";
import ScriptReasonSection from "./ScriptReasonSection";
import ScriptQuestionsSection from "./ScriptQuestionSection/ScriptQuestionsSection";
import { useDispatch, useSelector } from "react-redux";
import ScriptLoader from "../ScriptLoader/ScriptLoader";
import { applicationRoles } from "../../../utils/mocks";
import withPermission from "../../../hocks/withPermission";
import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const { ADMIN } = applicationRoles;

const WithPermissionScriptReasonSection = withPermission(ScriptReasonSection);

export default function ScriptForm({ currentPage }) {
  const dispatch = useDispatch();
  const allObjections = useSelector((state) => state.script.stages);
  const currentStage = useSelector((state) => state.script.stages[currentPage]);
  const [isScriptLoaderOpen, setScriptLoader] = useState(false);

  if (!currentStage) {
    return <div>There are no stages here</div>;
  }

  const scriptLoaderToggle = () => setScriptLoader(!isScriptLoaderOpen);

  return (
    <div className="script-body">
      <Button icon={<UploadOutlined />} className="load-script-btn" onClick={scriptLoaderToggle}>
        Load template
      </Button>
      <ScriptTextSection
        isScriptLoaderOpen={isScriptLoaderOpen}
        script={currentStage.script}
        currentPage={currentPage}
        dispatch={dispatch}
      />
      <ScriptObjectionSection
        allObjections={allObjections}
        currentStage={currentStage}
        currentPage={currentPage}
        dispatch={dispatch}
      />
      <WithPermissionScriptReasonSection
        roles={[ADMIN]}
        currentStage={currentStage}
        currentPage={currentPage}
        dispatch={dispatch}
      />
      <ScriptQuestionsSection currentStage={currentStage} currentPage={currentPage} dispatch={dispatch} />
      {isScriptLoaderOpen ? (
        <ScriptLoader
          scriptLoaderToggle={scriptLoaderToggle}
          currentPage={currentPage}
          title={"Script loader"}
          isModalVisible={isScriptLoaderOpen}
          onModalCancelHandler={scriptLoaderToggle}
        />
      ) : null}
    </div>
  );
}

ScriptForm.propTypes = {
  currentPage: PropTypes.number,
};
