import React from "react";
import { Form } from "antd";
import UsersDropdown from "../../../components/UsersDropdown/UsersDropdown";
import PropTypes from "prop-types";

function UserAssignForm({ onSubmit, currentValue, data, buttonTitle, name, onCancel }) {
  const [form] = Form.useForm();

  const formSubmit = () => form.validateFields().then((data) => onSubmit(data));

  return (
    <Form form={form} name="basic" initialValues={{ [name]: currentValue }} onFinish={onSubmit} autoComplete="off">
      <Form.Item name={name} rules={[{ required: true, message: "This field is required" }]}>
        <UsersDropdown optionField="name" valueField="id" allData={data} />
      </Form.Item>
      <div className="ant-modal-footer" style={{ marginLeft: "-22px", marginRight: "-22px", paddingRight: "22px" }}>
        <button type="button" className="ant-btn" onClick={onCancel}>
          <span>Cancel</span>
        </button>
        <button type="button" className="ant-btn ant-btn-primary" onClick={formSubmit}>
          <span>{buttonTitle ? buttonTitle : "OK"}</span>
        </button>
      </div>
    </Form>
  );
}

UserAssignForm.propTypes = {
  onSubmit: PropTypes.func,
  currentValue: PropTypes.string,
  data: PropTypes.array,
  buttonTitle: PropTypes.string,
  name: PropTypes.string,
  onCancel: PropTypes.func,
};

export default UserAssignForm;
