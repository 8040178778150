import React from "react";
import SelectComponent from "../../../components/Select/Select";
import { objectionTypes } from "../../../utils/mocks";
import { useDispatch } from "react-redux";
import { changeTypeObjectionAction } from "../../../redux/actions/scriptPageActions";
import PropTypes from "prop-types";
import { transformFirstUpper } from "../../../utils/helpers";
import "../_script-page.less";

export default function ScriptObjectionType({ selectedObjectionIdx, type, currentPage }) {
  const dispatch = useDispatch();

  const chooseTypeHandler = (text) => {
    const chosenType = transformFirstUpper(text, "lower");
    dispatch(changeTypeObjectionAction({ selectedObjectionIdx, currentPage, chosenType }));
  };

  return (
    <div className="objection_type">
      <span className="title">Objection type: </span>
      <SelectComponent
        mainField="name"
        data={objectionTypes}
        value={transformFirstUpper(type, "upper") || "Choose type"}
        handleChange={chooseTypeHandler}
      />
    </div>
  );
}

ScriptObjectionType.propTypes = {
  selectedObjectionIdx: PropTypes.number,
  type: PropTypes.string,
  currentPage: PropTypes.number,
};
