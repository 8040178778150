import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import "./_popup.less";

export default function Popup({ title, isModalVisible, className, submitData, modalCancel, footer, children, style }) {
  return (
    <Modal
      maskClosable={false}
      style={style}
      title={title}
      centered
      className={className}
      visible={isModalVisible}
      onOk={submitData}
      onCancel={modalCancel}
      footer={footer}>
      {children}
    </Modal>
  );
}

Popup.defaultProps = {
  className: "",
};

Popup.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  isModalVisible: PropTypes.bool,
  modalCancel: PropTypes.func,
  onModalCancelHandler: PropTypes.func,
  submitData: PropTypes.func,
  className: PropTypes.string,
};
