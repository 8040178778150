import React from "react";
import { Timeline } from "antd";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { sortByDate, transformDateForSafari } from "../../utils/helpers";
import "./_timeline.less";

const dateFormat = "MM-dd HH:mm";

export default function TimelineComponent({ data, param, label, mode, selectActivity }) {
  const getCallDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration / 3600) * 60);
    let seconds = Math.floor((duration / 3600) * 60 * 60);
    if (hours > 0) {
      return `${hours} hours ${minutes - hours * 60} minutes ${seconds - minutes * 60} seconds`;
    }
    if (minutes > 0) {
      return `${minutes - hours * 60} minutes ${seconds - minutes * 60} seconds`;
    }
    return `${seconds} seconds`;
  };

  const getLabel = (node) => {
    const dateOfCall = format(new Date(transformDateForSafari(node[label])), dateFormat);
    return (
      <p>
        {dateOfCall + ` (${getCallDuration(node.duration)})`} <br />
        <b>(GMT{node.offset})</b>
      </p>
    );
  };

  return (
    <Timeline mode={mode}>
      {data &&
        data.length &&
        sortByDate(data, "date").map((node, nodeIndex) => {
          return (
            <Timeline.Item
              onClick={() => selectActivity(node)}
              className={`timeline_item ${!node.duration ? "error" : ""}`}
              label={getLabel(node)}
              key={nodeIndex}>
              {node[param]}
            </Timeline.Item>
          );
        })}
    </Timeline>
  );
}

TimelineComponent.propTypes = {
  data: PropTypes.array,
  param: PropTypes.string,
  label: PropTypes.string,
  mode: PropTypes.string,
  selectActivity: PropTypes.func,
};
