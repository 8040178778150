import { createSelector } from "reselect";
import { END_REASONS_COLOR_MAPPING } from "../../pages/DashboardPage/dashboardPageMappings";

export const getLeftPieDashboards = (state) => state.callBreakdowns.leftPieDashboard;
export const getRightPieDashboards = (state) => state.callBreakdowns.rightPieDashboard;
export const getTotalValue = (state) => state.callBreakdowns.totalValue;
export const getTotalValueRightPie = (state) => state.callBreakdowns.totalValueRightPie;
export const getLeftPieLoading = (state) => state.callBreakdowns.isLeftPieLoading;
export const getRightPieLoading = (state) => state.callBreakdowns.isRightPieLoading;

export const getPiesSectionData = createSelector(
  getLeftPieDashboards,
  getRightPieDashboards,
  getTotalValue,
  getTotalValueRightPie,
  getLeftPieLoading,
  getRightPieLoading,
  (leftPieDashboard, rightPieDashboard, totalValue, totalValueRightPie, isLeftPieLoading, isRightPieLoading) => {
    return {
      leftPieDashboard,
      rightPieDashboard,
      totalValue,
      totalValueRightPie,
      isLeftPieLoading,
      isRightPieLoading,
    };
  }
);

export const getGroupedChartData = (state) => state.callBreakdowns.groupedChartData;

//TODO improve and move inside getTransformedGroupedChartData selector
const getTransformedData = (data) => {
  const names = [...new Set(data.reduce((a, x) => a.concat(x.stats.map((y) => y.endReason)), []))];

  return data.map((x) => ({
    ...x,
    stats: names.map((endReason) => {
      let color = END_REASONS_COLOR_MAPPING[endReason].color;
      return {
        endReason,
        color: color,
        value: (x.stats.find((z) => z.endReason === endReason) || { value: 0 }).value,
      };
    }),
  }));
};

//TODO improve and move inside getTransformedGroupedChartData selector
const getDataset = (groupedChartData) => {
  if (groupedChartData) {
    const result = [];

    groupedChartData.forEach((el) => {
      el.stats.forEach((el, ind) => {
        if (result[ind] === undefined) {
          result[ind] = { data: [], backgroundColor: [], label: "" };
        }

        //pushing new values to the new array
        if (!result[ind].label) {
          result[ind].label = el.endReason;
        }
        result[ind].data.push(el.value);
        result[ind].backgroundColor.push(el.color);
      });
    });

    return result;
  } else {
    return [];
  }
};

/**
 * Reselect implement next transformations :
 1)Sorting
 2)Add colors for each reason (mapping that associate each endReason with color)
 3)Transform in appropriate for stacked chart data format :

 we have:
 @example
 const groupedChartData = [
 {
      duration: '00:00:10',
      stats: [
        {color: 'red', reason: 'yes', value: 11},
        {color: 'green', reason: 'no', value: 33},
      ]
      },
 {
      duration: '00:01:00',
      stats: [
        {color: 'black', reason: 'call back', value: 32},
        {color: 'green', reason: 'no', value: 77},
      ]
      },
 {
      duration: '00:10:00',
      stats: [
        {color: 'red', reason: 'yes', value: 14},
      ]
    }
 ]

 expected result:
 @example
 let groupedChartData = [
 {
    data: [11, 0, 14],
    backgroundColor: 'red'
   },
 {
    data: [33, 77, 0],
    backgroundColor: 'green'
   },
 {
    data: [33, 77, 0],
    backgroundColor: 'green'
 }
 ]
 */
export const getTransformedGroupedChartData = createSelector(getGroupedChartData, (data) =>
  data && data.length ? getDataset(getTransformedData(data)) : null
);

export const getTimeLabels = createSelector(getGroupedChartData, (data) =>
  data && data.length ? data.map((reason) => reason.duration) : []
);
