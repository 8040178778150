import React from "react";
import { Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updatePlanningNotesAction, updateMeetingNotesAction } from "../../redux/actions/meetingsPlanActions";
import "./_meetingPlanPage.less";

const { TextArea } = Input;

function MeetingPlanNotes() {
  const dispatch = useDispatch();
  const planningNotes = useSelector((state) => state.meetingPlan.planningNotes);
  const meetingNotes = useSelector((state) => state.meetingPlan.meetingNotes);

  const planningNotesChange = ({ target: { value } }) => dispatch(updatePlanningNotesAction(value));

  const meetingNotesChange = ({ target: { value } }) => dispatch(updateMeetingNotesAction(value));

  return (
    <div className="plan_notes">
      <TextArea value={planningNotes} rows={4} onChange={planningNotesChange} placeholder="Planning notes" />
      <TextArea value={meetingNotes} rows={4} onChange={meetingNotesChange} placeholder="Meeting notes" />
    </div>
  );
}

export default MeetingPlanNotes;
