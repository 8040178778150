import React from "react";
import { Space, Tag, Tooltip } from "antd";
import { PieChartOutlined } from "@ant-design/icons";

function tableConfig(guidance, redirectToDashboards) {
  const getLevelColor = (level) => {
    if (level === "new") return "green";
    if (level === "ongoing") return "orange";
    if (level === "systemic") return "red";
    return "";
  };

  function getData(guidance) {
    let data = [];
    if (!Array.isArray(guidance)) return [];
    guidance.forEach((guidanceEntity) => {
      guidanceEntity &&
        guidanceEntity.new &&
        guidanceEntity.new
          .sort((a, b) => b.count - a.count)
          .slice(0, 1)
          .forEach((guidanceItem) => {
            data.push({
              user: guidanceEntity.name,
              level: "new",
              previousGuidance: guidanceItem.text,
              guidanceCount: guidanceItem.count,
              key: guidanceEntity.id + "new",
              userId: guidanceEntity.id,
            });
          });
      guidanceEntity &&
        guidanceEntity.ongoing &&
        guidanceEntity.ongoing
          .sort((a, b) => b.count - a.count)
          .slice(0, 1)
          .forEach((guidanceItem) => {
            data.push({
              user: guidanceEntity.name,
              level: "ongoing",
              previousGuidance: guidanceItem.text,
              guidanceCount: guidanceItem.count,
              key: guidanceEntity.id + "ongoing",
              userId: guidanceEntity.id,
            });
          });
      guidanceEntity &&
        guidanceEntity.systemic &&
        guidanceEntity.systemic
          .sort((a, b) => b.count - a.count)
          .slice(0, 1)
          .forEach((guidanceItem) => {
            data.push({
              user: guidanceEntity.name,
              level: "systemic",
              previousGuidance: guidanceItem.text,
              guidanceCount: guidanceItem.count,
              key: guidanceEntity.id + "systemic",
              userId: guidanceEntity.id,
            });
          });
    });
    return data;
  }

  function getColumns() {
    return [
      {
        title: "Members",
        dataIndex: "user",
        key: "user",
        className: "teamMember_column",
        ellipsis: true,
        align: "center",
        render: (text) => <span>{text}</span>,
        sorter: (a, b) => a.user.localeCompare(b.user),
      },
      {
        title: "Level",
        dataIndex: "level",
        key: "level",
        className: "level_column",
        ellipsis: true,
        align: "center",
        render: (level) => <Tag color={getLevelColor(level)}>{level}</Tag>,
      },
      {
        title: "Previous guidance",
        dataIndex: "previousGuidance",
        className: "prevGuidance_column",
        key: "previousGuidance",
      },
      {
        title: "Count",
        className: "guidance_column",
        dataIndex: "guidanceCount",
        key: "guidanceCount",
        align: "center",
      },
      {
        title: "Actions",
        className: "guidance_actions",
        dataIndex: "guidanceActions",
        key: "guidanceActions",
        align: "center",
        render: (_, record) => {
          return (
            <Space size="middle">
              <Tooltip placement="topLeft" title={"Show dashboards"}>
                <span className="action_link" onClick={() => redirectToDashboards(record)}>
                  <PieChartOutlined style={{ fontSize: "18px" }} className="icon redirect_icon" />
                </span>
              </Tooltip>
            </Space>
          );
        },
      },
    ];
  }

  return { getData: getData(guidance), getColumns: getColumns() };
}

export default tableConfig;
