import React from "react";
import UserButtonGroup from "../../components/UserButtonGroup/UserButtonGroup";
import { Col } from "antd";
import { updateCallActivityAction } from "../../redux/actions/scriptViewerPageActions";
import { callActivityFormObject } from "../../utils/helpers";
import PropTypes, { oneOfType } from "prop-types";
import "./_script-viewer-page.less";

export default function ObjectionsBlock({
  chosenObjections,
  objections,
  currentStage,
  dispatch,
  allScriptStages,
  currentCall,
  setSelectedObjection,
  selectedObjection,
}) {
  const openObjection = (selectedObjection) => {
    dispatch(
      updateCallActivityAction(
        callActivityFormObject({
          allScriptStages,
          currentCall,
          currentStage,
          itemInfo: "selectedObjection",
          itemType: selectedObjection.type,
          itemId: selectedObjection.id,
        })
      )
    );
    setSelectedObjection(selectedObjection);
  };

  const buttons = objections.map((objection) => {
    return {
      name: objection.name,
      action: () => openObjection(objection),
      visible: true,
      theme: "blue",
      themeActive: "orange",
      themeSelected: "blue-empty",
      isActive:
        chosenObjections[currentStage] &&
        chosenObjections[currentStage].name &&
        objection.name === chosenObjections[currentStage].name,
      isActiveSelected: objection.name === selectedObjection.name,
    };
  });

  return (
    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
      <div className="objection_section">
        <UserButtonGroup data={buttons} />
      </div>
    </Col>
  );
}

ObjectionsBlock.propTypes = {
  chosenObjections: PropTypes.arrayOf(
    PropTypes.shape({
      id: oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      priority: PropTypes.string,
      script: PropTypes.string,
      stageId: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  objections: PropTypes.arrayOf(PropTypes.object),
  currentStage: PropTypes.number,
  dispatch: PropTypes.func,
  allScriptStages: PropTypes.arrayOf(
    PropTypes.shape({
      endReasons: PropTypes.array,
      id: oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      objections: PropTypes.array,
      order: PropTypes.number,
      questions: PropTypes.array,
      script: PropTypes.string,
    })
  ),
  currentCall: PropTypes.shape({
    callActivities: PropTypes.array,
    companyId: PropTypes.string,
    currentStage: PropTypes.any,
    endDateTime: PropTypes.any,
    endReason: oneOfType([PropTypes.object, PropTypes.string]),
    id: oneOfType([PropTypes.number, PropTypes.string]),
    lead: PropTypes.any,
    listStages: PropTypes.array,
    offset: PropTypes.string,
    startDateTime: PropTypes.string,
    userId: PropTypes.string,
  }),
  setSelectedObjection: PropTypes.func,
  selectedObjection: PropTypes.shape({
    id: oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    priority: PropTypes.string,
    script: PropTypes.string,
    stageId: PropTypes.string,
    type: PropTypes.string,
  }),
};
