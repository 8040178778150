import React, { useEffect } from "react";
import MeetingPlanBottomBtn from "./MeetingPlanBottomBtn";
import MeetingPlanConfig from "./MeetingPlanConfig";
import MeetingPlanPlannedGuidance from "./MeetingPlanPlannedGuidance/MeetingPlanPlannedGuidance";
import {
  setAllMeetingPlanDataAsyncAction,
  setAllUsers,
  updateFiltersFromParamMeetingPlan,
} from "../../redux/actions/meetingsPlanActions";
import MeetingPlanConsultantGuidance from "./MeetingPlanConsultantGuidance/MeetingPlanConsultantGuidance";
import MeetingPlanNotes from "./MeetingPlanNotes";
import { Prompt, useHistory } from "react-router";
import { Spin, Typography } from "antd";
import withCallClear from "../../hocks/withCallClear";
import { useDispatch, useSelector } from "react-redux";
import { getUrlParam } from "../../utils/helpers";
import "./_meetingPlanPage.less";

function MeetingPlanPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAllDataLoading = useSelector((state) => state.meetingPlan.isAllDataLoading);
  const isSaveData = useSelector((state) => state.meetingPlan.isSaveData);

  useEffect(() => {
    (async () => {
      await dispatch(setAllUsers());
      const meetingId = getUrlParam(history.location.search, "id");
      //we dont need ability fork with url params in edit meeting mode
      if (meetingId) {
        return dispatch(setAllMeetingPlanDataAsyncAction(meetingId));
      }
      dispatch(updateFiltersFromParamMeetingPlan(history.location.search));
    })();
  }, []);

  if (isAllDataLoading) {
    return (
      <div className="spinner">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="meetingPlan-page">
      <div className="fluid_container">
        <Typography.Title className="title" level={2}>
          Meeting plan
        </Typography.Title>
        <MeetingPlanConfig />
        <MeetingPlanPlannedGuidance />
        <MeetingPlanConsultantGuidance />
        <MeetingPlanNotes />
        <MeetingPlanBottomBtn />
      </div>
      <Prompt
        when={isSaveData}
        message={() => "Are you sure you want to leave the page? You will lose unsaved changes"}
      />
    </div>
  );
}

export default withCallClear(MeetingPlanPage);
