import React, { useEffect } from "react";
import ModalComponent from "../Modal/Modal";
import Form from "antd/lib/form";
import ModalUpdateComponent from "../ModalUpdate/ModalUpdate";
import PropTypes from "prop-types";

//TODO Dont use this component for future modals, use composition component instead
export default function ModalGroup({
  modals,
  modalSubmitOk,
  mode,
  currentItem,
  onModalCancel,
  modalComponentsData,
  allItems,
  mainField,
}) {
  const [form] = Form.useForm();

  const onModalCancelHandler = () => {
    onModalCancel();
    if (mode !== "delete" && mode !== "info") {
      form.resetFields();
    }
  };

  useEffect(() => {
    if (mode === "add") {
      return form.resetFields();
    }

    if (currentItem && mode !== "delete") {
      return form.setFieldsValue(
        modalComponentsData.reduce((acc, el) => {
          acc[el.name] = currentItem[el.name];
          return acc;
        }, {})
      );
    }
  }, [currentItem, form, mode, modalComponentsData]);

  const renderModalType = (modal, index) => {
    if (modal.type === mode) {
      if (modal.type === "delete" || modal.type === "info") {
        return (
          <ModalComponent
            key={index}
            title={modal.title}
            text={modal.text}
            modalSubmitOk={modalSubmitOk}
            isModalVisible={modal.isVisible}
            onModalCancelHandler={onModalCancelHandler}
          />
        );
      } else {
        return (
          <ModalUpdateComponent
            currentItem={currentItem}
            mode={mode}
            mainField={mainField}
            allItems={allItems}
            key={index}
            index={index}
            form={form}
            modalSubmitOk={modalSubmitOk}
            modal={modal}
            onModalCancelHandler={onModalCancelHandler}
            modalComponentsData={modalComponentsData}
          />
        );
      }
    }
  };

  return (
    <>
      {modals.map((modal, index) => {
        return renderModalType(modal, index);
      })}
    </>
  );
}

ModalGroup.propTypes = {
  modals: PropTypes.arrayOf(PropTypes.object),
  modalSubmitOk: PropTypes.func,
  mode: PropTypes.string,
  currentItem: PropTypes.object,
  onModalCancel: PropTypes.func,
  modalComponentsData: PropTypes.arrayOf(PropTypes.object),
};
