export const TOGGLE_SLIDER_MENU = "TOGGLE_SLIDER_MENU";
export const CLOSE_SLIDER_MENU = "CLOSE_SLIDER_MENU";
export const LOG_IN = "LOG_IN";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const UNSET_ACTIVE_PAGE = "UNSET_ACTIVE_PAGE";

export const setActivePage = (payload) => ({ type: SET_ACTIVE_PAGE, payload });
export const unsetActivePage = () => ({ type: UNSET_ACTIVE_PAGE });

export const logInAction = (payload) => ({ type: LOG_IN, payload });
export const toggleSliderMenuAction = () => ({ type: TOGGLE_SLIDER_MENU });
export const closeSliderMenuAction = () => ({ type: CLOSE_SLIDER_MENU });
