import React from "react";
import { Select } from "antd";
import { sortByName } from "../../utils/helpers";
import PropTypes from "prop-types";
import "./_company_dropdown.less";

export default function CompanyDropdown({ loading, value, allData, onSelect, className, optionField, isDisabled }) {
  return (
    <Select
      disabled={isDisabled}
      placeholder="Choose company"
      showSearch
      className={"company_dropdown " + className}
      loading={loading || false}
      onSelect={(chosenCompany) => value !== chosenCompany && onSelect(chosenCompany)}
      value={value}>
      {allData &&
        sortByName(allData, optionField).map((company, index) => {
          return (
            <Select.Option key={index} value={company[optionField]}>
              {company[optionField]}
            </Select.Option>
          );
        })}
    </Select>
  );
}

CompanyDropdown.defaultProps = {
  loading: false,
  className: "",
};

CompanyDropdown.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.string,
  allData: PropTypes.array,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  optionField: PropTypes.string,
  isDisabled: PropTypes.bool,
};
