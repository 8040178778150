import API from "../services/apiService";
import { format } from "date-fns";
import moment from "moment";

const transformAttributes = (attributes) => {
  return attributes.reduce((acc, el, index, array) => {
    return acc + el.text + (array.length - 1 !== index ? " , " : ".");
  }, "");
};

export const transformDataGridForScriptLoader = async () => {
  let result = [];
  const companies = await API.get("company/all");

  for (let i = 0; i < companies.length; i++) {
    let curretCompanyName = companies[i].name;
    let curretCompanyId = companies[i].id;

    for (let k = 0; k < companies[i].scripts.length; k++) {
      if (companies[i].scripts[k].id) {
        const scriptResult = await API.get(`stage/by-script/${companies[i].scripts[k].id}`);
        for (let j = 0; j < scriptResult.length; j++) {
          result.push({
            company: { name: curretCompanyName, id: curretCompanyId },

            script: {
              name: companies[i].scripts[k] ? companies[i].scripts[k].name : "",
              id: companies[i].scripts[k] ? companies[i].scripts[k].id : "",
            },

            stage: {
              name: scriptResult[j] && scriptResult[j].name,
              id: scriptResult[j] && scriptResult[j].id,
              script: scriptResult[j] && scriptResult[j].script,
            },

            attributes: { name: transformAttributes(companies[i].attributes) },
          });
        }
      }
    }
  }
  return result;
};

export const removeProp = (data, prop) => {
  return data.map((el) => {
    delete el[prop];
    return el;
  });
};

export const setPrevDisabled = (currentScriptPage) => {
  if (currentScriptPage > 0) {
    return false;
  }
  if (currentScriptPage === 0) {
    return true;
  }
};

export const setNextDisabled = (currentScriptPage, allScripts) => currentScriptPage === allScripts.length - 1;

export const callActivityFormObject = ({ allScriptStages, currentStage, currentCall, itemInfo, itemType, itemId }) => {
  return {
    scriptId: allScriptStages[currentStage].scriptId,
    date: getDateInMilliseconds(new Date()),
    salesCallId: currentCall.id,
    itemId: itemId,
    itemType: itemType,
    itemInfo,
  };
};

export const transformFirstUpper = (value, type) => {
  if (value) {
    if (type === "lower") {
      return value[0].toLowerCase() + value.slice(1);
    } else {
      return value[0].toUpperCase() + value.slice(1);
    }
  } else {
    return "";
  }
};

export const clearDataAndRedirect = (history, ...params) => {
  params.forEach((param) => localStorage.removeItem(param));
  const external_id = getUrlParam(window.location.search, "lead_id");
  if (external_id) {
    return setTimeout(() => {
      history.push("leads");
    }, 100);
  }
  return setTimeout(() => {
    history.replace({ pathname: "/", search: "", state: { isActive: true } });
  }, 100);
};

export const clearStorageData = (...params) => {
  params.forEach((param) => localStorage.removeItem(param));
};

export function transformQuestions(data) {
  return data.reduce((acc, stage) => {
    stage.questions.forEach((question) => {
      if (0 > acc.findIndex((el) => el.question === question.name)) {
        acc.push({ question: question.name, isShown: question.isShown, answer: question.answer });
      }
    });
    return acc;
  }, []);
}

export const changeSortDirectionTitle = (title) => {
  if (title === "ascend") {
    return "ASC";
  } else if (title === "descend") {
    return "DESC";
  } else if (title === "ASC") {
    return "ascend";
  } else if (title === "DESC") {
    return "descend";
  } else {
    return "";
  }
};

export const transformDataForSelect = (itemList, replacedParam, newParam) => {
  return itemList.map((item) => ({ [newParam]: item[replacedParam] || item }));
};

//TODO shoud we save stats? and make with loop
export function clearStorageExept(stats, timezoneStats) {
  const statsIron = localStorage.getItem(stats);
  const timezoneStatsIron = localStorage.getItem(timezoneStats);
  localStorage.clear();
  localStorage.setItem(stats, statsIron);
  localStorage.setItem(timezoneStats, timezoneStatsIron);
}

export function b64e(str) {
  if (!str) return;
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode("0x" + p1);
    })
  );
}

export function b64d(str) {
  if (!str) return;
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return "%" + c.charCodeAt(0).toString(16);
      })
      .join("")
  );
}

export const transformDateForSafari = (date) => {
  return date.replace(" ", "T");
};

export const getUrlParam = (address, param) => {
  const params = new URLSearchParams(address);
  return params.get(param);
};

export const getAllUrlParam = (address, param) => {
  const params = new URLSearchParams(address);
  return params.getAll(param);
};

export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    rv[x[key]] = x;
    return rv;
  }, {});
};

export const getCurrentUserTodayStats = () => {
  let hashedUserId = b64e(JSON.parse(localStorage.getItem("currentUser")).id);
  if (localStorage.getItem("ironcage-stats")) {
    return JSON.parse(localStorage.getItem("ironcage-stats"))[hashedUserId];
  }
  return null;
};

export const getStatsExceptToday = (prevDayStats) => {
  const dateFormat = "yyyy-MM-dd";
  return prevDayStats && prevDayStats.length
    ? prevDayStats.filter((stat) => {
        return stat.date !== format(new Date(), dateFormat);
      })
    : [];
};

export const sortByDate = (data, field) => {
  return data.sort(function (a, b) {
    const dateA = new Date(a[field]),
      dateB = new Date(b[field]);
    return dateB - dateA;
  });
};

export const getAllUrlParams = function (url) {
  let params = {};
  let parser = document.createElement("a");
  parser.href = url;
  let query = parser.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
};

export const sortForSelectByAsc = (data, field) => {
  return data && data.length
    ? data.sort(function (a, b) {
        return a[field]?.localeCompare(b[field]);
      })
    : [];
};

export const FORMAT_DATE = "yyyy-MM-dd H:m:s";
export const FORMAT_DATE_DAY = "yyyy-MM-dd";

//For USERS
export const FORMAT_DATE_UI = "yyyy-MM-dd HH:mm:ss";
export const FORMAT_DATE_MINUTE_UI = "yyyy-MM-dd HH:mm";

//------------------Helpers for date-------------------------------

export const getDateInMilliseconds = (date) => {
  if (!date) {
    return null;
  }
  if (typeof date === "string") {
    date = transformDateForSafari(date); //for safari browser
    return new Date(date).getTime();
  }
  return date.getTime();
};

export const transformAndGetDataWithOffset = (date, formatType) => {
  date = transformDateForSafari(date); // For firefox browser
  return format(new Date(date), formatType);
};

export const transformArrayOfObjectInObject = (data) => {
  return data.reduce(function (result, current) {
    return Object.assign(result, current);
  }, {});
};

//function that help and give permission in places when we need restrict some functionality
export const isHavePermission = (role, accessRoles) => {
  return accessRoles.includes(role);
};

export const removeDuplicatesFromObject = (data, field) => {
  return data && data.length ? data.filter((v, i, a) => a.findIndex((t) => t[field] === v[field]) === i) : [];
};

export const transformTimezone = (timezone) => {
  if (timezone < 0) {
    let d = Math.abs(timezone) % 1 === 0.5 ? ":30" : Math.abs(timezone) % 1 === 0.75 ? ":45" : ":00";
    let t = -Math.floor(Math.abs(timezone));
    return t > -10 ? "-0" + Math.abs(t) + d : String(t) + d;
  } else {
    let d = Math.abs(timezone) % 1 === 0.5 ? ":30" : Math.abs(timezone) % 1 === 0.75 ? ":45" : ":00";
    let t = Math.floor(timezone);
    return t < 10 ? "+0" + t + d : "+" + String(t) + d;
  }
};

export const getCurrentTimezone = () => {
  let timezoneValue = (new Date().getTimezoneOffset() / 60) * -1;
  return transformTimezone(timezoneValue);
};

export const transformSecondsToTime = (seconds, format) => moment.utc(seconds * 1000).format(format);

export const sortByName = (data, field) => {
  if (data && data.length) {
    if (field) {
      return data.sort(function (a, b) {
        if (a[field] < b[field]) return -1;
        if (a[field] > b[field]) return 1;
        return 0;
      });
    } else {
      return data.sort(function (a, b) {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
    }
  } else {
    return [];
  }
};
