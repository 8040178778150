import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Main, LoginPage, ResetPassword, PasswordResetConfirmPage } from "../pages";
import LoginFooter from "../components/LoginFooter/LoginFooter";
import LoginHeader from "../components/LooginHeader/LoginHeader";
import { routesPaths } from "../utils/mocks";

export const authRoutes = (isAuth) => {
  if (isAuth) {
    return (
      <Switch>
        <Route path={routesPaths.HOME} component={Main} />
      </Switch>
    );
  }

  return (
    <>
      <LoginHeader />
      <Switch>
        <Route path={routesPaths.LOGIN} component={LoginPage} exact />
        <Route path={routesPaths.RESET} component={ResetPassword} exact />
        <Route path={routesPaths.CONFIRM_RESET} component={PasswordResetConfirmPage} />
        <Redirect to={routesPaths.LOGIN} />
      </Switch>
      <LoginFooter />
    </>
  );
};
