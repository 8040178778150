import React from "react";
import { Select, Tooltip } from "antd";
import { sortByName } from "../../utils/helpers";
import PropTypes from "prop-types";
import "./_userDropdown.less";

export default function UsersDropdown({
  loading,
  value,
  allData,
  onChange,
  className,
  optionField,
  valueField,
  isDisabled,
  onBlur,
  placeholder,
  mode,
}) {
  return (
    <Select
      onBlur={onBlur}
      disabled={isDisabled}
      placeholder={placeholder || "Choose user"}
      showSearch
      mode={mode || ""}
      className={"user_dropdown " + className}
      loading={loading}
      onChange={onChange}
      filterOption={(value, data) => {
        if (data.children?.props?.children.toLowerCase().includes(value.toLowerCase())) return data.children;
      }}
      value={value}>
      {allData &&
        sortByName(allData, optionField).map((user, index) => {
          return (
            <Select.Option key={index} value={user[valueField]}>
              <Tooltip placement="topLeft" title={`${user.name} (${user.email})`}>
                {user[optionField]}
              </Tooltip>
            </Select.Option>
          );
        })}
    </Select>
  );
}

UsersDropdown.defaultProps = {
  loading: false,
  className: "",
};

UsersDropdown.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  allData: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
  optionField: PropTypes.string,
  valueField: PropTypes.string,
  isDisabled: PropTypes.bool,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  mode: PropTypes.string,
};
