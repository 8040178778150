import {
  UPDATE_SCRIPT_TEXT_FIELD,
  FETCH_ALL_DATA_REQUEST,
  FETCH_ALL_DATA_SUCCESS,
  ADD_NEW_OBJECTION,
  DELETE_OBJECTION,
  EDIT_OBJECTION,
  DELETE_REASON,
  EDIT_REASON,
  ADD_REASON,
  ADD_NEW_TAB,
  DELETE_TAB,
  EDIT_TAB,
  CHANGE_TYPE_OBJECTION,
  SAVE_ALL_STAGES_SUCCESS,
  SAVE_ALL_STAGES_REQUEST,
  FETCH_NEW_SCRIPT_REQUEST,
  FETCH_NEW_SCRIPT_SUCCESS,
  DELETE_SCRIPT,
  UPDATE_SCRIPT_NAME,
  SET_NEW_SCRIPT_TO_SELECT,
  SET_NEW_SCRIPT_STATUS,
  ADD_MOVED_SCRIPT,
  LOAD_NEW_OBJECTIONS,
  CHANGE_ORDER_TABS,
  ADD_NEW_QUESTION,
  EDIT_QUESTION,
  DELETE_QUESTION,
  UPDATE_QUESTION,
  ADD_GENERAL_QUESTION,
  UPDATE_GENERAL_QUESTION,
  DELETE_GENERAL_QUESTION,
  SET_CURRENT_QUESTION,
} from "../actions/scriptPageActions";

import { GET_AND_REPLACE_REQUEST, GET_AND_REPLACE_SUCCESS } from "../actions/scriptLoaderActions";

const initialState = {
  allDataLoading: true,
  isDataLoadingForm: false,
  allCompanies: [],
  allQuestions: [],
  currentQuestion: null,
  currentCompany: {},
  currentScript: {},
  allScripts: [],
  stages: [],
};

export const scriptPageReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case FETCH_ALL_DATA_REQUEST:
      return { ...state, allDataLoading: true };

    case FETCH_ALL_DATA_SUCCESS:
      const { allCompanies, allScripts, currentCompany, currentScript, stages, allQuestions } = payload;
      return {
        ...state,
        allQuestions,
        allCompanies,
        currentCompany,
        allScripts,
        allDataLoading: false,
        currentScript,
        stages,
      };

    case UPDATE_SCRIPT_TEXT_FIELD:
      const { currentPage, script } = payload;
      return { ...state, stages: updateValue(state.stages, currentPage, script, "script") };

    case ADD_NEW_OBJECTION:
      const objectionAddObject = {
        ...state.stages[payload.currentPage],
        objections: [
          ...state.stages[payload.currentPage].objections,
          { name: payload.name, script: "", type: payload.type || "" },
        ],
      };
      return {
        ...state,
        isSave: false,
        stages: updateObject(state.stages, payload.currentPage, objectionAddObject),
      };

    case DELETE_OBJECTION:
      const objectionDeleteObject = {
        ...state.stages[payload.currentPage],
        objections: state.stages[payload.currentPage].objections.filter((el, idx) => idx !== payload.objectionIdx),
      };
      return {
        ...state,
        isSave: false,
        stages: updateObject(state.stages, payload.currentPage, objectionDeleteObject),
      };

    case EDIT_OBJECTION:
      const objectionEditedObject = {
        ...state.stages[payload.currentPage],
        objections: [
          ...state.stages[payload.currentPage].objections.slice(0, payload.objectionIdx),
          {
            ...state.stages[payload.currentPage].objections[payload.objectionIdx],
            name:
              payload.name !== undefined
                ? payload.name
                : state.stages[payload.currentPage].objections[payload.objectionIdx].name,
            script:
              payload.script !== undefined
                ? payload.script
                : state.stages[payload.currentPage].objections[payload.objectionIdx].script,
          },
          ...state.stages[payload.currentPage].objections.slice(payload.objectionIdx + 1),
        ],
      };
      return {
        ...state,
        isSave: false,
        stages: updateObject(state.stages, payload.currentPage, objectionEditedObject),
      };

    case ADD_REASON:
      const reasonAddObject = {
        ...state.stages[payload.currentPage],
        endReasons: [
          ...state.stages[payload.currentPage].endReasons,
          { name: payload.name, category: payload.category },
        ],
      };
      return {
        ...state,
        isSave: false,
        stages: updateObject(state.stages, payload.currentPage, reasonAddObject),
      };

    case DELETE_REASON:
      const reasonDeleteObject = {
        ...state.stages[payload.currentPage],
        endReasons: state.stages[payload.currentPage].endReasons.filter((el, idx) => idx !== payload.reasonIdx),
      };
      return {
        ...state,
        isSave: false,
        stages: updateObject(state.stages, payload.currentPage, reasonDeleteObject),
      };

    case EDIT_REASON:
      const reasonEditedObject = {
        ...state.stages[payload.currentPage],
        endReasons: [
          ...state.stages[payload.currentPage].endReasons.slice(0, payload.reasonIdx),
          {
            ...state.stages[payload.currentPage].endReasons[payload.reasonIdx],
            name: payload.name,
            category: payload.category,
          },
          ...state.stages[payload.currentPage].endReasons.slice(payload.reasonIdx + 1),
        ],
      };

      return {
        ...state,
        isSave: false,
        stages: updateObject(state.stages, payload.currentPage, reasonEditedObject),
      };

    case ADD_NEW_TAB:
      return { ...state, stages: [...state.stages, payload] };

    case DELETE_TAB:
      return { ...state, stages: state.stages.filter((node) => node.id !== payload) };

    case EDIT_TAB:
      return {
        ...state,
        stages: [...state.stages.slice(0, payload.idx), { ...payload.stage }, ...state.stages.slice(payload.idx + 1)],
      };

    case CHANGE_TYPE_OBJECTION:
      const objectionUpdateType = {
        ...state.stages[payload.currentPage],
        objections: [
          ...state.stages[payload.currentPage].objections.slice(0, payload.selectedObjectionIdx),
          {
            ...state.stages[payload.currentPage].objections[payload.selectedObjectionIdx],
            type: payload.chosenType,
          },
          ...state.stages[payload.currentPage].objections.slice(payload.selectedObjectionIdx + 1),
        ],
      };

      return {
        ...state,
        stages: updateObject(state.stages, payload.currentPage, objectionUpdateType),
      };

    case SAVE_ALL_STAGES_REQUEST:
      return { ...state, allDataLoading: true };

    case SAVE_ALL_STAGES_SUCCESS:
      return { ...state, allDataLoading: false, stages: payload.stages };

    case FETCH_NEW_SCRIPT_REQUEST:
      return { ...state, isDataLoadingForm: true };

    case FETCH_NEW_SCRIPT_SUCCESS:
      //I set order manually here since the server sends me everything with order = 0
      return {
        ...state,
        isDataLoadingForm: false,
        stages: payload.stages.map((el, idx) => {
          return {
            ...el,
            order: idx,
          };
        }),
        currentScript: payload.currentScript,
      };

    case SET_NEW_SCRIPT_TO_SELECT:
      return { ...state, allScripts: [...state.allScripts, payload] };

    case DELETE_SCRIPT:
      return {
        ...state,
        allScripts: state.allScripts.filter((scriptItem) => scriptItem.id !== payload.id),
        currentScript: {},
        stages: [],
      };

    case UPDATE_SCRIPT_NAME:
      return {
        ...state,
        currentScript: {
          ...state.currentScript,
          name: payload.name,
          expectedCash: payload.expectedCash,
          expectedSale: payload.expectedSale,
          expectedDuration: payload.expectedDuration,
          saleType: payload.saleType,
        },
        allScripts: state.allScripts.map((el) => {
          if (el.id === payload.id) {
            return {
              ...el,
              name: payload.name,
              expectedCash: payload.expectedCash,
              expectedSale: payload.expectedSale,
              expectedDuration: payload.expectedDuration,
              saleType: payload.saleType,
            };
          } else {
            return el;
          }
        }),
      };

    case SET_NEW_SCRIPT_STATUS:
      return {
        ...state,
        currentScript: { ...state.currentScript, enabled: payload.enabled },
        allScripts: state.allScripts.map((el) => {
          if (el.id === payload.id) {
            return { ...el, enabled: payload.enabled };
          } else {
            return el;
          }
        }),
      };

    case ADD_MOVED_SCRIPT:
      const selectedCompanyIdx = state.allCompanies.findIndex((company) => company.id === Number(payload.companyId));
      const editedCompany = {
        ...state.allCompanies[selectedCompanyIdx],
        scripts: [...state.allCompanies[selectedCompanyIdx].scripts, payload],
      };
      return {
        ...state,
        allCompanies: updateObject(state.allCompanies, selectedCompanyIdx, editedCompany),
      };

    case GET_AND_REPLACE_REQUEST:
      return { ...state, isStageGetAndReplaceLoading: true };

    case GET_AND_REPLACE_SUCCESS:
      const replacedStage = state.stages[payload.currentPage];
      return {
        ...state,
        isStageGetAndReplaceLoading: false,
        stages: [
          ...state.stages.slice(0, payload.currentPage),
          { ...replacedStage, ...payload.overrideData },
          ...state.stages.slice(payload.currentPage + 1),
        ],
      };

    case CHANGE_ORDER_TABS:
      const newStageOrder = payload.newStagesOrder;
      return { ...state, stages: newStageOrder };

    case LOAD_NEW_OBJECTIONS:
      const objectionLoadObject = {
        ...state.stages[payload.currentPage],
        objections: [...state.stages[payload.currentPage].objections, ...payload.newObjection],
      };
      return {
        ...state,
        isSave: false,
        stages: updateObject(state.stages, payload.currentPage, objectionLoadObject),
      };

    case ADD_NEW_QUESTION:
      const questionAddObject = {
        ...state.stages[payload.currentPage],
        questions: [
          ...state.stages[payload.currentPage].questions,
          { name: payload.name, id: payload.id, companyId: payload.companyId, params: [] },
        ],
      };
      return {
        ...state,
        isSave: false,
        stages: updateObject(state.stages, payload.currentPage, questionAddObject),
      };

    case EDIT_QUESTION:
      const questionEditedObject = {
        ...state.stages[payload.currentPage],
        questions: [
          ...state.stages[payload.currentPage].questions.slice(0, payload.questionIdx),
          {
            ...state.stages[payload.currentPage].questions[payload.questionIdx],
            name: payload.name,
          },
          ...state.stages[payload.currentPage].questions.slice(payload.questionIdx + 1),
        ],
      };

      return {
        ...state,
        isSave: false,
        stages: updateObject(state.stages, payload.currentPage, questionEditedObject),
      };

    case DELETE_QUESTION:
      const questionDeleteObject = {
        ...state.stages[payload.currentPage],
        questions: state.stages[payload.currentPage].questions.filter((el, idx) => idx !== payload.questionIdx),
      };

      return {
        ...state,
        isSave: false,
        stages: updateObject(state.stages, payload.currentPage, questionDeleteObject),
      };

    case UPDATE_QUESTION:
      const questionUpdatedObject = {
        ...state.stages[payload.currentPage],
        questions: [
          ...state.stages[payload.currentPage].questions.slice(0, payload.questionIdx),
          payload.updatedQuestion,
          ...state.stages[payload.currentPage].questions.slice(payload.questionIdx + 1),
        ],
      };
      return {
        ...state,
        isSave: false,
        stages: updateObject(state.stages, payload.currentPage, questionUpdatedObject),
      };

    case ADD_GENERAL_QUESTION:
      return { ...state, allQuestions: [...state.allQuestions, payload] };

    case UPDATE_GENERAL_QUESTION:
      let updatedQuestions = [
        ...state.allQuestions.slice(0, payload.questionIdx),
        payload.updatedQuestion,
        ...state.allQuestions.slice(payload.questionIdx + 1),
      ];

      return { ...state, allQuestions: updatedQuestions };

    case DELETE_GENERAL_QUESTION:
      return {
        ...state,
        allQuestions: [...state.allQuestions.slice(0, payload), ...state.allQuestions.slice(payload + 1)],
      };

    case SET_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: payload,
      };

    default:
      return state;
  }
};

const updateValue = (pathValue, currentPage, newValue, field) => {
  return [
    ...pathValue.slice(0, currentPage),
    {
      ...pathValue[currentPage],
      [field]: newValue,
    },
    ...pathValue.slice(currentPage + 1),
  ];
};

const updateObject = (pathValue, currentPage, newObject) => {
  return [...pathValue.slice(0, currentPage), newObject, ...pathValue.slice(currentPage + 1)];
};
