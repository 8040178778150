import API from "../../services/apiService";

export const UPDATE_SCRIPT_TEXT_FIELD = "UPDATE_SCRIPT_TEXT_FIELD";
export const FETCH_ALL_DATA_REQUEST = "FETCH_ALL_DATA_REQUEST";
export const FETCH_ALL_DATA_SUCCESS = "FETCH_ALL_DATA_SUCCESS";
export const ADD_NEW_OBJECTION = "ADD_NEW_OBJECTION";
export const DELETE_OBJECTION = "DELETE_OBJECTION";
export const EDIT_OBJECTION = "EDIT_OBJECTION";
export const DELETE_REASON = "DELETE_REASON";
export const EDIT_REASON = "EDIT_REASON";
export const ADD_REASON = "ADD_REASON";
export const ADD_NEW_TAB = "ADD_NEW_TAB";
export const DELETE_TAB = "DELETE_TAB";
export const EDIT_TAB = "EDIT_TAB";
export const CHANGE_TYPE_OBJECTION = "CHANGE_TYPE_OBJECTION";
export const SAVE_ALL_STAGES_SUCCESS = "SAVE_ALL_STAGES_SUCCESS";
export const SAVE_ALL_STAGES_REQUEST = "SAVE_ALL_STAGES_REQUEST";
export const FETCH_NEW_SCRIPT_REQUEST = "FETCH_NEW_SCRIPT_REQUEST";
export const FETCH_NEW_SCRIPT_SUCCESS = "FETCH_NEW_SCRIPT_SUCCESS";
export const UPDATE_SCRIPT_NAME = "UPDATE_SCRIPT_NAME";
export const DELETE_SCRIPT = "DELETE_SCRIPT";
export const SET_NEW_SCRIPT_TO_SELECT = "SET_NEW_SCRIPT_TO_SELECT";
export const SET_NEW_SCRIPT_STATUS = "SET_NEW_SCRIPT_STATUS";
export const ADD_MOVED_SCRIPT = "ADD_MOVED_SCRIPT";
export const CHANGE_ORDER_TABS = "CHANGE_ORDER_TABS";
export const LOAD_NEW_OBJECTIONS = "LOAD_NEW_OBJECTIONS";
export const ADD_NEW_QUESTION = "ADD_NEW_QUESTION";
export const EDIT_QUESTION = "EDIT_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const ADD_GENERAL_QUESTION = "ADD_GENERAL_QUESTION";
export const UPDATE_GENERAL_QUESTION = "UPDATE_GENERAL_QUESTION";
export const DELETE_GENERAL_QUESTION = "DELETE_GENERAL_QUESTION";
export const SET_CURRENT_QUESTION = "SET_CURRENT_QUESTION";

export const setNewScriptAction = (currentScript) => async (dispatch) => {
  dispatch(setNewScriptRequest());
  const stages = await API.get(`stage/by-script/${currentScript.id}`);
  dispatch(setNewScriptSuccess({ currentScript, stages }));
};

const setNewScriptRequest = () => {
  return {
    type: FETCH_NEW_SCRIPT_REQUEST,
  };
};

const setNewScriptSuccess = (payload) => {
  return {
    type: FETCH_NEW_SCRIPT_SUCCESS,
    payload,
  };
};

export const setNewScriptToSelectAction = (payload) => {
  return {
    type: SET_NEW_SCRIPT_TO_SELECT,
    payload,
  };
};

export const updateScriptStatusAction = (payload) => {
  return {
    type: SET_NEW_SCRIPT_STATUS,
    payload,
  };
};

export const addMovedScriptAction = (payload) => {
  return {
    type: ADD_MOVED_SCRIPT,
    payload,
  };
};

export const updateScriptNameAction = (payload) => {
  return {
    type: UPDATE_SCRIPT_NAME,
    payload,
  };
};

export const deleteScriptAction = (payload) => {
  return {
    type: DELETE_SCRIPT,
    payload,
  };
};

export const setAllDataAction = (currentCompanyId, persistedCurrentScript) => async (dispatch) => {
  dispatch(setAllDataRequested());
  const allCompanies = await API.get(`company/all`);
  const currentCompany = allCompanies.find((el) => el.id === Number(currentCompanyId));
  const allScripts = currentCompany.scripts || [];
  let currentScript =
    persistedCurrentScript && currentCompany.id === Number(persistedCurrentScript.companyId)
      ? persistedCurrentScript
      : {};
  const allQuestions = currentCompany.questions;
  let stages;
  if (currentScript && currentScript.id) {
    stages = await API.get(`stage/by-script/${currentScript.id}`);
  } else {
    stages = [];
  }
  dispatch(
    setAllDataLoaded({
      allQuestions,
      allCompanies,
      currentCompany,
      allScripts,
      currentScript,
      stages,
    })
  );
};

const setAllDataRequested = () => {
  return {
    type: FETCH_ALL_DATA_REQUEST,
  };
};

const setAllDataLoaded = (payload) => {
  return {
    type: FETCH_ALL_DATA_SUCCESS,
    payload,
  };
};

export const scriptTextFieldUpdateAction = (payload) => {
  return {
    type: UPDATE_SCRIPT_TEXT_FIELD,
    payload,
  };
};

export const addNewObjectionAction = (payload) => {
  return {
    type: ADD_NEW_OBJECTION,
    payload,
  };
};

export const deleteObjectionAction = (payload) => {
  return {
    type: DELETE_OBJECTION,
    payload,
  };
};

export const editObjectionAction = (payload) => {
  return {
    type: EDIT_OBJECTION,
    payload,
  };
};

export const addNewReasonsAction = (payload) => {
  return {
    type: ADD_REASON,
    payload,
  };
};

export const deleteReasonAction = (payload) => {
  return {
    type: DELETE_REASON,
    payload,
  };
};

export const editReasonAction = (payload) => {
  return {
    type: EDIT_REASON,
    payload,
  };
};

export const addNewTabAction = (payload) => {
  return {
    type: ADD_NEW_TAB,
    payload,
  };
};

export const deleteTabAction = (payload) => {
  return {
    type: DELETE_TAB,
    payload,
  };
};

export const editTabAction = (payload) => {
  return {
    type: EDIT_TAB,
    payload,
  };
};

export const changeOrderTabsAction = (payload) => {
  return {
    type: CHANGE_ORDER_TABS,
    payload,
  };
};

export const changeTypeObjectionAction = (payload) => {
  return {
    type: CHANGE_TYPE_OBJECTION,
    payload,
  };
};

export const saveAllStagesAction = (allStages) => async (dispatch) => {
  dispatch(saveAllStagesRequest());
  const stages = await API.post("stage/createStage", allStages);
  dispatch(saveAllStagesSuccess({ stages }));
};

export const saveAllStagesRequest = (payload) => {
  return {
    type: SAVE_ALL_STAGES_REQUEST,
    payload,
  };
};

export const loadNewObjections = (payload) => {
  return {
    type: LOAD_NEW_OBJECTIONS,
    payload,
  };
};

export const saveAllStagesSuccess = (payload) => {
  return {
    type: SAVE_ALL_STAGES_SUCCESS,
    payload,
  };
};

export const addNewQuestionAction = (payload) => {
  return {
    type: ADD_NEW_QUESTION,
    payload,
  };
};

export const editQuestionAction = (payload) => {
  return {
    type: EDIT_QUESTION,
    payload,
  };
};

export const deleteQuestionAction = (payload) => {
  return {
    type: DELETE_QUESTION,
    payload,
  };
};

export const updateQuestionAction = (payload) => {
  return {
    type: UPDATE_QUESTION,
    payload,
  };
};

export const addGeneralQuestions = (payload) => {
  return {
    type: ADD_GENERAL_QUESTION,
    payload,
  };
};

export const updateGeneralQuestion = (payload) => {
  return {
    type: UPDATE_GENERAL_QUESTION,
    payload,
  };
};

export const deleteGeneralQuestion = (payload) => {
  return {
    type: DELETE_GENERAL_QUESTION,
    payload,
  };
};

export const setCurrentQuestion = (payload) => {
  return {
    type: SET_CURRENT_QUESTION,
    payload,
  };
};
