import React from "react";
import { useSelector } from "react-redux";

export default function withPermission(Component, DefaultComponent = null) {
  return function ComponentWithPermission(props) {
    const { roles = [] } = props;

    const loggedUser = useSelector((state) => state.common.user);

    if (roles.includes(loggedUser.role)) {
      return <Component {...props} />;
    }
    return DefaultComponent ? <DefaultComponent /> : null;
  };
}
