import React from "react";
import CallBreakdownKpi from "./CallBreakdownsKpi/CallBreakdownsKpi";
import CallBreakdownsPies from "./CallBreakdownsPies/CallBreakdonwsPies";
import CallBreakdownsGroupedChart from "./CallBreakdownsGroupedChart/CallBreakdownsGroupedChart";

function CallBreakdownsTab() {
  return (
    <div className="callBreakdownsTab">
      <CallBreakdownKpi />
      <CallBreakdownsPies />
      <CallBreakdownsGroupedChart />
    </div>
  );
}

export default CallBreakdownsTab;
