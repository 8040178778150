import React from "react";
import { authRoutes } from "./authRoutes/authRoutes";
import Layout from "antd/lib/layout";
import { useAuthHook } from "./hooks/authHook";
import { AuthContext } from "./context/authContext";
import { Spin } from "antd";
import "antd/dist/antd.css";
import "./app.less";

const App = () => {
  const { isAuth, login, logout, userInfo, ready } = useAuthHook();
  const routes = authRoutes(!!isAuth);

  if (!ready) {
    return (
      <div className="spinner">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ isAuth, login, logout, userInfo }}>
      <div className="wrapper">
        <Layout className="layout">{routes}</Layout>
      </div>
    </AuthContext.Provider>
  );
};

export default App;
