import { format } from "date-fns";
import { getCurrentUserTodayStats, transformDateForSafari } from "../../utils/helpers";
import { resetStatsAction } from "./homePageActions";
import { sortByDate, getStatsExceptToday } from "../../utils/helpers";
import API from "../../services/apiService";

const dateFormat = "yyyy-MM-dd";

export const SET_INITIAL_STATS_SUCCESS = "SET_INITIAL_STATS_SUCCESS";
export const SET_INITIAL_STATS_REQUEST = "SET_INITIAL_STATS_REQUEST";
export const SET_ACTIVE_DOT_ACTION = "SET_ACTIVE_DOT_ACTION";
export const CLEAR_STATS_INFO = "CLEAR_STATS_INFO";

export const UPDATE_STATS_SUCCESS = "UPDATE_STATS_SUCCESS";

export const updateTodayStatsAndResetStatsAction = (prevDaysStats) => async (dispatch) => {
  dispatch(resetStatsAction());

  let todayStats = getCurrentUserTodayStats();
  let isLocalStats = todayStats && Object.entries(todayStats).every((stat) => stat[1] === 0 || Array.isArray(stat[1]));
  if (isLocalStats) {
    todayStats =
      prevDaysStats.length &&
      prevDaysStats.find((stat) => {
        return format(new Date(stat.date), dateFormat) === format(new Date(), dateFormat);
      });
  }
  dispatch(updateStatsSuccess(todayStats));
};
export const updateStatsSuccess = (payload) => ({ type: UPDATE_STATS_SUCCESS, payload });

export const setInitialStatsAction = (activeDotId, history) => async (dispatch) => {
  const prevDaysStats = await API.get("metrics");
  const statsExceptToday = getStatsExceptToday(prevDaysStats);
  let activeDot = null;

  if (!activeDotId) {
    if (statsExceptToday && statsExceptToday.length) {
      activeDot = sortByDate(statsExceptToday, "date")[0];
      history.push(`stats-info?id=${activeDot.id}`);
    }
  } else {
    activeDot =
      statsExceptToday &&
      statsExceptToday.length &&
      statsExceptToday.find((stat) => {
        return stat.id === activeDotId;
      });
  }

  //-----SET TODAY STATS (FROM LOCAL STORAGE OR FROM DB IF IT CLEAR-----
  const todayLocalStats = getCurrentUserTodayStats();
  let isLocalStatsEmpty =
    todayLocalStats && Object.entries(todayLocalStats).every((stat) => stat[1] === 0 || Array.isArray(stat[1]));

  let todayStats;
  if (isLocalStatsEmpty) {
    todayStats = prevDaysStats.length
      ? prevDaysStats.find((stat) => {
          return format(new Date(transformDateForSafari(stat.date)), dateFormat) === format(new Date(), dateFormat);
        })
      : todayLocalStats;
  } else {
    todayStats = todayLocalStats;
  }

  dispatch(setInitialStatsSuccess({ prevDaysStats, todayStats, activeDot }));
};

export const setInitialStatsSuccess = (payload) => ({ type: SET_INITIAL_STATS_SUCCESS, payload });
export const setInitialStatsRequest = () => ({ type: SET_INITIAL_STATS_REQUEST });
export const clearStatsInfoAction = () => ({ type: CLEAR_STATS_INFO });
export const setActiveDotAction = (payload) => ({ type: SET_ACTIVE_DOT_ACTION, payload });
