import React from "react";
import { Col, Row } from "antd";
import StaffDetailReasonsStat from "./components/StaffDetailReasonsStat";
import StaffDetailTimelineStat from "./components/StaffDetailTimelineStat";

function StaffDetailLoadedContent() {
  return (
    <Row className="content" gutter={4}>
      <Col className="gutter-row" xs={24} sm={24} md={24} lg={12}>
        <StaffDetailReasonsStat />
      </Col>
      <Col className="gutter-row" xs={24} sm={24} md={24} lg={12}>
        <StaffDetailTimelineStat />
      </Col>
    </Row>
  );
}

export default StaffDetailLoadedContent;
