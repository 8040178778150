import React, { useState, useContext } from "react";
import { Dropdown, Layout, Menu, Button, Avatar } from "antd";
import { UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined, ContactsOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { menuHeaderProfileItems } from "../../utils/mocks";
import { toggleSliderMenuAction } from "../../redux/actions/commonActions";
import { AuthContext } from "../../context/authContext";
import API from "../../services/apiService";
import notificationAlert from "../../utils/notificationAlert";
import { useLocation } from "react-router";
import "./_header.less";

const { Header } = Layout;

const statsMessage = "System is configured to automatically collect your stats. Daily wrap up is not required";

const HeaderComponent = () => {
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();

  const signOutHandler = () => {
    auth.logout();
    setTimeout(() => {
      history.push("/login");
    }, 0);
  };

  const menuHandler = (item) => {
    if (item === "Sign out") {
      return signOutHandler();
    }
    history.push("stats-schedule");
  };

  const setActiveItem = (item) => {
    const urlPatch = history.location.pathname.slice(1);
    const itemPatch = item.patch;
    if (urlPatch === itemPatch) return "active";
  };

  const menu = () => {
    const icon = (item) =>
      item === "Sign out" ? <UserOutlined className="setting_icon" /> : <ContactsOutlined className="setting_icon" />;
    return (
      <Menu className="header_settings">
        {menuHeaderProfileItems.map((item, index) => {
          return (
            <Menu.Item
              onClick={() => menuHandler(item.title)}
              key={index}
              className={`item ${setActiveItem(item)}`}
              icon={icon(item.title)}>
              {item.title}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    dispatch(toggleSliderMenuAction());
  };

  //hide header on startScript page
  const setHeaderHidden = () => history.location.pathname.split("/").includes("startScript");

  const checkAutoCollect = async () => {
    const isAutoStatsCollect = await API.get("stats/collector-status");
    if (isAutoStatsCollect.stats_auto === "false") {
      history.push("/stats");
    } else {
      return notificationAlert({
        type: "info",
        duration: 3,
        description: statsMessage,
      });
    }
  };

  return (
    <Header className={`header ${setHeaderHidden() ? "hidden" : ""}`}>
      <Button onClick={toggleCollapsed} className="drawer_btn">
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
      </Button>
      <div className="header_navigation_btn">
        <Button className={`check_stats btn ${location.pathname === "/stats-info" ? "active" : ""}`}>
          <NavLink to={"/stats-info"}>Check stats</NavLink>
        </Button>
        <Button
          className={`daily_wrap btn ${location.pathname === "/stats" ? "active" : ""}`}
          onClick={checkAutoCollect}>
          Daily wrap up
        </Button>
        <Dropdown className="logout_tab" overlay={menu} trigger={["click"]} placement="bottomCenter">
          <Avatar className="user_avatar" icon={<UserOutlined />} />
        </Dropdown>
      </div>
    </Header>
  );
};

export default HeaderComponent;
