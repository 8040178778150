import React, { useEffect, useState } from "react";
import API from "../../../services/apiService";
import StatsScheduleDaysSection from "./StatsScheduleDaysSection";
import StatsScheduleConfigTimeSection from "./StatsScheduleConfigTimeSection";
import StatsScheduleSaveButton from "./StatsScheduleSaveButton";
import notificationAlert from "../../../utils/notificationAlert";
import { Col, Spin, Row } from "antd";
import PropTypes from "prop-types";
import axios from "axios";
import Multiselect from "../../../components/Multiselect/Multiselect";
import { sortByName, transformTimezone } from "../../../utils/helpers";
import { timezonesMapping } from "../../../utils/mocks";
import UsersDropdown from "../../../components/UsersDropdown/UsersDropdown";
import "./_statsSchedulePage.less";

const DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

function StatsScheduleTab({ companyUsers, currentCompany }) {
  const [days, setDays] = useState([]);
  const [currentScheduleUsers, setCurrentScheduleUsers] = useState([]);
  const [currentTimezone, setCurrentTimezone] = useState(null);

  const [currentHour, setCurrentHour] = useState(null);
  const [isDirty, setDirty] = useState({ hour: false, timezone: false });
  const [isLoading, setLoading] = useState(true);

  function getTimezoneOffset(timeZone) {
    const currentDate = new Date();
    const specificTimezone = currentDate.toLocaleString("en-US", { timeZone });
    const localTimezone = currentDate.toLocaleString("en-US");
    const timezoneDifference = (Date.parse(localTimezone) - Date.parse(specificTimezone)) / 3600000;
    const offset = timezoneDifference + currentDate.getTimezoneOffset() / 60;
    return -offset;
  }

  const mapTimezones = (timezones) => {
    return timezones.map((timezone) => {
      const offset = transformTimezone(getTimezoneOffset(timezone));
      return { label: `(${offset}) ${timezone}`, value: offset, name: timezone };
    });
  };

  const getTimezonesAndPrepare = () => {
    return mapTimezones(sortByName(timezonesMapping));
  };

  const setTime = (metrics) => {
    if (metrics.time === 0) return "24";
    return metrics.time < 10 ? "0" + metrics.time : String(metrics.time);
  };

  const resetMetrics = () => {
    setDays([]);
    setCurrentHour(null);
    setCurrentTimezone(currentCompany.timezoneName);
  };

  const setMetricsDependsOnUsers = (metrics) => {
    //If we have more than 1 user selected just reset params
    if (metrics) {
      setCurrentHour(setTime(metrics));
      setCurrentTimezone(metrics.timezoneName || currentCompany.timezoneName);
      const metricsDays = DAYS.reduce((acc, day) => {
        if (metrics[day.charAt(0).toLowerCase() + day.slice(1)]) {
          return acc.concat(day);
        }
        return acc.concat([]);
      }, []);
      setDays(metricsDays);
      return;
    }
    resetMetrics();
  };

  useEffect(() => {
    (async function getScheduleMetrics() {
      try {
        const metrics =
          currentScheduleUsers.length === 1
            ? await axios.get(`api/metrics/options/${currentScheduleUsers[0].id}`)
            : false;
        setMetricsDependsOnUsers(metrics.data);
      } catch (err) {
        const error = err.response;
        if (error.status === 404) {
          return resetMetrics();
        }
      }
    })();

    setLoading(false);
  }, [currentScheduleUsers]);

  const onDaysPickHandler = (days) => setDays(days);

  const pickTimezoneHandler = (timezone) => setCurrentTimezone(timezone);

  const pickHoursHandler = (hour) => setCurrentHour(hour);

  const saveStatsSchedule = async () => {
    setDirty({ hour: true, timezone: true });

    if (currentHour && currentTimezone) {
      const offset = transformTimezone(getTimezoneOffset(currentTimezone));
      await API.put("metrics/options", {
        timezoneName: currentTimezone,
        timezone: offset,
        userId: currentScheduleUsers.map((user) => user.id),
        time: Number(currentHour).toString(),
        ...DAYS.reduce((acc, n) => {
          acc[n.toLowerCase()] = false;
          return acc;
        }, {}),
        ...days.reduce((acc, n) => {
          acc[n.toLowerCase()] = true;
          return acc;
        }, {}),
      });
      notificationAlert({
        type: "success",
        description: "Stats schedule metrics has been successfully saved",
      });
    }
  };

  const chooseUserHandler = (userData) => {
    setLoading(true);
    const currentUsers = userData.map((user) => {
      return companyUsers.find((companyUser) => companyUser.id === user);
    });
    setDays([]);
    setCurrentTimezone(null);
    setCurrentScheduleUsers(currentUsers);
    setDirty({ hour: false, timezone: false });
  };

  const transformSelectedUsers = (users) => users.map((user) => user.id);

  if (isLoading) {
    return (
      <div className="spinner">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="stats_schedule__wrapper">
      <div className="schedule_config">
        <Row className="selectUser_wrapper">
          <Col xs={24} sm={24} md={12} lg={12} xl={5}>
            <span className="label">Users:</span>
            <UsersDropdown
              className={"full_width"}
              value={transformSelectedUsers(currentScheduleUsers)}
              allData={companyUsers || []}
              mode={"multiple"}
              valueField={"id"}
              optionField={"name"}
              onChange={chooseUserHandler}
            />
          </Col>
        </Row>
        {currentScheduleUsers.length ? (
          <>
            <Row className="selectUser_wrapper">
              <Col xs={24} sm={24} md={12} lg={12} xl={5}>
                <span className="label">Notify days:</span>
                <Multiselect
                  className={"multiselect"}
                  onChange={onDaysPickHandler}
                  placeholder="Select notify days"
                  options={DAYS}
                  sorted={false}
                  value={days}
                />
              </Col>
            </Row>
            <StatsScheduleConfigTimeSection
              isDirty={isDirty}
              setDirty={setDirty}
              timezones={getTimezonesAndPrepare(timezonesMapping)}
              currentTimezone={currentTimezone}
              currentHour={currentHour}
              pickTimezoneHandler={pickTimezoneHandler}
              pickHoursHandler={pickHoursHandler}
            />
            <StatsScheduleSaveButton saveStatsSchedule={saveStatsSchedule} />
          </>
        ) : null}
      </div>
    </div>
  );
}

StatsScheduleDaysSection.propTypes = {
  companyUsers: PropTypes.array,
  currentCompany: PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.number,
    name: PropTypes.string,
    attributes: PropTypes.array,
    consultants: PropTypes.array,
    funnels: PropTypes.array,
    performanceIndicators: PropTypes.array,
    questions: PropTypes.array,
    reportSettings: PropTypes.object,
    scripts: PropTypes.array,
    timezone: PropTypes.string,
    timezoneName: PropTypes.string,
    webhooks: PropTypes.array,
  }),
};

export default StatsScheduleTab;
