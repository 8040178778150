import React, { useState } from "react";
import TimelineComponent from "../../../components/TimelineComponent/TimelineComponent";
import { Card, Spin } from "antd";
import StaffDetailFilter from "./StaffDetailFilter";
import { useDispatch, useSelector } from "react-redux";
import StaffDetailTableModal from "./StaffDetailTableModal";
import { setCallTimelineFilters, updateCallTimeline } from "../../../redux/actions/callsInfoPageActions";

function StaffDetailTimelineStat() {
  const dispatch = useDispatch();
  const callTimeline = useSelector((state) => state.callsInfo.callTimeline);
  const callTimelineLoading = useSelector((state) => state.callsInfo.callTimelineLoading);
  const callTimelineFiltersActive = useSelector((state) => state.callsInfo.callTimelineFiltersActive);
  const callTimelineFilters = useSelector((state) => state.callsInfo.callTimelineFilters);
  const [isModalVisible, setModalVisible] = useState(false);
  const [currentActivity, setCurrentActivity] = useState(null);

  const getContent = () => {
    if (callTimelineLoading) {
      return (
        <div className="block_spinner">
          <Spin size="large" />
        </div>
      );
    }
    return (
      <TimelineComponent
        selectActivity={selectActivity}
        mode={"left"}
        data={getOnlyEndedCalls(callTimeline)}
        param={"endReason"}
        label={"date"}
      />
    );
  };

  const filterCallTimeline = (filters) => {
    const filtersObj =
      filters &&
      filters.length &&
      filters.map((filterName) => {
        return callTimelineFilters.find((reason) => reason.endReason === filterName);
      });
    dispatch(setCallTimelineFilters(filtersObj));
    dispatch(updateCallTimeline(new Date()));
  };

  const selectActivity = (activity) => {
    setCurrentActivity(activity);
    setModalVisible(true);
  };

  const closeActivityModal = () => setModalVisible(false);

  const transformFilters = (filters) => {
    return filters && filters.length && filters.map((filter) => filter.endReason);
  };

  const getOnlyEndedCalls = (calls) => {
    return calls && calls.length ? calls.filter((call) => call.duration) : [];
  };

  return (
    <>
      <div className="timeline_stat">
        <Card
          title="Call timeline"
          className="cards"
          extra={
            <div className="extra_container">
              <StaffDetailFilter
                value={transformFilters(callTimelineFiltersActive) || []}
                allData={callTimelineFilters}
                optionField={"endReason"}
                onChange={filterCallTimeline}
              />
            </div>
          }>
          {getContent()}
        </Card>
      </div>
      {isModalVisible ? (
        <StaffDetailTableModal
          currentActivity={currentActivity}
          isModalVisible={isModalVisible}
          closeActivityModal={closeActivityModal}
        />
      ) : null}
    </>
  );
}

export default StaffDetailTimelineStat;
