import API from "../../services/apiService";
import { isHavePermission } from "../../utils/helpers";
import { applicationRoles } from "../../utils/mocks";

const { ADMIN, CONSULTANT } = applicationRoles;

export const SET_ALL_INITIAL_DATA_REQUEST = "SET_ALL_INITIAL_DATA_REQUEST";
export const SET_ALL_INITIAL_DATA_SUCCESS = "SET_ALL_INITIAL_DATA_SUCCESS";
export const CHOOSE_CURRENT_COMPANY = "CHOOSE_CURRENT_COMPANY";
export const CHOOSE_CURRENT_SCRIPT = "CHOOSE_CURRENT_SCRIPT";
export const SET_STATS = "SET_STATS";
export const RESET_STATS = "RESET_STATS";
export const SET_PREV_DAY_REQUEST = "SET_PREV_DAY_REQUEST";
export const SET_PREV_DAY_SUCCESS = "SET_PREV_DAY_SUCCESS";

export const setPresDayStatsAsyncAction = () => async (dispatch) => {
  dispatch(setPrevDayRequestAction());
  const prevDaysStats = await API.get("metrics");
  dispatch(setPrevDaySuccessAction(prevDaysStats));
};

const setPrevDayRequestAction = () => {
  return {
    type: SET_PREV_DAY_REQUEST,
  };
};
const setPrevDaySuccessAction = (payload) => {
  return {
    type: SET_PREV_DAY_SUCCESS,
    payload,
  };
};

export const setAllInitialDataAction = () => async (dispatch) => {
  dispatch(setAllInitialDataRequest());
  const currentUser = await API.get("user/by-token");
  let allCompanies = [];
  let currentCompany;
  if (isHavePermission(currentUser.role, [ADMIN, CONSULTANT])) {
    allCompanies = await API.get("company/all");
    if (localStorage.getItem("currentCompanyName")) {
      currentCompany = allCompanies.find((company) => company.name === localStorage.getItem("currentCompanyName"));
    } else {
      currentCompany = null;
    }
  } else {
    currentCompany = currentUser.companyId ? await API.get(`company/${currentUser.companyId}`) : null;
  }

  const currentScriptName = localStorage.getItem("selectedScript");
  const allScripts = currentCompany && currentCompany.scripts ? currentCompany.scripts : [];
  let currentScript = {};
  if (currentScriptName) {
    currentScript =
      currentCompany && currentCompany.scripts
        ? currentCompany.scripts.find((script) => script.name === currentScriptName)
        : {};
  }

  dispatch(
    setAllInitialDataSuccess({
      allCompanies,
      currentCompany,
      currentUser,
      allScripts,
      currentScript,
    })
  );
};

const setAllInitialDataRequest = () => {
  return {
    type: SET_ALL_INITIAL_DATA_REQUEST,
  };
};

const setAllInitialDataSuccess = (payload) => {
  return {
    type: SET_ALL_INITIAL_DATA_SUCCESS,
    payload,
  };
};

export const chooseCurrentCompanyAction = (payload) => {
  return {
    type: CHOOSE_CURRENT_COMPANY,
    payload,
  };
};

export const chooseCurrentScriptAction = (payload) => {
  return {
    type: CHOOSE_CURRENT_SCRIPT,
    payload,
  };
};

export const setStatsAction = () => {
  return {
    type: SET_STATS,
  };
};

export const resetStatsAction = () => {
  return {
    type: RESET_STATS,
  };
};
