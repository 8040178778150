import React, { useEffect } from "react";
import { format } from "date-fns";
import StartScriptPanel from "./StartScriptPanel";
import DotsComponent from "../../components/DotsComponent/DotsComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllInitialDataAction,
  setStatsAction,
  setPresDayStatsAsyncAction,
} from "../../redux/actions/homePageActions";
import { getEnabledScriptsSelector, getEnabledCompaniesSelector } from "../../redux/selectors/homePageSelectors";
import { Spin } from "antd";
import Footer from "../../components/Footer/Footer";
import { useHistory } from "react-router";
import withCallClear from "../../hocks/withCallClear";
import { routes } from "../../utils/mocks";
import useActivePage from "../../hooks/useActivePage";
import "./_home.less";

const dateFormat = "yyyy-MM-dd";

function HomePage() {
  useActivePage(routes.HOME);
  const dispatch = useDispatch();
  const history = useHistory();

  const currentScript = useSelector((state) => state.home.currentScript);
  const currentCompany = useSelector((state) => state.home.currentCompany);
  const currentUser = useSelector((state) => state.home.currentUser);
  const allScripts = useSelector(getEnabledScriptsSelector);
  const allCompanies = useSelector(getEnabledCompaniesSelector);
  const isLoading = useSelector((state) => state.home.isLoading);

  const isPrevDayStatsLoading = useSelector((state) => state.home.isPrevDayStatsLoading);
  const prevDaysStats = useSelector((state) => state.home.prevDaysStats);

  useEffect(() => {
    dispatch(setAllInitialDataAction());
    dispatch(setPresDayStatsAsyncAction());
    dispatch(setStatsAction());
  }, []);

  const chooseDayAndRedirect = ({ selectedStat }) => {
    history.push(`stats-info?id=${selectedStat.id}`);
  };

  const getStatsExceptToday = (prevDayStats) => {
    return prevDayStats && prevDayStats.length
      ? prevDayStats.filter((stat) => {
          return stat.date !== format(new Date(), dateFormat);
        })
      : [];
  };

  return (
    <>
      <div className="home-page">
        {!isLoading ? (
          <>
            <h1 className="home-page__welcome-message">Hi, {currentUser.name}! Welcome to Script Sidekick!</h1>
            <StartScriptPanel
              currentUser={currentUser}
              allScripts={allScripts}
              allCompanies={allCompanies}
              currentScript={currentScript}
              currentCompany={currentCompany}
            />{" "}
          </>
        ) : (
          <div className="home-page__spinner">
            <Spin size="large" />
          </div>
        )}
        {isPrevDayStatsLoading || !prevDaysStats.length ? (
          <DotsComponent stats={getStatsExceptToday(prevDaysStats)} onChange={chooseDayAndRedirect} />
        ) : null}
      </div>
      <Footer />
    </>
  );
}

export default withCallClear(HomePage);
