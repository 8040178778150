import React from "react";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { clearMeetingPlanReducerAction } from "../../redux/actions/meetingsPlanActions";
import notificationAlert from "../../utils/notificationAlert";
import API from "../../services/apiService";
import { useDispatch, useSelector } from "react-redux";
import { getDateInMilliseconds } from "../../utils/helpers";
import { getUrlParam } from "../../utils/helpers";
import "./_meetingPlanPage.less";

function MeetingPlanBottomBtn() {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedTeamMembers = useSelector((state) => state.meetingPlan.selectedTeamMembers);
  const submitData = useSelector((state) => {
    return {
      date: getDateInMilliseconds(state.meetingPlan.date),
      planningNotes: state.meetingPlan.planningNotes,
      meetingNotes: state.meetingPlan.meetingNotes,
      actualGuidances: state.meetingPlan.actualGuidances,
      consultantId: state.meetingPlan.consultantId,
      status: state.meetingPlan.status,
      plannedGuidances: transformGuidanceDataFormat(state.meetingPlan.guidance),
      meetingMembers: state.meetingPlan.selectedTeamMembers,
    };
  });

  function transformGuidanceDataFormat(guidance) {
    let data = [];
    if (!Array.isArray(guidance)) return [];
    guidance.forEach((guidanceEntity) => {
      guidanceEntity &&
        guidanceEntity.new &&
        guidanceEntity.new
          .sort((a, b) => b.count - a.count)
          .slice(0, 1)
          .forEach((guidanceItem) => {
            data.push({
              userId: guidanceEntity.id,
              level: "new",
              previousGuidance: guidanceItem.text,
              count: guidanceItem.count,
            });
          });
      guidanceEntity &&
        guidanceEntity.ongoing &&
        guidanceEntity.ongoing
          .sort((a, b) => b.count - a.count)
          .slice(0, 1)
          .forEach((guidanceItem) => {
            data.push({
              userId: guidanceEntity.id,
              level: "ongoing",
              previousGuidance: guidanceItem.text,
              count: guidanceItem.count,
            });
          });
      guidanceEntity &&
        guidanceEntity.systemic &&
        guidanceEntity.systemic
          .sort((a, b) => b.count - a.count)
          .slice(0, 1)
          .forEach((guidanceItem) => {
            data.push({
              userId: guidanceEntity.id,
              level: "systemic",
              previousGuidance: guidanceItem.text,
              count: guidanceItem.count,
            });
          });
    });
    return data;
  }

  const saveMeetingPlan = async () => {
    const meetingId = getUrlParam(history.location.search, "id");
    if (meetingId) {
      await updateMeeting();
    } else {
      await addNewMeeting();
    }
    notificationAlert({
      type: "success",
      description: `Meeting has been successfully ${meetingId !== "null" ? "updated" : "added"}`,
    });
    history.push("/meetings");
  };

  const updateMeeting = async () => {
    const meetingId = getUrlParam(history.location.search, "id");
    await API.put(`meetings/${meetingId}`, submitData);
    dispatch(clearMeetingPlanReducerAction());
  };

  const addNewMeeting = async () => {
    await API.post("meetings", submitData);
    dispatch(clearMeetingPlanReducerAction());
  };

  const setSaveDisable = () => !submitData.date || !selectedTeamMembers.length;

  return (
    <div className="bottom_btn">
      <Button type="primary" className="btn">
        <Link to="meetings">Cancel</Link>
      </Button>
      <Tooltip placement="top" title={setSaveDisable() ? "Fill required fields at first" : ""}>
        <Button type="success" className="btn" disabled={setSaveDisable()} onClick={saveMeetingPlan}>
          Save
        </Button>
      </Tooltip>
    </div>
  );
}

export default MeetingPlanBottomBtn;
