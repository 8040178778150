import {
  SET_INITIAL_STATS_SUCCESS,
  SET_INITIAL_STATS_REQUEST,
  SET_ACTIVE_DOT_ACTION,
  CLEAR_STATS_INFO,
  UPDATE_STATS_SUCCESS,
} from "../actions/statsInfoActions";

const initialState = {
  todayStats: null,
  activeDot: null,
  prevDaysStats: [],
  isDataLoading: true,
};

export const statsInfoReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case SET_INITIAL_STATS_SUCCESS:
      return {
        ...state,
        todayStats: payload.todayStats,
        activeDot: payload.activeDot,
        prevDaysStats: payload.prevDaysStats,
        isDataLoading: false,
      };
    case SET_INITIAL_STATS_REQUEST:
      return { ...state, isDataLoading: true };

    case SET_ACTIVE_DOT_ACTION:
      return { ...state, activeDot: payload.activeDot };

    case UPDATE_STATS_SUCCESS:
      return { ...state, todayStats: payload };

    case CLEAR_STATS_INFO:
      return initialState;

    default:
      return state;
  }
};
