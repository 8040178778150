import React, { useState } from "react";
import SelectComponent from "../../../components/Select/Select";
import { useDispatch } from "react-redux";
import { loadNewObjections } from "../../../redux/actions/scriptPageActions";
import PropTypes from "prop-types";
import "../_script-page.less";

export default function ScriptObjectionStageLoader({ allObjections, currentPage }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState("Load stages from");

  const objectionLoadHandler = (chosenObjection) => {
    setValue(chosenObjection);
    const chosenStage = allObjections.find((el) => el.name === chosenObjection);
    const loadedObjections = chosenStage.objections.map(({ id, ...rest }) => rest);
    const currentObjections = allObjections[currentPage].objections;
    const newFilteredObjections = loadedObjections.filter(
      (loadedObjection) => !currentObjections.some((currentObjection) => currentObjection.name === loadedObjection.name)
    );
    dispatch(loadNewObjections({ newObjection: newFilteredObjections, currentPage }));
  };

  return (
    <div className="stage__loader">
      <SelectComponent
        mainField="name"
        data={allObjections.filter((objection) => objection.name !== allObjections[currentPage].name)}
        value={value}
        handleChange={(item) => objectionLoadHandler(item)}
      />
    </div>
  );
}

ScriptObjectionStageLoader.propTypes = {
  allObjections: PropTypes.array,
  currentPage: PropTypes.number,
};
