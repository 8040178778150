import React from "react";
import { Tabs } from "antd";
import { EmployeeSummaryTab, CallBreakdownsTab } from "../DashboardTabPages";
import usePersistedState from "../../../hooks/usePersistedState";

const DASHBOARD_TABS = [
  { name: "Employee summary", component: <EmployeeSummaryTab /> },
  { name: "Call breakdowns", component: <CallBreakdownsTab /> },
];

function DashboardTabs() {
  const [activeTab, setActiveTab] = usePersistedState("activeTab", "0");

  const changeActiveTab = (tabIndex) => setActiveTab(tabIndex);

  return (
    <Tabs activeKey={activeTab} onChange={changeActiveTab}>
      {DASHBOARD_TABS.map((tab, tabIndex) => {
        return (
          <Tabs.TabPane tab={tab.name} key={tabIndex}>
            {activeTab === String(tabIndex) ? DASHBOARD_TABS[activeTab].component : null}
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
}

export default DashboardTabs;
