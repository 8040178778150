import { Form, Select } from "antd";
import { sortByName } from "../../../utils/helpers";
import PropTypes from "prop-types";

const { Option } = Select;

function FormWithSelect({ onSubmit, currentValue, data, buttonTitle, placeholder, name, onCancel }) {
  const [form] = Form.useForm();

  const formSubmit = () => form.validateFields().then((data) => onSubmit(data));

  return (
    <Form form={form} name="basic" initialValues={{ [name]: currentValue }} onFinish={onSubmit} autoComplete="off">
      <Form.Item name={name} rules={[{ required: true, message: "This field is required" }]}>
        <Select placeholder={placeholder}>
          {data.length &&
            sortByName(data, "name").map((script, index) => {
              return (
                <Option key={index} value={script.name}>
                  {script.name}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
      <div className="ant-modal-footer" style={{ marginLeft: "-22px", marginRight: "-22px", paddingRight: "22px" }}>
        <button type="button" className="ant-btn" onClick={onCancel}>
          <span>Cancel</span>
        </button>
        <button type="button" className="ant-btn ant-btn-primary" onClick={formSubmit}>
          <span>{buttonTitle ? buttonTitle : "OK"}</span>
        </button>
      </div>
    </Form>
  );
}

FormWithSelect.propTypes = {
  onSubmit: PropTypes.func,
  currentValue: PropTypes.string,
  data: PropTypes.array,
  buttonTitle: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onCancel: PropTypes.func,
};

export default FormWithSelect;
