import React, { useState } from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { addActualGuidanceAction } from "../../../redux/actions/meetingsPlanActions";
import AddGuidanceModal from "./AddGuidanceModal";
import "../_meetingPlanPage.less";

function MeetingPlanConsultantGuidanceActions() {
  const dispatch = useDispatch();
  const [isGuidanceModalOpen, setGuidanceModalOpen] = useState(false);

  const handleGuidanceModal = () => setGuidanceModalOpen(!isGuidanceModalOpen);
  const addGuidance = (data) => dispatch(addActualGuidanceAction(data));

  return (
    <div className="guidance_actions">
      <Button type="primary" onClick={handleGuidanceModal}>
        Add guidance
      </Button>
      {isGuidanceModalOpen ? (
        <AddGuidanceModal
          title={"Add guidance"}
          isOpen={isGuidanceModalOpen}
          handleGuidanceModal={handleGuidanceModal}
          updateGuidance={addGuidance}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default MeetingPlanConsultantGuidanceActions;
