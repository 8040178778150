import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table } from "antd";
import tableConfig from "./tableConfig";
import {
  setCurrentPageAction,
  setInitialMeetingsData,
  setSortParamAction,
  meetingsUpdateAction,
  updateMeetingFilters,
  meetingsUpdateActionWithReset,
  updateFiltersFromParam,
} from "../../../redux/actions/meetingsPageAction";
import { useHistory } from "react-router";
import API from "../../../services/apiService";
import { changeSortDirectionTitle } from "../../../utils/helpers";
import { getMeetingsTableTransformed } from "../../../redux/selectors/meetingsSelectors";
import { clearMeetingPlanReducerAction } from "../../../redux/actions/meetingsPlanActions";
import notificationAlert from "../../../utils/notificationAlert";
import Popup from "../../../components/Popup/Popup";
import DeleteForm from "../../LeadsPage/LeadForm/DeleteForm";
import { useLocation } from "react-router";

const pageSizeOptions = ["10", "20", "50", "100"];
const paginationPosition = "bottomCenter";

function MeetingsTable({ meetings, tableSettings, isTableDataLoading, updateAction, filters, dispatch, teamMembers }) {
  const history = useHistory();
  const location = useLocation();
  const { pageSize, totalCount, currentPage, sortedDirection, sortedField } = tableSettings;
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeMeetingId, setActiveMeetingId] = useState(null);

  useEffect(() => {
    if (location.search) {
      dispatch(updateFiltersFromParam(location.search));
    }
    dispatch(setInitialMeetingsData());
  }, [updateAction]);

  const getSearchUrl = ({ s_direction, s_field, f_dateAfter, f_dateBefore, f_userId, page, pageSize }) => {
    const sField = s_field ? "&s_field=" + s_field : "";
    const sDirection = s_direction ? "&s_direction=" + s_direction : "";
    const dateAfter = f_dateAfter ? "&f_dateAfter=" + f_dateAfter : "";
    const dateBefore = f_dateBefore ? "&f_dateBefore=" + f_dateBefore : "";
    const usersId = f_userId && f_userId.length ? f_userId.map((id) => "&f_userId[]=" + id).join("") : "";
    const currentPage = page ? "&currentPage=" + page : "";
    const pageSizeOption = pageSize ? "&pageSize=" + pageSize : "";
    let res = `?${currentPage}${pageSizeOption}${sField}${sDirection}${dateAfter}${dateBefore}${usersId}`;
    return res[1] === "&" ? res.substring(0, 1) + res.substring(2, res.length) : res;
  };

  const setMeetingAction = (actionType, record) => {
    if (actionType === "delete") {
      setActiveMeetingId(record);
      return setModalOpen(true);
    } else if (actionType === "edit") {
      return editMeeting(record);
    }
  };

  const onModalClose = () => {
    setModalOpen(false);
    setActiveMeetingId(null);
  };

  const deleteMeeting = async () => {
    await API.delete(`meetings/${activeMeetingId}`);
    notificationAlert({
      type: "success",
      description: "Meeting has been successfully deleted",
    });
    dispatch(meetingsUpdateAction(new Date()));
    setModalOpen(false);
  };

  const editMeeting = (meetingId) => {
    dispatch(clearMeetingPlanReducerAction());
    history.push(`meeting-plan?id=${meetingId}`);
  };

  const updateFilters = (filters) => {
    history.push({
      pathname: "/meetings",
      search: getSearchUrl({
        s_field: sortedField,
        s_direction: sortedDirection,
        f_dateAfter: filters.dateAfter,
        f_dateBefore: filters.dateBefore,
        f_userId: filters.userId,
        page: currentPage,
        pageSize: pageSize,
      }),
    });
    dispatch(
      updateMeetingFilters({
        f_dateAfter: filters.dateAfter,
        f_dateBefore: filters.dateBefore,
      })
    );
    dispatch(meetingsUpdateActionWithReset(new Date().getMilliseconds()));
  };

  const updateUserFilter = (filter) => {
    history.push({
      pathname: "/meetings",
      search: getSearchUrl({
        s_field: sortedField,
        s_direction: sortedDirection,
        dateAfter: filters.dateAfter,
        dateBefore: filters.dateBefore,
        f_userId: filter.searchText,
        page: currentPage,
        pageSize: pageSize,
      }),
    });
    dispatch(updateMeetingFilters({ userId: filter.searchText }));
    dispatch(meetingsUpdateActionWithReset(new Date().getMilliseconds()));
  };

  const { getData, getColumns } = tableConfig(
    meetings,
    sortedField,
    sortedDirection,
    setMeetingAction,
    filters,
    updateFilters,
    updateUserFilter,
    teamMembers
  );

  const onTableChange = (pagination, _, sorter, extra) => {
    if (extra.action === "paginate") {
      history.push({
        pathname: "/meetings",
        search: getSearchUrl({
          s_field: sorter.field,
          s_direction: changeSortDirectionTitle(sorter.order),
          f_dateAfter: filters.dateAfter,
          f_dateBefore: filters.dateBefore,
          f_userId: filters.userId,
          page: pagination.current,
          pageSize: pagination.pageSize,
        }),
      });
      dispatch(setCurrentPageAction({ currentPage: pagination.current, pageSize: pagination.pageSize }));
      setTimeout(() => {
        dispatch(meetingsUpdateAction(new Date()));
        window.scrollTo({ top: 0, behavior: "auto" });
      }, 200);
    } else if (extra.action === "sort") {
      history.push({
        pathname: "/meetings",
        search: getSearchUrl({
          s_field: sorter.field,
          s_direction: changeSortDirectionTitle(sorter.order),
          f_dateAfter: filters.dateAfter,
          f_dateBefore: filters.dateBefore,
          f_userId: filters.userId,
          page: currentPage,
          pageSize: pageSize,
        }),
      });
      dispatch(
        setSortParamAction({
          sortedField: sorter.field,
          direction: changeSortDirectionTitle(sorter.order),
        })
      );
      dispatch(meetingsUpdateAction(new Date()));
    }
  };

  return (
    <>
      <Table
        className="meeting-table"
        data-testid="meetingTable"
        loading={isTableDataLoading}
        scroll={{ x: "auto" }}
        sticky={{ offsetHeader: 63 }}
        pagination={{
          pageSize: Number(pageSize),
          current: Number(currentPage),
          pageSizeOptions: pageSizeOptions,
          total: totalCount,
          showSizeChanger: true,
          position: [paginationPosition],
        }}
        size="small"
        columns={getColumns}
        dataSource={getData}
        bordered={true}
        onChange={onTableChange}
      />
      {(isModalOpen && (
        <Popup
          title={"Delete"}
          isModalVisible={isModalOpen}
          footer={null}
          className={"without_footer"}
          modalCancel={onModalClose}>
          <DeleteForm
            onCancel={onModalClose}
            onSubmit={deleteMeeting}
            title={"Are you sure you want to delete meeting?"}
          />
        </Popup>
      )) ||
        null}
    </>
  );
}

function mapStateToProps(state) {
  const { tableSettings, isTableDataLoading, updateAction, filters, teamMembers } = state.meetings;
  return {
    meetings: getMeetingsTableTransformed(state),
    tableSettings,
    isTableDataLoading,
    updateAction,
    filters,
    teamMembers,
  };
}

export default connect(mapStateToProps)(MeetingsTable);
