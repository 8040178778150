export const END_REASONS_COLOR_MAPPING = {
  Voicemail: {
    color: "#dbabab",
    labelColor: "white",
  },
  "Wrong Number": {
    color: "#db4d4d",
    labelColor: "black",
  },
  "Not Qualified": {
    color: "#e3b37d",
    labelColor: "white",
  },
  "No Answer": {
    color: "#cfe37d",
    labelColor: "black",
  },
  "They hung up": {
    color: "#a57ade",
    labelColor: "white",
  },
  /* 'Call not ended': {
    color: '#687cc4',
    labelColor: 'white',
  },*/
  No: {
    color: "#b3abab",
    labelColor: "white",
  },
  Yes: {
    color: "#7bd1ad",
    labelColor: "white",
  },
  "Not Interested": {
    color: "#76a4cf",
    labelColor: "white",
  },
  "Call me back to discuss": {
    color: "#80d9d9",
    labelColor: "white",
  },
  "Call Drop": {
    color: "#b3abab",
    labelColor: "white",
  },
  Reschedule: {
    color: "#82de81",
    labelColor: "white",
  },
  Disconnect: {
    color: "#b3abab",
    labelColor: "white",
  },
  "Takeaway close": {
    color: "#b3abab",
    labelColor: "white",
  },
  Time: {
    color: "#b3abab",
    labelColor: "white",
  },
  Money: {
    color: "#b3abab",
    labelColor: "white",
  },
  Partner: {
    color: "#b3abab",
    labelColor: "white",
  },
  Info: {
    color: "#b3abab",
    labelColor: "white",
  },
  Trust: {
    color: "#b3abab",
    labelColor: "white",
  },
  Other: {
    color: "#b3abab",
    labelColor: "white",
  },
};

export const VISIBLE_END_REASONS = [
  "Voicemail",
  "Wrong Number",
  "Not Qualified",
  "No Answer",
  "They hung up",
  "Yes",
  "Not Interested",
  "Call me back to discuss",
];

export const OTHER_END_REASONS = [
  "No",
  "Call Drop",
  "Reschedule",
  "Disconnect",
  "Takeaway close",
  "Time",
  "Money",
  "Partner",
  "Info",
  "Trust",
];
