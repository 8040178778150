import {
  SET_INITIAL_MEETINGS_DATA_SUCCESS,
  SET_INITIAL_MEETINGS_DATA_REQUEST,
  SET_CURRENT_PAGE_ACTION,
  SET_SORT_PARAM_ACTION,
  MEETINGS_UPDATE_ACTION,
  UPDATE_MEETING_FILTERS,
  MEETINGS_UPDATE_ACTION_WITH_RESET,
  CLEAR_MEETINGS_REDUCER,
} from "../actions/meetingsPageAction";

export const initialState = {
  meetings: [],
  teamMembers: [],
  isTableDataLoading: true,
  updateAction: "",
  tableSettings: {
    pageSize: 10,
    currentPage: 1,
    totalCount: 0,
    sortedField: "date",
    sortedDirection: "DESC",
  },
  filters: { dateAfter: "", dateBefore: "", userId: [] },
};

export const meetingsPageReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case SET_INITIAL_MEETINGS_DATA_REQUEST:
      return {
        ...state,
        isTableDataLoading: true,
      };
    case SET_INITIAL_MEETINGS_DATA_SUCCESS:
      return {
        ...state,
        meetings: payload.meetings,
        tableSettings: { ...state.tableSettings, totalCount: payload.totalCount },
        isTableDataLoading: false,
        teamMembers: payload.teamMembers,
      };

    case SET_CURRENT_PAGE_ACTION:
      return {
        ...state,
        tableSettings: {
          ...state.tableSettings,
          currentPage: payload.currentPage,
          pageSize: payload.pageSize,
        },
      };

    case SET_SORT_PARAM_ACTION:
      return {
        ...state,
        tableSettings: {
          ...state.tableSettings,
          sortedField: payload.sortedField,
          sortedDirection: payload.direction,
        },
      };

    case MEETINGS_UPDATE_ACTION_WITH_RESET:
      return {
        ...state,
        updateAction: payload,
        tableSettings: { ...initialState.tableSettings },
      };

    case MEETINGS_UPDATE_ACTION:
      return {
        ...state,
        updateAction: payload,
      };

    case UPDATE_MEETING_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...payload },
      };

    case CLEAR_MEETINGS_REDUCER:
      return initialState;

    default:
      return state;
  }
};
