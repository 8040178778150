import API from "../../services/apiService";
import { transformDataGridForScriptLoader, removeProp } from "../../utils/helpers";

export const SET_GRID_DATA_REQUEST = "SET_GRID_DATA_REQUEST";
export const SET_GRID_DATA_SUCCESS = "SET_GRID_DATA_SUCCESS";
export const REPLACE_STAGE_ACTION = "REPLACE_STAGE_ACTION";
export const GET_AND_REPLACE_REQUEST = "GET_AND_REPLACE_REQUEST";
export const GET_AND_REPLACE_SUCCESS = "GET_AND_REPLACE_SUCCESS";
export const GET_AND_REPLACE_OVERRIDE_SUCCESS = "GET_AND_REPLACE_OVERRIDE_SUCCESS";
export const SCRIPT_OVERRIDE = "SCRIPT_OVERRIDE";
export const CLOSE_OVERRIDE_MODAL = "CLOSE_OVERRIDE_MODAL";
export const CANCEL_OVERRIDE_MODAL = "CANCEL_OVERRIDE_MODAL";

export const setGridDataAction = () => async (dispatch) => {
  dispatch(setGridDataRequest());
  const data = await transformDataGridForScriptLoader();
  dispatch(setGridDataSuccess(data));
};

const setGridDataRequest = () => {
  return {
    type: SET_GRID_DATA_REQUEST,
  };
};

const setGridDataSuccess = (payload) => {
  return {
    type: SET_GRID_DATA_SUCCESS,
    payload,
  };
};

export const replaceStageAction = (payload) => {
  return {
    type: REPLACE_STAGE_ACTION,
    payload,
  };
};

export const replaceStageWithCurrentAction = (stageId, currentPage) => async (dispatch) => {
  dispatch(replaceStageWithCurrentRequest());
  const selectedStage = await API.get(`stage/${stageId}`);
  const objections = removeProp(selectedStage.objections, "id");
  const endReasons = removeProp(selectedStage.endReasons, "id");
  const isStagesAndReason = objections.length || endReasons.length;
  if (isStagesAndReason) {
    dispatch(
      replaceStageWithCurrentOverrideSuccess({
        script: selectedStage.script,
        objections,
        endReasons,
      })
    );
  } else {
    dispatch(
      replaceStageWithCurrentSuccess({
        script: selectedStage.script,
        objections,
        endReasons,
        currentPage,
      })
    );
  }
};

const replaceStageWithCurrentRequest = () => {
  return {
    type: GET_AND_REPLACE_REQUEST,
  };
};

export const replaceStageWithCurrentSuccess = (payload) => {
  return {
    type: GET_AND_REPLACE_SUCCESS,
    payload,
  };
};

const replaceStageWithCurrentOverrideSuccess = (payload) => {
  return {
    type: GET_AND_REPLACE_OVERRIDE_SUCCESS,
    payload,
  };
};

export const scriptOverrideAction = (payload) => {
  return {
    type: SCRIPT_OVERRIDE,
    payload,
  };
};

export const closeOverrideModalAction = () => {
  return {
    type: CLOSE_OVERRIDE_MODAL,
  };
};

export const cancelOverrideModalAction = () => {
  return {
    type: CANCEL_OVERRIDE_MODAL,
  };
};
