import React from "react";
import { PageHeader, Select, Button, Typography } from "antd";
import PropTypes from "prop-types";
import TimezoneDropdown from "../../components/TimezoneDropdown/TimezoneDropdown";
import { sortByName, transformTimezone } from "../../utils/helpers";
import { timezonesMapping } from "../../utils/mocks";

const dateViewRange = [
  { value: 7, label: "7 Days" },
  { value: 14, label: "14 Days" },
  { value: 30, label: "30 Days" },
  { value: 60, label: "60 Days" },
  { value: 90, label: "90 Days" },
];

export default function StatsViewerRangeConfig({
  handleRangeChange,
  onChangeTimezone,
  dayRange,
  resetSettings,
  timezone,
}) {
  function getTimezoneOffset(timeZone) {
    const currentDate = new Date();
    const specificTimezone = currentDate.toLocaleString("en-US", { timeZone });
    const localTimezone = currentDate.toLocaleString("en-US");
    const timezoneDifference = (Date.parse(localTimezone) - Date.parse(specificTimezone)) / 3600000;
    const offset = timezoneDifference + currentDate.getTimezoneOffset() / 60;
    return -offset;
  }

  const mapTimezones = (timezones) => {
    return timezones.map((timezone) => {
      const offset = transformTimezone(getTimezoneOffset(timezone));
      return { label: `(${offset}) ${timezone}`, value: offset, name: timezone };
    });
  };

  const getTimezonesAndPrepare = () => mapTimezones(sortByName(timezonesMapping));

  return (
    <div className="stats_rangeConfig">
      <PageHeader
        className={"pageHeader"}
        title={
          <Typography.Title className="title" level={2}>
            Stats viewer
          </Typography.Title>
        }
        extra={[
          <TimezoneDropdown
            timezones={getTimezonesAndPrepare(timezonesMapping)}
            className={"statsViewerTimezoneDropdown"}
            key="timezone"
            onChange={onChangeTimezone}
            value={timezone}
          />,
          <b key="title" className="range_title">
            Load date range:
          </b>,
          <Select
            data-testid="rangeSelect"
            key="select"
            className="select"
            placeholder="Select date"
            value={dayRange}
            onChange={handleRangeChange}
            options={dateViewRange}
          />,
          <Button key="button" className="btn" type="primary" onClick={resetSettings}>
            Reset
          </Button>,
        ]}
      />
    </div>
  );
}

StatsViewerRangeConfig.propTypes = {
  handleRangeChange: PropTypes.func,
  dayRange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resetSettings: PropTypes.func,
  timezone: PropTypes.string,
  onChangeTimezone: PropTypes.func,
};
