import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addGeneralQuestions,
  updateGeneralQuestion,
  deleteGeneralQuestion,
  setCurrentQuestion,
} from "../../../redux/actions/scriptPageActions";
import ModalGroup from "../../../components/ModalGroup/ModalGroup";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import API from "../../../services/apiService";
import PropTypes from "prop-types";
import notificationAlert from "../../../utils/notificationAlert";
import SelectComponent from "../../../components/Select/Select";
import { transformFirstUpper } from "../../../utils/helpers";
import "../_script-page.less";

const questionTypes = [{ name: "Text" }, { name: "Options" }];

export default function ScriptQuestionPanel({ allQuestions, currentQuestion }) {
  const dispatch = useDispatch();
  const currentCompany = useSelector((state) => state.script.currentCompany);
  const [modalMode, setModalMode] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);

  const onModalCancel = () => {
    setModalVisible(false);
    setModalMode("");
  };

  const onModalOpen = (mode) => {
    setModalVisible(true);
    setModalMode(mode);
  };

  const createQuestion = async ({ name, questionType, isShown }) => {
    questionType = transformFirstUpper(questionType, "lower");
    const companyId = currentCompany.id;
    const updateObject = { name, questionType, isShown, companyId };
    const newQuestion = await API.post("question/create", updateObject);
    dispatch(setCurrentQuestion(newQuestion));
    dispatch(addGeneralQuestions(newQuestion));
    notificationAlert({
      type: "success",
      description: "Question has been successfully created",
    });
  };

  const deleteQuestion = async () => {
    const questionIdx = allQuestions.findIndex((item) => item.name === currentQuestion.name);
    await API.delete(`question/${currentQuestion.id}`);
    dispatch(setCurrentQuestion(null));
    dispatch(deleteGeneralQuestion(questionIdx));
    notificationAlert({
      type: "success",
      description: "Question has been successfully deleted",
    });
  };

  const editQuestion = async ({ name, questionType, isShown }) => {
    questionType = transformFirstUpper(questionType, "lower");
    const companyId = currentCompany.id;
    const questionIdx = allQuestions.findIndex((item) => item.name === currentQuestion.name);
    dispatch(setCurrentQuestion({ ...currentQuestion, name, questionType, isShown }));

    const updatedQuestion = await API.patch(`question/${currentQuestion.id}`, {
      name: name,
      isShown,
      questionType,
      companyId,
    });

    dispatch(updateGeneralQuestion({ questionIdx, updatedQuestion }));
    notificationAlert({
      type: "success",
      description: "Question has been successfully changed",
    });
  };

  const changeQuestionHandler = (name) => {
    const selectedQuestion = allQuestions.find((el) => el.name === name);
    dispatch(setCurrentQuestion(selectedQuestion));
  };

  const modalSubmitOk = async (value) => {
    if (modalMode === "add") {
      await createQuestion(value);
    } else if (modalMode === "delete") {
      await deleteQuestion();
    } else if (modalMode === "edit") {
      await editQuestion(value);
    }
    setModalVisible(false);
  };

  const modals = [
    {
      title: "Delete",
      text: `Delete «${currentQuestion && currentQuestion.name}» question?`,
      type: "delete",
      isVisible: isModalVisible,
    },
    { title: "Edit", text: "Edit question", type: "edit", isVisible: isModalVisible },
    { title: "Add", text: "Add question", type: "add", isVisible: isModalVisible },
  ];

  const buttons = [
    { name: "Create", action: () => onModalOpen("add"), visible: true },
    { name: "Edit", action: () => onModalOpen("edit"), visible: !!setCurrentQuestion },
    { name: "Delete", action: () => onModalOpen("delete"), visible: !!setCurrentQuestion },
  ];

  const modalComponentsData = useCallback(() => {
    return [
      {
        name: "name",
        placeholder: "Type question name",
        elementType: "input",
        maxLength: 150,
        checkDuplicate: true,
      },
      {
        name: "questionType",
        placeholder: "Select question type",
        elementType: "select",
        elementList: questionTypes,
      },
      {
        name: "isShown",
        placeholder: "Show question at the end of the call?",
        elementType: "checkbox",
        notRequired: true,
      },
    ];
  }, [modalMode]);

  return (
    <div className="questions-section">
      <h3 className="title">Questions:</h3>
      <div className="select_wrapper">
        <SelectComponent
          mainField="name"
          data={allQuestions}
          value={currentQuestion && currentQuestion.name}
          handleChange={changeQuestionHandler}
        />
      </div>
      <ButtonGroup width={185} data={buttons} isBtnVisible={false} />
      {isModalVisible ? (
        <ModalGroup
          editableItemIdx={""}
          mainField="name"
          allItems={allQuestions}
          mode={modalMode}
          modals={modals}
          modalComponentsData={modalComponentsData()}
          onModalCancel={onModalCancel}
          modalSubmitOk={modalSubmitOk}
          currentItem={currentQuestion}
        />
      ) : (
        ""
      )}
    </div>
  );
}

ScriptQuestionPanel.propTypes = {
  allQuestions: PropTypes.array,
  currentQuestion: PropTypes.object,
};
