import {
  GET_CURRENT_PAGE_LEADS_REQUEST,
  GET_CURRENT_PAGE_LEADS_SUCCESS,
  SET_SELECTED_LEADS,
  GET_ADDITIONAL_LEADS_DATA_REQUEST,
  GET_ADDITIONAL_LEADS_DATA_SUCCESS,
  RESET_COMPANY,
  SET_SELECTED_COMPANY,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_REQUEST,
  RESET_LEADS_FILTERS,
  UPLOAD_LEADS_SUCCESS,
  UPLOAD_LEADS_REQUEST,
  UPDATE_PAGINATION,
  UPDATE_SORTER,
  UPDATE_LEAD_TABLE,
  EDIT_LEAD_SUCCESS,
  DELETE_LEAD_SUCCESS,
  DELETE_All_LEADS_SUCCESS,
  UPDATE_LEAD_FILTERS,
  CLEAR_LEADS_REDUCER,
} from "../actions/leadsActions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const pageSizeOptions = ["10", "20", "50", "100"];
const paginationPosition = "bottomCenter";

export const initialState = {
  allScripts: [],
  funnels: [],
  allUsers: [],
  allCompanies: [],
  currentPageLeads: [],
  selectedLeads: [],
  isCompaniesLoading: true,
  selectedCompany: null,
  isUpdated: null,
  isLoading: true,
  pagination: {
    pageSize: 20,
    current: 1,
    pageSizeOptions: pageSizeOptions,
    total: 0,
    showSizeChanger: true,
    position: [paginationPosition],
  },
  sorter: {
    field: "name",
    order: "ascend",
  },
  filters: {
    filteredField: "",
    filteredValue: "",
  },
};

const leadsReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case GET_CURRENT_PAGE_LEADS_REQUEST:
      return { ...state, isLoading: true };
    case GET_CURRENT_PAGE_LEADS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pagination: {
          ...state.pagination,
          total: payload.total,
        },
        currentPageLeads: payload.currentPageLeads,
      };

    case SET_SELECTED_LEADS:
      return { ...state, selectedLeads: payload };

    case GET_ADDITIONAL_LEADS_DATA_REQUEST:
      return { ...state, isLoading: true };

    case GET_ADDITIONAL_LEADS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allScripts: payload.allScripts,
        funnels: payload.funnels,
        allUsers: payload.allUsers || [],
        selectedCompany: payload.selectedCompany || state.selectedCompany,
      };

    case RESET_COMPANY:
      return { ...initialState, allCompanies: state.allCompanies, isCompaniesLoading: false };

    case SET_SELECTED_COMPANY:
      return { ...state, selectedCompany: payload, selectedLeads: [] };

    case GET_COMPANIES_REQUEST:
      return { ...state, isCompaniesLoading: true };

    case GET_COMPANIES_SUCCESS:
      return { ...state, allCompanies: payload, isCompaniesLoading: false };

    case RESET_LEADS_FILTERS:
      return { ...state, filters: initialState.filters, selectedLeads: [] };

    case UPLOAD_LEADS_REQUEST:
      return { ...state, isLoading: true };

    case UPLOAD_LEADS_SUCCESS:
      return { ...state, isUpdated: payload, isLoading: false };

    case UPDATE_PAGINATION:
      return {
        ...state,
        pagination: { ...payload.pagination },
        selectedLeads: [],
      };

    case UPDATE_SORTER:
      return {
        ...state,
        isUpdated: payload.isUpdated,
        sorter: {
          field: payload.sorter.field,
          order: payload.sorter.order,
        },
        selectedLeads: [],
      };

    case UPDATE_LEAD_TABLE:
      return { ...state, isUpdated: payload.isUpdated };

    case EDIT_LEAD_SUCCESS:
      return {
        ...state,
        isUpdated: payload.isUpdated,
        selectedLeads: [],
        sorter: { ...state.sorter, current: 1 },
      };

    case DELETE_LEAD_SUCCESS:
      return {
        ...state,
        isUpdated: payload.isUpdated,
        selectedLeads: [],
        pagination: { ...state.pagination, current: 1 },
      };

    case DELETE_All_LEADS_SUCCESS:
      return {
        ...state,
        isUpdated: payload.isUpdated,
        selectedLeads: [],
        pagination: { ...state.pagination, current: 1 },
      };

    case UPDATE_LEAD_FILTERS:
      return { ...state, filters: payload, selectedLeads: [] };

    case CLEAR_LEADS_REDUCER:
      return { ...initialState };

    default:
      return state;
  }
};

const persistConfig = {
  key: "leads",
  storage: storage,
  whitelist: ["selectedCompany", "allScripts", "allUsers", "funnels"],
};

export default persistReducer(persistConfig, leadsReducer);
