import React from "react";
import { Button } from "antd";
import "./_loginFooter.less";

const LoginFooter = () => (
  <div className="login_footer">
    {process.env.REACT_APP_BRANCH ? (
      <div style={{ marginRight: "auto", marginLeft: "10px" }}>{process.env.REACT_APP_BRANCH}</div>
    ) : null}
    <Button type="orange-empty" className="report_issue">
      <a href="https://form.jotform.com/211077245989062" rel="noopener noreferrer" target="_blank">
        Report issue
      </a>
    </Button>
  </div>
);

export default LoginFooter;
