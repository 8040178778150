import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "antd/lib/button";
import { saveAllStagesAction } from "../../redux/actions/scriptPageActions";
import PropTypes from "prop-types";
import notificationAlert from "../../utils/notificationAlert";
import { Spin } from "antd";
import "./_script-page.less";

export default function ScriptControlSection({ isDataLoadingForm }) {
  const dispatch = useDispatch();
  const stages = useSelector((state) => state.script.stages);

  const saveAllStages = async () => {
    dispatch(saveAllStagesAction(stages));
    notificationAlert({
      type: "success",
      description: "Stage data has been successfully saved",
    });
  };

  if (isDataLoadingForm) {
    return (
      <div className="spinner">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="saveScript-section">
      <Button className="button" type="success" onClick={saveAllStages}>
        Save
      </Button>
    </div>
  );
}

ScriptControlSection.propTypes = {
  isDataLoadingForm: PropTypes.bool,
};
