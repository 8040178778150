import { Space, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, SearchOutlined, PhoneOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import FilterDropdownLead from "../FilterDropdownLead";
import "./_leadTable.less";

function tableConfig({
  currentPageLeads,
  selectedCompany,
  openModal,
  startScriptAfterDialing,
  sorter,
  filters,
  setFilters,
  loggedUserRole,
}) {
  const getDefaultValue = () => {
    return filters.filteredField === "name" ? filters.filteredValue : "";
  };

  const getFunnel = (selectedCompany, lead) => {
    return selectedCompany.funnels.length
      ? selectedCompany.funnels.find((funnel) => funnel.id === lead.funnelId) &&
          selectedCompany.funnels.find((funnel) => funnel.id === lead.funnelId).name
      : "";
  };

  const getFunnelStage = (selectedCompany, lead) => {
    return selectedCompany.funnels.length
      ? selectedCompany.funnels.find((funnel) => funnel.id === lead.funnelId) &&
          selectedCompany.funnels
            .find((funnel) => funnel.id === lead.funnelId)
            .funnelStages.find((stage) => stage.id === lead.funnelStageId) &&
          selectedCompany.funnels
            .find((funnel) => funnel.id === lead.funnelId)
            .funnelStages.find((stage) => stage.id === lead.funnelStageId).name
      : "";
  };

  function getData() {
    return (
      currentPageLeads &&
      currentPageLeads.length &&
      currentPageLeads.map((lead) => {
        return {
          key: lead.id,
          id: lead.id,
          name: lead.name,
          email: lead.email,
          phone: lead.phone,
          leadInfo: lead.leadInfo,
          leadsScripts: lead.leadsScripts.length ? lead.leadsScripts.map((leadItem) => leadItem.name).join("||") : "",
          status: lead.status,
          leadsSalesPersons: lead.leadsSalesPersons.length ? lead.leadsSalesPersons.map((el) => el.name) : "",
          funnel: getFunnel(selectedCompany, lead),
          funnelStage: getFunnelStage(selectedCompany, lead),
        };
      })
    );
  }

  function getColumns() {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        className: "name_column",
        align: "center",
        sorter: () => null,
        sortOrder: sorter.field === "name" ? sorter.order : "",
        filterIcon: () => (
          <SearchOutlined
            data-testid="name_filter"
            className={filters.filteredField === "name" && filters.filteredValue ? "activeFilter" : ""}
          />
        ),
        filterDropdown: ({ confirm }) => {
          return (
            <FilterDropdownLead
              placeholder={"name"}
              defaultValue={getDefaultValue()}
              onSearch={setFilters}
              dataIndex={"name"}
              confirm={confirm}
            />
          );
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        className: "email_column",
        align: "center",
        sorter: () => null,
        sortOrder: sorter.field === "email" ? sorter.order : "",
        filterIcon: () => (
          <SearchOutlined
            data-testid="email_filter"
            className={filters.filteredField === "email" && filters.filteredValue ? "activeFilter" : ""}
          />
        ),
        filterDropdown: ({ confirm, clearFilters }) => {
          return (
            <FilterDropdownLead
              placeholder={"email"}
              defaultValue={filters.filteredField === "email" ? filters.filteredValue : ""}
              onSearch={setFilters}
              dataIndex={"email"}
              confirm={confirm}
              clearFilters={clearFilters}
            />
          );
        },
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        className: "phone_column",
        align: "center",
        sorter: () => null,
        sortOrder: sorter.field === "phone" ? sorter.order : "",
        filterIcon: () => (
          <SearchOutlined
            data-testid="phone_filter"
            className={filters.filteredField === "phone" && filters.filteredValue ? "activeFilter" : ""}
          />
        ),
        filterDropdown: ({ confirm, clearFilters }) => {
          return (
            <FilterDropdownLead
              placeholder={"phone"}
              defaultValue={filters.filteredField === "phone" ? filters.filteredValue : ""}
              onSearch={setFilters}
              dataIndex={"phone"}
              confirm={confirm}
              clearFilters={clearFilters}
            />
          );
        },
      },
      {
        title: "Lead info",
        dataIndex: "leadInfo",
        className: "leadInfo_column",
        key: "leadInfo",
        align: "center",
        sorter: () => null,
        sortOrder: sorter.field === "leadInfo" ? sorter.order : "",
        filterIcon: () => (
          <SearchOutlined
            data-testid="leadInfo_filter"
            className={filters.filteredField === "leadInfo" && filters.filteredValue ? "activeFilter" : ""}
          />
        ),
        filterDropdown: ({ confirm, clearFilters }) => {
          return (
            <FilterDropdownLead
              placeholder={"info"}
              defaultValue={filters.filteredField === "leadInfo" ? filters.filteredValue : ""}
              onSearch={setFilters}
              dataIndex={"leadInfo"}
              confirm={confirm}
              clearFilters={clearFilters}
            />
          );
        },
      },
      {
        title: "Users",
        dataIndex: "leadsSalesPersons",
        key: "leadsSalesPersons",
        className: "users_column",
        align: "center",
        filterIcon: () => (
          <SearchOutlined
            data-testid="users_filter"
            className={filters.filteredField === "leadsSalesPersons" && filters.filteredValue ? "activeFilter" : ""}
          />
        ),
        filterDropdown: ({ confirm, clearFilters }) => {
          return (
            <FilterDropdownLead
              defaultValue={filters.filteredField === "leadsSalesPersons" ? filters.filteredValue : ""}
              placeholder={"users"}
              onSearch={setFilters}
              dataIndex={"leadsSalesPersons"}
              confirm={confirm}
              clearFilters={clearFilters}
            />
          );
        },
      },
      {
        title: "Scripts",
        dataIndex: "leadsScripts",
        key: "leadsScripts",
        className: "scripts_column",
        align: "center",
        filterIcon: () => (
          <SearchOutlined
            data-testid="scripts_filter"
            className={filters.filteredField === "leadsScripts" && filters.filteredValue ? "activeFilter" : ""}
          />
        ),
        filterDropdown: ({ confirm, clearFilters }) => {
          return (
            <FilterDropdownLead
              placeholder={"scripts"}
              defaultValue={filters.filteredField === "leadsScripts" ? filters.filteredValue : ""}
              onSearch={setFilters}
              dataIndex={"leadsScripts"}
              confirm={confirm}
              clearFilters={clearFilters}
            />
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        className: "status_column",
        align: "center",
        sorter: () => null,
        sortOrder: sorter.field === "status" ? sorter.order : "",
        filterIcon: () => (
          <SearchOutlined
            data-testid="status_filter"
            className={filters.filteredField === "status" && filters.filteredValue ? "activeFilter" : ""}
          />
        ),
        filterDropdown: ({ confirm, clearFilters }) => {
          return (
            <FilterDropdownLead
              placeholder={"status"}
              defaultValue={filters.filteredField === "status" ? filters.filteredValue : ""}
              onSearch={setFilters}
              dataIndex={"status"}
              confirm={confirm}
              clearFilters={clearFilters}
            />
          );
        },
      },
      {
        title: "Funnel",
        dataIndex: "funnel",
        key: "funnel",
        className: "funnel_column",
        align: "center",
        sorter: () => null,
        sortOrder: sorter.field === "funnel" ? sorter.order : "",
        filterIcon: () => (
          <SearchOutlined
            data-testid="funnel_filter"
            className={filters.filteredField === "funnel" && filters.filteredValue ? "activeFilter" : ""}
          />
        ),
        filterDropdown: ({ confirm, clearFilters }) => {
          return (
            <FilterDropdownLead
              placeholder={"funnel"}
              defaultValue={filters.filteredField === "funnel" ? filters.filteredValue : ""}
              onSearch={setFilters}
              dataIndex={"funnel"}
              confirm={confirm}
              clearFilters={clearFilters}
            />
          );
        },
      },
      {
        title: "Funnel stage",
        dataIndex: "funnelStage",
        key: "funnelStage",
        className: "funnelStage_column",
        align: "center",
        sorter: () => null,
        sortOrder: sorter.field === "funnelStage" ? sorter.order : "",
        filterIcon: () => (
          <SearchOutlined
            data-testid="funnelStage_filter"
            className={filters.filteredField === "funnelStage" && filters.filteredValue ? "activeFilter" : ""}
          />
        ),
        filterDropdown: ({ confirm, clearFilters }) => {
          return (
            <FilterDropdownLead
              placeholder={"funnel stage"}
              defaultValue={filters.filteredField === "funnelStage" ? filters.filteredValue : ""}
              onSearch={setFilters}
              dataIndex={"funnelStage"}
              confirm={confirm}
              clearFilters={clearFilters}
            />
          );
        },
      },
      {
        title: "Actions",
        key: "action",
        align: "center",
        className: "action_column",
        fixed: "right",
        render: (text, record) => {
          return (
            <Space size="middle" className="action_group">
              {loggedUserRole === "user" && (
                <span className="action_link">
                  <Tooltip placement="topLeft" title={"Assign Funnel to Lead"}>
                    <FontAwesomeIcon
                      data-testid="assign_icon"
                      onClick={() => openModal("funnelAssign", record)}
                      icon={faUserPlus}
                      className="assign_icon icon"
                    />
                  </Tooltip>
                </span>
              )}
              {loggedUserRole !== "user" && (
                <span className="action_link">
                  <Tooltip placement="topLeft" title={"Edit lead"}>
                    <EditOutlined
                      data-testid="edit_icon"
                      style={{ fontSize: "18px" }}
                      className="icon icon_edit"
                      onClick={() => openModal("edit", record)}
                    />
                  </Tooltip>
                </span>
              )}
              {loggedUserRole !== "user" && (
                <span className="action_link">
                  <Tooltip placement="topLeft" title={"Delete lead"}>
                    <DeleteOutlined
                      data-testid="delete_icon"
                      style={{ fontSize: "18px" }}
                      onClick={() => openModal("delete", record)}
                      className="icon icon_delete"
                    />
                  </Tooltip>
                </span>
              )}
              <span className="action_link">
                <Tooltip placement="topLeft" title={"Deal and Start call"}>
                  {record.leadsScripts ? (
                    <a href={`tel:${record.phone}`}>
                      <PhoneOutlined
                        onClick={() => startScriptAfterDialing(record)}
                        style={{ fontSize: "18px" }}
                        className="icon icon_dial"
                      />
                    </a>
                  ) : (
                    <PhoneOutlined
                      onClick={() => startScriptAfterDialing(record)}
                      style={{ fontSize: "18px" }}
                      className="icon icon_dial"
                    />
                  )}
                </Tooltip>
              </span>
              <span className="action_link">
                <Tooltip placement="topLeft" title={"Start call"}>
                  <PlayCircleOutlined
                    data-testid="call_icon"
                    onClick={() => openModal("startCall", record)}
                    style={{ fontSize: "18px" }}
                    className="icon icon_start"
                  />
                </Tooltip>
              </span>
            </Space>
          );
        },
      },
    ];
  }

  return { getData: getData(), getColumns: getColumns() };
}

export default tableConfig;
