import React, { useState, useEffect } from "react";
import { Modal, Spin, Button } from "antd";
import { Input } from "antd";
import { useHistory } from "react-router-dom";
import API from "../../services/apiService";
import { clearViewerAction } from "../../redux/actions/scriptViewerPageActions";
import PropTypes from "prop-types";
import { clearDataAndRedirect, getAllUrlParams, transformQuestions } from "../../utils/helpers";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import SelectComponent from "../../components/Select/Select";
import axios from "axios";
import notificationAlert from "../../utils/notificationAlert";
import "./_script-viewer-page.less";

const { TextArea } = Input;

/**
 * A component that shows a list of answered and missed questions with
 * their answers, if any, and allows you to take notes after the call
 */
export default function EndCallQuestionModal({ title, isVisible, companyId, dispatch }) {
  const [isLoading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const currentCall = useSelector((state) => state.viewer.currentCall);
  const loggedUser = useSelector((state) => state.common.user);
  const [funnelStages, setFunnelStages] = useState([]);
  const [currentFunnelStage, setCurrentFunnelStage] = useState({});
  const [hiddenQuestions, setHiddenQuestions] = useState([]);

  const history = useHistory();

  useEffect(() => {
    async function setItemsInitial() {
      const stagesQuestions = JSON.parse(localStorage.getItem("answersList"));
      if (currentCall && currentCall.listStages) {
        setFunnelStages(currentCall.listStages);
        setCurrentFunnelStage(currentCall.currentStage);
      }

      const itemsData = items.length ? items : transformQuestions(stagesQuestions);
      const filteredIsShownQuestion = itemsData.filter((question) => question.isShown);
      const filteredHiddenQuestion = itemsData.filter((question) => !question.isShown);

      if (filteredIsShownQuestion.length === 0 && currentCall.listStages.length === 0) {
        return clearDataAndRedirect(history, "answersList");
      }
      setHiddenQuestions(filteredHiddenQuestion);
      setItems(filteredIsShownQuestion);
      setLoading(false);
    }

    setItemsInitial();
  }, []);

  const onChangeAnswer = (event, item) => {
    setItems((items) => {
      return items.map((element) => {
        if (element.question === item.question) {
          if (element && element.answer && element.answer.name) {
            element.answer.name = event.target.value;
          } else {
            element.answer = { name: event.target.value };
          }
          return element;
        }
        return element;
      });
    });
  };

  const onStageChange = (funnelStageName) => {
    const currentFunnelStage = funnelStages.find((funnelStage) => funnelStage.name === funnelStageName);
    setCurrentFunnelStage(currentFunnelStage);
  };

  const renderContent = (items) => {
    if (items.length) {
      return items.map((item, index) => {
        return (
          <div className="test" key={index}>
            <TextArea rows={3} className="question" disabled value={item.question || ""} />
            <TextArea
              rows={4}
              onChange={(event) => onChangeAnswer(event, item)}
              className="answer"
              value={(item.answer && item.answer.name) || ""}
            />
          </div>
        );
      });
    } else {
      return <div>Question list is empty</div>;
    }
  };

  const handleOk = async () => {
    if (currentFunnelStage && currentFunnelStage.funnelId) {
      await API.put(`lead/${currentCall.lead.id}`, {
        funnelId: currentFunnelStage.funnelId,
        funnelStageId: currentFunnelStage.id,
      });
    }
    const externalParams = getAllUrlParams(history.location.search);
    const callActivityObject = {
      answers: [...items, ...hiddenQuestions],
      callInfo: {
        startDate: currentCall.startDateTime,
        endDate: currentCall.endDateTime,
        endReason: currentCall.endReason,
      },
      operator: {
        name: loggedUser.name,
        email: loggedUser.email,
      },
      submittedTime: format(new Date(), "yyyy-MM-dd H:m:s"),
      lead: currentCall?.lead || null,
      ...externalParams,
    };
    try {
      await axios.post(`/api/webhook/send/${companyId}`, callActivityObject);
    } catch (e) {
      notificationAlert({
        type: "warning",
        description: "Webhook request failed",
      });
    }
    dispatch(clearViewerAction());
    clearDataAndRedirect(history, "answersList", "currentCall");
  };

  const renderFunnel = () => {
    if (funnelStages.length) {
      return (
        <div className="call_stage_assign_select">
          <h2>Funnel stage status:</h2>
          <SelectComponent
            width={"100%"}
            mainField="name"
            placeholder={"Select an attribute to add"}
            data={funnelStages}
            value={(currentFunnelStage && currentFunnelStage.name) || ""}
            handleChange={onStageChange}
          />
        </div>
      );
    }
  };

  const footer = [
    <Button key="1" onClick={handleOk} type="orange">
      Finish
    </Button>,
  ];

  if (isLoading) {
    return (
      <div className="spinner">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Modal
      onOk={handleOk}
      footer={footer}
      title={title}
      maskClosable={false}
      className="endCallQuestionModal"
      centered
      width={"100%"}
      visible={isVisible}>
      {renderFunnel()}
      <div className="reason__viewer">
        <div className="questionBlock">
          <h2>Notes for questions:</h2>
          {renderContent(items)}
        </div>
        <div className="answerBlock"></div>
      </div>
    </Modal>
  );
}

EndCallQuestionModal.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  companyId: PropTypes.string,
  dispatch: PropTypes.func,
};
