import React from "react";
import { Select, Button, Divider } from "antd";
import { sortByName } from "../../utils/helpers";
import PropTypes from "prop-types";
import "./_multiselect.less";

const { Option } = Select;

export default function Multiselect({
  value,
  onChange,
  placeholder,
  name,
  options,
  field,
  className,
  rendererCallback,
  dropdownRender,
  sorted,
}) {
  const renderOptions = () => {
    if (sorted) {
      return sortByName(options, field).map((option, index) => {
        return (
          <Option key={index} value={field ? option[field] : option}>
            {field ? option[field] : option}
          </Option>
        );
      });
    }
    return options.map((option, index) => {
      return (
        <Option key={index} value={field ? option[field] : option}>
          {field ? option[field] : option}
        </Option>
      );
    });
  };

  return (
    <Select
      className={className || ""}
      value={value}
      showSearch
      dropdownRender={(menu) => {
        if (dropdownRender) {
          return (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div style={{ display: "flex", flexWrap: "nowrap" }}>
                <Button onClick={rendererCallback} type="text" style={{ color: "#1890ff" }}>
                  Select all
                </Button>
              </div>
            </div>
          );
        } else {
          return menu;
        }
      }}
      mode="multiple"
      placeholder={placeholder}
      onChange={(item) => onChange(item, name)}>
      {options && options.length && renderOptions()}
    </Select>
  );
}

Multiselect.defaultProps = {
  sorted: true,
  name: "",
  className: "",
};

Multiselect.propTypes = {
  sorted: PropTypes.bool,
  value: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  field: PropTypes.string,
  className: PropTypes.string,
  rendererCallback: PropTypes.func,
  dropdownRender: PropTypes.bool,
};
