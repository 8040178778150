import { Progress } from "antd";

function employeeSummaryChartTableConfig({ chartData, maxChartValues, sortOrder, sortedField }) {
  const getData = () => {
    return chartData && chartData.length
      ? chartData.map((chartItem, chartIndex) => {
          return { ...chartItem, key: chartIndex };
        })
      : [];
  };

  function getColumns() {
    return [
      {
        title: "Date Trunc",
        dataIndex: "date",
        key: "date",
        className: "dateTrunc_column",
        align: "center",
        ellipsis: true,
        width: "10%",
        render: (text) => {
          if (text.type) {
            return null;
          }
          return text;
        },
        sorter: () => null,
        sortOrder: sortedField === "date" ? sortOrder : "",
      },
      {
        title: "Dials",
        dataIndex: "dials",
        key: "dials",
        className: "dials_column",
        align: "center",
        ellipsis: true,
        width: "19%",
        render: (text) => {
          const maxDial = maxChartValues.dials;
          const currentPercentValue = (text * 100) / maxDial;
          return (
            <Progress
              format={() => `${text.toFixed()}`}
              strokeColor={"#3b79f5"}
              status={"normal"}
              percent={currentPercentValue}
              size="small"
            />
          );
        },
        sorter: () => null,
        sortOrder: sortedField === "dials" ? sortOrder : "",
      },
      {
        title: "Conversations",
        dataIndex: "conversations",
        key: "conversations",
        className: "conversations_column",
        align: "center",
        ellipsis: true,
        width: "19%",
        render: (text) => {
          const maxDial = maxChartValues.conversations;
          const currentPercentValue = (text * 100) / maxDial;
          return (
            <Progress
              format={() => `${text.toFixed()}`}
              strokeColor={"#47eded"}
              status={"normal"}
              percent={currentPercentValue}
              size="small"
            />
          );
        },
        sorter: () => null,
        sortOrder: sortedField === "conversations" ? sortOrder : "",
      },
      {
        title: "Pitches",
        dataIndex: "pitches",
        key: "pitches",
        className: "pitches_column",
        align: "center",
        ellipsis: true,
        width: "19%",
        render: (text) => {
          const maxDial = maxChartValues.pitches;
          const currentPercentValue = (text * 100) / maxDial;
          return (
            <Progress
              format={() => `${text.toFixed()}`}
              strokeColor={"#fa5298"}
              status={"normal"}
              percent={currentPercentValue}
              size="small"
            />
          );
        },
        sorter: () => null,
        sortOrder: sortedField === "pitches" ? sortOrder : "",
      },
      {
        title: "Sales",
        dataIndex: "sales",
        key: "sales",
        className: "sales_column",
        align: "center",
        ellipsis: true,
        width: "19%",
        render: (text) => {
          const maxDial = maxChartValues.sales;
          const currentPercentValue = (text * 100) / maxDial;
          return (
            <Progress
              format={() => `${text.toFixed()}`}
              strokeColor={"#f78e52"}
              status={"normal"}
              percent={currentPercentValue}
              size="small"
            />
          );
        },
        sorter: () => null,
        sortOrder: sortedField === "sales" ? sortOrder : "",
      },
      {
        title: "Sales value",
        dataIndex: "salesValue",
        key: "salesValue",
        className: "salesValue_column",
        align: "center",
        ellipsis: true,
        width: "10%",
        sorter: () => null,
        sortOrder: sortedField === "salesValue" ? sortOrder : "",
      },
      {
        title: "Cash",
        dataIndex: "cash",
        key: "cash",
        className: "cash_column",
        align: "center",
        ellipsis: true,
        width: "10%",
        sorter: () => null,
        sortOrder: sortedField === "cash" ? sortOrder : "",
      },
    ];
  }

  return { getData: getData(), getColumns: getColumns() };
}

export default employeeSummaryChartTableConfig;
