import {
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  CREATE_NEW_COMPANY,
  SET_CHOSEN_COMPANY,
  DELETE_CURRENT_COMPANY,
  EDIT_COMPANY,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  SET_CHOSEN_USER,
  CREATE_NEW_USER,
  DELETE_CURRENT_USER,
  EDIT_USER,
  SET_ALL_ATTRIBUTES,
  SELECT_COMPANY,
  RESET_COMPANY_SETTINGS,
  SET_CURRENT_FUNNEL,
  UPDATE_CURRENT_COMPANY,
} from "../actions/adminPageActions";

const initialState = {
  allCompanies: [],
  companyUsers: [],
  allAttributes: [],
  currentCompany: {},
  currentUser: {},
  currentAttribute: {},
  loading: true,
};

export const adminPageReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case FETCH_COMPANIES_REQUEST:
      return { ...state, allCompanies: [], loading: true };

    case FETCH_COMPANIES_SUCCESS:
      return { ...state, allCompanies: payload, loading: false };

    case CREATE_NEW_COMPANY:
      const { newCompanyData } = payload;
      return {
        ...state,
        allCompanies: [...state.allCompanies, { ...newCompanyData }],
        currentCompany: newCompanyData,
        companyUsers: [],
        currentUser: {},
      };

    case SET_CHOSEN_COMPANY:
      const { chosenCompany } = payload;
      return { ...state, currentCompany: chosenCompany, currentUser: {} };

    case DELETE_CURRENT_COMPANY:
      const { id } = payload;
      return {
        ...state,
        allCompanies: state.allCompanies.filter((el) => el.id !== id),
        currentCompany: {},
      };

    case EDIT_COMPANY:
      const { updatedCompany } = payload;
      const { companyIndex } = payload;

      return {
        ...state,
        allCompanies: [
          ...state.allCompanies.slice(0, companyIndex),
          updatedCompany,
          ...state.allCompanies.slice(companyIndex + 1),
        ],
        currentCompany: updatedCompany,
      };

    case FETCH_USERS_REQUEST:
      return { ...state, companyUsers: [], loading: true };

    case FETCH_USERS_SUCCESS:
      return { ...state, companyUsers: payload, loading: false };

    case SET_CHOSEN_USER:
      return { ...state, currentUser: payload };

    case CREATE_NEW_USER:
      const { newUserData } = payload;
      return {
        ...state,
        companyUsers: [...state.companyUsers, { ...newUserData }],
        currentUser: newUserData,
      };

    case DELETE_CURRENT_USER:
      const { userDeletedId } = payload;
      return {
        ...state,
        companyUsers: state.companyUsers.filter((el) => el.id !== userDeletedId),
        currentUser: {},
      };

    case EDIT_USER:
      const { updatedUser } = payload;
      return {
        ...state,
        companyUsers: state.companyUsers.map((user) => {
          if (user.id === updatedUser.id) {
            return updatedUser;
          }
          return user;
        }),
        currentUser: updatedUser,
      };

    case SET_ALL_ATTRIBUTES:
      return { ...state, allAttributes: payload };

    case SELECT_COMPANY:
      const { selectedCompany } = payload;
      return {
        ...state,
        currentCompany: selectedCompany,
        currentUser: {},
      };

    case RESET_COMPANY_SETTINGS:
      return { ...state, currentCompany: {}, currentUser: {} };

    case SET_CURRENT_FUNNEL:
      return { ...state, selectedFunnel: payload };

    case UPDATE_CURRENT_COMPANY:
      const updatedCompanyIndex = state.allCompanies.findIndex((company) => company.id === payload.id);

      return {
        ...state,
        currentCompany: payload,
        allCompanies: [
          ...state.allCompanies.slice(0, updatedCompanyIndex),
          payload,
          ...state.allCompanies.slice(updatedCompanyIndex + 1),
        ],
      };

    default:
      return state;
  }
};
