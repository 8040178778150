import React from "react";
import { Space, Rate, Tag, Tooltip, Checkbox } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { sortByName } from "../../../utils/helpers";

function tableConfig(actualGuidance, setGuidanceTableAction, onDoneCallback, setSentimentCallback) {
  const getLevelColor = (level) => {
    if (level === "new") return "green";
    if (level === "ongoing") return "orange";
    if (level === "systemic") return "red";
    return "";
  };

  function getData() {
    return (
      actualGuidance &&
      actualGuidance.length &&
      actualGuidance.map(({ userName, level, done, sentiment, guidanceArea, id, type, notes }, i) => {
        return {
          id,
          key: i,
          userName: userName,
          level,
          done,
          sentiment,
          guidanceArea: guidanceArea.text,
          type,
          notes,
        };
      })
    );
  }

  function getColumns() {
    return [
      {
        title: "Members",
        dataIndex: "userName",
        key: "userName",
        align: "center",
        className: "teamMembers_column",
        ellipsis: true,
        render: (allTeamMembers) => {
          return (
            <span className="dropdown-text">
              {allTeamMembers &&
                allTeamMembers.length &&
                sortByName(allTeamMembers).map((member, i) => {
                  return (
                    <p key={i} className="member_text">
                      {member}
                    </p>
                  );
                })}
            </span>
          );
        },
      },
      {
        title: "Needed interventions",
        dataIndex: "guidanceArea",
        className: "guidanceArea_column",
        key: "guidanceArea",
        sorter: (a, b) => a.guidanceArea.localeCompare(b.guidanceArea),
      },
      {
        title: "Level",
        dataIndex: "level",
        className: "level_column",
        key: "level",
        align: "center",
        sorter: (a, b) => a.level.localeCompare(b.level),
        render: (level) => {
          return <Tag color={getLevelColor(level)}>{level}</Tag>;
        },
      },
      {
        title: "Done?",
        dataIndex: "done",
        className: "done_column",
        key: "done",
        ellipsis: true,
        align: "center",
        render: (text, data) => {
          return (
            <Checkbox
              key={data.key}
              checked={text}
              onChange={({ target: { checked } }) => onDoneCallback(checked, data.key, data.type)}
            />
          );
        },
      },
      {
        title: "Sentiment",
        dataIndex: "sentiment",
        key: "sentiment",
        ellipsis: true,
        className: "sentiment_column",
        align: "center",
        render: (text, data) => {
          return (
            <Rate
              key={data.key}
              value={text || 0}
              onChange={(value) => setSentimentCallback(value, data.key, data.type)}
            />
          );
        },
      },
      {
        title: "Actions",
        key: "action",
        className: "actions_column",
        align: "center",
        render: (record) => (
          <Space size="middle">
            <Tooltip placement="topLeft" title={"Edit guidance"}>
              <span className="action_link" onClick={() => setGuidanceTableAction("edit", record)} data-testid="edit">
                <EditOutlined style={{ fontSize: "18px" }} className="icon icon_edit" />
              </span>
            </Tooltip>
            <Tooltip placement="topLeft" title={"Delete guidance"}>
              <span
                className="action_link"
                data-testid="delete"
                onClick={() => setGuidanceTableAction("delete", record)}>
                <DeleteOutlined style={{ fontSize: "18px" }} className="icon icon_delete" />
              </span>
            </Tooltip>
          </Space>
        ),
      },
    ];
  }

  return { getData: getData(), getColumns: getColumns() };
}

export default tableConfig;
