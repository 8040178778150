import React, { useCallback, useState } from "react";
import ModalGroup from "../../../../components/ModalGroup/ModalGroup";
import ChipComponent from "../../../../components/Chip/Chip";
import { addNewQuestionAction, deleteQuestionAction } from "../../../../redux/actions/scriptPageActions";
import { useSelector } from "react-redux";
import Multiselect from "../../../../components/Multiselect/Multiselect";
import PropTypes from "prop-types";
import "../../_script-page.less";

export default function ScriptQuestionsSection({ currentPage, dispatch }) {
  const [modalMode, setModalMode] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const [chosenQuestion, setChosenQuestion] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const questions = useSelector((state) => state.script.stages[currentPage].questions);
  const allQuestions = useSelector((state) => state.script.allQuestions);

  const deleteQuestion = () => {
    setChosenQuestion(0);
    dispatch(deleteQuestionAction({ questionIdx: chosenQuestion, currentPage }));
  };

  const selectCurrentHandler = (value) => setChosenQuestion(value);

  const questionsRender = useCallback(() => {
    return questions.map((reason, index) => {
      return (
        <ChipComponent
          active={false}
          key={index}
          editable={false}
          deleteHandler={() => onModalOpen("delete")}
          selectCurrentHandler={() => selectCurrentHandler(index)}
          text={reason.name}
        />
      );
    });
  }, [questions, chosenQuestion]);

  const onModalCancel = () => {
    setModalVisible(false);
    setModalMode("");
  };

  const onModalOpen = (mode) => {
    setModalVisible(true);
    setModalMode(mode);
  };

  const modals = [
    {
      title: "Delete",
      text: `Delete «${questions[chosenQuestion] && questions[chosenQuestion].name}» question`,
      type: "delete",
      isVisible: isModalVisible,
    },
  ];

  const modalSubmitOk = async () => {
    if (modalMode === "delete") {
      await deleteQuestion();
    }
    setModalVisible(false);
  };

  const changeQuestionHandler = (selectedQuestions) => {
    selectedQuestions.forEach((selectedQuestionName) => {
      const selectedQuestion = allQuestions.find((question) => question.name === selectedQuestionName);
      dispatch(
        addNewQuestionAction({
          name: selectedQuestionName,
          companyId: selectedQuestion.companyId,
          id: selectedQuestion.id,
          currentPage,
          questionIdx: questions.length,
        })
      );
    });
    setSelectedItems([]);
  };

  const filterAddedQuestions = (allQuestions) =>
    allQuestions.filter((question) => !questions.some((selectedQuestion) => selectedQuestion.name === question.name));

  return (
    <>
      <div className="section">
        <div className="config_panel">
          <h3 className="config_title">Questions:</h3>
          <div className="select_wrapper">
            <Multiselect
              className="select"
              value={selectedItems}
              placeholder="Choose questions to add"
              field="name"
              options={filterAddedQuestions(allQuestions)}
              onChange={changeQuestionHandler}
            />
          </div>
        </div>
        <div className="content_panel">{questionsRender()}</div>
      </div>
      <ModalGroup
        allItems={questions}
        mode={modalMode}
        modals={modals}
        onModalCancel={onModalCancel}
        modalSubmitOk={modalSubmitOk}
      />
    </>
  );
}

ScriptQuestionsSection.propTypes = {
  currentPage: PropTypes.number,
  dispatch: PropTypes.func,
};
