import React, { useState } from "react";
import { Button, Popconfirm } from "antd";
import { updateTodayStatsAndResetStatsAction } from "../../redux/actions/statsInfoActions";
import { useDispatch, useSelector } from "react-redux";
import notificationAlert from "../../utils/notificationAlert";
import API from "../../services/apiService";
import "./_footer.less";

const statsMessage = "System is configured to automatically collect stats. You do not need to reset stats";

const LoginFooter = () => {
  const dispatch = useDispatch();
  const prevDaysStats = useSelector((state) => state.statsInfo.prevDaysStats);
  const [visible, setVisible] = useState(false);

  const confirmReset = () => {
    dispatch(updateTodayStatsAndResetStatsAction(prevDaysStats));
    setVisible(false);
  };

  const showPopconfirm = async () => {
    const isAutoStatsCollect = await API.get("stats/collector-status");
    if (isAutoStatsCollect.stats_auto === "true") {
      return notificationAlert({
        type: "info",
        duration: 3,
        description: statsMessage,
      });
    }
    setVisible(true);
  };

  const handleCancel = () => setVisible(false);

  return (
    <div className="footer">
      <Popconfirm
        placement="topLeft"
        visible={visible}
        onCancel={handleCancel}
        title={
          <p>
            Are you sure you want to reset your stats?
            <br />
            You typically only want to reset your stats at the start of each day
          </p>
        }
        onConfirm={confirmReset}
        okText="Yes"
        cancelText="No">
        <Button className="btn report_issue" onClick={showPopconfirm}>
          Reset stats
        </Button>
      </Popconfirm>
      <Button className="btn report_issue">
        <a href="https://form.jotform.com/211077245989062" rel="noopener noreferrer" target="_blank">
          Report issue
        </a>
      </Button>
      {process.env.REACT_APP_BRANCH ? <div style={{ marginLeft: "auto" }}>{process.env.REACT_APP_BRANCH}</div> : null}
    </div>
  );
};

export default LoginFooter;
