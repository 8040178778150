import React, { useEffect } from "react";
import { Table, Spin, Typography } from "antd";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import DotsComponent from "../../components/DotsComponent/DotsComponent";
import { setInitialStatsAction, setActiveDotAction } from "../../redux/actions/statsInfoActions";
import Footer from "../../components/Footer/Footer";
import { getStatsExceptToday, getUrlParam } from "../../utils/helpers";
import withCallClear from "../../hocks/withCallClear";
import "./_statsInfo.less";

function StatsInfoPage({ dispatch, activeDot, todayStats, user, prevDaysStats, history, isDataLoading }) {
  useEffect(() => {
    let activeDotId = getUrlParam(history.location.search, "id");
    dispatch(setInitialStatsAction(activeDotId, history));
  }, []);

  const columns = [
    { title: "Stats", dataIndex: "name", key: "name", width: "50%", align: "center" },
    { title: "Today", dataIndex: "today", key: "today", width: "25%", align: "center" },
    {
      title: `${activeDot && activeDot.date ? activeDot.date.slice(0, 10) : "Previous day"}`,
      dataIndex: "previousDay",
      key: "previousDay",
      align: "center",
    },
  ];

  const dataSource = [
    {
      key: "1",
      name: "Dials",
      today: (todayStats && todayStats.calls) || (todayStats && todayStats.dials) || "0",
      previousDay: (activeDot && activeDot.calls) || "",
    },
    {
      key: "2",
      name: "Conversations",
      today: (todayStats && todayStats.conversations) || "0",
      previousDay: (activeDot && activeDot.conversations) || "",
    },
    {
      key: "3",
      name: "Pitches",
      today: (todayStats && todayStats.pitches) || "0",
      previousDay: (activeDot && activeDot.pitches) || "",
    },
    {
      key: "4",
      name: "Sales",
      today: (todayStats && todayStats.sales) || "0",
      previousDay: (activeDot && activeDot.sales) || "",
    },
  ];

  const onChangeHandler = ({ selectedStat }) => {
    history.push(`stats-info?id=${selectedStat.id}`);
    dispatch(setActiveDotAction({ activeDot: selectedStat }));
  };

  if (isDataLoading) {
    return (
      <div className="spinner">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <div className="statsInfo-page">
        <Typography.Title className="title" level={2}>
          {user.name}'s stats for today!
        </Typography.Title>
        <div className="container">
          <div className="table_stats">
            <Table pagination={false} size="small" columns={columns} dataSource={dataSource} bordered={true} />
          </div>
        </div>
        <div className="dots_container">
          <DotsComponent stats={getStatsExceptToday(prevDaysStats)} onChange={onChangeHandler} activeDot={activeDot} />
        </div>
      </div>
      <Footer />
    </>
  );
}

function mapStateToProps(state) {
  const { activeDot, isStatsLoading, todayStats, prevDaysStats, isDataLoading } = state.statsInfo;
  const { user } = state.common;
  return {
    activeDot,
    isStatsLoading,
    todayStats,
    user,
    prevDaysStats,
    isDataLoading,
  };
}

export default withRouter(connect(mapStateToProps)(withCallClear(StatsInfoPage)));
