import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearStorageExept } from "../utils/helpers";

export const useAuthHook = () => {
  const dispatch = useDispatch();
  const [isAuth, setIsAuth] = useState(false);
  const user = useSelector((state) => state.common.user);
  const [ready, setReady] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const login = useCallback((info) => {
    localStorage.setItem("currentUser", JSON.stringify(info));
    setIsAuth(true);
    setUserInfo(info);
  }, []);

  const logout = useCallback(() => {
    setIsAuth(false);
    setUserInfo(null);
    dispatch({ type: "LOG_OUT" });
    localStorage.removeItem("persist:root");
    clearStorageExept("ironcage-stats", "timezoneStatsViewer"); //We dont need clear this fields when user log out
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }, []);

  useEffect(() => {
    if (user.name) {
      login(user);
    }
    setReady(true);
  }, [login]);

  return { isAuth, login, logout, userInfo, ready };
};
