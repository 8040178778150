import React from "react";
import { TimePicker as MiTimePickerComponent } from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/de_DE";
import PropTypes from "prop-types";
import "./_timePicker.less";

export default function TimePicker({ placeholder, onChange, value, showTime, format, className, showNow }) {
  const onInnerChange = (time, string) => onChange(string);

  return (
    <MiTimePickerComponent
      value={value ? moment(value, "HH:mm") : null}
      placeholder={placeholder}
      className={`timePicker ${className}`}
      onChange={onInnerChange}
      showTime={showTime}
      showNow={showNow}
      format={format}
      locale={{
        ...locale,
        lang: {
          ...locale.lang,
          now: "Now",
          ok: "OK",
        },
      }}
    />
  );
}

TimePicker.defaultProps = {
  className: "",
  placeholder: "",
  format: "HH:mm",
  showNow: false,
};

TimePicker.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  showTime: PropTypes.func,
  format: PropTypes.string,
  className: PropTypes.string,
  showNow: PropTypes.bool,
};
