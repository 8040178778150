import React from "react";
import { Pagination } from "antd";
import PropTypes from "prop-types";

export default function StatsViewerTablePagination({
  total,
  pageSize,
  currentPage,
  setCurrentPage,
  changePageSizeHandler,
}) {
  const paginationHandler = (selectedPage) => setCurrentPage(selectedPage);

  return (
    <Pagination
      current={currentPage}
      total={total}
      pageSize={pageSize}
      size="small"
      showSizeChanger={true}
      onChange={paginationHandler}
      onShowSizeChange={changePageSizeHandler}
      pageSizeOptions={["10", "20", "50", "100"]}
    />
  );
}

StatsViewerTablePagination.propTypes = {
  total: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  changePageSizeHandler: PropTypes.func,
};
