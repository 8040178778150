import React, { useMemo, useState } from "react";
import ModalGroup from "../../../components/ModalGroup/ModalGroup";
import { useDispatch, useSelector } from "react-redux";
import { editCompanyAction } from "../../../redux/actions/adminPageActions";
import API from "../../../services/apiService";
import PropTypes from "prop-types";
import SelectComponent from "../../../components/Select/Select";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import FunnelStagePanel from "./FunnelStages";
import { setCurrentFunnelAction } from "../../../redux/actions/adminPageActions";
import notificationAlert from "../../../utils/notificationAlert";
import "./_funnelPanel.less";

const FunnelPanel = ({ allCompanies, currentCompany }) => {
  const dispatch = useDispatch();
  const selectedFunnel = useSelector((state) => state.setting.selectedFunnel);

  const [modalMode, setModalMode] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);

  const createNewFunnel = async ({ name }) => {
    const createdFuneral = await API.post(`funnel`, { companyId: currentCompany.id, name });

    const updatedCompany = {
      ...currentCompany,
      funnels: [...currentCompany.funnels, createdFuneral],
    };
    const companyIndex = allCompanies.findIndex((el) => el.id === currentCompany.id);

    notificationAlert({
      type: "success",
      description: "Funnel has been successfully created",
    });

    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
    dispatch(setCurrentFunnelAction(createdFuneral));
  };

  const editFunnel = async ({ name }) => {
    const createdFuneral = await API.put(`funnel/${selectedFunnel.id}`, { name });
    const updatedCompany = {
      ...currentCompany,
      funnels: currentCompany.funnels.map((funnel) => {
        if (funnel.name === selectedFunnel.name) {
          return createdFuneral;
        }
        return funnel;
      }),
    };

    dispatch(setCurrentFunnelAction(createdFuneral));
    const companyIndex = allCompanies.findIndex((company) => company.id === currentCompany.id);
    notificationAlert({
      type: "success",
      description: "Funnel has been successfully updated",
    });
    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
  };

  const deleteFunnel = async () => {
    await API.delete(`funnel/${selectedFunnel.id}`);

    const updatedCompany = {
      ...currentCompany,
      funnels: currentCompany.funnels.filter((funnel) => funnel.name !== selectedFunnel.name),
    };
    dispatch(setCurrentFunnelAction({}));
    const companyIndex = allCompanies.findIndex((company) => company.id === currentCompany.id);
    notificationAlert({
      type: "success",
      description: "Funnel has been successfully deleted",
    });
    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
  };

  const chooseFunnelHandler = async (value) => {
    const funnelItem = currentCompany.funnels.find((item) => item.name === value);
    dispatch(setCurrentFunnelAction(funnelItem));
  };

  const modalSubmitOk = async (value) => {
    if (modalMode === "add") {
      await createNewFunnel(value);
    } else if (modalMode === "edit") {
      await editFunnel(value);
    } else if (modalMode === "delete") {
      await deleteFunnel();
    }
    setModalVisible(false);
  };

  const onModalCancel = () => {
    setModalVisible(false);
    setModalMode("");
  };

  const onModalOpen = (mode) => {
    setModalVisible(true);
    setModalMode(mode);
  };

  const modals = [
    {
      title: "Delete",
      text: `Delete «${selectedFunnel && selectedFunnel.name}» funnel`,
      type: "delete",
      isVisible: isModalVisible,
    },
    { title: "Edit", text: "Edit funnel", type: "edit", isVisible: isModalVisible },
    { title: "Add", text: "Add new funnel", type: "add", isVisible: isModalVisible },
  ];

  const modalComponentsData = useMemo(
    () => () => {
      return [
        {
          name: "name",
          placeholder: "Type funnel name",
          elementType: "input",
          checkDuplicate: true,
        },
      ];
    },
    []
  );

  const isBtnVisible = !!(selectedFunnel && selectedFunnel.name);

  const buttons = [
    { name: "Add funnel", action: () => onModalOpen("add"), visible: true },
    { name: "Edit funnel", action: () => onModalOpen("edit"), visible: isBtnVisible },
    { name: "Delete funnel", action: () => onModalOpen("delete"), visible: isBtnVisible },
  ];

  return (
    <>
      <div className="funnel-config">
        {isModalVisible ? (
          <ModalGroup
            editableItemIdx={""}
            mainField={"name"}
            allItems={currentCompany.funnels}
            mode={modalMode}
            modals={modals}
            modalComponentsData={modalComponentsData()}
            onModalCancel={onModalCancel}
            modalSubmitOk={modalSubmitOk}
            currentItem={selectedFunnel}
          />
        ) : null}
        <div className="block__title">Funnels:</div>
        <div className="select_container">
          <SelectComponent
            mainField="name"
            data={currentCompany.funnels}
            value={selectedFunnel && selectedFunnel.name}
            handleChange={chooseFunnelHandler}
          />
        </div>
        <ButtonGroup data={buttons} />
      </div>
      {selectedFunnel && selectedFunnel.name ? (
        <FunnelStagePanel
          allCompanies={allCompanies}
          currentCompany={currentCompany}
          currentFunnel={currentCompany.funnels[currentCompany.funnels.findIndex((el) => el.id === selectedFunnel.id)]}
        />
      ) : (
        ""
      )}
    </>
  );
};

FunnelPanel.propTypes = {
  currentCompany: PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.number,
    name: PropTypes.string,
    attributes: PropTypes.array,
    consultants: PropTypes.array,
    funnels: PropTypes.array,
    performanceIndicators: PropTypes.array,
    questions: PropTypes.array,
    reportSettings: PropTypes.object,
    scripts: PropTypes.array,
    timezone: PropTypes.string,
    timezoneName: PropTypes.string,
    webhooks: PropTypes.array,
  }),
  allCompanies: PropTypes.array,
};

export default FunnelPanel;
