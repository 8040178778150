import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table, Typography } from "antd";
import {
  editActualGuidanceAction,
  deleteActualGuidanceAction,
  updateDoneStatus,
  updateSentiment,
  getAutoGuidances,
} from "../../../redux/actions/meetingsPlanActions";
import tableConfig from "./tableConfig";
import AddGuidanceModal from "../MeetingPlanConsultantGuidanceActions/AddGuidanceModal";
import MeetingPlanConsultantGuidanceActions from "../MeetingPlanConsultantGuidanceActions/MeetingPlanConsultantGuidanceActions";
import Popup from "../../../components/Popup/Popup";
import DeleteForm from "../../LeadsPage/LeadForm/DeleteForm";
import PropTypes from "prop-types";
import "./_meetingPlanConsultantGuidance.less";

function MeetingPlanConsultantGuidance({ actualGuidances, systemUsers, selectedTeamMember, dispatch }) {
  const [guidanceModalMode, setGuidanceModalMode] = useState("");
  const [isGuidanceModalOpen, setGuidanceModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [firstLoad, setFirstLoad] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!firstLoad) {
      dispatch(getAutoGuidances(selectedTeamMember));
    }
    setFirstLoad(false);
  }, [selectedTeamMember]);

  const transformActualGuidance = (guidances) => {
    return guidances.length
      ? guidances.map((guidance) => ({
          ...guidance,
          userName: systemUsers
            .filter((user) => guidance.userId.find((guidanceUserId) => guidanceUserId === user.id))
            .map((user) => user.name),
        }))
      : [];
  };

  const setGuidanceTableAction = (actionType, selectedRow) => {
    if (actionType === "delete") {
      setSelectedRow(selectedRow);
      setModalOpen(true);
    } else if (actionType === "edit") {
      setSelectedRow(selectedRow);
      setGuidanceModalMode(actionType);
      setGuidanceModalOpen(true);
    }
  };

  const handleGuidanceModal = () => setGuidanceModalOpen(!isGuidanceModalOpen);

  const editGuidance = (guidance) => {
    dispatch(
      editActualGuidanceAction({ guidance: { ...guidance, done: selectedRow.done }, guidanceIndex: selectedRow.key })
    );
    setSelectedRow({});
    setGuidanceModalOpen(false);
  };

  const onDoneCallback = (value, indexRow, type) => {
    dispatch(updateDoneStatus({ value, indexRow, type }));
  };

  const deleteGuidance = () => {
    dispatch(deleteActualGuidanceAction(selectedRow.key));
    setModalOpen(false);
  };

  const setSentimentCallback = (value, indexRow, type) => {
    dispatch(updateSentiment({ value, indexRow, type }));
  };

  const { getData, getColumns } = tableConfig(
    transformActualGuidance(actualGuidances),
    setGuidanceTableAction,
    onDoneCallback,
    setSentimentCallback
  );

  const onModalClose = () => {
    setModalOpen(false);
    setSelectedRow({});
  };

  return (
    <div className="consultant_guidance">
      <div className="config">
        <Typography.Title level={4}>Consultant guidance</Typography.Title>
        <MeetingPlanConsultantGuidanceActions />
      </div>
      <Table pagination={false} size="small" columns={getColumns} dataSource={getData} bordered={true} />
      {isGuidanceModalOpen ? (
        <AddGuidanceModal
          title={"Edit Guidance"}
          currentRow={selectedRow}
          guidanceModalMode={guidanceModalMode}
          isOpen={isGuidanceModalOpen}
          handleGuidanceModal={handleGuidanceModal}
          updateGuidance={editGuidance}
        />
      ) : (
        ""
      )}
      {(isModalOpen && (
        <Popup
          title={"Delete"}
          isModalVisible={isModalOpen}
          footer={null}
          className={"without_footer"}
          modalCancel={onModalClose}>
          <DeleteForm
            onCancel={onModalClose}
            onSubmit={deleteGuidance}
            title={"Are you sure you want to delete guidance?"}
          />
        </Popup>
      )) ||
        null}
    </div>
  );
}

function mapStateToProps(state) {
  const { actualGuidances, systemUsers, selectedTeamMember } = state.meetingPlan;
  return { actualGuidances, systemUsers, selectedTeamMember };
}

MeetingPlanConsultantGuidance.propTypes = {
  actualGuidances: PropTypes.array,
  systemUsers: PropTypes.arrayOf(PropTypes.object),
  selectedTeamMember: PropTypes.array,
  dispatch: PropTypes.func,
};

export default connect(mapStateToProps)(MeetingPlanConsultantGuidance);
