import React, { useEffect } from "react";
import { Row, Col, Form, Button } from "antd";
import TimezoneDropdown from "../../../components/TimezoneDropdown/TimezoneDropdown";
import API from "../../../services/apiService";
import { updateCurrentCompany } from "../../../redux/actions/adminPageActions";
import { useDispatch } from "react-redux";
import notificationAlert from "../../../utils/notificationAlert";
import { sortByName, transformTimezone } from "../../../utils/helpers";
import { timezonesMapping } from "../../../utils/mocks";
import PropTypes from "prop-types";
import "./_timezonePanel.less";

const TimezonePanel = ({ currentCompany }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  function getTimezoneOffset(timeZone) {
    const currentDate = new Date();
    const specificTimezone = currentDate.toLocaleString("en-US", { timeZone });
    const localTimezone = currentDate.toLocaleString("en-US");
    const timezoneDifference = (Date.parse(localTimezone) - Date.parse(specificTimezone)) / 3600000;
    const offset = timezoneDifference + currentDate.getTimezoneOffset() / 60;
    return -offset;
  }

  const mapTimezones = (timezones) => {
    return timezones.map((timezone) => {
      const offset = transformTimezone(getTimezoneOffset(timezone));
      return { label: `(${offset}) ${timezone}`, value: offset, name: timezone };
    });
  };

  const getTimezonesAndPrepare = () => mapTimezones(sortByName(timezonesMapping));

  useEffect(() => {
    form.setFieldsValue({ timezone: currentCompany.timezoneName });
  }, [currentCompany]);

  const onFinish = async ({ timezone }) => {
    const offset = transformTimezone(getTimezoneOffset(timezone));
    await API.patch(`company/${currentCompany.id}`, { ...currentCompany, timezone: offset, timezoneName: timezone });
    notificationAlert({
      type: "success",
      description: "Timezone has been successfully updated",
    });
    dispatch(updateCurrentCompany({ ...currentCompany, timezone: offset, timezoneName: timezone }));
  };

  return (
    <div className="timezone-panel">
      <Form
        form={form}
        name="timezone-panel__form"
        onFinish={onFinish}
        initialValues={{ timezone: currentCompany.timezoneName }}>
        <Row gutter={15}>
          <Col xs={1.5} sm={1.5} md={1.5} lg={1.5} className="title">
            Timezones:
          </Col>
          <Col xs={24} sm={16} md={10} lg={6} xl={6}>
            <Form.Item name="timezone" rules={[{ required: true, message: "Field is required" }]}>
              <TimezoneDropdown timezones={getTimezonesAndPrepare(timezonesMapping)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4}>
            <Form.Item>
              <Button className="btn save_timezone" type="success" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

TimezonePanel.propTypes = {
  currentCompany: PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.number,
    name: PropTypes.string,
    attributes: PropTypes.array,
    consultants: PropTypes.array,
    funnels: PropTypes.array,
    performanceIndicators: PropTypes.array,
    questions: PropTypes.array,
    reportSettings: PropTypes.object,
    scripts: PropTypes.array,
    timezone: PropTypes.string,
    timezoneName: PropTypes.string,
    webhooks: PropTypes.array,
  }),
};

export default TimezonePanel;
