import React, { useState, useMemo } from "react";
import DashboardComponent from "../../../components/Dashboard/Dashboard";
import { useDispatch } from "react-redux";
import Button from "antd/lib/button";
import ModalGroup from "../../../components/ModalGroup/ModalGroup";
import { editCompanyAction } from "../../../redux/actions/adminPageActions";
import API from "../../../services/apiService";
import PropTypes from "prop-types";
import notificationAlert from "../../../utils/notificationAlert";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./_configDashboardPanel.less";

const ConfigDashboardPanel = React.memo(({ allCompanies, currentCompany }) => {
  const dispatch = useDispatch();
  const [currentDashboard, setCurrentDashboard] = useState(0);
  const [modalMode, setModalMode] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);

  const createNewDashboard = async ({ name, value, type }) => {
    const updateCompanyObject = {
      ...currentCompany,
      performanceIndicators: [
        ...currentCompany.performanceIndicators,
        { name, value, type, companyId: currentCompany.id },
      ],
    };

    const updatedCompany = await API.patch(`company/${currentCompany.id}`, updateCompanyObject);
    const companyIndex = allCompanies.findIndex((company) => company.id === updatedCompany.id);
    notificationAlert({
      type: "success",
      description: "Dashboard has been successfully created",
    });
    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
  };

  const editDashboard = async ({ name, type, value }) => {
    const updateCompanyObject = {
      ...currentCompany,
      performanceIndicators: [
        ...currentCompany.performanceIndicators.slice(0, currentDashboard),
        {
          name,
          value,
          type,
          companyId: currentCompany.id,
          id: currentCompany.performanceIndicators[currentDashboard].id,
        },
        ...currentCompany.performanceIndicators.slice(currentDashboard + 1),
      ],
    };

    const updatedCompany = await API.patch(`company/${currentCompany.id}`, updateCompanyObject);
    const companyIndex = allCompanies.findIndex((company) => company.id === updatedCompany.id);
    notificationAlert({
      type: "success",
      description: "Dashboard has been successfully edited",
    });
    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
  };

  const deleteDashboard = async () => {
    const updateCompanyObject = {
      ...currentCompany,
      performanceIndicators: [
        ...currentCompany.performanceIndicators.slice(0, currentDashboard),
        ...currentCompany.performanceIndicators.slice(currentDashboard + 1),
      ],
    };
    const updatedCompany = await API.patch(`company/${currentCompany.id}`, updateCompanyObject);
    const companyIndex = allCompanies.findIndex((company) => company.id === updatedCompany.id);
    notificationAlert({
      type: "success",
      description: "Dashboard has been successfully deleted",
    });
    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
  };

  const modalSubmitOk = async (value) => {
    if (modalMode === "add") {
      await createNewDashboard(value);
    } else if (modalMode === "edit") {
      await editDashboard(value);
    } else if (modalMode === "delete") {
      await deleteDashboard();
    }
    setModalVisible(false);
  };

  const onModalCancel = () => {
    setModalVisible(false);
    setModalMode("");
  };

  const onModalOpen = (mode) => {
    setModalVisible(true);
    setModalMode(mode);
  };

  const onSetCurrentDashboard = (index) => setCurrentDashboard(index);

  const modals = [
    {
      title: "Delete",
      text: `Delete «${
        currentCompany &&
        currentCompany.performanceIndicators &&
        currentCompany.performanceIndicators[currentDashboard] &&
        currentCompany.performanceIndicators[currentDashboard].name
      }» dashboard?`,
      type: "delete",
      isVisible: isModalVisible,
    },
    { title: "Edit", text: "Edit dashboard", type: "edit", isVisible: isModalVisible },
    { title: "Add", text: "Add new dashboard", type: "add", isVisible: isModalVisible },
  ];

  const modalComponentsData = useMemo(
    () => () => {
      return [
        {
          name: "name",
          placeholder: "Choose KPI name",
          elementType: "select",
          elementList: [
            { name: "Dialing hours per employee" },
            { name: "Dials per employee" },
            { name: "Sales or 'Yes' targets per employee per week" },
          ],
        },
        {
          name: "type",
          placeholder: "Choose KPI type",
          elementType: "select",
          elementList: [{ name: "DAY" }, { name: "WEEK" }, { name: "MONTH" }],
        },
        { name: "value", placeholder: "Type KPI value", elementType: "input_number" },
      ];
    },
    []
  );

  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 900 }, items: 3, slidesToSlide: 3 },
    tablet: { breakpoint: { max: 900, min: 464 }, items: 2, slidesToSlide: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const getDashboards = () =>
    (currentCompany && currentCompany.performanceIndicators && currentCompany.performanceIndicators) || [];

  const showDots = () => getDashboards().length > 3;

  return (
    <div className="config-dashboards">
      {isModalVisible ? (
        <ModalGroup
          editableItemIdx={""}
          mainField={"text"}
          allItems={currentCompany && currentCompany.performanceIndicators}
          mode={modalMode}
          modals={modals}
          modalComponentsData={modalComponentsData()}
          onModalCancel={onModalCancel}
          modalSubmitOk={modalSubmitOk}
          currentItem={
            currentCompany &&
            currentCompany.performanceIndicators &&
            currentCompany.performanceIndicators[currentDashboard]
          }
        />
      ) : null}
      <div className="panel">
        <div className="block__title">Dashboards:</div>
        <Button className="btn" type="primary" onClick={() => onModalOpen("add")}>
          Add
        </Button>
      </div>
      <Carousel
        dotListClass="carousel_dots"
        responsive={responsive}
        showDots={showDots()}
        draggable={true}
        swipeable={true}
        removeArrowOnDeviceType={["mobile"]}
        containerClass="carousel_container"
        itemClass="carousel_item">
        {getDashboards().map((dashboard, index) => {
          return (
            <DashboardComponent
              onClick={() => onSetCurrentDashboard(index)}
              key={index}
              title={dashboard.name}
              value={dashboard.value}
              onDelete={() => onModalOpen("delete")}
              onEdit={() => onModalOpen("edit")}
            />
          );
        })}
      </Carousel>
    </div>
  );
});

ConfigDashboardPanel.propTypes = {
  currentCompany: PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.number,
    name: PropTypes.string,
    attributes: PropTypes.array,
    consultants: PropTypes.array,
    funnels: PropTypes.array,
    performanceIndicators: PropTypes.array,
    questions: PropTypes.array,
    reportSettings: PropTypes.object,
    scripts: PropTypes.array,
    timezone: PropTypes.string,
    timezoneName: PropTypes.string,
    webhooks: PropTypes.array,
  }),
  allCompanies: PropTypes.array,
};

export default ConfigDashboardPanel;
