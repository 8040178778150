import React, { useState, useEffect } from "react";
import UserButtonGroup from "../../components/UserButtonGroup/UserButtonGroup";
import QuestionModal from "./QuestionModal";
import { Col, Spin } from "antd";
import PropTypes, { oneOfType } from "prop-types";
import usePersistedState from "../../hooks/usePersistedState";
import "./_script-viewer-page.less";

const setInitialAnswersList = (allScriptStages) => {
  return allScriptStages.map((scriptStage) => {
    return {
      name: scriptStage.name,
      questions: scriptStage.questions.map((question) => {
        return {
          name: question.name,
          isShown: question.isShown,
          id: question.id,
          answer: null,
        };
      }),
    };
  });
};

export default function QuestionsBlock({ questions, currentStage, allScriptStages }) {
  const [isEndReasonVisible, setEndReasonVisible] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({}); //selected question on current stage
  const [answersList, setAnswers] = usePersistedState("answersList", []); //selected answer in each question
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const data = answersList.length ? answersList : setInitialAnswersList(allScriptStages);
    setAnswers(data);
    setLoading(false);
  }, []);

  const openQuestion = (question) => {
    setSelectedQuestion(question);
    setEndReasonVisible(true);
  };

  const finishQuiz = (answer) => {
    let updatedAnswersListClone = Object.assign([], answersList);
    let questionId = updatedAnswersListClone[currentStage].questions.find(
      (question) => question.name === selectedQuestion.name
    ).id;
    let updatedAnswersList = updatedAnswersListClone.map((answerClone) => {
      return {
        ...answerClone,
        questions: answerClone.questions.map((question) => {
          if (question.id === questionId) {
            question.answer = answer;
            return question;
          }
          return question;
        }),
      };
    });

    setAnswers(updatedAnswersList);
    setEndReasonVisible(false);
    setSelectedQuestion({});
  };

  const closeQuiz = () => {
    setEndReasonVisible(false);
    setSelectedQuestion({});
  };

  const quizButtons = () => {
    return questions.map((question, index) => {
      return {
        name: question.name,
        action: () => openQuestion(question),
        visible: true,
        theme: "blue-empty",
        themeActive: "orange",
        isActive:
          answersList[currentStage].questions[index] && answersList[currentStage].questions[index].answer !== null,
      };
    });
  };

  const getAnsweredQuestion = () => {
    if (Object.entries(selectedQuestion).length) {
      let currentQuestionIdx = questions.findIndex((el) => el.name === selectedQuestion.name);
      return answersList && answersList[currentStage]
        ? answersList[currentStage].questions[currentQuestionIdx].answer
        : {};
    }
    return {};
  };

  const resetAnswer = (selectedQuestion) => {
    setAnswers((list) => {
      return [
        ...list.slice(0, currentStage),
        list[currentStage].questions.map((question) => {
          if (question.id === selectedQuestion.questionId) {
            question.answer = null;
            return question;
          }
          return question;
        }),
        ...list.slice(currentStage + 1),
      ];
    });
  };

  if (loading) {
    return (
      <div className="spinner bottom">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
      <div className="quiz_section">
        <UserButtonGroup data={quizButtons()} />
      </div>
      {isEndReasonVisible ? (
        <QuestionModal
          mode="question"
          resetAnswer={resetAnswer}
          selectedQuestion={selectedQuestion}
          selected={getAnsweredQuestion()}
          title={"Question/Select an answer to the current question"}
          isVisible={isEndReasonVisible}
          items={Object.keys(selectedQuestion).length ? selectedQuestion.params : []}
          finish={finishQuiz}
          closeModal={closeQuiz}
        />
      ) : (
        ""
      )}
    </Col>
  );
}

QuestionsBlock.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      companyId: PropTypes.string,
      id: oneOfType([PropTypes.string, PropTypes.number]),
      isShown: PropTypes.number,
      name: PropTypes.shape,
      params: PropTypes.array,
      questionType: PropTypes.string,
    })
  ),
  currentStage: PropTypes.number,
  allScriptStages: PropTypes.arrayOf(
    PropTypes.shape({
      endReasons: PropTypes.array,
      id: oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      objections: PropTypes.array,
      order: PropTypes.number,
      questions: PropTypes.array,
      script: PropTypes.string,
    })
  ),
};
