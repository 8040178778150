import {
  SET_MEETING_DATE,
  SET_TEAM_MEMBERS,
  SET_GUIDANCE_ACTION,
  ADD_ACTUAL_GUIDANCE,
  EDIT_ACTUAL_GUIDANCE,
  DELETE_ACTUAL_GUIDANCE,
  UPDATE_PLANNING_NOTES,
  UPDATE_MEETING_NOTES,
  CLEAR_MEETING_PLAN,
  SET_ALL_MEETING_PLAN_SUCCESS,
  SET_ALL_MEETING_PLAN_REQUEST,
  SET_GUIDANCE_REQUEST,
  UPDATE_DONE_STATUS_ACTUAL_GUIDANCE,
  UPDATE_SENTIMENT_ACTUAL_GUIDANCE,
  GET_AUTO_GUIDANCES_SUCCESS,
  UNSET_TEAM_MEMBERS,
  SET_ALL_USERS_SUCCESS,
} from "../actions/meetingsPlanActions";

export const initialState = {
  isAllDataLoading: false,
  systemUsers: [],
  date: "",
  selectedTeamMembers: [],
  selectedTeamMember: [],
  guidance: [],
  actualGuidances: [],
  planningNotes: "",
  meetingNotes: "",
  status: "Planned",
  consultantId: null,
  isGuidanceLoading: false,
  isSaveData: false,
};

export const meetingPlanReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case SET_ALL_USERS_SUCCESS:
      return { ...state, systemUsers: payload };
    case SET_ALL_MEETING_PLAN_REQUEST:
      return { ...state, isAllDataLoading: true };
    case SET_ALL_MEETING_PLAN_SUCCESS:
      return {
        ...state,
        selectedTeamMembers:
          payload.meetingPlanData && payload.meetingPlanData.plannedGuidances
            ? payload.meetingPlanData.users.map((item) => item.id)
            : [],
        guidance:
          payload.meetingPlanData && payload.meetingPlanData.plannedGuidances
            ? payload.meetingPlanData.plannedGuidances
            : [],
        isAllDataLoading: false,
        ...payload.meetingPlanData,
      };
    case SET_MEETING_DATE:
      return { ...state, date: payload };
    case SET_TEAM_MEMBERS:
      if (Array.isArray(payload)) {
        return {
          ...state,
          selectedTeamMembers: [...state.selectedTeamMembers, ...payload],
          selectedTeamMember: payload,
        };
      }
      return { ...state, selectedTeamMembers: [...state.selectedTeamMembers, payload], selectedTeamMember: [payload] };

    case UNSET_TEAM_MEMBERS:
      let actualGuidances = state.actualGuidances.filter((guidance) => {
        return guidance.userId[0] !== payload;
      });

      return {
        ...state,
        actualGuidances: actualGuidances,
        selectedTeamMember: [],
        selectedTeamMembers: state.selectedTeamMembers.filter((member) => member !== payload),
      };

    case SET_GUIDANCE_ACTION:
      return { ...state, guidance: payload.guidance, isGuidanceLoading: false };
    case SET_GUIDANCE_REQUEST:
      return { ...state, isGuidanceLoading: true };
    case UPDATE_PLANNING_NOTES:
      return { ...state, planningNotes: payload, isSaveData: true };
    case UPDATE_MEETING_NOTES:
      return { ...state, meetingNotes: payload, isSaveData: true };
    case CLEAR_MEETING_PLAN:
      return initialState;
    case ADD_ACTUAL_GUIDANCE:
      return { ...state, actualGuidances: [...state.actualGuidances, payload], isSaveData: true };
    case EDIT_ACTUAL_GUIDANCE:
      return {
        ...state,
        actualGuidances: [
          ...state.actualGuidances.slice(0, payload.guidanceIndex),
          payload.guidance,
          ...state.actualGuidances.slice(payload.guidanceIndex + 1),
        ],
        isSaveData: true,
      };
    case DELETE_ACTUAL_GUIDANCE:
      return {
        ...state,
        actualGuidances: state.actualGuidances.filter((guidance, index) => index !== payload),
        isSaveData: true,
      };

    case UPDATE_DONE_STATUS_ACTUAL_GUIDANCE:
      return {
        ...state,
        actualGuidances: state.actualGuidances.map((guidance, index) => {
          if (index === payload.indexRow) {
            return { ...guidance, done: payload.value };
          }
          return guidance;
        }),
        isSaveData: true,
      };

    case UPDATE_SENTIMENT_ACTUAL_GUIDANCE:
      return {
        ...state,
        actualGuidances: state.actualGuidances.map((guidance, index) => {
          if (index === payload.indexRow) {
            return { ...guidance, sentiment: payload.value };
          }
          return guidance;
        }),
        isSaveData: true,
      };

    case GET_AUTO_GUIDANCES_SUCCESS:
      return {
        ...state,
        actualGuidances: [...state.actualGuidances, ...payload],
      };
    default:
      return state;
  }
};
