import {
  TOGGLE_SLIDER_MENU,
  LOG_IN,
  CLOSE_SLIDER_MENU,
  SET_ACTIVE_PAGE,
  UNSET_ACTIVE_PAGE,
} from "../actions/commonActions";

const initialState = {
  isSliderMenuOpen: true,
  user: {},
  activePage: "Home",
  prevDaysStats: [],
};

export const commonReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case LOG_IN:
      return { ...state, user: payload };

    case TOGGLE_SLIDER_MENU:
      return { ...state, isSliderMenuOpen: !state.isSliderMenuOpen };

    case CLOSE_SLIDER_MENU:
      return { ...state, isSliderMenuOpen: false };

    case SET_ACTIVE_PAGE:
      return { ...state, activePage: payload };

    case UNSET_ACTIVE_PAGE:
      return { ...state, activePage: null };

    default:
      return state;
  }
};
