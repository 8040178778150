import React, { useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  choseCompany,
  choseUser,
  choseDate,
  updateDashboardKPI,
  updateFiltersByParam,
} from "../../../redux/actions/dashboardPageActions/dashboardPageActions";
import MiAutocomplete from "../../../components/Autocomplete/Autocomplete";
import RangePicker from "../../../components/RangePicker/RangePicker";
import { APP_DATE_FORMATS } from "../../../utils/vars";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import UsersDropdown from "../../../components/UsersDropdown/UsersDropdown";
import "./_dashboardFIlterSection.less";

const START_TIME = "00:00:00";
const END_TIME = "23:59:59";

function DashboardFiltersSection() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const companies = useSelector((state) => state.dashboard.companies);
  const activeCompany = useSelector((state) => state.dashboard.activeCompany);
  const activeUsers = useSelector((state) => state.dashboard.activeUsers);
  const users = useSelector((state) => state.dashboard.users);
  const { endDate, startDate } = useSelector((state) => state.dashboard.date);

  const getSearchUrl = ({ chosenCompanyId, sDate, eDate, usersId }) => {
    const company = chosenCompanyId ? "&companyId=" + chosenCompanyId : "";
    const dateStart = sDate ? "&startDate=" + sDate : "";
    const dateEnd = eDate ? "&endDate=" + eDate : "";
    const users = usersId.length ? usersId.map((user) => "&userId[]=" + user.id).join("") : "";
    let res = `?${company}${dateStart}${dateEnd}${users}`;
    return res[1] === "&" ? res.substring(0, 1) + res.substring(2, res.length) : res;
  };

  useEffect(() => {
    if (location.search) {
      dispatch(updateFiltersByParam(location.search));
    }
  }, []);

  const chooseDateHandler = (dates, dateStrings) => {
    if (!dates) {
      dispatch(choseDate({ startDate: null, endDate: null }));
      history.push({
        pathname: "/dashboard",
        search: getSearchUrl({
          chosenCompanyId: activeCompany?.id,
          sDate: null,
          eDate: null,
          usersId: activeUsers,
        }),
      });
      dispatch(updateDashboardKPI(new Date()));
      return;
    }
    dispatch(
      choseDate({
        startDate: `${dateStrings[0]} ${START_TIME}`,
        endDate: `${dateStrings[1]} ${END_TIME}`,
      })
    );
    history.push({
      pathname: "/dashboard",
      search: getSearchUrl({
        chosenCompanyId: activeCompany?.id,
        sDate: `${dateStrings[0]} ${START_TIME}`,
        eDate: `${dateStrings[1]} ${END_TIME}`,
        usersId: activeUsers,
      }),
    });
    dispatch(updateDashboardKPI(new Date()));
  };

  const chooseCompanyHandler = (companyName) => {
    const chosenCompany = companies.find((company) => company.name === companyName);
    if (chosenCompany && chosenCompany.name !== (activeCompany && activeCompany.name)) {
      history.push({
        pathname: "/dashboard",
        search: getSearchUrl({
          chosenCompanyId: chosenCompany.id,
          sDate: startDate,
          eDate: endDate,
          usersId: activeUsers,
        }),
      });
      dispatch(choseCompany(chosenCompany));
      dispatch(updateDashboardKPI(new Date()));
    }
  };

  const chooseUserHandler = (usersNames) => {
    if (usersNames) {
      const chosenUsers =
        users && users.length ? users.filter((user) => usersNames.some((name) => name === user.id)) : [];
      dispatch(choseUser(chosenUsers));
      history.push({
        pathname: "/dashboard",
        search: getSearchUrl({
          chosenCompanyId: activeCompany?.id,
          sDate: startDate,
          eDate: endDate,
          usersId: chosenUsers,
        }),
      });
      dispatch(updateDashboardKPI(new Date()));
    }
  };

  return (
    <div>
      <Row className="employeeSummaryConfig" gutter={[14, 14]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <MiAutocomplete
            testId="company_select"
            value={activeCompany ? activeCompany.name : null}
            onSelect={chooseCompanyHandler}
            data={companies}
            field={"name"}
            placeholder={`Company name`}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <UsersDropdown
            className={"full_width"}
            value={activeUsers.length ? activeUsers.map((user) => user.id) : []}
            allData={users}
            placeholder={"Company user"}
            mode={"multiple"}
            valueField={"id"}
            optionField={"name"}
            onChange={chooseUserHandler}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <RangePicker
            testId="date_picker"
            format={APP_DATE_FORMATS.DATE_Y_M_D}
            endDate={endDate}
            startDate={startDate}
            onChange={chooseDateHandler}
          />
        </Col>
      </Row>
    </div>
  );
}

export default DashboardFiltersSection;
