import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import CompanyDropdown from "../../../components/CompanyDropdown/CompanyDropdown";
import {
  getAdditionalLeadsData,
  resetCompany,
  setSelectedCompany,
  setSelectedLeads,
  updateLeadTable,
} from "../../../redux/actions/leadsActions";
import { applicationRoles } from "../../../utils/mocks";
import notificationAlert from "../../../utils/notificationAlert";
import "./_leadsCompanySection.less";

const { ADMIN } = applicationRoles;

export default function LeadsCompanySection() {
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.common.user);
  const allCompanies = useSelector((state) => state.leads.allCompanies);
  const selectedCompany = useSelector((state) => state.leads.selectedCompany);

  const isCompaniesLoading = useSelector((state) => state.leads.isCompaniesLoading);

  const resetChosenCompany = () => dispatch(resetCompany());

  const changeCompanyHandler = async (chosenCompany) => {
    if (!selectedCompany || (selectedCompany && selectedCompany.name !== chosenCompany)) {
      const selectedCompany = allCompanies.find((company) => company.name === chosenCompany);

      if (!selectedCompany) {
        return notificationAlert({
          duration: 3,
          type: "error",
          description: "There is no company with that name",
        });
      }

      dispatch(setSelectedCompany(selectedCompany));
      dispatch(setSelectedLeads([]));
      dispatch(getAdditionalLeadsData(selectedCompany.id));
      dispatch(updateLeadTable({ isUpdated: new Date() }));
    }
  };

  return (
    <div className="companySectionWrapper">
      <div className="companySection">
        <CompanyDropdown
          loading={isCompaniesLoading}
          onSelect={(chosenCompany) => changeCompanyHandler(chosenCompany)}
          value={selectedCompany && selectedCompany.name}
          allData={allCompanies}
          optionField="name"
        />
        {loggedUser.role === ADMIN ? (
          <Button type="primary" className="btn" onClick={resetChosenCompany}>
            Reset
          </Button>
        ) : null}
      </div>
    </div>
  );
}
