import React, { useEffect, useState } from "react";
import ConfigCompanyPanel from "./ConfigCompanyPanel/ConfigCompanyPanel";
import ConfigUserPanel from "./ConfigUserPanel/ConfigUserPanel";
import ConfigDashboardPanel from "./ConfigDashboardPanel/ConfigDashboardPanel";
import ConfigAttributesPanel from "./ConfigAttributesPanel/ConfigAttributesPanel";
import WebhookPanel from "./ConfigWebhookPanel/WebhookPanel";
import FunnelPanel from "./FunelPanel/FunnelPanel";
import TimezonePanel from "./ConfigTimezonePanel/TImezonePanel";
import { setInitialCompanies } from "../../redux/actions/adminPageActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import withCallClear from "../../hocks/withCallClear";
import { Spin, Typography, Tabs } from "antd";
import { applicationRoles, routes } from "../../utils/mocks";
import StatsScheduleTab from "./StatsScheduleTab/StatsScheduleTab";
import ScriptPage from "../ScriptPage/ScriptPage";
import useActivePage from "../../hooks/useActivePage";
import withPermission from "../../hocks/withPermission";
import Reports from "./ReportsTab/ReportsTab";
import "./_admin-page.less";
const { ADMIN } = applicationRoles;

const { TabPane } = Tabs;

const COMPANY_SETTING_TABS = ["General", "Users", "Funnels", "Scripts", "Stats schedule", "Reports"];

const WithPermissionDashboardPanel = withPermission(ConfigDashboardPanel);
const WithPermissionAttributesPanel = withPermission(ConfigAttributesPanel);

function AdminPage() {
  useActivePage(routes.ADMIN);

  const dispatch = useDispatch();
  const allCompanies = useSelector((state) => state.setting.allCompanies, shallowEqual);
  const currentCompany = useSelector((state) => state.setting.currentCompany, shallowEqual);
  const companyUsers = useSelector((state) => state.setting.companyUsers, shallowEqual);
  const [activeTab, setActiveTab] = useState("General");
  const loading = useSelector((state) => state.setting.loading);
  const user = useSelector((state) => state.common.user);

  useEffect(() => {
    dispatch(setInitialCompanies(user));
  }, []);

  const chooseCompanyTabHandler = (activeTab) => setActiveTab(activeTab);

  const getTabContent = (tab, activeTab) => {
    if (tab === "Users") {
      return <ConfigUserPanel loggedUser={user} currentCompany={currentCompany} allCompanies={allCompanies} />;
    } else if (tab === "General") {
      return (
        <>
          <TimezonePanel currentCompany={currentCompany} />
          <WithPermissionDashboardPanel roles={[ADMIN]} allCompanies={allCompanies} currentCompany={currentCompany} />
          <WithPermissionAttributesPanel roles={[ADMIN]} allCompanies={allCompanies} currentCompany={currentCompany} />
          <WebhookPanel allCompanies={allCompanies} currentCompany={currentCompany} />
        </>
      );
    } else if (tab === "Funnels") {
      return <FunnelPanel allCompanies={allCompanies} currentCompany={currentCompany} />;
    } else if (tab === "Scripts" && activeTab === "Scripts") {
      return <ScriptPage currentCompany={currentCompany} />;
    } else if (tab === "Stats schedule" && activeTab === "Stats schedule") {
      return <StatsScheduleTab companyUsers={companyUsers} currentCompany={currentCompany} />;
    } else if (tab === "Reports" && activeTab === "Reports") {
      return <Reports currentCompany={currentCompany} allCompanies={allCompanies} />;
    }
  };

  if (loading) {
    return (
      <div className="spinner">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="admin-page">
      <div className="fluid_container">
        <div className="admin-page__inner">
          <Typography.Title className="admin-page__title" level={2}>
            Company settings
          </Typography.Title>
          <div className="admin-page__setting">
            <ConfigCompanyPanel
              allCompanies={allCompanies}
              currentCompany={currentCompany}
              chooseCompanyTabHandler={chooseCompanyTabHandler}
            />
            {currentCompany && currentCompany.id && (
              <Tabs
                type="card"
                position="left"
                className="admin-page__tabs"
                onChange={chooseCompanyTabHandler}
                activeKey={activeTab}>
                {COMPANY_SETTING_TABS.map((tab) => {
                  return (
                    <TabPane tab={<div className="tab-wrapper chip">{tab}</div>} closable={false} key={tab}>
                      {getTabContent(tab, activeTab)}
                    </TabPane>
                  );
                })}
              </Tabs>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withCallClear(AdminPage);
