import React from "react";
import { Space, Dropdown, Menu, Tooltip } from "antd";
import { changeSortDirectionTitle } from "../../utils/helpers";
import { DeleteOutlined, EditOutlined, FilterFilled, SearchOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import FilterDropdownLead from "../LeadsPage/FilterDropdownLead";
import CheckboxFilter from "../../components/CheckboxFIlter/CheckboxFilter";

function consultantTableConfig(consultants, tableSettings, filterByField, companies, onModalOpen) {
  const getData = () =>
    consultants &&
    consultants.map(({ name, companies, email, id }) => {
      return {
        name,
        company: companies.length
          ? companies.reduce((acc, company, index, allItems) => {
              if (allItems.length - 1 === index) {
                return acc + company.name;
              }
              return acc + company.name + ";";
            }, "")
          : "",
        email,
        key: id,
      };
    });

  const getFilteredFieldValue = (fieldName) => {
    return tableSettings.filters.find((filter) => filter.hasOwnProperty(fieldName))[fieldName];
  };

  const action_menu = (companies) => {
    return (
      <Menu className="overlay_menu">
        {companies.map((company, idx) => {
          return <Menu.Item key={idx}>{company}</Menu.Item>;
        })}
      </Menu>
    );
  };

  function getColumns() {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        className: "name-column",
        align: "center",
        ellipsis: true,
        render: (text) => {
          return <span>{text}</span>;
        },
        sorter: () => null,
        sortOrder: tableSettings.sortedField === "name" ? changeSortDirectionTitle(tableSettings.sortedDirection) : "",
        filterIcon: () => {
          return (
            <SearchOutlined
              data-testid="consultantNameFilter"
              style={{ color: getFilteredFieldValue("f_name") ? "#1890ff" : "" }}
            />
          );
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          const filterBy = filterByField.bind(null, "f_name");
          return (
            <FilterDropdownLead
              defaultValue={getFilteredFieldValue("f_name")}
              onSearch={filterBy}
              onReset={() => {}}
              dataIndex={"name"}
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
            />
          );
        },
      },
      {
        title: "Email",
        className: "email-column",
        dataIndex: "email",
        align: "center",
        key: "email",
        ellipsis: true,
        sorter: () => null,
        sortOrder: tableSettings.sortedField === "email" ? changeSortDirectionTitle(tableSettings.sortedDirection) : "",
        filterIcon: () => {
          return (
            <SearchOutlined
              data-testid="consultantEmailFilter"
              style={{ color: getFilteredFieldValue("f_email") ? "#1890ff" : "" }}
            />
          );
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          const filterBy = filterByField.bind(null, "f_email");
          return (
            <FilterDropdownLead
              defaultValue={getFilteredFieldValue("f_email")}
              onSearch={filterBy}
              onReset={() => {}}
              dataIndex={"email"}
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
            />
          );
        },
      },
      {
        title: "Companies",
        dataIndex: "company",
        key: "company",
        className: "company-column",
        align: "center",
        filterIcon: () => {
          return (
            <FilterFilled
              data-testid="consultantCompanyFilter"
              style={{ color: getFilteredFieldValue("f_companyId").length ? "#1890ff" : "" }}
            />
          );
        },
        filterDropdown: ({ confirm, clearFilters }) => {
          const filterBy = filterByField.bind(null, "f_companyId");
          return (
            <CheckboxFilter
              clearFilters={clearFilters}
              defaultValue={getFilteredFieldValue("f_companyId")}
              data={companies}
              search={filterBy}
              confirm={confirm}
              dataIndex={"f_companyId"}
            />
          );
        },
        render: (text) => {
          const allCompanies = text.split(";").sort();
          const visibleCompanies = allCompanies.slice(0, 3);
          return (
            <>
              <span className="dropdown-text">
                {visibleCompanies.map((visibleCompany, index) => {
                  return <p key={index}>{visibleCompany}</p>;
                })}
              </span>
              <Dropdown trigger={["click"]} placement="bottomCenter" overlay={() => action_menu(allCompanies)}>
                <span className="dropdown-text">
                  {allCompanies.length > 3 && (
                    <span className="ant-dropdown-link" style={{ color: "#1890ff" }}>
                      more...
                    </span>
                  )}
                </span>
              </Dropdown>
            </>
          );
        },
      },
      {
        title: "Actions",
        key: "action",
        align: "center",
        className: "action_column",
        fixed: "right",
        render: (text, record) => {
          record = { ...record, id: record.key };
          return (
            <Space size="middle" className="action_group desktop_actions">
              <Tooltip placement="topLeft" title={"Assign company"}>
                <span
                  className="icon assign"
                  data-testid={"assignAction"}
                  onClick={() => onModalOpen("assign", record)}>
                  <UsergroupAddOutlined style={{ fontSize: "18px" }} className="icon" />
                </span>
              </Tooltip>
              <Tooltip placement="topLeft" title={"Edit consultant"}>
                <span className="icon edit" data-testid={"editAction"} onClick={() => onModalOpen("edit", record)}>
                  <EditOutlined style={{ fontSize: "18px" }} className="icon icon_edit" />
                </span>
              </Tooltip>
              <Tooltip placement="topLeft" title={"Delete consultant"}>
                <span
                  className="action_link delete"
                  data-testid={"deleteAction"}
                  onClick={() => onModalOpen("delete", record)}>
                  <DeleteOutlined style={{ fontSize: "18px" }} className="icon icon_delete" />
                </span>
              </Tooltip>
            </Space>
          );
        },
      },
    ];
  }

  return [getData(), getColumns()];
}

export default consultantTableConfig;
