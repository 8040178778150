import React, { useEffect, useState } from "react";
import { setAllDataAction } from "../../redux/actions/scriptPageActions";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Collapse } from "antd";
import ScriptTabsForm from "./ScriptTabsForm";
import ScriptControlSection from "./ScriptControlSection";
import ScriptAdmin from "./ScriptAdmin/ScriptAdmin";
import ScriptGeneralQuestionsPanel from "./ScriptGeneralQuestionPanel/ScriptGeneralQuestionsPanel";
import withCallClear from "../../hocks/withCallClear";
import PropTypes from "prop-types";
import "./_script-page.less";

const { Panel } = Collapse;

function ScriptPage({ currentCompany }) {
  const dispatch = useDispatch();
  const currentScript = useSelector((state) => state.script.currentScript);
  const allScripts = useSelector((state) => state.script.allScripts);
  const allCompanies = useSelector((state) => state.script.allCompanies);
  const allDataLoading = useSelector((state) => state.script.allDataLoading);
  const isDataLoadingForm = useSelector((state) => state.script.isDataLoadingForm);
  const allQuestions = useSelector((state) => state.script.allQuestions);
  const [isFormConfigOpen, setFormConfig] = useState("0");

  useEffect(() => {
    const currentCompanyId = currentCompany.id;
    dispatch(setAllDataAction(currentCompanyId, currentScript));
  }, [currentCompany]);

  const panelOpenHandler = (value) => {
    setFormConfig(value);
    localStorage.setItem("isFormConfigOpen", value);
  };

  if (allDataLoading) {
    return (
      <div className="spinner">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="script-page">
      <ScriptAdmin
        currentCompany={currentCompany}
        currentScript={currentScript}
        allScripts={allScripts}
        allCompanies={allCompanies}
      />
      <ScriptGeneralQuestionsPanel questions={allQuestions} />
      {Object.entries(currentScript).length ? (
        <>
          <Collapse accordion activeKey={isFormConfigOpen} onChange={panelOpenHandler}>
            <Panel header="Script Details:">
              <ScriptTabsForm currentScript={currentScript} isDataLoadingForm={isDataLoadingForm} />
              <ScriptControlSection isDataLoadingForm={isDataLoadingForm} />
            </Panel>
          </Collapse>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

ScriptPage.propTypes = {
  currentCompany: PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.number,
    name: PropTypes.string,
    attributes: PropTypes.array,
    consultants: PropTypes.array,
    funnels: PropTypes.array,
    performanceIndicators: PropTypes.array,
    questions: PropTypes.array,
    reportSettings: PropTypes.object,
    scripts: PropTypes.array,
    timezone: PropTypes.string,
    timezoneName: PropTypes.string,
    webhooks: PropTypes.array,
  }),
};

export default withCallClear(ScriptPage);
