import React from "react";
import { Dropdown, Menu, Tag } from "antd";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import FilterDropdownLead from "../LeadsPage/FilterDropdownLead";
import { transformAndGetDataWithOffset } from "../../utils/helpers";
import CheckboxFilter from "../../components/CheckboxFIlter/CheckboxFilter";

export default function tableConfig({ companies, sortSettings, filterByField, filterSetting }) {
  let getCompanyName = (companyId) => {
    if (!companyId) {
      return null;
    }

    let company = companies.find((company) => company.id === companyId);
    if (company && company.name) {
      return <Tag className="item_tag">{companies.find((company) => company.id === companyId).name}</Tag>;
    } else {
      return null;
    }
  };

  const action_menu = (guidanceMessages) => {
    return (
      <Menu className="consultant overlay_menu">
        {guidanceMessages.map((message, messageIdx) => {
          return <Menu.Item key={messageIdx}>{message}</Menu.Item>;
        })}
      </Menu>
    );
  };

  const renderGuidance = (guidanceTitle) => {
    if (guidanceTitle) {
      let guidance = guidanceTitle.split("\n").sort();
      const visibleGuidance = guidance.slice(0, 2);
      return (
        <>
          <span className="dropdown-text">
            {visibleGuidance.map((g, index) => {
              return (
                <Tag className="item_tag" key={index}>
                  {g}
                </Tag>
              );
            })}
          </span>
          <Dropdown trigger={["click"]} placement="bottomCenter" overlay={() => action_menu(guidance)}>
            <span className="dropdown-text">
              {guidance.length > 2 && (
                <span className="ant-dropdown-link" style={{ color: "#1890ff" }}>
                  more...
                </span>
              )}
            </span>
          </Dropdown>
        </>
      );
    }
  };

  const getFilteredFieldValue = (fieldName) => {
    return filterSetting.find((filter) => filter.hasOwnProperty(fieldName))[fieldName];
  };

  let getColumns = () => {
    return [
      {
        className: "date_column",
        name: "Date",
        title: "Date",
        dataIndex: "date",
        key: "date",
        align: "center",
        sorter: () => null,
        sortOrder: sortSettings.sortedField === "date" ? sortSettings.sortedDirection : "",
        render: (date) => transformAndGetDataWithOffset(date, "yyyy-MM-dd HH:mm"),
      },
      {
        className: "timezone_column",
        name: "companyTimezone",
        title: "Timezone",
        dataIndex: "companyTimezone",
        key: "companyTimezone",
        align: "center",
        render: (companyTimezone) => {
          return companyTimezone;
        },
      },
      {
        className: "name_column",
        name: "Name",
        title: "Name",
        dataIndex: "name",
        key: "name",
        align: "center",
        sorter: () => null,
        sortOrder: sortSettings.sortedField === "name" ? sortSettings.sortedDirection : "",
        filterIcon: () => {
          return <SearchOutlined style={{ color: getFilteredFieldValue("f_name") ? "#1890ff" : "" }} />;
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          const filterBy = filterByField.bind(null, "f_name");
          return (
            <FilterDropdownLead
              defaultValue={getFilteredFieldValue("f_name")}
              onSearch={filterBy}
              onReset={() => {}}
              dataIndex={"name"}
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
            />
          );
        },
        render: (text) => text,
      },
      {
        className: "email_column",
        name: "Email",
        title: "Email",
        dataIndex: "email",
        key: "email",
        align: "center",
        sorter: () => null,
        sortOrder: sortSettings.sortedField === "email" ? sortSettings.sortedDirection : "",
      },
      {
        className: "stat_column",
        name: "Calls",
        title: "Calls",
        dataIndex: "calls",
        key: "calls",
        align: "center",
        sorter: () => null,
        sortOrder: sortSettings.sortedField === "calls" ? sortSettings.sortedDirection : "",
      },

      {
        className: "conversation_column",
        name: "Conversations",
        title: "Conversations",
        dataIndex: "conversations",
        key: "conversations",
        align: "center",
        sorter: () => null,
        sortOrder: sortSettings.sortedField === "conversations" ? sortSettings.sortedDirection : "",
      },

      {
        className: "stat_column",
        name: "Pitches",
        title: "Pitches",
        dataIndex: "pitches",
        key: "pitches",
        align: "center",
        sorter: () => null,
        sortOrder: sortSettings.sortedField === "pitches" ? sortSettings.sortedDirection : "",
      },

      {
        className: "stat_column",
        name: "Sales",
        title: "Sales",
        dataIndex: "sales",
        key: "sales",
        align: "center",
        sorter: () => null,
        sortOrder: sortSettings.sortedField === "sales" ? sortSettings.sortedDirection : "",
      },

      {
        className: "stat_column",
        name: "Cash",
        title: "Cash",
        dataIndex: "cash",
        key: "cash",
        align: "center",
        sorter: () => null,
        sortOrder: sortSettings.sortedField === "cash" ? sortSettings.sortedDirection : "",
      },

      {
        className: "stat_column",
        name: "Sales value",
        title: "Value",
        dataIndex: "salesValue",
        key: "salesValue",
        align: "center",
        sorter: () => null,
        sortOrder: sortSettings.sortedField === "salesValue" ? sortSettings.sortedDirection : "",
      },
      {
        className: "guidance_column",
        name: "Guidance",
        title: "Guidance",
        dataIndex: "guidance",
        key: "guidance",
        align: "center",
        render: (guidanceTitle) => renderGuidance(guidanceTitle),
      },
      {
        className: "company_column",
        name: "Company",
        title: "Company",
        dataIndex: "company_id",
        key: "company_id",
        align: "center",
        render: (companyId) => {
          if (companyId) {
            return getCompanyName(companyId);
          }
          return "";
        },
        filterIcon: () => {
          return (
            <FilterFilled
              data-testid="consultantEmailFilter"
              style={{ color: getFilteredFieldValue("f_companyId").length ? "#1890ff" : "" }}
            />
          );
        },
        filterDropdown: ({ confirm, clearFilters }) => {
          const filterBy = filterByField.bind(null, "f_companyId");
          return (
            <CheckboxFilter
              clearFilters={clearFilters}
              defaultValue={getFilteredFieldValue("f_companyId")}
              data={companies}
              search={filterBy}
              confirm={confirm}
              dataIndex={"f_companyId"}
            />
          );
        },
      },
    ];
  };

  return { getColumns: getColumns() };
}
