import React, { useCallback, useState } from "react";
import {
  setNewScriptAction,
  deleteScriptAction,
  updateScriptNameAction,
  setNewScriptToSelectAction,
  updateScriptStatusAction,
  addMovedScriptAction,
} from "../../../redux/actions/scriptPageActions";
import { useDispatch, useSelector } from "react-redux";
import ModalGroup from "../../../components/ModalGroup/ModalGroup";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import { Switch, Collapse } from "antd";
import API from "../../../services/apiService";
import PropTypes from "prop-types";
import notificationAlert from "../../../utils/notificationAlert";
import ScriptDropdown from "../../../components/ScriptDropdown/ScriptDropdown";
import usePersistedState from "../../../hooks/usePersistedState";
import "../_script-page.less";

const { Panel } = Collapse;

export default function ScriptAdmin({ currentCompany, currentScript, allCompanies, allScripts }) {
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.common.user);
  const [modalMode, setModalMode] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const [isConfigPanelOpen, setConfigPanelOpen] = usePersistedState("isConfigPanelOpen", "0");

  const [overrideCompany, setOverrideCompany] = useState(null); //Use if try to move duplicate script to another company

  const changeScriptStatusHandler = async (value) => {
    const script = await API.patch(`script/enable/${currentScript.id}`, { enabled: value });
    const selectedForCallScript = localStorage.getItem("selectedScript");
    //if we enabled script we should clear this script from start call select
    if (selectedForCallScript === currentScript.name && !value) {
      localStorage.removeItem("selectedScript");
    }
    dispatch(updateScriptStatusAction(script));
  };

  const onModalCancel = () => {
    setModalVisible(false);
    setModalMode("");
  };

  const onModalOpen = (mode) => {
    setModalVisible(true);
    setModalMode(mode);
  };

  const createNewScript = async (data) => {
    const companyId = currentCompany.id;
    const script = await API.post("script/create", { data, companyId, enabled: 1 });
    notificationAlert({
      type: "success",
      description: "Script has been successfully created",
    });
    dispatch(setNewScriptAction(script));
    dispatch(setNewScriptToSelectAction(script));
    setModalVisible(false);
  };

  const deleteScript = async () => {
    await API.delete(`script/${currentScript.id}`);
    notificationAlert({
      type: "success",
      description: "Script has been successfully deleted",
    });
    dispatch(deleteScriptAction(currentScript));
    setModalVisible(false);
  };

  const editScript = async (data) => {
    if (overrideCompany) {
      return await moveAndRenameScript(overrideCompany, { ...currentScript, name: data.name });
    }
    const script = await API.patch(`script/${currentScript.id}`, data);
    notificationAlert({
      type: "success",
      description: "Script has been successfully changed",
    });
    dispatch(updateScriptNameAction(script));
    setModalVisible(false);
  };

  const cloneScript = async ({ name }) => {
    const script = await API.post("script/cloneScript", { name, scriptId: currentScript.id });
    notificationAlert({
      type: "success",
      description: "Script has been successfully cloned",
    });
    dispatch(setNewScriptAction(script));
    dispatch(setNewScriptToSelectAction(script));
    setModalVisible(false);
  };

  const showRenameModal = () => setModalMode("edit");

  const moveAndRenameScript = async (company, currentScript) => {
    const companyId = allCompanies.find((el) => el.name === company).id;
    const movedCompany = await API.get(`company/${companyId}`);

    //If there is script duplicate in moved company we show rename modal
    if (movedCompany.scripts.some((script) => script.name === currentScript.name)) {
      setOverrideCompany(company);
      return setModalMode("info");
    }

    //If rename flag is active and there are no duplicates we update script with new name and moved it
    if (overrideCompany) {
      setOverrideCompany(null);
      await API.patch(`script/${currentScript.id}`, { name: currentScript.name });
      const movedScript = await API.patch(`script/${currentScript.id}`, { companyId });
      notificationAlert({
        description: `Script «${currentScript.name}» successfully renamed and moved`,
      });
      dispatch(addMovedScriptAction(movedScript));
      if (Number(currentScript.companyId) !== companyId) {
        dispatch(deleteScriptAction(currentScript));
      }
      return setModalVisible(false);
    }
  };

  const moveScript = async ({ company }) => {
    const companyId = allCompanies.find((innerCompany) => innerCompany.name === company).id;
    const movedCompany = await API.get(`company/${companyId}`);

    //If there is script duplicate in moved company we show rename modal
    if (movedCompany.scripts.some((script) => script.name === currentScript.name)) {
      setOverrideCompany(company);
      return setModalMode("info");
    }

    const movedScript = await API.patch(`script/${currentScript.id}`, { companyId });
    notificationAlert({
      description: `Script «${currentScript.name}» successfully moved`,
    });
    dispatch(addMovedScriptAction(movedScript));
    if (Number(currentScript.companyId) !== companyId) {
      dispatch(deleteScriptAction(movedScript));
    }
    setModalVisible(false);
  };

  const changeScriptHandler = async (name) => {
    const script = allScripts.find((script) => script.name === name);
    dispatch(setNewScriptAction(script));
  };

  const modalSubmitOk = async (value) => {
    if (modalMode === "add") {
      await createNewScript(value);
    } else if (modalMode === "delete") {
      await deleteScript();
    } else if (modalMode === "edit") {
      await editScript(value);
    } else if (modalMode === "clone") {
      await cloneScript(value);
    } else if (modalMode === "move") {
      await moveScript(value);
    } else if (modalMode === "info") {
      showRenameModal();
    }
  };

  const onExportScript = async () => {
    window.location.href = `/api/script/${currentScript.id}/export`;
  };

  const modals = [
    {
      title: "Delete",
      text: `Delete «${currentScript && currentScript.name}» script?`,
      type: "delete",
      isVisible: isModalVisible,
    },
    {
      title: "Info",
      text: "Script with the same name already exist in chosen company.Do you want to rename it?",
      type: "info",
      isVisible: isModalVisible,
    },
    { title: "Edit", text: "Edit script", type: "edit", isVisible: isModalVisible },
    { title: "Add", text: "Add new script", type: "add", isVisible: isModalVisible },
    { title: "Clone", text: "Clone script", type: "clone", isVisible: isModalVisible },
    {
      title: "Move to",
      text: "Move script to another company",
      type: "move",
      isVisible: isModalVisible,
    },
  ];

  const isBtnVisible = () => !!currentScript.id;

  const getButtons = () => {
    if (loggedUser.role === "admin") {
      return [
        { name: "Create", action: () => onModalOpen("add"), visible: true },
        { name: "Clone", action: () => onModalOpen("clone"), visible: isBtnVisible() },
        { name: "Edit", action: () => onModalOpen("edit"), visible: isBtnVisible() },
        { name: "Export", action: () => onExportScript(), visible: isBtnVisible() },
        {
          name: "Move to another company",
          action: () => onModalOpen("move"),
          visible: isBtnVisible(),
        },
        { name: "Delete", action: () => onModalOpen("delete"), visible: isBtnVisible() },
      ];
    }
    return [
      { name: "Create", action: () => onModalOpen("add"), visible: true },
      { name: "Clone", action: () => onModalOpen("clone"), visible: isBtnVisible() },
      { name: "Edit", action: () => onModalOpen("edit"), visible: isBtnVisible() },
      { name: "Export", action: () => onExportScript(), visible: isBtnVisible() },
      { name: "Delete", action: () => onModalOpen("delete"), visible: isBtnVisible() },
    ];
  };

  const modalComponentsData = useCallback(() => {
    if (modalMode === "move") {
      return [
        {
          name: "company",
          placeholder: "Choose company for move",
          elementType: "select",
          elementList:
            allCompanies && allCompanies.length
              ? allCompanies.filter((company) => company.id !== currentCompany.id && company.isActive)
              : [],
        },
      ];
    }
    return [
      {
        name: "name",
        elementType: "input",
        placeholder: "Script name",
        checkDuplicate: true,
        label: "Script name",
      },
      {
        name: "saleType",
        placeholder: "Sale type",
        elementType: "select",
        elementList: [
          { name: "Free Appointment" },
          { name: "Paid Appointment" },
          { name: "Workshop Sale" },
          { name: "Sale" },
        ],
        label: "Sale type",
      },
      {
        name: "expectedCash",
        placeholder: "Expected cash",
        elementType: "input_number",
        label: "Deposit",
      },
      {
        name: "expectedSale",
        placeholder: "Expected sale",
        elementType: "input_number",
        label: "Sale value",
      },
      {
        name: "expectedDuration",
        placeholder: "Expected duration",
        elementType: "input_number",
        label: "Call length (mins)",
      },
    ];
  }, [modalMode]);

  const panelOpenHandler = (value) => setConfigPanelOpen(value);

  const getCurrentItemDependsOnMode = (currentItem) => {
    if (modalMode === "clone") {
      return { ...currentItem, name: currentItem.name + "_clone" };
    }
    return currentItem;
  };

  return (
    <>
      <div className="script_config_wrapper">
        <div className="script_config">
          <Collapse accordion activeKey={isConfigPanelOpen} onChange={panelOpenHandler}>
            <Panel header="Script admin:">
              <h3 className="script_title">{`Current script: ${currentScript.name || "No script selected"}`}</h3>
              <div className="script_config-row">
                <ScriptDropdown
                  onSelect={(chosenScript) =>
                    currentScript && currentScript.name !== chosenScript && changeScriptHandler(chosenScript)
                  }
                  value={currentScript && currentScript.name}
                  allData={allScripts}
                  optionField="name"
                />
                {Object.entries(currentScript).length ? (
                  <Switch
                    onChange={changeScriptStatusHandler}
                    checked={currentScript.enabled}
                    unCheckedChildren={"Inactive"}
                    checkedChildren={"Active"}
                  />
                ) : (
                  ""
                )}
              </div>
              <ButtonGroup data={getButtons()} className="script_config_btn" />
            </Panel>
          </Collapse>
        </div>
      </div>
      {/*-------------Modal----------------*/}
      {isModalVisible ? (
        <ModalGroup
          editableItemIdx={""}
          mainField="name"
          allItems={modalMode === "move" ? allCompanies : allScripts}
          mode={modalMode}
          modals={modals}
          modalComponentsData={modalComponentsData()}
          onModalCancel={onModalCancel}
          modalSubmitOk={modalSubmitOk}
          currentItem={getCurrentItemDependsOnMode(currentScript)}
        />
      ) : null}
    </>
  );
}

ScriptAdmin.propTypes = {
  currentScript: PropTypes.shape({
    companyId: PropTypes.string,
    enabled: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    userId: PropTypes.string,
  }),
  allCompanies: PropTypes.array,
  allScripts: PropTypes.array,
  currentCompany: PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.number,
    name: PropTypes.string,
    attributes: PropTypes.array,
    consultants: PropTypes.array,
    funnels: PropTypes.array,
    performanceIndicators: PropTypes.array,
    questions: PropTypes.array,
    reportSettings: PropTypes.object,
    scripts: PropTypes.array,
    timezone: PropTypes.string,
    timezoneName: PropTypes.string,
    webhooks: PropTypes.array,
  }),
};
