import React, { useRef, useState } from "react";
import { Table } from "antd";
import FilterDropdownComponent from "./ScriptTableFilterDropdown";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import "./_script-loader.less";

export default function ScriptLoaderTable({ gridData, setCurrentStage }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState("");
  const inputRef = useRef();

  const getHeaderColumn = () => {
    return Object.entries(gridData[0]).map((gridItem, index) => {
      const field = gridItem[0];
      return {
        title: field[0].toUpperCase() + field.slice(1),
        dataIndex: field,
        sorter: (prev, next) => prev[field] < next[field],
        sortDirections: ["descend", "ascend"],
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <FilterDropdownComponent
            inputRef={inputRef}
            onSearch={() => {}}
            dataIndex={field}
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
          />
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
        onFilter: (value, record) =>
          record[field] ? record[field].toString().toLowerCase().includes(value.toLowerCase()) : "",
      };
    });
  };

  const getDataSource = () => {
    return gridData.map((gridItem, index) => {
      return {
        company: gridItem.company.name,
        script: gridItem.script.name,
        stage: gridItem.stage.name,
        attributes: gridItem.attributes.name,
        key: index,
      };
    });
  };

  const onSelectRowChange = (value) => {
    setSelectedRowKeys(value);
    setCurrentStage(gridData[value[0]].stage);
  };

  const rowSelection = { type: "radio", selectedRowKeys, onChange: onSelectRowChange };

  return (
    <Table
      size={"small"}
      bordered={true}
      pagination={{ pageSize: 7, position: ["bottomCenter"] }}
      rowSelection={rowSelection}
      dataSource={getDataSource()}
      columns={getHeaderColumn()}
    />
  );
}

ScriptLoaderTable.propTypes = {
  gridData: PropTypes.array,
  setCurrentStage: PropTypes.func,
};
