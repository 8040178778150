import {
  CONSULTANT_TABLE_REQUEST,
  GET_CURRENT_PAGE_CONSULTANTS_SUCCESS,
  SET_ACTIVE_COMPANY,
  RESET_SETTING_FOR_CONSULTANT,
  CHOOSE_CONSULTANTS,
  SET_CURRENT_PAGE,
  GET_FIRST_LOAD_DATA_REQUEST,
  GET_FIRST_LOAD_DATA_SUCCESS,
  SET_SORT_PARAMS,
  SET_FILTERS,
  CLEAR_CONSULTANT_REDUCER,
} from "../actions/consultantPageActions";

const DEFAULT_SORTED_DIRECTION = "ASC";
const DEFAULT_SORTED_FIELD = "name";

export const initialState = {
  allConsultants: [],
  tableSettings: {
    currentPage: 1,
    sortedDirection: DEFAULT_SORTED_DIRECTION,
    sortedField: DEFAULT_SORTED_FIELD,
    pageSize: 20,
    filters: [{ f_name: "" }, { f_companyId: [] }, { f_email: "" }],
  },
  totalCount: 0,
  isDataLoading: true,
  activeCompany: null,
  consultants: [],
  chosenConsultants: [],
  tableUpdate: null,
  companies: [],
};

export const consultantPageReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case GET_FIRST_LOAD_DATA_REQUEST:
      return { ...state, isDataLoading: true };

    case CONSULTANT_TABLE_REQUEST:
      return { ...state, isDataLoading: true };

    case GET_FIRST_LOAD_DATA_SUCCESS:
      return { ...state, companies: payload.companies };

    case GET_CURRENT_PAGE_CONSULTANTS_SUCCESS:
      return {
        ...state,
        consultants: payload.consultants,
        isDataLoading: false,
        totalCount: payload.totalCount,
      };

    case SET_ACTIVE_COMPANY:
      return { ...state, activeCompany: payload };

    case RESET_SETTING_FOR_CONSULTANT:
      return {
        ...state,
        chosenConsultants: [],
        tableSettings: { ...initialState.tableSettings },
        tableUpdate: payload.tableUpdate,
      };

    case CHOOSE_CONSULTANTS:
      return { ...state, chosenConsultants: payload };

    case SET_CURRENT_PAGE:
      return {
        ...state,
        tableUpdate: payload.tableUpdate,
        tableSettings: {
          ...state.tableSettings,
          currentPage: payload.currentPage,
          pageSize: payload.pageSize,
        },
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        chosenConsultants: [],
        tableUpdate: payload.tableUpdate,
        tableSettings: {
          ...state.tableSettings,
          sortedField: payload.sortedField,
          sortedDirection: payload.sortedDirection,
        },
      };

    case SET_FILTERS:
      const updatedFilterIdx = state.tableSettings.filters.findIndex((filter) => filter.hasOwnProperty(payload.field));

      const updatedFiltersArray = [
        ...state.tableSettings.filters.slice(0, updatedFilterIdx),
        payload.filter,
        ...state.tableSettings.filters.slice(updatedFilterIdx + 1),
      ];

      return {
        ...state,
        chosenConsultants: [],
        tableUpdate: payload.tableUpdate,
        tableSettings: {
          ...state.tableSettings,
          currentPage: 1,
          filters: updatedFiltersArray,
        },
      };

    case CLEAR_CONSULTANT_REDUCER:
      return initialState;

    default:
      return state;
  }
};
