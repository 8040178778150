import React, { useEffect, useState } from "react";
import StatsViewerRangeConfig from "./StatsViewerRangeConfig";
import StatsViewerTable from "./StatsViewerTable";
import API from "../../services/apiService";
import StatsViewerTablePagination from "./StatsViewerTablePagination";
import { transformArrayOfObjectInObject } from "../../utils/helpers";
import usePersistedState from "../../hooks/usePersistedState";
import useActivePage from "../../hooks/useActivePage";
import { routes } from "../../utils/mocks";
import usePersistedStateWithHash from "../../hooks/usePersistedStateWithHash";
import "./_statsViewer.less";

const DEFAULT_DAY_RANGE = 7;
const DEFAULT_CURRENT_PAGE = 1;
const DEFAULT_SORT_SETTINGS = { sortedField: "date", sortedDirection: "descend" };
const DEFAULT_PAGE_SIZE = 50;

const DEFAULT_FILTER_SETTING = [{ f_name: "" }, { f_companyId: [] }];

export default function StatsViewerPage() {
  useActivePage(routes.STATS_VIEWER);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [dayRange, setDayRange] = usePersistedState("dayRange", DEFAULT_DAY_RANGE);
  const [currentPage, setCurrentPage] = useState(DEFAULT_CURRENT_PAGE);
  const [tableUpdate, setTableUpdate] = useState(null);
  const [sortSettings, setSortSettings] = useState(DEFAULT_SORT_SETTINGS);
  const [filterSetting, setFilterSettings] = useState(DEFAULT_FILTER_SETTING);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(null);
  const [timezone, setTimezone] = usePersistedStateWithHash("timezoneStatsViewer", null);

  const getDataWithKey = (data) => data.map((node, key) => ({ ...node, key }));

  const filterEmptyFilters = (data) => {
    return data.filter((filterItem) => {
      if (Array.isArray(Object.entries(filterItem)[0][1])) {
        return !!Object.entries(filterItem)[0][1].length;
      } else {
        return Object.entries(filterItem)[0][1] !== "";
      }
    });
  };

  useEffect(() => {
    (async function getPageRecords() {
      setLoading(true);

      const recordsData = await API.get(`/stats/${dayRange}`, {
        params: {
          page: currentPage,
          pageSize: pageSize,
          timezone: timezone,
          s_field: sortSettings.sortedField,
          s_direction:
            sortSettings.sortedDirection === "ascend"
              ? "ASC"
              : sortSettings.sortedDirection === "descend"
              ? "DESC"
              : null,
          ...transformArrayOfObjectInObject(filterEmptyFilters(filterSetting)), //we leave only filters with not empty filter and transform them into object for spread
        },
      });

      setData(getDataWithKey(recordsData.stats));
      setTotalCount(recordsData.totalCount);
      setLoading(false);
    })();
  }, [tableUpdate]);

  useEffect(() => {
    (async function getAddtionalData() {
      const allCompanies = await API.get("/company/all");
      setCompanies(allCompanies);
    })();
    return () => setDayRange(DEFAULT_DAY_RANGE);
  }, []);

  const handleRangeChange = (value) => {
    setDayRange(value);
    setTableUpdate(new Date());
  };

  const changePageSize = (value) => {
    setCurrentPage(value);
    setTableUpdate(new Date());
  };

  const filterByField = (filterType, filterValue) => {
    const filter = { [filterType]: filterValue.searchText };
    const updatedFilterIdx = filterSetting.findIndex((filter) => filter.hasOwnProperty(filterType));
    const updatedFiltersArray = [
      ...filterSetting.slice(0, updatedFilterIdx),
      filter,
      ...filterSetting.slice(updatedFilterIdx + 1),
    ];
    setFilterSettings(updatedFiltersArray);
    setCurrentPage(1);
    setTableUpdate(new Date());
  };

  const sortTable = (_, filters, actionInfo, { action }) => {
    if (action === "sort") {
      setSortSettings({ sortedField: actionInfo.field, sortedDirection: actionInfo.order });
      setTableUpdate(new Date());
    }
    if (action === "filter") {
      if (filters.company_id && filters.company_id.length) {
        const companyIdArray = filters.company_id.map((company) => {
          return companies.find((inner_company) => inner_company.name === company).id;
        });
        setFilterSettings((filters) => {
          return filters.map((filter) => {
            if (filter.hasOwnProperty("f_companyId")) {
              return { f_companyId: companyIdArray };
            }
            return filter;
          });
        });
        setCurrentPage(1);
        setTableUpdate(new Date());
      } else {
        setFilterSettings((filters) =>
          filters.map((filter) => {
            if (filter.hasOwnProperty("f_companyId")) {
              return { f_companyId: [] };
            }
            return filter;
          })
        );
        setCurrentPage(1);
        setTableUpdate(new Date());
      }
    }
  };

  const resetSettings = () => {
    setSortSettings(DEFAULT_SORT_SETTINGS);
    setFilterSettings(DEFAULT_FILTER_SETTING);
    setDayRange(DEFAULT_DAY_RANGE);
    setTimezone(null);
    setTableUpdate(new Date());
  };

  const changePageSizeHandler = (_, value) => setPageSize(value);

  const onChangeTimezone = (timezone) => {
    setTimezone(timezone);
    setTableUpdate(new Date());
  };

  return (
    <div className="statsSubmission_page">
      <div className="fluid_container">
        <StatsViewerRangeConfig
          onChangeTimezone={onChangeTimezone}
          timezone={timezone}
          resetSettings={resetSettings}
          dayRange={dayRange}
          handleRangeChange={handleRangeChange}
        />
        <StatsViewerTable
          filterSetting={filterSetting}
          filterByField={filterByField}
          sortSettings={sortSettings}
          sortTable={sortTable}
          companies={companies}
          data={data}
          loading={loading}
        />
        <StatsViewerTablePagination
          changePageSizeHandler={changePageSizeHandler}
          setCurrentPage={changePageSize}
          pageSize={pageSize}
          currentPage={currentPage}
          total={totalCount}
        />
      </div>
    </div>
  );
}
