import React from "react";
import { scriptTextFieldUpdateAction } from "../../../redux/actions/scriptPageActions";
import PropTypes from "prop-types";
import EditorToolbar from "./TextEditor/EditorToolbar";

export default function ScriptTextSection({ script, currentPage, dispatch }) {
  const onChangeScript = (data) => dispatch(scriptTextFieldUpdateAction({ script: data, currentPage }));

  return (
    <div className="script_text_section">
      <EditorToolbar value={script} onChange={onChangeScript} />
    </div>
  );
}

ScriptTextSection.propTypes = {
  script: PropTypes.string,
  currentPage: PropTypes.number,
  dispatch: PropTypes.func,
};
