import React, { useEffect } from "react";
import StaffDetailConfigPanel from "./StaffDetailConfigPanel";
import {
  getAllUsers,
  clearCallInfoReducer,
  getCallEndReasonsData,
  getTimelineData,
} from "../../redux/actions/callsInfoPageActions";
import StaffDetailLoadedContent from "./StaffDetailLoadedContent";
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import useActivePage from "../../hooks/useActivePage";
import { routes } from "../../utils/mocks";
import "./_staffDetail.less";

function StaffDetail() {
  useActivePage(routes.CALLS_DETAIL);
  const dispatch = useDispatch();
  const callEndReasonsUpdate = useSelector((state) => state.callsInfo.callEndReasonsUpdate);
  const callTimelineUpdate = useSelector((state) => state.callsInfo.callTimelineUpdate);

  useEffect(() => {
    dispatch(getAllUsers());
    return () => {
      dispatch(clearCallInfoReducer());
    };
  }, []);

  useEffect(() => {
    dispatch(getCallEndReasonsData());
  }, [callEndReasonsUpdate]);

  useEffect(() => {
    dispatch(getTimelineData());
  }, [callTimelineUpdate]);

  return (
    <div className="staff_detail">
      <div className="fluid_container">
        <Typography.Title className="title" level={2}>
          Staff detail
        </Typography.Title>
        <StaffDetailConfigPanel />
        <StaffDetailLoadedContent />
      </div>
    </div>
  );
}

export default StaffDetail;
