import { combineReducers } from "redux";
import { commonReducer } from "./commonReducer";
import { adminPageReducer } from "./adminPageReducer";
import { scriptPageReducer } from "./scriptPageReducer";
import { scriptLoaderReducer } from "./scriptLoaderReducer";
import { homePageReducer } from "./homePageReducer";
import { scriptViewerPageReducer } from "./scriptViewerPageReducer";
import { meetingsPageReducer } from "./meetingsPageReducer";
import { meetingPlanReducer } from "./meetingPlanReducer";
import { statsInfoReducer } from "./statsInfoReducer";
import { consultantPageReducer } from "./consultantPageReducer";
import { callsInfoPageReducer } from "./callsInfoPageReducer";
import dashboardPageReducers from "./dashboardPageReducers";
import leadsReducer from "./leadsReducer";

const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  common: commonReducer,
  setting: adminPageReducer,
  script: scriptPageReducer,
  scriptLoader: scriptLoaderReducer,
  home: homePageReducer,
  viewer: scriptViewerPageReducer,
  meetings: meetingsPageReducer,
  meetingPlan: meetingPlanReducer,
  statsInfo: statsInfoReducer,
  consultant: consultantPageReducer,
  callsInfo: callsInfoPageReducer,
  leads: leadsReducer,
  ...dashboardPageReducers,
});

export default rootReducer;
