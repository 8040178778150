import React from "react";
import { Tag } from "antd";

function DashboardTableConfig({ guidanceKPI, sortOrder, sortedField }) {
  const getData = () => {
    return (
      guidanceKPI &&
      guidanceKPI.length &&
      guidanceKPI.map((guidance) => {
        return { ...guidance, key: guidance.id };
      })
    );
  };

  const calculateDialsPerSaleColor = ({ dialsKPI, pitchKPI, salesKPI, conversationKPI }) => {
    if (dialsKPI === "GREEN" && pitchKPI === "GREEN" && salesKPI === "GREEN" && conversationKPI === "GREEN") {
      return "GREEN";
    } else {
      return "RED";
    }
  };

  function getColumns() {
    return [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        className: "date_column",
        align: "center",
        ellipsis: true,
        render: (text, record) => {
          const color = calculateDialsPerSaleColor(record);
          return <Tag color={color === "RED" ? "" : "green"}>{text}</Tag>;
        },
        sorter: () => null,
        sortOrder: sortedField === "date" ? sortOrder : "",
      },
      {
        title: "Guidance",
        dataIndex: "guidance",
        key: "guidance",
        className: "guidance_column",
        align: "center",
        render: (text) => {
          return <span>{text}</span>;
        },
      },
      {
        title: "Dials KPI",
        dataIndex: "dialsKPI",
        key: "dialsKPI",
        className: "dialsKpi_column",
        align: "center",
        render: (text) => {
          return <Tag color={text === "RED" ? "volcano" : text === "GREEN" ? "green" : ""}>{text}</Tag>;
        },
        sorter: () => null,
        sortOrder: sortedField === "dialsKPI" ? sortOrder : "",
      },
      {
        title: "Conv KPI",
        dataIndex: "conversationKPI",
        key: "conversationKPI",
        className: "conversationKPI_column",
        align: "center",
        ellipsis: true,
        render: (text) => {
          return <Tag color={text === "RED" ? "volcano" : text === "GREEN" ? "green" : ""}>{text}</Tag>;
        },
        sorter: () => null,
        sortOrder: sortedField === "conversationKPI" ? sortOrder : "",
      },
      {
        title: "Pitch KPI",
        dataIndex: "pitchKPI",
        key: "pitchKPI",
        className: "pitchKpi_column",
        align: "center",
        ellipsis: true,
        render: (text) => {
          return <Tag color={text === "RED" ? "volcano" : text === "GREEN" ? "green" : ""}>{text}</Tag>;
        },
        sorter: () => null,
        sortOrder: sortedField === "pitchKPI" ? sortOrder : "",
      },
      {
        title: "Sales KPI",
        dataIndex: "salesKPI",
        key: "salesKPI",
        className: "salesKpi_column",
        align: "center",
        ellipsis: true,
        render: (text) => {
          return <Tag color={text === "RED" ? "volcano" : text === "GREEN" ? "green" : ""}>{text}</Tag>;
        },
        sorter: () => null,
        sortOrder: sortedField === "salesKPI" ? sortOrder : "",
      },
      {
        title: () => {
          return (
            <span>
              Conversations <br /> per sale
            </span>
          );
        },
        dataIndex: "conversationsPerSale",
        key: "conversationsPerSale",
        className: "conversationsPerSale_column",
        align: "center",
        ellipsis: true,
        render: (text) => {
          return <span>{text}</span>;
        },
        sorter: () => null,
        sortOrder: sortedField === "conversationsPerSale" ? sortOrder : "",
      },
      {
        title: () => {
          return (
            <span>
              Dials
              <br /> per sale
            </span>
          );
        },
        dataIndex: "dialsPerSale",
        key: "dialsPerSale",
        className: "dialsPerSale_column",
        align: "center",
        ellipsis: true,
        render: (text, record) => {
          const color = calculateDialsPerSaleColor(record);
          return <Tag color={color === "RED" ? "" : "green"}>{text}</Tag>;
        },
        sorter: () => null,
        sortOrder: sortedField === "dialsPerSale" ? sortOrder : "",
      },
      {
        title: () => {
          return (
            <span>
              Hours dialed <br />
              per sale
            </span>
          );
        },
        dataIndex: "hoursDialedPerSale",
        key: "hoursDialedPerSale",
        className: "hoursDialedPerSale_column",
        align: "center",
        ellipsis: true,
        render: (text) => {
          return <span>{text}</span>;
        },
        sorter: () => null,
        sortOrder: sortedField === "hoursDialedPerSale" ? sortOrder : "",
      },
    ];
  }

  return { getData: getData(), getColumns: getColumns() };
}

export default DashboardTableConfig;
