import React, { useEffect } from "react";
import { clearViewerAction } from "../redux/actions/scriptViewerPageActions";
import { clearStorageData } from "../utils/helpers";
import { useDispatch } from "react-redux";

const withCallClear = (Component) => {
  return (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
      const currentCall = JSON.parse(localStorage.getItem("currentCall"));
      if (currentCall && currentCall.id) {
        dispatch(clearViewerAction());
        clearStorageData(
          "currentScriptPage",
          "chosenObjections",
          "currentCall",
          "answeredQuestion",
          "highlightData",
          "answersList"
        );
      }
    }, []);
    return <Component {...props} />;
  };
};

export default withCallClear;
