import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";

export default function NavigationSection({ setEndReasonVisible }) {
  return (
    <div className="navigation_section">
      <div className="end_call_row">
        <Button type="orange-empty" onClick={() => setEndReasonVisible(true)}>
          End call
        </Button>
      </div>
    </div>
  );
}

NavigationSection.propTypes = {
  setEndReasonVisible: PropTypes.func,
};
