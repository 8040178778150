import API from "../../services/apiService";
import { getDateInMilliseconds, removeDuplicatesFromObject } from "../../utils/helpers";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CHOOSE_DATE_RANGE = "CHOOSE_DATE_RANGE";
export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const SET_CALL_AND_REASON_FILTERS = "SET_CALL_AND_REASON_FILTERS";
export const SET_CALL_TIMELINE_FILTERS = "SET_CALL_TIMELINE_FILTERS";
export const RESET_SETTINGS = "RESET_SETTINGS";
export const CLEAR_CALL_INFO_REDUCER = "CLEAR_CALL_INFO_REDUCER";
export const GET_TIMELINE_DATA_REQUEST = "GET_TIMELINE_DATA_REQUEST";
export const GET_TIMELINE_DATA_SUCCESS = "GET_TIMELINE_DATA_SUCCESS";
export const SET_ALL_FILTERS = "SET_ALL_FILTERS";
export const GET_CALL_END_REASONS_REQUEST = "GET_CALL_END_REASONS_REQUEST";
export const GET_CALL_END_REASONS_SUCCESS = "GET_CALL_END_REASONS_SUCCESS";

export const SET_END_REASON_FILTERS = "SET_END_REASON_FILTERS";
export const SET_TIMELINE_FILTERS = "SET_TIMELINE_FILTERS";

export const UPDATE_CALL_END_REASONS = "UPDATE_CALL_END_REASONS";
export const UPDATE_CALL_TIMELINE = "UPDATE_CALL_TIMELINE";
//settings actions
export const updateCallEndReasons = (payload) => ({ type: UPDATE_CALL_END_REASONS, payload });
export const updateCallTimeline = (payload) => ({ type: UPDATE_CALL_TIMELINE, payload });

export const chooseCurrentUser = (payload) => ({ type: SET_CURRENT_USER, payload });
export const chooseDateRange = (payload) => ({ type: CHOOSE_DATE_RANGE, payload });

export const setCallEndReasonFilters = (payload) => ({
  type: SET_CALL_AND_REASON_FILTERS,
  payload,
});
export const setCallTimelineFilters = (payload) => ({ type: SET_CALL_TIMELINE_FILTERS, payload });

export const resetSettings = (payload) => ({ type: RESET_SETTINGS, payload });
export const setAllFilters = (payload) => ({ type: SET_ALL_FILTERS, payload });

export const setTimelineFilters = (payload) => ({ type: SET_TIMELINE_FILTERS, payload });
export const setEndReasonFilters = (payload) => ({ type: SET_END_REASON_FILTERS, payload });

export const getCallEndReasonsRequest = () => ({ type: GET_CALL_END_REASONS_REQUEST });
export const getCallEndReasonsDataSuccess = (payload) => ({ type: GET_CALL_END_REASONS_SUCCESS, payload });

export const getCallEndReasonsData = () => async (dispatch, getState) => {
  const { startDate, endDate, currentUser, callEndReasonsFiltersActive } = getState().callsInfo;

  if (!currentUser) return dispatch(getCallEndReasonsDataSuccess({ callEndReasons: [] }));

  dispatch(getCallEndReasonsRequest());

  const callEndReasons = await API.get(`dashboards/end-reasons`, {
    params: {
      f_userId: [currentUser.id],
      f_dateAfter: startDate ? getDateInMilliseconds(startDate) : null,
      f_dateBefore: endDate ? getDateInMilliseconds(endDate) + 86399999 : null, //86399999 - 23.59  we need add this value to get all day data if pick the same day in start and end
      f_endReason:
        callEndReasonsFiltersActive && callEndReasonsFiltersActive.length
          ? callEndReasonsFiltersActive.map((filter) => filter.type)
          : [],
    },
  });

  //we need have all filters for every section, and we form this filters from all data of this section
  if (!(callEndReasonsFiltersActive && callEndReasonsFiltersActive.length)) {
    dispatch(setEndReasonFilters({ callEndReasonsFilters: callEndReasons }));
  }

  dispatch(getCallEndReasonsDataSuccess({ callEndReasons }));
};

export const getTimelineDataRequest = () => ({ type: GET_TIMELINE_DATA_REQUEST });
export const getTimelineDataSuccess = (payload) => ({ type: GET_TIMELINE_DATA_SUCCESS, payload });

export const getTimelineData = () => async (dispatch, getState) => {
  const { startDate, endDate, currentUser, callTimelineFiltersActive } = getState().callsInfo;

  if (!currentUser) return dispatch(getTimelineDataSuccess({ callTimelineFilters: [] }));

  dispatch(getTimelineDataRequest());

  const callTimeline = await API.get(`dashboards/call-history`, {
    params: {
      f_userId: [currentUser.id],
      f_dateAfter: startDate ? getDateInMilliseconds(startDate) : null,
      f_dateBefore: endDate ? getDateInMilliseconds(endDate) + 86399999 : null,
      f_endReason:
        callTimelineFiltersActive && callTimelineFiltersActive.length
          ? callTimelineFiltersActive.map((filter) => filter.endReason)
          : [],
    },
  });

  if (callTimelineFiltersActive && !callTimelineFiltersActive.length) {
    dispatch(setTimelineFilters({ callTimelineFilters: removeDuplicatesFromObject(callTimeline, "endReason") }));
  }

  dispatch(getTimelineDataSuccess({ callTimeline }));
};

//unmount clear
export const clearCallInfoReducer = () => ({ type: CLEAR_CALL_INFO_REDUCER });

//Users request
export const getAllUsers = () => async (dispatch) => {
  dispatch(getAllUserRequest());
  const users = await API.get("user/all");
  dispatch(getAllUserSuccess({ users }));
};

export const getAllUserRequest = () => ({ type: GET_ALL_USERS_REQUEST });
export const getAllUserSuccess = (payload) => ({ type: GET_ALL_USERS_SUCCESS, payload });
