import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "antd/lib/button";
import ModalGroup from "../../../components/ModalGroup/ModalGroup";
import SelectComponent from "../../../components/Select/Select";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setAllAttributesAction, editCompanyAction } from "../../../redux/actions/adminPageActions";
import ChipComponent from "../../../components/Chip/Chip";
import API from "../../../services/apiService";
import PropTypes from "prop-types";
import notificationAlert from "../../../utils/notificationAlert";
import "./_configAttributesPanel.less";

const ConfigAttributesPanel = React.memo(({ allCompanies, currentCompany }) => {
  const dispatch = useDispatch();
  const allAttributes = useSelector((state) => state.setting.allAttributes, shallowEqual);
  const [currentAttribute, setCurrentAttribute] = useState(0);
  const [modalMode, setModalMode] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);

  const setDataExceptCurrentCompany = useCallback(
    (attributes) => {
      return attributes.filter((attribute) => !currentCompany.attributes.some((curent) => curent.id === attribute.id));
    },
    [currentCompany.attributes]
  );

  const getAttributes = useCallback(() => {
    const allAttributesData = allCompanies.reduce((acc, el) => acc.concat(el.attributes), []);
    dispatch(setAllAttributesAction(setDataExceptCurrentCompany(allAttributesData)));
  }, [allCompanies, setDataExceptCurrentCompany]);

  useEffect(() => {
    getAttributes();
  }, [getAttributes, allCompanies]);

  const createNewAttribute = async ({ text }) => {
    const updateCompanyObject = {
      ...currentCompany,
      attributes: [...currentCompany.attributes, { text: text, companyId: currentCompany.id }],
    };

    const updatedCompany = await API.patch(`company/${currentCompany.id}`, updateCompanyObject);
    const companyIndex = allCompanies.findIndex((company) => company.id === updatedCompany.id);
    notificationAlert({
      type: "success",
      description: "Attribute has been successfully created",
    });
    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
  };

  const editAttribute = async ({ text }) => {
    const updateCompanyObject = {
      ...currentCompany,
      attributes: [
        ...currentCompany.attributes.slice(0, currentAttribute),
        { text: text, companyId: currentCompany.id },
        ...currentCompany.attributes.slice(currentAttribute + 1),
      ],
    };

    const companyIndex = allCompanies.findIndex((company) => company.name === currentCompany.name);
    const result = await API.patch(`company/${currentCompany.id}`, updateCompanyObject);
    notificationAlert({
      type: "success",
      description: "Attribute has been successfully edited",
    });
    dispatch(editCompanyAction({ updatedCompany: result, companyIndex }));
  };

  const deleteAttribute = async () => {
    const updateCompanyObject = {
      ...currentCompany,
      attributes: [
        ...currentCompany.attributes.slice(0, currentAttribute),
        ...currentCompany.attributes.slice(currentAttribute + 1),
      ],
    };

    const updatedCompany = await API.patch(`company/${currentCompany.id}`, updateCompanyObject);
    const companyIndex = allCompanies.findIndex((company) => company.id === updatedCompany.id);
    notificationAlert({
      type: "success",
      description: "Attribute has been successfully deleted",
    });
    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
  };

  const addSelectedAttribute = async (value) => {
    const updateCompanyObject = {
      ...currentCompany,
      attributes: [...currentCompany.attributes, { text: value, companyId: currentCompany.id }],
    };

    const updatedCompany = await API.patch(`company/${currentCompany.id}`, updateCompanyObject);
    const companyIndex = allCompanies.findIndex((company) => company.id === updatedCompany.id);
    notificationAlert({
      type: "success",
      description: "Attribute has been successfully added",
    });
    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
  };

  const onSelectAttribute = (event, index) => setCurrentAttribute(index);

  const modalSubmitOk = async (value) => {
    if (modalMode === "add") {
      await createNewAttribute(value);
    } else if (modalMode === "edit") {
      await editAttribute(value);
    } else if (modalMode === "delete") {
      await deleteAttribute();
    }
    setModalVisible(false);
  };

  const onModalCancel = () => {
    setModalVisible(false);
    setModalMode("");
  };

  const onModalOpen = (mode) => {
    setModalVisible(true);
    setModalMode(mode);
  };

  const modals = [
    {
      title: "Delete",
      text: `Delete «${
        currentCompany &&
        currentCompany.attributes &&
        currentCompany.attributes[currentAttribute] &&
        currentCompany.attributes[currentAttribute].text
      }» attribute`,
      type: "delete",
      isVisible: isModalVisible,
    },
    { title: "Edit", text: "Edit attribute", type: "edit", isVisible: isModalVisible },
    { title: "Add", text: "Add new attribute", type: "add", isVisible: isModalVisible },
  ];

  const modalComponentsData = useMemo(
    () => () => {
      return [
        {
          name: "text",
          placeholder: "Type attribute text",
          elementType: "input",
          checkDuplicate: true,
        },
      ];
    },
    []
  );

  const showAttributesWithoutTextDuplicate = (attributes) => {
    const filteredAttributes = attributes.filter(
      (attribute) => !currentCompany.attributes.some((current) => current.text === attribute.text)
    );
    return filteredAttributes.filter(
      ({ text }, i) => i === filteredAttributes.findIndex((filteredAttribute) => filteredAttribute.text === text)
    );
  };

  return (
    <div className="config__attributes">
      {isModalVisible ? (
        <ModalGroup
          editableItemIdx={""}
          mainField={"text"} //field for duplicate check
          allItems={currentCompany && currentCompany.attributes}
          mode={modalMode}
          modals={modals}
          modalComponentsData={modalComponentsData()}
          onModalCancel={onModalCancel}
          modalSubmitOk={modalSubmitOk}
          currentItem={currentCompany && currentCompany.attributes && currentCompany.attributes[currentAttribute]}
        />
      ) : null}
      <div className="panel">
        <div className="block__title">Attributes:</div>
        <Button className="btn" type="primary" onClick={() => onModalOpen("add")}>
          Add
        </Button>
        <SelectComponent
          mainField="text"
          placeholder={"Select an attribute to add"}
          data={showAttributesWithoutTextDuplicate(allAttributes)}
          value={"Select an attribute to add"}
          handleChange={addSelectedAttribute}
        />
      </div>
      <div className="attributes-block">
        {currentCompany &&
          currentCompany.attributes.map((attribute, index) => {
            return (
              <ChipComponent
                selectCurrentHandler={(event) => onSelectAttribute(event, index)}
                editHandler={() => onModalOpen("edit")}
                deleteHandler={() => onModalOpen("delete")}
                text={attribute.text}
                key={index}
              />
            );
          })}
      </div>
    </div>
  );
});

ConfigAttributesPanel.propTypes = {
  currentCompany: PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.number,
    name: PropTypes.string,
    attributes: PropTypes.array,
    consultants: PropTypes.array,
    funnels: PropTypes.array,
    performanceIndicators: PropTypes.array,
    questions: PropTypes.array,
    reportSettings: PropTypes.object,
    scripts: PropTypes.array,
    timezone: PropTypes.string,
    timezoneName: PropTypes.string,
    webhooks: PropTypes.array,
  }),
  allCompanies: PropTypes.array,
};

export default ConfigAttributesPanel;
