import API from "../../../services/apiService";
import { getAllUrlParam, getUrlParam } from "../../../utils/helpers";

//CONSTANTS
export const GET_COMPANIES = "GET_COMPANIES";
export const CHOSE_COMPANY = "CHOSE_COMPANY";
export const GET_USERS_BY_COMPANY = "GET_USERS_BY_COMPANY";
export const CHOSE_USER = "CHOSE_USER";
export const CHOSE_DATE = "CHOSE_DATE";
export const UPDATE_DASHBOARD_KPI = "UPDATE_DASHBOARD_KPI";
export const CLEAR_DASHBOARD_PAGE = "CLEAR_DASHBOARD_PAGE";
export const SET_IGNORE_TIMEZONE = "SET_IGNORE_TIMEZONE";

export const clearDashboardPage = (payload) => {
  return {
    type: CLEAR_DASHBOARD_PAGE,
    payload,
  };
};

export const getCompaniesSuccess = (payload) => {
  return {
    type: GET_COMPANIES,
    payload,
  };
};

export const getCompanies = () => async (dispatch) => {
  const companies = await API.get("company/all");
  const activeCompanies = companies && companies.length ? companies.filter((company) => company.isActive) : [];
  dispatch(getCompaniesSuccess(activeCompanies));
};

export const choseCompany = (payload) => {
  return {
    type: CHOSE_COMPANY,
    payload,
  };
};

export const getUsersByCompanySuccess = (payload) => {
  return {
    type: GET_USERS_BY_COMPANY,
    payload,
  };
};

export const getUsersByCompany = (activeCompany) => async (dispatch) => {
  const users = await API.get(`user/by-company/${activeCompany.id}`);
  dispatch(getUsersByCompanySuccess(users));
};

export const choseUser = (payload) => {
  return {
    type: CHOSE_USER,
    payload,
  };
};

export const choseDate = (payload) => {
  return {
    type: CHOSE_DATE,
    payload,
  };
};

export const updateDashboardKPI = (payload) => {
  return {
    type: UPDATE_DASHBOARD_KPI,
    payload,
  };
};

export const setIgnoreTimezone = (payload) => {
  return {
    type: SET_IGNORE_TIMEZONE,
    payload,
  };
};

export const updateFiltersByParam = (params) => async (dispatch) => {
  const companyId = getUrlParam(params, "companyId");
  const chosenCompany = await API.get(`company/${companyId}`);
  const startDate = getUrlParam(params, "startDate");
  const endDate = getUrlParam(params, "endDate");
  const usersId = getAllUrlParam(params, "userId[]");
  const ignoreTimezone = getUrlParam(params, "ignoreTimezone");
  //If company has been deleted but users exist in system we dont show this user here
  const chosenUsers =
    chosenCompany && chosenCompany.id
      ? await Promise.all(
          usersId.map(async (id) => {
            return await API.get(`user/${id}`);
          })
        )
      : [];
  const filteredChosenUsers = chosenUsers.length ? chosenUsers.filter((user) => user) : [];
  dispatch(choseDate({ startDate, endDate }));
  dispatch(choseCompany(chosenCompany));
  dispatch(choseUser(filteredChosenUsers));
  dispatch(setIgnoreTimezone(ignoreTimezone));
  dispatch(updateDashboardKPI(new Date()));
};
