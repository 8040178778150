import API from "../../services/apiService";
import { transformArrayOfObjectInObject } from "../../utils/helpers";
import notificationAlert from "../../utils/notificationAlert";
import { sortByName } from "../../utils/helpers";

export const CONSULTANT_TABLE_REQUEST = "CONSULTANT_TABLE_REQUEST";
export const GET_CURRENT_PAGE_CONSULTANTS_SUCCESS = "GET_CURRENT_PAGE_CONSULTANTS_SUCCESS";
export const SET_ACTIVE_COMPANY = "SET_ACTIVE_COMPANY";
export const RESET_SETTING_FOR_CONSULTANT = "RESET_SETTING_FOR_CONSULTANT";
export const CHOOSE_CONSULTANTS = "CHOOSE_CONSULTANTS";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const GET_FIRST_LOAD_DATA_REQUEST = "GET_FIRST_LOAD_DATA_REQUEST";
export const GET_FIRST_LOAD_DATA_SUCCESS = "GET_FIRST_LOAD_DATA_SUCCESS";
export const SET_SORT_PARAMS = "SET_SORT_PARAMS";
export const SET_FILTERS = "SET_FILTERS";
export const CLEAR_CONSULTANT_REDUCER = "CLEAR_CONSULTANT_REDUCER";

export const consultantTableRequest = () => {
  return {
    type: CONSULTANT_TABLE_REQUEST,
  };
};

export const getCurrentPageConsultants = () => async (dispatch, getState) => {
  const { currentPage, pageSize, sortedDirection, sortedField, filters } = getState().consultant.tableSettings;

  dispatch(consultantTableRequest());

  const filterEmptyFilters = (data) => {
    return data.filter((filterItem) => {
      if (Array.isArray(Object.entries(filterItem)[0][1])) {
        return !!Object.entries(filterItem)[0][1].length;
      } else {
        return Object.entries(filterItem)[0][1] !== "";
      }
    });
  };

  const consultants = await API.get("consultants", {
    params: {
      page: currentPage,
      pageSize: pageSize,
      s_field: sortedField,
      s_direction: sortedDirection,
      ...transformArrayOfObjectInObject(filterEmptyFilters(filters)), //we leave only filters with not empty filter and transform them into object for spread
    },
  });

  dispatch(
    getCurrentPageConsultantsSuccess({
      consultants: consultants.consultants,
      totalCount: consultants.totalCount,
    })
  );
};

const getCurrentPageConsultantsSuccess = (payload) => {
  return {
    type: GET_CURRENT_PAGE_CONSULTANTS_SUCCESS,
    payload,
  };
};

export const getFirstLoadData = () => async (dispatch) => {
  dispatch(getFirstLoadDataRequest());

  const companies = await API.get("company/all");

  dispatch(
    getFirstLoadDataSuccess({
      companies: sortByName(companies, "name"),
    })
  );
};

const getFirstLoadDataRequest = () => {
  return {
    type: GET_FIRST_LOAD_DATA_REQUEST,
  };
};

const getFirstLoadDataSuccess = (payload) => {
  return {
    type: GET_FIRST_LOAD_DATA_SUCCESS,
    payload,
  };
};

export const setActiveCompany = (payload) => {
  return {
    type: SET_ACTIVE_COMPANY,
    payload,
  };
};

export const resetSettingForConsultant = (payload) => {
  return {
    type: RESET_SETTING_FOR_CONSULTANT,
    payload,
  };
};

export const assignCompaniesToConsultant =
  ({ consultantsId, companiesId }) =>
  async (dispatch) => {
    dispatch(consultantTableRequest());
    try {
      await API.post("consultants/assign", {
        consultantsId: consultantsId,
        companiesId: companiesId,
      });
      notificationAlert({
        type: "success",
        description: "Companies has been successfully assigned",
      });
      dispatch(resetSettingForConsultant({ tableUpdate: new Date() }));
    } catch (err) {
      //We handle errors in axios interseptors
      dispatch(resetSettingForConsultant({ tableUpdate: new Date() }));
    }
  };

export const unassignCompaniesToConsultant =
  ({ consultantsId, companiesId }) =>
  async (dispatch) => {
    dispatch(consultantTableRequest());
    try {
      await API.post("consultants/unassign", {
        consultantsId: consultantsId,
        companiesId: companiesId,
      });
      notificationAlert({
        type: "success",
        description: "Companies has been successfully assigned",
      });
      dispatch(resetSettingForConsultant({ tableUpdate: new Date() }));
    } catch (err) {
      dispatch(resetSettingForConsultant({ tableUpdate: new Date() }));
    }
  };

export const chooseConsultans = (payload) => {
  return {
    type: CHOOSE_CONSULTANTS,
    payload,
  };
};

export const setCurrentPage = (payload) => {
  return {
    type: SET_CURRENT_PAGE,
    payload,
  };
};

export const setSortParams = (payload) => {
  return {
    type: SET_SORT_PARAMS,
    payload,
  };
};

export const setFilters = (payload) => {
  return {
    type: SET_FILTERS,
    payload,
  };
};

export const addConsultant = (data) => async (dispatch) => {
  dispatch(consultantTableRequest());
  try {
    await API.post("user/create", {
      ...data,
      companyId: null,
      role: "consultant",
      welcomeEmail: false,
    });
    notificationAlert({
      type: "success",
      description: "Consultant has been successfully created",
    });
    dispatch(resetSettingForConsultant({ tableUpdate: new Date() }));
  } catch (err) {
    dispatch(resetSettingForConsultant({ tableUpdate: new Date() }));
  }
};

export const deleteConsultant = (currentUser) => async (dispatch) => {
  dispatch(consultantTableRequest());
  try {
    await API.delete(`user/${currentUser.id}`);
    notificationAlert({
      type: "success",
      description: "Consultant has been successfully deleted",
    });
    dispatch(resetSettingForConsultant({ tableUpdate: new Date() }));
  } catch (err) {
    dispatch(resetSettingForConsultant({ tableUpdate: new Date() }));
  }
};

export const updateConsultant = (currentUser, data) => async (dispatch) => {
  dispatch(consultantTableRequest());
  try {
    await API.patch(`user/${currentUser.id}`, {
      ...data,
      companyId: null,
      role: "consultant",
      welcomeEmail: false,
    });
    notificationAlert({
      type: "success",
      description: "Consultant has been successfully updated",
    });
    dispatch(resetSettingForConsultant({ tableUpdate: new Date() }));
  } catch (err) {
    dispatch(resetSettingForConsultant({ tableUpdate: new Date() }));
  }
};

export const deleteSelectedConsultants = (consultantsIdArray) => async (dispatch) => {
  dispatch(consultantTableRequest());
  try {
    await Promise.all(consultantsIdArray.map(async (consultantId) => await API.delete(`user/${consultantId}`)));

    const message =
      consultantsIdArray.length > 1
        ? "Consultants has been successfully deleted"
        : "Consultant has been successfully deleted";
    notificationAlert({
      type: "success",
      description: message,
    });
    dispatch(resetSettingForConsultant({ tableUpdate: new Date() }));
  } catch (err) {
    dispatch(resetSettingForConsultant({ tableUpdate: new Date() }));
  }
};

export const clearConsultantReducer = () => {
  return {
    type: CLEAR_CONSULTANT_REDUCER,
  };
};

export const assignCompanyConsultant = (companiesArrayId, currentConsultant) => async (dispatch) => {
  dispatch(consultantTableRequest());

  try {
    await API.patch(`consultants/${currentConsultant.id}`, {
      companiesId: companiesArrayId,
    });

    notificationAlert({
      type: "success",
      description: "Consultant has been successfully updated",
    });

    dispatch(resetSettingForConsultant({ tableUpdate: new Date() }));
  } catch (err) {
    dispatch(resetSettingForConsultant({ tableUpdate: new Date() }));
  }
};
