import React, { useState, useMemo } from "react";
import { Table } from "antd";
import consultantTableConfig from "./consultantTableConfig";
import {
  chooseConsultans,
  setCurrentPage,
  setSortParams,
  setFilters,
  updateConsultant,
  deleteConsultant,
  assignCompanyConsultant,
} from "../../redux/actions/consultantPageActions";
import { useDispatch, useSelector } from "react-redux";
import { changeSortDirectionTitle } from "../../utils/helpers";
import ModalGroup from "../../components/ModalGroup/ModalGroup";

const pageSizeOptions = ["10", "20", "50", "100"];

function ConsultantsTableViewSection() {
  const dispatch = useDispatch();
  const consultants = useSelector((state) => state.consultant.consultants);
  const chosenConsultants = useSelector((state) => state.consultant.chosenConsultants);
  const pageSize = useSelector((state) => state.consultant.tableSettings.pageSize);
  const currentPage = useSelector((state) => state.consultant.tableSettings.currentPage);
  const totalCount = useSelector((state) => state.consultant.totalCount);
  const tableSettings = useSelector((state) => state.consultant.tableSettings);
  const companies = useSelector((state) => state.consultant.companies);
  const isDataLoading = useSelector((state) => state.consultant.isDataLoading);

  const [isModalVisible, setModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  const onModalOpen = (mode, currentItem) => {
    setModalVisible(true);
    setModalMode(mode);
    setCurrentItem(currentItem);
  };

  const filterByField = (field, data) => {
    const filter = { [field]: data.searchText };
    dispatch(setFilters({ filter: filter, field, tableUpdate: new Date() }));
  };

  const [getData, getColumns] = consultantTableConfig(
    consultants,
    tableSettings,
    filterByField,
    companies,
    onModalOpen
  );

  const chooseConsultantsHandler = (consultantsIdArray) => dispatch(chooseConsultans(consultantsIdArray));

  const rowSelection = {
    type: "checkbox",
    onChange: chooseConsultantsHandler,
    selectedRowKeys: chosenConsultants,
    fixed: true,
  };

  const modalSubmit = (data) => {
    if (modalMode === "edit") {
      dispatch(updateConsultant(currentItem, data));
    }
    if (modalMode === "delete") {
      dispatch(deleteConsultant(currentItem));
    }

    if (modalMode === "assign") {
      const companiesArrayId = companies
        .filter((company) => {
          return data.companies.some((dataCompany) => dataCompany === company.name);
        })
        .map((company) => company.id);

      dispatch(assignCompanyConsultant(companiesArrayId, currentItem));
    }
    modalCancel();
  };

  const modalCancel = () => {
    setModalVisible(false);
    setModalMode(null);
    setCurrentItem(null);
  };

  const modals = [
    {
      title: "Assign",
      text: `Assign companies for «${currentItem && currentItem.name}»`,
      type: "assign",
      isVisible: isModalVisible,
    },
    { title: "Edit", text: "Edit consultant", type: "edit", isVisible: isModalVisible },
    {
      title: "Delete",
      text: `Delete «${currentItem && currentItem.name}»`,
      type: "delete",
      isVisible: isModalVisible,
    },
  ];

  const modalComponentsData = useMemo(
    () => () => {
      if (modalMode === "assign") {
        return [
          {
            name: "companies",
            placeholder: "Select companies",
            elementType: "multiSelect",
            elementList: companies,
            notRequired: true,
          },
        ];
      }
      return [
        { name: "name", elementType: "input", placeholder: "Consultant name" },
        { name: "email", elementType: "input", placeholder: "Consultant email", email: true },
        {
          name: "password",
          elementType: "input_password",
          placeholder: "Consultant password",
          password: true,
          notRequired: true,
        },
      ];
    },
    [modalMode, companies, currentItem]
  );

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === "paginate") {
      dispatch(
        setCurrentPage({
          currentPage: pagination.current,
          pageSize: pagination.pageSize,
          tableUpdate: new Date(),
        })
      );
      window.scrollTo({ top: 0, behavior: "auto" });
    } else if (extra.action === "sort") {
      dispatch(
        setSortParams({
          sortedField: sorter.field,
          sortedDirection: changeSortDirectionTitle(sorter.order),
          tableUpdate: new Date(),
        })
      );
    } else if (extra.action === "filter") {
      if (filters.company && filters.company.length) {
        const companyIdArray = filters.company.map((company) => {
          return companies.find((inner_company) => {
            return inner_company.name === company;
          }).id;
        });
        const filter = { f_companyId: companyIdArray };
        dispatch(setFilters({ filter: filter, field: "f_companyId", tableUpdate: new Date() }));
      } else {
        const filter = { f_companyId: [] };
        dispatch(setFilters({ filter: filter, field: "f_companyId", tableUpdate: new Date() }));
      }
    }
  };

  const transformCurrentItem = (currentItem) => {
    if (modalMode === "assign") {
      return {
        companies: currentItem.company.length ? currentItem.company.split(";") : [],
      };
    }
    return currentItem;
  };

  return (
    <div className="table_view">
      <Table
        loading={isDataLoading}
        className="consultant_table"
        scroll={{ x: "auto" }}
        sticky={{ offsetHeader: 60 }}
        pagination={{
          defaultPageSize: pageSize,
          current: currentPage,
          showSizeChanger: true,
          pageSizeOptions: pageSizeOptions,
          total: totalCount,
          position: ["bottomCenter"],
        }}
        size="small"
        columns={getColumns}
        dataSource={getData}
        bordered={true}
        rowSelection={rowSelection}
        onChange={onTableChange}
      />
      {isModalVisible ? (
        <ModalGroup
          allItems={consultants}
          mode={modalMode}
          modals={modals}
          currentItem={transformCurrentItem(currentItem)}
          modalComponentsData={modalComponentsData()}
          onModalCancel={modalCancel}
          modalSubmitOk={modalSubmit}
        />
      ) : null}
    </div>
  );
}

export default ConsultantsTableViewSection;
