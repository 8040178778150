import API from "../../../services/apiService";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import React from "react";

export const GET_DASHBOARDS_KPI_SUCCESS = "GET_DASHBOARDS_KPI_SUCCESS";
export const GET_GUIDANCE_KPI_SUCCESS = "GET_GUIDANCE_KPI_SUCCESS";
export const UPDATE_GUIDANCE_SORT = "UPDATE_GUIDANCE_SORT";
export const UPDATE_GUIDANCE_TABLE = "UPDATE_GUIDANCE_TABLE";
export const UPDATE_GUIDANCE_PAGINATION = "UPDATE_GUIDANCE_PAGINATION";
export const GET_CHART_DATA_SUCCESS = "GET_CHART_DATA_SUCCESS";
export const UPDATE_CHART_PAGINATION = "UPDATE_CHART_PAGINATION";
export const UPDATE_CHART_SORT = "UPDATE_CHART_SORT";
export const UPDATE_CHART_TABLE_ACTION = "UPDATE_CHART_TABLE_ACTION";

//TODO remove from here another plase
const statsSectionDashboardsInitial = [
  { title: "Hours dialing", name: "hoursDialling", differenceName: "hoursDiallingDifference", difference: 0, value: 0 },
  { title: "Dials", name: "dials", differenceName: "dialsDifference", difference: 0, value: 0 },
  { title: "Conversations", name: "conversations", differenceName: "conversationsDifference", difference: 0, value: 0 },
  { title: "Pitches", name: "pitches", differenceName: "pitchesDifference", difference: 0, value: 0 },
  { title: "Total sales", name: "sales", differenceName: "salesDifference", difference: 0, value: 0 },
  {
    title: "Avg cash per dial",
    name: "avgCashPerDial",
    differenceName: "avgCashPerDialDifference",
    difference: 0,
    value: 0,
  },
  { title: "Dials per sale", name: "dialsPerSale", differenceName: "dialsPerSaleDifference", difference: 0, value: 0 },
  {
    title: "Conversations per sale",
    name: "conversationsPerSale",
    differenceName: "conversationsPerSaleDifference",
    difference: 0,
    value: 0,
  },
  {
    title: "Hours dialed per sale",
    name: "hoursDialedPerSale",
    differenceName: "hoursDialedPerSaleDifference",
    difference: 0,
    value: 0,
  },
];

const checkArrowDirectionException = (name) => {
  return name === "dialsPerSale" || name === "conversationsPerSale";
};

//TODO and remove from here
const mapDashboards = (dashboards) => {
  return statsSectionDashboardsInitial.map((dashboard) => {
    const value = dashboards[dashboard.name] ? dashboards[dashboard.name] : 0;
    let difference = dashboards[dashboard.differenceName];
    let differenceValue;

    if (difference === null) {
      differenceValue = "N/A";
    } else if (difference === 0) {
      differenceValue = difference + "%";
    } else if (value === 0) {
      differenceValue = "-";
    } else {
      differenceValue = Number(((difference / value) * 100).toFixed(2)) + "%";
      if (difference > 0) {
        differenceValue = (
          <p>
            <ArrowUpOutlined
              className={`increase arrow  ${checkArrowDirectionException(dashboard.name) ? " red" : ""}`}
            />
            {differenceValue}
          </p>
        );
      } else {
        differenceValue = (
          <p>
            <ArrowDownOutlined
              className={`decrease arrow' ${checkArrowDirectionException(dashboard.name) ? " green" : ""}`}
            />
            {differenceValue}
          </p>
        );
      }
    }
    return { ...dashboard, value, difference, differenceValue };
  });
};

const getUserIdArray = (activeUsers) => {
  return activeUsers.length ? activeUsers.map((user) => user.id) : [];
};

export const getDashboardsKPI = () => async (dispatch, getState) => {
  const { date, activeUsers, activeCompany, ignoreTimezone } = getState().dashboard;
  if (date.endDate && date.startDate && activeCompany) {
    const kpi = await API.get(`dashboards/kpi`, {
      params: {
        f_userId: getUserIdArray(activeUsers),
        f_companyId: [activeCompany.id],
        f_dateBefore: date.endDate,
        f_dateAfter: date.startDate,
        ignoreTimezone: ignoreTimezone,
      },
    });

    return dispatch(getDashboardsKPISuccess({ dashboardsKPI: mapDashboards(kpi) }));
  } else {
    return dispatch(getDashboardsKPISuccess({ dashboardsKPI: statsSectionDashboardsInitial }));
  }
};

export const getDashboardsKPISuccess = (payload) => {
  return {
    type: GET_DASHBOARDS_KPI_SUCCESS,
    payload,
  };
};

export const updateGuidanceTableAction = (payload) => {
  return {
    type: UPDATE_GUIDANCE_TABLE,
    payload,
  };
};

export const getGuidanceKPISuccess = (payload) => {
  return {
    type: GET_GUIDANCE_KPI_SUCCESS,
    payload,
  };
};

export const getGuidanceKPI = () => async (dispatch, getState) => {
  const { date, activeUsers, activeCompany, users, ignoreTimezone } = getState().dashboard;
  const { guidanceKpiSettings } = getState().employeeSummary;

  if (date.endDate && date.startDate && activeCompany) {
    const { stats, totalCount } = await API.get(`dashboards/stats`, {
      params: {
        page: guidanceKpiSettings.page,
        pageSize: guidanceKpiSettings.pageSize,
        f_userId: getUserIdArray(activeUsers, users),
        f_companyId: [activeCompany.id],
        f_dateBefore: date.endDate,
        f_dateAfter: date.startDate,
        s_field: guidanceKpiSettings.sortedField,
        s_direction:
          guidanceKpiSettings.sortOrder === "ascend"
            ? "ASC"
            : guidanceKpiSettings.sortOrder === "descend"
            ? "DESC"
            : null,
        ignoreTimezone: ignoreTimezone,
      },
    });
    return dispatch(getGuidanceKPISuccess({ guidanceKPI: stats, totalCount }));
  } else {
    return dispatch(getGuidanceKPISuccess({ guidanceKPI: null, totalCount: 0 }));
  }
};

export const updateGuidanceSort = (payload) => {
  return {
    type: UPDATE_GUIDANCE_SORT,
    payload,
  };
};

export const updateGuidancePagination = (payload) => {
  return {
    type: UPDATE_GUIDANCE_PAGINATION,
    payload,
  };
};

export const getChartDataSuccess = (payload) => {
  return {
    type: GET_CHART_DATA_SUCCESS,
    payload,
  };
};

export const getChartData = () => async (dispatch, getState) => {
  const { date, activeUsers, activeCompany, users, ignoreTimezone } = getState().dashboard;
  const { chartSettings } = getState().employeeSummary;

  if (date.endDate && date.startDate && activeCompany) {
    const { stats, totalCount } = await API.get(`dashboards/daily-stats`, {
      params: {
        page: chartSettings.page,
        pageSize: chartSettings.pageSize,
        f_userId: getUserIdArray(activeUsers, users),
        f_companyId: [activeCompany.id],
        f_dateBefore: date.endDate,
        f_dateAfter: date.startDate,
        s_field: chartSettings.sortedField,
        s_direction:
          chartSettings.sortOrder === "ascend" ? "ASC" : chartSettings.sortOrder === "descend" ? "DESC" : null,
        ignoreTimezone: ignoreTimezone,
      },
    });

    let maxChartValues;
    if (stats.length) {
      maxChartValues = {
        dials: Math.max(...stats.map((item) => item.dials)),
        conversations: Math.max(...stats.map((item) => item.conversations)),
        pitches: Math.max(...stats.map((item) => item.pitches)),
        sales: Math.max(...stats.map((item) => item.sales)),
        salesValue: Math.max(...stats.map((item) => item.salesValue)),
      };
    } else {
      maxChartValues = null;
    }

    return dispatch(
      getChartDataSuccess({
        chartData: stats,
        maxChartValues,
        totalCount: totalCount,
      })
    );
  }

  return dispatch(getChartDataSuccess({ chartData: [], maxChartValues: null, totalCount: 0 }));
};

export const updateChartPagination = (payload) => {
  return {
    type: UPDATE_CHART_PAGINATION,
    payload,
  };
};

export const updateChartSort = (payload) => {
  return {
    type: UPDATE_CHART_SORT,
    payload,
  };
};

export const updateChartTableAction = (payload) => {
  return {
    type: UPDATE_CHART_TABLE_ACTION,
    payload,
  };
};
