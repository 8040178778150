import {
  SET_GRID_DATA_REQUEST,
  SET_GRID_DATA_SUCCESS,
  GET_AND_REPLACE_OVERRIDE_SUCCESS,
  CLOSE_OVERRIDE_MODAL,
  CANCEL_OVERRIDE_MODAL,
} from "../actions/scriptLoaderActions";

const initialState = { isGridDataLoading: true, gridData: [], gridOverrideData: {} };

export const scriptLoaderReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case SET_GRID_DATA_REQUEST:
      return { ...state, isGridDataLoading: true };

    case SET_GRID_DATA_SUCCESS:
      return { ...state, isGridDataLoading: false, gridData: payload };

    case GET_AND_REPLACE_OVERRIDE_SUCCESS:
      return {
        ...state,
        isOverrideModalOpen: true,
        gridOverrideData: {
          objections: payload.objections,
          endReasons: payload.endReasons,
          script: payload.script,
        },
      };

    case CLOSE_OVERRIDE_MODAL:
      return {
        ...state,
        isOverrideModalOpen: false,
        gridOverrideData: {},
      };

    case CANCEL_OVERRIDE_MODAL:
      return {
        ...state,
        isOverrideModalOpen: false,
      };

    default:
      return state;
  }
};
