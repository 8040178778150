import React, { useState, useMemo } from "react";
import { Tabs, Tooltip, Spin } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ScriptForm from "./ScriptForm/ScriptForm";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewTabAction,
  deleteTabAction,
  editTabAction,
  changeOrderTabsAction,
} from "../../redux/actions/scriptPageActions";
import ModalGroup from "../../components/ModalGroup/ModalGroup";
import API from "../../services/apiService";
import PropTypes from "prop-types";
import { getSortedStagesSelector } from "../../redux/selectors/scriptPageSelectors";
import "./_script-page.less";

const { TabPane } = Tabs;

export default function ScriptTabsForm({ currentScript, isDataLoadingForm }) {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState("0");
  const stages = useSelector(getSortedStagesSelector);
  const [modalMode, setModalMode] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const [currentCard, setCurrentCard] = useState(0);

  const onChange = (activePage) => setCurrentPage(activePage);

  const onEdit = (mode) => {
    setModalVisible(true);
    setModalMode(mode);
  };

  const onModalCancel = () => {
    setModalVisible(false);
    setModalMode("");
  };

  const modals = [
    {
      title: "Delete",
      text: `Delete «${stages[currentPage] && stages[currentPage].name}» stage?`,
      type: "delete",
      isVisible: isModalVisible,
    },
    { title: "Edit", text: "Edit stage", type: "edit", isVisible: isModalVisible },
    { title: "Add", text: "Add new stage", type: "add", isVisible: isModalVisible },
  ];

  const modalComponentsData = useMemo(
    () => () => {
      return [
        {
          name: "name",
          placeholder: "Type stage name",
          elementType: "input",
          checkDuplicate: true,
        },
      ];
    },
    []
  );

  const createNewStage = async ({ name, objections = [], endReasons = [], type = 0 }) => {
    const newObjectionTab = [
      {
        order: stages.length,
        name,
        scriptId: currentScript.id,
        type,
        objections,
        endReasons,
        questions: [],
      },
    ];
    const createStageData = await API.post("stage/createStage", newObjectionTab);
    dispatch(addNewTabAction(createStageData[createStageData.length - 1]));
    setCurrentPage(String(stages.length));
  };

  const editStageName = async ({ name }) => {
    const stageResponse = await API.patch(`stage/${stages[currentPage].id}`, { name });
    const idx = stages.findIndex((el) => el.id === stages[currentPage].id);
    const stage = { ...stages[currentPage], name: stageResponse.name };
    dispatch(editTabAction({ stage, idx }));
  };

  const deleteStage = async () => {
    await API.delete(`stage/${stages[currentPage].id}`);
    dispatch(deleteTabAction(stages[currentPage].id));
    setCurrentPage(String(stages.length - 2));
  };

  const modalSubmitOk = async (value) => {
    if (modalMode === "add") {
      await createNewStage(value);
    } else if (modalMode === "edit") {
      await editStageName(value);
    } else if (modalMode === "delete") {
      await deleteStage();
    }
    setModalVisible(false);
  };

  const onDragStart = (event, card) => {
    event.target.classList.add("drag-active");
    setCurrentCard(card);
  };

  const onDragLeave = (event) => {
    if (event.target.classList.contains("chip")) {
      event.target.classList.remove("overed");
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
    if (event.target.classList.contains("chip")) {
      event.target.classList.add("overed");
    }
  };

  const onDragEnd = (event, card) => {
    event.preventDefault();
    event.target.classList.remove("drag-active");

    if (event.target.classList.contains("chip")) {
      event.target.classList.remove("overed");
    }

    const data = stages.map((cardItem) => {
      if (cardItem.id === card.id) {
        return { ...cardItem, order: currentCard.order };
      }
      if (cardItem.id === currentCard.id) {
        return { ...cardItem, order: card.order };
      }
      return cardItem;
    });

    const currentPageIdx = String(stages.findIndex((el) => el.name === card.name));
    setCurrentPage(currentPageIdx);
    dispatch(changeOrderTabsAction({ newStagesOrder: data }));
  };

  if (isDataLoadingForm) {
    return (
      <div className="spinnerForm">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Tabs
        type="editable-card"
        className="script_setting_tabs"
        tabPosition={"top"}
        onChange={onChange}
        activeKey={currentPage || "0"}
        onEdit={() => onEdit("add")}>
        {stages.map((stage, index) => {
          return (
            <TabPane
              tab={
                <div
                  className="tab-wrapper chip"
                  draggable
                  onDragStart={(event) => onDragStart(event, stage)}
                  onDragLeave={onDragLeave}
                  onDragOver={onDragOver}
                  onDrop={(event) => onDragEnd(event, stage)}>
                  {stage.name}
                  <div className="icons-wrapper">
                    <Tooltip placement="topLeft" title={"Edit stage"}>
                      <EditOutlined key="edit" className="icons-wrapper__edit-icon" onClick={() => onEdit("edit")} />
                    </Tooltip>
                    <Tooltip placement="topLeft" title={"Delete stage"}>
                      <DeleteOutlined
                        key="delete"
                        className="icons_wrapper__delete-icon"
                        onClick={() => onEdit("delete")}
                      />
                    </Tooltip>
                  </div>
                </div>
              }
              key={index}
              closable={false}>
              {index === Number(currentPage) ? <ScriptForm key={index} currentPage={Number(currentPage)} /> : null}
            </TabPane>
          );
        })}
        )}
      </Tabs>
      {!stages.length && <div>There are no stages here. Add new stage!</div>}
      {isModalVisible ? (
        <ModalGroup
          editableItemIdx={""}
          mainField={"name"}
          allItems={stages}
          mode={modalMode}
          modals={modals}
          modalComponentsData={modalComponentsData()}
          onModalCancel={onModalCancel}
          modalSubmitOk={modalSubmitOk}
          currentItem={stages[currentPage]}
        />
      ) : null}
    </>
  );
}

ScriptTabsForm.propTypes = {
  currentScript: PropTypes.shape({
    companyId: PropTypes.string,
    enabled: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    userId: PropTypes.string,
  }),
  isDataLoadingForm: PropTypes.bool,
};
