import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { store, persistor } from "redux/store";
import App from "App";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ErrorBoundary>
        <BrowserRouter>
          <ConfigProvider locale={enUS}>
            <App />
          </ConfigProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
