import React, { useCallback, useState, useEffect } from "react";
import ModalGroup from "../../../components/ModalGroup/ModalGroup";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import API from "../../../services/apiService";
import PropTypes from "prop-types";
import notificationAlert from "../../../utils/notificationAlert";
import SelectComponent from "../../../components/Select/Select";
import { useDispatch } from "react-redux";
import { updateGeneralQuestion, setCurrentQuestion } from "../../../redux/actions/scriptPageActions";
import "../_script-page.less";

export default function ScriptAnswerPanel({ allQuestions, currentQuestion }) {
  const dispatch = useDispatch();
  const [modalMode, setModalMode] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const [chosenAnswer, setChosenAnswer] = useState({});

  const onModalCancel = () => {
    setModalVisible(false);
    setModalMode("");
  };

  useEffect(() => {
    setChosenAnswer({});
  }, [currentQuestion]);

  const onModalOpen = (mode) => {
    setModalVisible(true);
    setModalMode(mode);
  };

  const createAnswer = async ({ name }) => {
    const updatedQuestionObject = {
      ...currentQuestion,
      params: [...currentQuestion.params, { name }],
    };

    const updatedQuestion = await API.patch(`question/${currentQuestion.id}`, updatedQuestionObject);
    const questionIdx = allQuestions.findIndex((item) => item.name === currentQuestion.name);
    dispatch(setCurrentQuestion(updatedQuestion));
    dispatch(updateGeneralQuestion({ questionIdx, updatedQuestion }));

    setChosenAnswer(updatedQuestion.params[updatedQuestion.params.length - 1]);
    notificationAlert({
      type: "success",
      description: "Answer has been successfully created",
    });
  };

  const deleteAnswer = async () => {
    const chosenAnswerIdx = currentQuestion.params.findIndex(
      (questionParam) => questionParam.name === chosenAnswer.name
    );

    const updatedQuestionObject = {
      ...currentQuestion,
      params: [
        ...currentQuestion.params.slice(0, chosenAnswerIdx),
        ...currentQuestion.params.slice(chosenAnswerIdx + 1),
      ],
    };

    setChosenAnswer({});
    const updatedQuestion = await API.patch(`question/${currentQuestion.id}`, updatedQuestionObject);
    const questionIdx = allQuestions.findIndex((item) => item.name === currentQuestion.name);
    dispatch(setCurrentQuestion(updatedQuestion));
    dispatch(updateGeneralQuestion({ questionIdx, updatedQuestion }));
    notificationAlert({
      type: "success",
      description: "Answer has been successfully deleted",
    });
  };

  const editAnswer = async ({ name }) => {
    const chosenAnswerIdx = currentQuestion.params.findIndex((item) => item.name === chosenAnswer.name);
    const updatedQuestionObject = {
      ...currentQuestion,
      params: [
        ...currentQuestion.params.slice(0, chosenAnswerIdx),
        { name },
        ...currentQuestion.params.slice(chosenAnswerIdx + 1),
      ],
    };

    const updatedQuestion = await API.patch(`question/${currentQuestion.id}`, updatedQuestionObject);
    const questionIdx = allQuestions.findIndex((item) => item.name === currentQuestion.name);
    dispatch(setCurrentQuestion(updatedQuestion));
    dispatch(updateGeneralQuestion({ questionIdx, updatedQuestion }));
    setChosenAnswer((state) => ({ ...state, name }));
    notificationAlert({
      type: "success",
      description: "Answer has been successfully changed",
    });
  };

  const changeAnswerHandler = (name) => {
    const selectedAnswer = currentQuestion.params.find((questionParam) => questionParam.name === name);
    setChosenAnswer(selectedAnswer);
  };

  const modalSubmitOk = async (value) => {
    if (modalMode === "add") {
      await createAnswer(value);
    } else if (modalMode === "delete") {
      await deleteAnswer();
    } else if (modalMode === "edit") {
      await editAnswer(value);
    }
    setModalVisible(false);
  };

  const modals = [
    {
      title: "Delete",
      text: `Delete «${chosenAnswer && chosenAnswer.name}» answer?`,
      type: "delete",
      isVisible: isModalVisible,
    },
    { title: "Edit", text: "Edit answer", type: "edit", isVisible: isModalVisible },
    { title: "Add", text: "Add answer", type: "add", isVisible: isModalVisible },
  ];

  const buttons = [
    { name: "Create", action: () => onModalOpen("add"), visible: true },
    {
      name: "Edit",
      action: () => onModalOpen("edit"),
      visible: Object.entries(chosenAnswer).length,
    },
    {
      name: "Delete",
      action: () => onModalOpen("delete"),
      visible: Object.entries(chosenAnswer).length,
    },
  ];

  const modalComponentsData = useCallback(() => {
    return [
      {
        name: "name",
        placeholder: "Type answer name",
        elementType: "input",
        maxLength: 150,
        checkDuplicate: true,
      },
    ];
  }, [modalMode]);

  return (
    <div className="answers-section">
      <h3 className="title">Answers:</h3>
      <div className="select_wrapper">
        <SelectComponent
          mainField="name"
          data={currentQuestion.params}
          value={chosenAnswer && chosenAnswer.name}
          handleChange={changeAnswerHandler}
        />
      </div>
      <ButtonGroup data={buttons} />
      {isModalVisible ? (
        <ModalGroup
          editableItemIdx={""}
          mainField="name"
          allItems={currentQuestion.params}
          mode={modalMode}
          modals={modals}
          modalComponentsData={modalComponentsData()}
          onModalCancel={onModalCancel}
          modalSubmitOk={modalSubmitOk}
          currentItem={chosenAnswer}
        />
      ) : (
        ""
      )}
    </div>
  );
}

ScriptAnswerPanel.propTypes = {
  allQuestions: PropTypes.array,
  currentQuestion: PropTypes.object,
};
