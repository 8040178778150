import React, { useEffect } from "react";
import CallBreakdownsLeftPie from "./CallBreakdownsLeftPIe";
import CallBreakdownsRightPie from "./CallBreakdownsRightPIe";
import { useDispatch, useSelector } from "react-redux";
import {
  getCallBreakdownsPieLeft,
  getCallBreakdownsPieRight,
} from "../../../../../redux/actions/dashboardPageActions/callBreakdownsActions";
import { Col, Row } from "antd";
import { getPiesSectionData } from "../../../../../redux/selectors/dashboardsPageSelectors";
import Title from "../../../../../components/Title/Title";
import "./_callBreakdownsPies.less";

function CallBreakdownsPies() {
  const dispatch = useDispatch();
  const { leftPieDashboard, rightPieDashboard, totalValue, totalValueRightPie, isLeftPieLoading, isRightPieLoading } =
    useSelector((state) => getPiesSectionData(state));

  const updateDashboardsKpi = useSelector((state) => state.dashboard.updateDashboardsKpi);

  useEffect(() => {
    dispatch(getCallBreakdownsPieLeft());
  }, [updateDashboardsKpi]);

  useEffect(() => {
    dispatch(getCallBreakdownsPieRight());
  }, [updateDashboardsKpi]);

  const checkDataAvailable = () => leftPieDashboard || isLeftPieLoading;

  return (
    <div className="pieCharts">
      <Title tag="h5" position="left" weight="bold">
        How are sales calls ending?
      </Title>
      <Title tag="h5" position="left">
        This shows the selected date range and the last 28 days range of how sales call have ended.
      </Title>
      <Row gutter={[10, 30]}>
        <Col xs={24} sm={24} md={24} lg={12}>
          {checkDataAvailable() && <h5 className="dateRange_title">Previous period</h5>}
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          {checkDataAvailable() && <h5 className="dateRange_title">Selected period</h5>}
        </Col>
      </Row>
      <Row gutter={[10, 30]}>
        {checkDataAvailable() && (
          <Col xs={24} sm={24} md={24} lg={12}>
            <CallBreakdownsLeftPie
              isLoading={isLeftPieLoading}
              data={leftPieDashboard}
              totalValueLeftPie={totalValue}
            />
          </Col>
        )}
        {checkDataAvailable() && (
          <Col xs={24} sm={24} md={24} lg={12}>
            <CallBreakdownsRightPie
              isLoading={isRightPieLoading}
              data={rightPieDashboard}
              totalValueRightPie={totalValueRightPie}
            />
          </Col>
        )}
      </Row>
    </div>
  );
}

export default CallBreakdownsPies;
