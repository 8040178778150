import { Table } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getChartData,
  updateChartPagination,
  updateChartSort,
  updateChartTableAction,
} from "../../../../../redux/actions/dashboardPageActions/employeeSummaryActions";
import employeeSummaryChartTableConfig from "./employeeSummaryChartTableConfig";
import "./_employeeSummaryChartTable.less";

function EmployeeSummaryChartTable() {
  const dispatch = useDispatch();
  const chartData = useSelector((state) => state.employeeSummary.chartData);
  const maxChartValues = useSelector((state) => state.employeeSummary.maxChartValues);
  const updateDashboardsKpi = useSelector((state) => state.dashboard.updateDashboardsKpi);
  const updateChartTable = useSelector((state) => state.employeeSummary.updateChartTable);

  const { pageSize, page, totalCount, sortOrder, sortedField } = useSelector(
    (state) => state.employeeSummary.chartSettings
  );

  const { getData, getColumns } = employeeSummaryChartTableConfig({
    chartData,
    maxChartValues,
    sortOrder,
    sortedField,
  });

  useEffect(() => {
    dispatch(getChartData());
  }, [updateDashboardsKpi, updateChartTable]);

  const onTableChange = (pagination, filters, sorter, { action }) => {
    if (action === "paginate") {
      dispatch(updateChartPagination({ page: pagination.current, pageSize: pagination.pageSize }));
      dispatch(updateChartTableAction(new Date()));
    }
    if (action === "sort") {
      dispatch(updateChartSort({ sortOrder: sorter.order, sortedField: sorter.field }));
      dispatch(updateChartTableAction(new Date()));
    }
  };

  return (
    <>
      <Table
        className="employeeTableCharts"
        scroll={{ x: "auto" }}
        bordered={true}
        pagination={{
          pageSize: pageSize,
          current: page,
          pageSizeOptions: [5],
          total: totalCount,
          position: ["bottomCenter"],
        }}
        size="small"
        rowClassName={(record, index) => {
          if (record.conversations?.type) {
            return "metric_row";
          }
        }}
        onChange={onTableChange}
        columns={getColumns}
        dataSource={getData}
      />
    </>
  );
}

export default EmployeeSummaryChartTable;
