import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "../../utils/mocks";
import {
  getCurrentPageConsultants,
  getFirstLoadData,
  clearConsultantReducer,
} from "../../redux/actions/consultantPageActions";
import ConsultantsTableViewSection from "./ConsultantsTableViewSection";
import ConsultantsConfigSection from "./ConsultantConfigSection";
import useActivePage from "../../hooks/useActivePage";
import "./_consultantsPage.less";

function ConsultantsPage() {
  useActivePage(routes.CONSULTANTS);
  const dispatch = useDispatch();
  const tableUpdate = useSelector((state) => state.consultant.tableUpdate);

  useEffect(() => {
    dispatch(getFirstLoadData());
    return () => {
      dispatch(clearConsultantReducer());
    };
  }, []);

  useEffect(() => {
    dispatch(getCurrentPageConsultants());
  }, [tableUpdate]);

  return (
    <div className="consultant-page">
      <div className="fluid_container">
        <div className="consultant-page_inner">
          <div className="consultant-page__content">
            <ConsultantsConfigSection />
            <ConsultantsTableViewSection />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultantsPage;
