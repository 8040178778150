import React, { useState, useEffect } from "react";
import { Modal, Button, Table } from "antd";
import API from "../../../services/apiService";
import { FORMAT_DATE_UI, transformAndGetDataWithOffset } from "../../../utils/helpers";
import PropTypes from "prop-types";
import useWindowSize from "../../../hooks/resizeHook";

function StaffDetailTableModal({ isModalVisible, closeActivityModal, currentActivity }) {
  const [activityData, setActivityData] = useState([]);
  const [isDataLoading, setDataLoading] = useState(true);
  const size = useWindowSize();

  const calculateCallActivityDurationSeconds = (activity, startCallTime, index) => {
    //if 0 index it means first call activity and we calculate it from startCallTime
    if (!index) {
      return (new Date(activity[index].date).getTime() - new Date(startCallTime).getTime()) / 1000;
    } else {
      return (new Date(activity[index].date).getTime() - new Date(activity[index - 1].date).getTime()) / 1000;
    }
  };

  const transformCallActivityDate = (activity, startCallTime) => {
    return activity && activity.length
      ? activity.map((entity, index) => {
          const activityDuration = calculateCallActivityDurationSeconds(activity, startCallTime, index);

          return {
            ...entity,
            key: index,
            date: transformAndGetDataWithOffset(entity.date, FORMAT_DATE_UI),
            duration: activityDuration,
          };
        })
      : [];
  };

  useEffect(() => {
    const getActivity = async () => {
      const activityData = await API.get(`dashboards/call-history/${currentActivity.id}`);
      setActivityData(transformCallActivityDate(activityData.callActivity, currentActivity.date) || []);
      setDataLoading(false);
    };

    getActivity();
  }, []);

  const transformSecondsToTimeString = (activityDuration) => {
    let activityDurationSeconds = Math.floor((activityDuration / 3600) * 60 * 60);
    let activityDurationMinutes = Math.floor((activityDuration / 3600) * 60);

    if (activityDurationMinutes) {
      return `${activityDurationMinutes} minute(s) ${activityDurationSeconds - activityDurationMinutes * 60} second(s)`;
    }
    return `${activityDurationSeconds - activityDurationMinutes * 60} second(s)`;
  };

  const columns = [
    {
      title: "Type",
      className: "type_column",
      dataIndex: "itemType",
      key: "type",
      width: "28%",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.itemType.localeCompare(b.itemType),
    },
    {
      title: "Info",
      className: "info_column",
      dataIndex: "itemInfo",
      key: "info",
      width: "28%",
      sorter: (a, b) => a.itemInfo.localeCompare(b.itemInfo),
    },
    {
      title: "Date",
      className: "date_column",
      dataIndex: "date",
      width: "22%",
      key: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: "Duration",
      className: "duration_column",
      dataIndex: "duration",
      width: "22%",
      key: "date",
      sorter: (a, b) => a.duration - b.duration,
      render: (data) => transformSecondsToTimeString(data),
    },
  ];

  return (
    <Modal
      width={size.width > 1200 ? "80%" : "100%"}
      title={"Call activity"}
      centered
      visible={isModalVisible}
      footer={[
        <Button key="back" onClick={closeActivityModal}>
          Cancel
        </Button>,
      ]}
      onCancel={closeActivityModal}>
      <Table
        bordered={true}
        className="activity_table"
        scroll={{ x: "auto" }}
        loading={isDataLoading}
        columns={columns}
        dataSource={activityData}
      />
    </Modal>
  );
}

StaffDetailTableModal.propTypes = {
  isModalVisible: PropTypes.bool,
  closeActivityModal: PropTypes.func,
  currentActivity: PropTypes.shape({
    companyId: PropTypes.string,
    date: PropTypes.string,
    duration: PropTypes.number,
    endDate: PropTypes.string,
    endReason: PropTypes.string,
    id: PropTypes.string,
    offset: PropTypes.string,
  }),
};

export default StaffDetailTableModal;
