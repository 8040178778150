import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";

function StatsScheduleSaveButton({ saveStatsSchedule }) {
  return (
    <div>
      <Button type="success" className="btn" onClick={saveStatsSchedule}>
        Save
      </Button>
    </div>
  );
}

StatsScheduleSaveButton.propTypes = {
  saveStatsSchedule: PropTypes.func,
};

export default StatsScheduleSaveButton;
