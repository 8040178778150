import { transformSecondsToTime } from "../../../../../utils/helpers";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

export const kpiMapping = [
  {
    title: "Сall duration",
    name: "averageCallDuration",
    differenceName: "averageCallDurationDifference",
    difference: 0,
    value: 0,
  },
  {
    title: "Average time to get «Yes»",
    name: "averageTimeToGetYes",
    differenceName: "averageTimeToGetYesDifference",
    difference: 0,
    value: 0,
  },
  {
    title: "«Yes» responses",
    name: "yesAnswerCount",
    differenceName: "yesAnswerCountDifference",
    difference: 0,
    value: 0,
  },
  {
    title: "Average time to get «No»",
    name: "averageTimeToGetNo",
    differenceName: "averageTimeToGetNoDifference",
    difference: 0,
    value: 0,
  },
  {
    title: "«No» responses",
    name: "noAnswerCount",
    differenceName: "noAnswerCountDifference",
    difference: 0,
    value: 0,
  },
];

const TIME_KPI_FORMAT = "HH:mm:ss";

const checkArrowDownGoodDirection = (name) => {
  return (
    name === "averageCallDuration" ||
    name === "averageTimeToGetYes" ||
    name === "averageTimeToGetNo" ||
    name === "noAnswerCount"
  );
};

export const getKpiWithDifferenceAndArrows = (dashboards) => {
  return kpiMapping.map((dashboard) => {
    const value = dashboards[dashboard.name] ? transformSecondsToTime(dashboards[dashboard.name], TIME_KPI_FORMAT) : 0;
    let difference = dashboards[dashboard.differenceName];
    let differenceValue;
    if (difference === null) {
      differenceValue = "N/A";
    } else if (difference === 0) {
      differenceValue = difference;
    } else if (value === 0) {
      differenceValue = "-";
    } else {
      differenceValue = !(dashboard.name === "yesAnswerCount" || dashboard.name === "noAnswerCount")
        ? transformSecondsToTime(difference, TIME_KPI_FORMAT)
        : difference;
      if (difference > 0) {
        differenceValue = (
          <p>
            <ArrowUpOutlined
              className={`increase arrow  ${checkArrowDownGoodDirection(dashboard.name) ? " red" : ""}`}
            />
            {differenceValue}
          </p>
        );
      } else {
        differenceValue = (
          <p>
            <ArrowDownOutlined
              className={`decrease arrow' ${checkArrowDownGoodDirection(dashboard.name) ? " green" : ""}`}
            />
            {differenceValue}
          </p>
        );
      }
    }
    return { ...dashboard, value, difference, differenceValue };
  });
};
