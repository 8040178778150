import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSettingForConsultant,
  assignCompaniesToConsultant,
  unassignCompaniesToConsultant,
  addConsultant,
  deleteSelectedConsultants,
} from "../../redux/actions/consultantPageActions";
import { Row, Col, Menu, Dropdown, Button, Typography } from "antd";
import ModalGroup from "../../components/ModalGroup/ModalGroup";
import { DownOutlined, MinusOutlined, PlusOutlined, UserAddOutlined, UserDeleteOutlined } from "@ant-design/icons";

function ConsultantsConfigSection() {
  const dispatch = useDispatch();
  const chosenConsultants = useSelector((state) => state.consultant.chosenConsultants);
  const companies = useSelector((state) => state.consultant.companies);
  const consultants = useSelector((state) => state.consultant.consultants);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  const onModalOpen = (mode, currentItem) => {
    setModalVisible(true);
    setModalMode(mode);
    setCurrentItem(currentItem);
  };

  const resetSettingHandler = () => {
    dispatch(resetSettingForConsultant({ tableUpdate: new Date() }));
  };

  //function that return array of companies id from array of companies name
  const getCompaniesIdArray = (companiesNamesArray) => {
    return companiesNamesArray.map((companyName) => {
      return companies.find((company) => company.name === companyName).id;
    });
  };

  const modalSubmit = async (data) => {
    if (modalMode === "company_assign") {
      await dispatch(
        assignCompaniesToConsultant({
          consultantsId: chosenConsultants,
          companiesId: getCompaniesIdArray(data.companies),
        })
      );
    }
    if (modalMode === "company_remove") {
      await dispatch(
        unassignCompaniesToConsultant({
          consultantsId: chosenConsultants,
          companiesId: getCompaniesIdArray(data.companies),
        })
      );
    }
    if (modalMode === "add") {
      await dispatch(addConsultant(data));
    }
    if (modalMode === "delete") {
      await dispatch(deleteSelectedConsultants(chosenConsultants));
    }
    modalCancel();
  };

  const modalCancel = () => {
    setModalVisible(false);
    setModalMode(null);
    setCurrentItem(null);
  };

  const getConsultantNameById = (choosenConsultants) => {
    if (choosenConsultants.length && isModalVisible) {
      return consultants.find((consultant) => consultant.id === choosenConsultants[0])?.name;
    }
  };

  const modals = [
    {
      title: "Assign",
      text: "Assign companies to consultants",
      type: "company_assign",
      isVisible: isModalVisible,
    },
    {
      title: "Unassign",
      text: "Unassign companies from consultants",
      type: "company_remove",
      isVisible: isModalVisible,
    },
    { title: "Create", text: "Create new consultant", type: "add", isVisible: isModalVisible },
    {
      title: "Delete",
      text:
        chosenConsultants.length > 1 ? "Delete consultants" : `Delete «${getConsultantNameById(chosenConsultants)}»`,
      type: "delete",
      isVisible: isModalVisible,
    },
  ];

  //function that return all companies from selected leads without duplicates
  const getDefaultSelectedConsultantsCompanies = (chosenConsultants) => {
    const defaultSelectedConsultants =
      chosenConsultants && chosenConsultants.length
        ? chosenConsultants.map((consultantId) => {
            const currentConsultant = consultants.find((consultant) => consultant.id === consultantId);
            return currentConsultant.companies.map((company) => company);
          })
        : [];

    if (defaultSelectedConsultants.length) {
      return defaultSelectedConsultants
        .reduce((acc, curr) => acc.concat(curr), [])
        .filter((company, i, companyArray) => companyArray.findIndex((t) => t.name === company.name) === i);
    }
    return [];
  };

  //function that filters company and return company if at least 1 lead doesnt have this company
  const getCompaniesByUsers = (companies, chosenConsultants) => {
    return companies && companies.length
      ? companies.filter(({ name }) => {
          return chosenConsultants.some((consultantId) => {
            const currentConsultantCompanies = consultants.find((consultant) => consultant.id === consultantId)[
              "companies"
            ];
            return !currentConsultantCompanies.some((s) => s.name === name);
          });
        })
      : [];
  };

  const modalComponentsData = useMemo(
    () => () => {
      if (modalMode === "add") {
        return [
          { name: "name", elementType: "input", placeholder: "Consultant name" },
          { name: "email", elementType: "input", placeholder: "Consultant email", email: true },
          {
            name: "password",
            elementType: "input_password",
            placeholder: "Consultant password",
            password: true,
          },
        ];
      }
      return [
        {
          name: "companies",
          placeholder: "Select companies",
          elementType: "multiSelect",
          elementList:
            modalMode === "company_remove"
              ? getDefaultSelectedConsultantsCompanies(chosenConsultants)
              : getCompaniesByUsers(companies, chosenConsultants),
        },
      ];
    },
    [companies, modalMode, chosenConsultants]
  );

  const actionsMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => onModalOpen("add")} icon={<PlusOutlined />}>
        Add consultant
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => onModalOpen("company_assign")}
        icon={<UserAddOutlined />}
        disabled={!(chosenConsultants && chosenConsultants.length > 0)}>
        Assign company
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => onModalOpen("company_remove")}
        icon={<UserDeleteOutlined />}
        disabled={!(chosenConsultants && chosenConsultants.length > 0)}>
        Unassign company
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => onModalOpen("delete")}
        icon={<MinusOutlined />}
        disabled={!(chosenConsultants && chosenConsultants.length > 0)}>
        {chosenConsultants.length > 1 ? "Remove consultants" : "Remove consultant"}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="config_section">
        <Row className="row">
          <Col xs={24} sm={24} md={24} lg={24} className="config_section">
            <Typography.Title className="title" level={2}>
              Consultants
            </Typography.Title>
            <div className="actions">
              <Button className={"btn"} type="primary" onClick={() => resetSettingHandler()}>
                Reset
              </Button>
              <Dropdown overlay={actionsMenu} trigger={["click"]}>
                <Button className={"btn"} type="primary">
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
      {isModalVisible ? (
        <ModalGroup
          allItems={consultants}
          mode={modalMode}
          modals={modals}
          currentItem={currentItem}
          modalComponentsData={modalComponentsData()}
          onModalCancel={modalCancel}
          modalSubmitOk={modalSubmit}
        />
      ) : null}
    </>
  );
}

export default ConsultantsConfigSection;
