import React from "react";
import EmployeeSummatyGuidanceTable from "./EmployeeSummaryGuidanceTable/EmployeeSummaryGuidanceTable";
import DashboardChartTable from "./EmployeeSummaryChartTable/DashboardChartTable";
import EmployeeSummaryKpiDashboards from "./EmployeeSummaryKpiDashboards/EmployeeSummaryKpiDashboards";

function EmployeeSummaryTab() {
  return (
    <div className="employeeSummaryTab">
      <EmployeeSummaryKpiDashboards />
      <DashboardChartTable />
      <EmployeeSummatyGuidanceTable />
    </div>
  );
}

export default EmployeeSummaryTab;
