import { createSelector } from "reselect";
import { FORMAT_DATE_MINUTE_UI, transformAndGetDataWithOffset } from "../../utils/helpers";

const allMeetingsSelector = (state) => state.meetings.meetings;
const getAllPlannedGuidance = (state) => state.meetingPlan.guidance;
const getAllTeamMembers = (state) => state.meetingPlan.selectedTeamMembers;
const getAllUsers = (state) => state.meetingPlan.systemUsers;

export const getMeetingsTableTransformed = createSelector(
  allMeetingsSelector,
  (meetings) =>
    meetings &&
    meetings.length &&
    meetings.map((meeting) => {
      return {
        ...meeting,
        date: transformAndGetDataWithOffset(meeting.date, FORMAT_DATE_MINUTE_UI),
        teamMembers: meeting.users.reduce((acc, teamMember, index, array) => {
          return acc + teamMember.name + (array.length - 1 !== index ? ", " : "");
        }, ""),
      };
    })
);

export const getPlannedGuidanceTransformed = createSelector(getAllPlannedGuidance, (plannedGuidance) =>
  plannedGuidance ? Object.values(plannedGuidance) : []
);

export const getTeamMembersArrayId = createSelector(getAllUsers, getAllTeamMembers, (allUsers, selectedTeamMembers) => {
  return allUsers.filter((user) => selectedTeamMembers.find((member) => member === user.name)).map((item) => item.id);
});
