import {
  GET_CALL_BREAKDOWNS_KPI_SUCCESS,
  GET_CALL_BREAKDOWNS_PIE_LEFT_SUCCESS,
  GET_CALL_BREAKDOWNS_PIE_RIGHT_SUCCESS,
  GET_CALL_BREAKDOWNS_PIE_LEFT_REQUEST,
  GET_CALL_BREAKDOWNS_PIE_RIGHT_REQUEST,
  GET_GROUPED_CHART_DATA_SUCCESS,
  GET_GROUPED_CHART_DATA_REQUEST,
  UPDATE_GROUPED_CHART_DATA,
} from "../../actions/dashboardPageActions/callBreakdownsActions";

export const initialState = {
  kpi: [],
  leftPieDashboard: null,
  totalValue: 0,
  rightPieDashboard: null,
  totalValueRightPie: 0,
  isLeftPieLoading: false,
  isRightPieLoading: false,
  groupedChartData: null,
  totalGroupedChartData: 0,
  isGroupedChartDataLoading: false,
};

export const callBreakdownReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case GET_CALL_BREAKDOWNS_KPI_SUCCESS:
      return { ...state, kpi: payload.kpi };

    case GET_CALL_BREAKDOWNS_PIE_LEFT_REQUEST:
      return { ...state, isLeftPieLoading: true };

    case GET_CALL_BREAKDOWNS_PIE_LEFT_SUCCESS:
      return {
        ...state,
        leftPieDashboard: payload.leftPieDashboard,
        totalValue: payload.totalValue,
        isLeftPieLoading: false,
      };

    case GET_CALL_BREAKDOWNS_PIE_RIGHT_REQUEST:
      return { ...state, isRightPieLoading: true };

    case GET_CALL_BREAKDOWNS_PIE_RIGHT_SUCCESS:
      return {
        ...state,
        rightPieDashboard: payload.rightPieDashboard,
        totalValueRightPie: payload.totalValueRightPie,
        isRightPieLoading: false,
      };

    case GET_GROUPED_CHART_DATA_SUCCESS:
      return {
        ...state,
        groupedChartData: payload.groupedChartData,
        isGroupedChartDataLoading: false,
      };

    case GET_GROUPED_CHART_DATA_REQUEST:
      return { ...state, isGroupedChartDataLoading: true };

    case UPDATE_GROUPED_CHART_DATA:
      return {
        ...state,
        groupedChartData: state.groupedChartData.filter((data) => {
          const stats = data.stats.filter((el) => el.endReason !== payload);
          return stats.length;
        }),
      };

    default:
      return state;
  }
};
