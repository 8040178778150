import { notification } from "antd";

function notificationAlert({
  type = "success",
  description = "Message",
  duration = 3,
  placement = "topRight",
  destroy = false,
  icon,
}) {
  if (destroy) {
    return notification.destroy();
  }

  const message = type[0].toUpperCase() + type.slice(1);

  notification[type]({ message, description, duration, placement });
}

export default notificationAlert;
