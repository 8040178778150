import API from "../../services/apiService";
import { getAllUrlParam, getUrlParam } from "../../utils/helpers";

export const SET_INITIAL_MEETINGS_DATA_SUCCESS = "SET_INITIAL_MEETINGS_DATA_SUCCESS";
export const SET_INITIAL_MEETINGS_DATA_REQUEST = "SET_INITIAL_MEETINGS_DATA_REQUEST";
export const SET_CURRENT_PAGE_ACTION = "SET_CURRENT_PAGE_ACTION";
export const SET_SORT_PARAM_ACTION = "SET_SORT_PARAM_ACTION";
export const MEETINGS_UPDATE_ACTION = "MEETINGS_UPDATE_ACTION";
export const MEETINGS_UPDATE_ACTION_WITH_RESET = "MEETINGS_UPDATE_ACTION_WITH_RESET";
export const UPDATE_MEETING_FILTERS = "UPDATE_MEETING_FILTERS";
export const UPDATE_MEETING_USER_FILTER = "UPDATE_MEETING_USER_FILTER";
export const CLEAR_MEETINGS_REDUCER = "CLEAR_MEETINGS_REDUCER";

export const updateFiltersFromParam = (params) => async (dispatch) => {
  const currentPage = getUrlParam(params, "currentPage") || 1;
  const pageSize = getUrlParam(params, "pageSize") || 10;
  const s_field = getUrlParam(params, "s_field");
  const s_direction = getUrlParam(params, "s_direction");
  const f_dateAfter = getUrlParam(params, "f_dateAfter");
  const f_dateBefore = getUrlParam(params, "f_dateBefore");
  const f_userId = getAllUrlParam(params, "f_userId[]");
  dispatch(setCurrentPageAction({ currentPage, pageSize: pageSize }));
  dispatch(setSortParamAction({ sortedField: s_field, direction: s_direction }));
  dispatch(updateMeetingFilters({ dateAfter: f_dateAfter || "", dateBefore: f_dateBefore || "" }));
  dispatch(updateMeetingFilters({ userId: f_userId }));
};

const allowedSortedFields = ["date"];

//if user type field that doesnt exist we dont need send it to backend
const checkSortedField = (field) => (allowedSortedFields.includes(field) ? field : "");

export const setInitialMeetingsData = () => async (dispatch, getState) => {
  let {
    tableSettings: { currentPage, pageSize, sortedField, sortedDirection },
  } = getState().meetings;

  const { filters } = getState().meetings;

  dispatch(setInitialMeetingsDataRequest());

  let data = await API.get("meetings", {
    params: {
      page: currentPage,
      pageSize: pageSize,
      s_field: checkSortedField(sortedField),
      s_direction: sortedDirection,
      f_dateAfter: filters.dateAfter ? filters.dateAfter + " 00:00:00" : "",
      f_dateBefore: filters.dateBefore ? filters.dateBefore + " 23:59:59" : "",
      f_userId: filters.userId,
    },
  });

  const teamMembers = await API.get("user/all");

  dispatch(
    setInitialMeetingsDataSuccess({
      meetings: data.meetings,
      totalCount: data.totalCount,
      teamMembers: teamMembers,
    })
  );
};

export const setInitialMeetingsDataRequest = () => ({ type: SET_INITIAL_MEETINGS_DATA_REQUEST });

export const setInitialMeetingsDataSuccess = (payload) => ({
  type: SET_INITIAL_MEETINGS_DATA_SUCCESS,
  payload,
});

export const setCurrentPageAction = (payload) => ({ type: SET_CURRENT_PAGE_ACTION, payload });
export const setSortParamAction = (payload) => ({ type: SET_SORT_PARAM_ACTION, payload });

export const meetingsUpdateActionWithReset = (payload) => ({ type: MEETINGS_UPDATE_ACTION_WITH_RESET, payload });
export const meetingsUpdateAction = (payload) => ({ type: MEETINGS_UPDATE_ACTION, payload });
export const updateMeetingFilters = (payload) => ({ type: UPDATE_MEETING_FILTERS, payload });
export const updateMeetingUserFilter = (payload) => ({ type: UPDATE_MEETING_USER_FILTER, payload });

export const clearMeetingsReducer = (payload) => ({ type: CLEAR_MEETINGS_REDUCER, payload });
