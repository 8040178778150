import React from "react";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PropTypes from "prop-types";
import { Spin } from "antd";
import "./_callBreakdownsPies.less";

function CallBreakdownsLeftPie({ data, totalValueLeftPie, isLoading }) {
  return (
    <div className="pieChart">
      {isLoading ? (
        <div>
          <Spin size="large" />
        </div>
      ) : data.reduce((acc, next) => acc + next.value, 0) !== 0 ? (
        <Pie
          id="leftPie"
          plugins={[ChartDataLabels]}
          data={{
            labels: [...data.map((pie) => pie.name)],
            datasets: [
              {
                label: "End-reasons-stats",
                data: data.map((pie) => pie.value),
                scaleStepWidth: 1,
                backgroundColor: data.map((pie) => pie.color),
                borderWidth: 1,
                hoverOffset: 2,
              },
            ],
          }}
          options={{
            onClick(event, elements, chart) {
              //here we can get access to pie part after click
            },
            maintainAspectRatio: false,
            backgroundColor: "black",
            scales: {},
            plugins: {
              tooltip: {
                enabled: true,
                usePointStyle: true,
                callbacks: {
                  title: (data) => {
                    return (
                      data[0].formattedValue + ` (${((data[0].formattedValue * 100) / totalValueLeftPie).toFixed(2)}%)`
                    );
                  },
                  label: (data) => {
                    return data.label;
                  },
                },
              },
              legend: {
                position: "right",
                align: "center",
                labels: { padding: 15, font: { size: "12px" }, color: "black" },
              },
              datalabels: {
                rotation: 0,
                position: "end",
                formatter: function (value, { dataIndex }) {
                  const percent = ((value * 100) / totalValueLeftPie).toFixed(2);
                  if (+percent <= 5) {
                    return null;
                  } else {
                    return percent + "%";
                  }
                },
                align: "end",
                color: data.map((pie) => pie.labelColor),
                font: {
                  weight: "bold",
                  size: "10px",
                },
              },
            },
          }}
        />
      ) : (
        <div>Nothing to display</div>
      )}
    </div>
  );
}

CallBreakdownsLeftPie.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  totalValueRightPie: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default CallBreakdownsLeftPie;
