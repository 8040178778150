import React, { useState } from "react";
import { Button } from "antd";
import UsersDropdown from "../../components/UsersDropdown/UsersDropdown";
import RangePicker from "../../components/RangePicker/RangePicker";
import {
  chooseCurrentUser,
  chooseDateRange,
  resetSettings,
  updateCallEndReasons,
  updateCallTimeline,
} from "../../redux/actions/callsInfoPageActions";
import { useDispatch, useSelector } from "react-redux";
import { applicationRoles } from "../../utils/mocks";

function StaffDetailConfigPanel() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.callsInfo.users);
  const currentUser = useSelector((state) => state.callsInfo.currentUser);
  const startDate = useSelector((state) => state.callsInfo.startDate);
  const endDate = useSelector((state) => state.callsInfo.endDate);
  const [onDirty, setDirty] = useState(false);

  const chooseCurrentUserHandler = (chosenUserName) => {
    const currentUser = users.find((user) => user.id === chosenUserName);
    dispatch(chooseCurrentUser(currentUser));
  };

  const chooseDateRangeHandler = (dates, dateStrings) => {
    if (!dates) return dispatch(chooseDateRange({ startDate: null, endDate: null }));
    dispatch(chooseDateRange({ startDate: dateStrings[0], endDate: dateStrings[1] }));
  };

  const resetSettingsHandler = () => {
    setDirty(false);
    dispatch(resetSettings(new Date()));
  };

  const loadCallInfoHandler = () => {
    if (!currentUser) {
      setDirty(true);
    }
    dispatch(updateCallEndReasons(new Date()));
    dispatch(updateCallTimeline(new Date()));
  };

  const setDirtyHandler = () => setDirty(true);

  const getSearchUrl = ({ chosenCompanyId, sDate, eDate, usersId }) => {
    const company = chosenCompanyId ? "&companyId=" + chosenCompanyId : "";
    const dateStart = sDate ? "&startDate=" + sDate : "";
    const dateEnd = eDate ? "&endDate=" + eDate : "";
    const users = usersId.length ? usersId.map((user) => "&userId[]=" + user.id).join("") : "";
    let res = `?${company}${dateStart}${dateEnd}${users}`;
    return res[1] === "&" ? res.substring(0, 1) + res.substring(2, res.length) : res;
  };

  const redirectToDashboards = () => {
    window.open(
      `/dashboard${getSearchUrl({
        chosenCompanyId: currentUser?.companyId,
        sDate: startDate,
        eDate: endDate,
        usersId: [currentUser],
      })}`,
      "_blank"
    );
  };

  //Consultant doesn't have companies and cannot be chosen on dashboard page to see stats
  const isRedirectBtnVisible = () =>
    currentUser && currentUser.role !== applicationRoles.CONSULTANT && startDate && endDate;

  return (
    <div className="config_panel">
      <div className="elements_wrapper">
        <div className="dropdown_wrapper">
          <UsersDropdown
            value={currentUser ? currentUser.id : null}
            allData={users}
            onBlur={setDirtyHandler}
            className={onDirty && !currentUser ? "error" : ""}
            valueField={"id"}
            optionField={"name"}
            onChange={chooseCurrentUserHandler}
          />
          {onDirty && !currentUser ? <span className="dropdown_wrapper-error">Field is required</span> : null}
        </div>
        <RangePicker
          className="xray"
          format="YYYY-MM-DD"
          endDate={endDate}
          startDate={startDate}
          onChange={chooseDateRangeHandler}
        />
        <Button type="primary" className="btn" onClick={resetSettingsHandler}>
          Reset
        </Button>
        <Button type="primary" className="btn" onClick={loadCallInfoHandler}>
          Load
        </Button>
        {isRedirectBtnVisible() ? (
          <Button type="primary" className="btn" onClick={redirectToDashboards}>
            Show dashboards
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default StaffDetailConfigPanel;
