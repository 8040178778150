import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PropTypes from "prop-types";
import "./_phoneInput.less";

export default function PhoneInputComponent({ value, onChange, form }) {
  const [phoneInputError, setPhoneInputError] = useState(false);
  const [focused, setFocuses] = useState(false);

  const onBlur = () => {
    const phoneRegexp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{4})\2([0-9]{4})/;
    if (!value || !phoneRegexp.test(value)) {
      form.validateFields(["phone"]);
      setPhoneInputError(true);
    } else {
      form.validateFields(["phone"]);
      setFocuses(false);
      setPhoneInputError(false);
    }
  };

  const onFocus = () => {
    if (!phoneInputError) {
      setFocuses(true);
    }
    setFocuses(false);
  };

  const setClass = () => {
    if (focused && !phoneInputError) {
      return "success";
    }
    if (phoneInputError || form.getFieldError(["phone"]).length) {
      return "error";
    }
    return "default";
  };

  return (
    <PhoneInput
      value={value}
      onFocus={onFocus}
      inputClass={setClass()}
      onBlur={onBlur}
      onChange={onChange}
      placeholder="Enter phone number"
    />
  );
}

PhoneInputComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  form: PropTypes.object,
};
