import { createSelector } from "reselect";

const allScriptsSelector = (state) => state.home.allScripts;
const allCompaniesSelector = (state) => state.home.allCompanies;

export const getEnabledScriptsSelector = createSelector(allScriptsSelector, (scripts) =>
  scripts ? scripts.filter((script) => script.enabled === 1) : []
);

export const getEnabledCompaniesSelector = createSelector(allCompaniesSelector, (companies) =>
  companies ? companies.filter((company) => company.isActive === 1) : []
);
