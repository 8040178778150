import React, { useContext } from "react";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { NavLink, useHistory } from "react-router-dom";
import { validationAntService as validation } from "../../utils/validationAntService";
import API from "../../services/apiService";
import { AuthContext } from "../../context/authContext";
import { useDispatch } from "react-redux";
import { logInAction } from "../../redux/actions/commonActions";
import robotLogo from "../../assets/robot.png";
import "./_login.less";

const LoginPage = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const { validatePassword, validateEmail } = validation();

  const onFinish = async (userData) => {
    const data = await API.post("auth/login", {
      email: userData.email,
      password: userData.password,
    });
    dispatch(logInAction(data.user));
    auth.login(data.user);
    history.push("/");
  };

  const getDayTime = (currentTime) => {
    if (currentTime >= 6 && currentTime < 11) return "morning";
    if (currentTime >= 11 && currentTime < 18) return "day";
    if (currentTime >= 18 && currentTime <= 23) return "evening";
    if (currentTime >= 0 && currentTime < 6) return "evening";
  };

  const welcomeMessageGenerate = () => {
    const dayTime = getDayTime(new Date().getHours()) || "morning";
    return `Good ${dayTime}, time to log in`;
  };

  return (
    <div className="login-page">
      <div className="login-page__form">
        <h1 className="login_preview_name">Script Sidekick</h1>
        <div className="login-form">
          <h1 className="welcome_message">{welcomeMessageGenerate()}</h1>
          <Form name="normal_login" initialValues={{ remember: true }} onFinish={onFinish}>
            <Form.Item
              name="email"
              validateTrigger={["onBlur"]}
              rules={[{ required: true, message: "This field is required" }, { validator: validateEmail }]}>
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email address"
              />
            </Form.Item>
            <Form.Item
              name="password"
              validateTrigger={["onBlur"]}
              rules={[{ required: true, message: "This field is required" }, { validator: validatePassword }]}>
              <Input.Password
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox className="remember_me-checkbox">Remember me</Checkbox>
              </Form.Item>
              <NavLink to="/reset" className="forgot_password-link">
                Forgot password
              </NavLink>
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" className="btn" type="orange">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="login_img_section">
        <div className="login_img">
          <img src={robotLogo} alt="robot logo" />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
