import {
  GET_DASHBOARDS_KPI_SUCCESS,
  GET_GUIDANCE_KPI_SUCCESS,
  UPDATE_GUIDANCE_SORT,
  UPDATE_GUIDANCE_TABLE,
  UPDATE_GUIDANCE_PAGINATION,
  GET_CHART_DATA_SUCCESS,
  UPDATE_CHART_PAGINATION,
  UPDATE_CHART_SORT,
  UPDATE_CHART_TABLE_ACTION,
} from "../../actions/dashboardPageActions/employeeSummaryActions";

const DEFAULT_SORTED_ORDER = "descend";
const DEFAULT_SORTED_FIELD = "date";

export const initialState = {
  dashboardsKPI: null,
  chartData: [],
  chartSettings: {
    page: 1,
    pageSize: 5,
    totalCount: 0,
    sortOrder: DEFAULT_SORTED_ORDER,
    sortedField: DEFAULT_SORTED_FIELD,
  },
  updateChartTable: null,
  maxChartValues: null, //values to calculate difference in percent between values
  updateGuidanceTable: null,
  guidanceKPI: null,
  guidanceKpiSettings: {
    page: 1,
    pageSize: 50,
    totalCount: 0,
    sortOrder: DEFAULT_SORTED_ORDER,
    sortedField: DEFAULT_SORTED_FIELD,
  },
};

export const employeeSummaryReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case UPDATE_GUIDANCE_TABLE:
      return { ...state, updateGuidanceTable: payload };

    case GET_DASHBOARDS_KPI_SUCCESS:
      return { ...state, dashboardsKPI: payload.dashboardsKPI };

    case GET_GUIDANCE_KPI_SUCCESS:
      return {
        ...state,
        guidanceKPI: payload.guidanceKPI,
        guidanceKpiSettings: { ...state.guidanceKpiSettings, totalCount: payload.totalCount },
      };

    case UPDATE_GUIDANCE_SORT:
      return {
        ...state,
        guidanceKpiSettings: {
          ...state.guidanceKpiSettings,
          sortedField: payload.sortedField,
          sortOrder: payload.sortOrder,
        },
      };

    case UPDATE_GUIDANCE_PAGINATION:
      return {
        ...state,
        guidanceKpiSettings: {
          ...state.guidanceKpiSettings,
          page: payload.page,
          pageSize: payload.pageSize,
        },
      };

    case GET_CHART_DATA_SUCCESS:
      return {
        ...state,
        chartData: payload.chartData,
        maxChartValues: payload.maxChartValues,
        chartSettings: { ...state.chartSettings, totalCount: payload.totalCount },
      };

    case UPDATE_CHART_PAGINATION:
      return {
        ...state,
        chartSettings: {
          ...state.chartSettings,
          page: payload.page,
          pageSize: payload.pageSize,
        },
      };

    case UPDATE_CHART_SORT:
      return {
        ...state,
        chartSettings: {
          ...state.chartSettings,
          sortedField: payload.sortedField,
          sortOrder: payload.sortOrder,
        },
      };

    case UPDATE_CHART_TABLE_ACTION:
      return {
        ...state,
        updateChartTable: payload,
      };

    default:
      return state;
  }
};
