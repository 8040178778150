import {
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  SET_CURRENT_USER,
  CHOOSE_DATE_RANGE,
  SET_CALL_AND_REASON_FILTERS,
  RESET_SETTINGS,
  CLEAR_CALL_INFO_REDUCER,
  SET_CALL_TIMELINE_FILTERS,
  SET_END_REASON_FILTERS,
  SET_TIMELINE_FILTERS,
  GET_CALL_END_REASONS_REQUEST,
  GET_CALL_END_REASONS_SUCCESS,
  GET_TIMELINE_DATA_REQUEST,
  GET_TIMELINE_DATA_SUCCESS,
  UPDATE_CALL_END_REASONS,
  UPDATE_CALL_TIMELINE,
} from "../actions/callsInfoPageActions";

export const initialState = {
  isDataLoading: true,
  isUserDataLoading: false,
  users: [],
  currentUser: null,
  startDate: null,
  endDate: null,

  callEndReasonsFiltersActive: [],
  callTimelineFiltersActive: [],
  pageUpdate: "",

  callEndReasons: [],
  callEndReasonsLoading: false,
  callEndReasonsUpdate: null,
  callEndReasonsFilters: [],

  callTimelineLoading: false,
  callTimeline: [],
  callTimelineUpdate: null,
  callTimelineFilters: [],
};

export const callsInfoPageReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    //we dont need rehydrate loading indicators in redux persist
    case "persist/REHYDRATE":
      return { ...payload.callsInfo, isDataLoading: true, isUserDataLoading: false };

    case UPDATE_CALL_END_REASONS:
      return { ...state, callEndReasonsUpdate: payload };

    case UPDATE_CALL_TIMELINE:
      return { ...state, callTimelineUpdate: payload };

    case SET_END_REASON_FILTERS:
      return {
        ...state,
        callEndReasonsFilters: payload.callEndReasonsFilters,
      };

    case SET_TIMELINE_FILTERS:
      return {
        ...state,
        callTimelineFilters: payload.callTimelineFilters,
      };

    case GET_CALL_END_REASONS_REQUEST:
      return { ...state, callEndReasonsLoading: true };

    case GET_CALL_END_REASONS_SUCCESS:
      return { ...state, callEndReasonsLoading: false, callEndReasons: payload.callEndReasons };

    case GET_TIMELINE_DATA_REQUEST:
      return { ...state, callTimelineLoading: true };

    case GET_TIMELINE_DATA_SUCCESS:
      return { ...state, callTimeline: payload.callTimeline, callTimelineLoading: false };

    case GET_ALL_USERS_REQUEST:
      return { ...state, isDataLoading: true };

    case GET_ALL_USERS_SUCCESS:
      return { ...state, isDataLoading: false, users: payload.users };

    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload,
        callEndReasonsFiltersActive: [],
        callTimelineFiltersActive: [],
      };

    case CHOOSE_DATE_RANGE:
      return { ...state, startDate: payload.startDate, endDate: payload.endDate };

    case SET_CALL_AND_REASON_FILTERS:
      return { ...state, callEndReasonsFiltersActive: payload };

    case SET_CALL_TIMELINE_FILTERS:
      return { ...state, callTimelineFiltersActive: payload };

    case RESET_SETTINGS:
      return {
        ...state,
        callEndReasonsFiltersActive: [],
        callTimelineFiltersActive: [],
        currentUser: null,
        startDate: null,
        endDate: null,
        callTimelineUpdate: payload,
        callEndReasonsUpdate: payload,
      };

    case CLEAR_CALL_INFO_REDUCER:
      return initialState;

    default:
      return state;
  }
};
