import React from "react";
import PropTypes from "prop-types";
import "./_breathBar.less";

export default function BreathBar({ height, progress, title }) {
  return (
    <>
      <div className="breath_bar_wrapper">
        <div className="progress" style={{ height }}>
          <div className="progress-bar progress-bar-animated" style={{ width: progress }} role="progressbar"></div>
        </div>
      </div>
      {title && <span className="breath_message">{title}</span>}
    </>
  );
}

BreathBar.propTypes = {
  height: PropTypes.string,
  progress: PropTypes.string,
  title: PropTypes.string,
};
