import React, { useState } from "react";
import { Collapse } from "antd";
import ScriptQuestionPanel from "./ScriptQuestionPanel";
import ScriptAnswersPanel from "./ScriptAnswersPanel";
import { useSelector } from "react-redux";

const { Panel } = Collapse;

export default function ScriptGeneralQuestionsPanel() {
  const allQuestions = useSelector((state) => state.script.allQuestions); //set all data on script page, get here
  const currentQuestion = useSelector((state) => state.script.currentQuestion);
  const [isConfigPanelOpen, setConfigPanelOpen] = useState("1");

  const panelOpenHandler = (value) => setConfigPanelOpen(value);

  return (
    <div className="script_config_wrapper">
      <div className="script_config-questions">
        <Collapse accordion activeKey={isConfigPanelOpen} onChange={panelOpenHandler}>
          <Panel header="Script questions:">
            <ScriptQuestionPanel allQuestions={allQuestions} currentQuestion={currentQuestion} />
            {currentQuestion && currentQuestion.questionType !== "text" ? (
              <ScriptAnswersPanel allQuestions={allQuestions} currentQuestion={currentQuestion} />
            ) : (
              ""
            )}
          </Panel>
        </Collapse>
      </div>
    </div>
  );
}
