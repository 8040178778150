import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { subnavMenuItems } from "../../utils/mocks";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useWindowSize from "../../hooks/resizeHook";
import "./_side-menu.less";

const { Sider } = Layout;

const DESKTOP_WIDTH = 240;
const MOBILE_WIDTH = 52;

export default function SideMenu() {
  const isSliderOpen = useSelector((state) => state.common.isSliderMenuOpen);
  const user = useSelector((state) => state.common.user);
  const activePage = useSelector((state) => state.common.activePage);
  const [sideBarMode, setSideBarMode] = useState("desktop");
  const size = useWindowSize();

  useEffect(() => {
    if (size.width < 650) {
      setSideBarMode("mobile");
    } else {
      setSideBarMode("desktop");
    }
  }, [size]);

  const getSiderWidth = () => {
    if (sideBarMode === "mobile") {
      return MOBILE_WIDTH;
    }
    return DESKTOP_WIDTH;
  };

  const getActivePageKey = () => String(subnavMenuItems[user.role].findIndex((item) => item.title === activePage));

  return (
    <Sider width={isSliderOpen ? getSiderWidth() : 0} className="site-layout">
      <Menu
        selectable={false}
        mode="inline"
        selectedKeys={[getActivePageKey()]}
        className={`menu ${!isSliderOpen ? "close" : ""}`}>
        {subnavMenuItems[user.role].map((item, index) => {
          return (
            <Menu.Item key={index}>
              {sideBarMode === "desktop" ? (
                <Link to={`${item.path}`}>
                  <span className="side_icon">{item.icon}</span>
                  {item.title}
                </Link>
              ) : (
                <Link to={`${item.path}`}>{item.icon}</Link>
              )}
            </Menu.Item>
          );
        })}
      </Menu>
    </Sider>
  );
}
