import React, { useEffect } from "react";
import MeetingsTable from "./MeetingsTable/MeetingsTable";
import ConfigMeetingsTable from "./ConfigMeetingsPanel";
import withCallClear from "../../hocks/withCallClear";
import { routes } from "../../utils/mocks";
import useActivePage from "../../hooks/useActivePage";
import { useDispatch } from "react-redux";
import { clearMeetingsReducer } from "../../redux/actions/meetingsPageAction";
import "./_meetingsPage.less";

function MeetingsPage() {
  useActivePage(routes.MEETINGS);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearMeetingsReducer());
    };
  }, []);

  return (
    <div className="meetings-page">
      <div className="fluid_container">
        <ConfigMeetingsTable />
        <MeetingsTable />
      </div>
    </div>
  );
}

export default withCallClear(MeetingsPage);
