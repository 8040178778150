import {
  GET_COMPANIES,
  CHOSE_COMPANY,
  GET_USERS_BY_COMPANY,
  CHOSE_USER,
  CHOSE_DATE,
  UPDATE_DASHBOARD_KPI,
  CLEAR_DASHBOARD_PAGE,
  SET_IGNORE_TIMEZONE,
} from "../../actions/dashboardPageActions/dashboardPageActions";

export const initialState = {
  companies: [],
  users: [],
  activeCompany: null,
  activeUsers: [],
  date: { endDate: null, startDate: null },
  updateDashboardsKpi: null,
  ignoreTimezone: false,
};

export const dashboardPageReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case CLEAR_DASHBOARD_PAGE:
      localStorage.removeItem("activeTab");
      return initialState;

    case UPDATE_DASHBOARD_KPI:
      return { ...state, updateDashboardsKpi: payload };

    case GET_COMPANIES:
      return { ...state, companies: payload };

    case CHOSE_COMPANY:
      return {
        ...state,
        activeCompany: payload,
        users: [],
        activeUsers: [],
        chartData: null,
        maxChartValues: null,
      };

    case CHOSE_USER:
      return { ...state, activeUsers: payload };

    case CHOSE_DATE:
      return { ...state, date: payload };

    case GET_USERS_BY_COMPANY:
      return { ...state, users: payload };

    case SET_IGNORE_TIMEZONE:
      return { ...state, ignoreTimezone: payload };

    default:
      return state;
  }
};
