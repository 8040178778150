import React, { useContext, useEffect } from "react";
import API from "../services/apiService";
import { AuthContext } from "../context/authContext";

const withAuthLogout = (Component) => {
  return (props) => {
    const auth = useContext(AuthContext);

    useEffect(() => {
      if (!window.location.href.split("/").includes("server-error")) {
        async function checkAuthAccess() {
          await API.post("auth/check").catch((err) => {
            if (err.response.status === 401) return auth.logout();
          });
        }
        checkAuthAccess();
      }
    }, []);

    return <Component {...props} />;
  };
};

export default withAuthLogout;
