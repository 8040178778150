import React from "react";
import { Tag, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import "./_chip.less";

export default function ChipComponent({ editHandler, deleteHandler, text, selectCurrentHandler, active, editable }) {
  return (
    <Tag className={`tag ${active ? "active" : ""}`} onClick={selectCurrentHandler}>
      <span className="text">{text}</span>
      <div className="icon__group">
        {editable ? (
          <Tooltip placement="top" title="Edit">
            <EditOutlined onClick={editHandler} className="icon edit" />
          </Tooltip>
        ) : (
          ""
        )}
        <Tooltip placement="top" title="Delete">
          <DeleteOutlined onClick={deleteHandler} className="icon delete" />
        </Tooltip>
      </div>
    </Tag>
  );
}

ChipComponent.defaultProps = {
  editable: true,
};

ChipComponent.propTypes = {
  editHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  text: PropTypes.string,
  selectCurrentHandler: PropTypes.func,
  active: PropTypes.bool,
  editable: PropTypes.bool,
};
