import API from "../../services/apiService";
import { isHavePermission } from "../../utils/helpers";
import { applicationRoles } from "../../utils/mocks";

const { ADMIN, CONSULTANT } = applicationRoles;

export const FETCH_COMPANIES_REQUEST = "FETCH_COMPANIES_REQUEST";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const CREATE_NEW_COMPANY = "CREATE_NEW_COMPANY";
export const SET_CHOSEN_COMPANY = "SET_CHOSEN_COMPANY";
export const SELECT_COMPANY = "SELECT_COMPANY";
export const DELETE_CURRENT_COMPANY = "DELETE_CURRENT_COMPANY";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const SET_CHOSEN_USER = "SET_CHOSEN_USER";
export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const DELETE_CURRENT_USER = "DELETE_CURRENT_USER";
export const EDIT_USER = "EDIT_USER";
export const SET_ALL_ATTRIBUTES = "SET_ALL_ATTRIBUTES";
export const RESET_COMPANY_SETTINGS = "RESET_COMPANY_SETTINGS";
export const SET_CURRENT_FUNNEL = "SET_CURRENT_FUNNEL";
export const UPDATE_CURRENT_COMPANY = "UPDATE_CURRENT_COMPANY";

export const setCompanyUsersAction = (companyId) => async (dispatch) => {
  dispatch(usersRequested());
  await API.get(`user/by-company/${companyId}`).then((data) => dispatch(usersLoaded(data)));
};

const usersRequested = () => {
  return {
    type: FETCH_USERS_REQUEST,
  };
};

const usersLoaded = (allCompanies) => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: allCompanies,
  };
};

export const setInitialCompanies = (user) => async (dispatch, getState) => {
  const { currentCompany } = getState().setting;
  dispatch(companiesRequested());
  if (isHavePermission(user.role, [ADMIN, CONSULTANT])) {
    await API.get("company/all").then((data) => {
      dispatch(companiesLoaded(data));
      if (currentCompany && currentCompany.name) {
        const actualCurrentCompany = data.find((company) => company.id === currentCompany.id);
        dispatch(setChosenCompanyAction({ chosenCompany: actualCurrentCompany }));
      }
    });
  } else {
    await API.get(`company/${user.companyId}`).then((chosenCompany) => {
      dispatch(setChosenCompanyAction({ chosenCompany })); //consultant have to see company by default
      dispatch(companiesLoaded([chosenCompany]));
      dispatch(setCompanyUsersAction(chosenCompany.id));
    });
  }
};

const companiesRequested = () => {
  return {
    type: FETCH_COMPANIES_REQUEST,
  };
};

const companiesLoaded = (allCompanies) => {
  return {
    type: FETCH_COMPANIES_SUCCESS,
    payload: allCompanies,
  };
};

export const createNewCompanyAction = (newCompany) => {
  return {
    type: CREATE_NEW_COMPANY,
    payload: newCompany,
  };
};

export const setChosenCompanyAction = (chosenCompany) => {
  return {
    type: SET_CHOSEN_COMPANY,
    payload: chosenCompany,
  };
};

export const deleteCurrentCompanyAction = (name) => {
  return {
    type: DELETE_CURRENT_COMPANY,
    payload: name,
  };
};

export const editCompanyAction = (data) => {
  return {
    type: EDIT_COMPANY,
    payload: data,
  };
};

export const updateCurrentCompany = (data) => {
  return {
    type: UPDATE_CURRENT_COMPANY,
    payload: data,
  };
};

export const setChosenUserAction = (data) => {
  return {
    type: SET_CHOSEN_USER,
    payload: data,
  };
};

export const selectCurrentCompanyAction = (action) => {
  return {
    type: SELECT_COMPANY,
    payload: action,
  };
};

export const createNewUserAction = (data) => {
  return {
    type: CREATE_NEW_USER,
    payload: data,
  };
};

export const deleteCurrentUserAction = (data) => {
  return {
    type: DELETE_CURRENT_USER,
    payload: data,
  };
};

export const editUserAction = (data) => {
  return {
    type: EDIT_USER,
    payload: data,
  };
};

export const setAllAttributesAction = (action) => {
  return {
    type: SET_ALL_ATTRIBUTES,
    payload: action,
  };
};

export const resetCompanyPageAction = () => {
  return {
    type: RESET_COMPANY_SETTINGS,
  };
};

export const setCurrentFunnelAction = (data) => {
  return {
    type: SET_CURRENT_FUNNEL,
    payload: data,
  };
};
