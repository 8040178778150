import { useEffect } from "react";
import { setActivePage, unsetActivePage } from "../redux/actions/commonActions";
import { useDispatch } from "react-redux";

export default function useActivePage(activePage) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivePage(activePage));
    return () => {
      dispatch(unsetActivePage());
    };
  }, []);
}
