import React, { useState, useRef } from "react";
import { Button, Col } from "antd";
import PropTypes from "prop-types";
import { updateScriptObjectionLocal, updateScriptTextLocal } from "../../redux/actions/scriptViewerPageActions";
import { useDispatch } from "react-redux";
import TextEditorViewer from "./TextEditor/TextEditor";

export default function ScriptBlock({
  currentStage,
  currentCall,
  previousStageHandler,
  prevDisabled,
  nextDisabled,
  nextStageHandler,
  handledObjectionHandler,
  backToScriptHandler,
  value,
  type,
  title,
  objectionTitle,
}) {
  const textEditorRef = useRef(null);
  const dispatch = useDispatch();
  const [text, setText] = useState(value);

  const showLeadName = () => {
    if (currentCall && currentCall.lead && currentCall.lead.name) {
      return (
        <h3>
          Current lead: <b>{currentCall.lead.name}</b>
        </h3>
      );
    }
  };

  const onTextChange = (value, checked, action) => {
    //React quill call function first render component so we skip this for onChange handler
    if (action !== "api") {
      setText(value);
      if (type === "textScript") return dispatch(updateScriptTextLocal({ value, currentStage: currentStage }));
      return dispatch(updateScriptObjectionLocal({ value, currentStage: currentStage, objectionTitle }));
    }
  };

  return (
    <Col className="script_section_wrapper" xs={24} sm={24} md={24} lg={14} xl={14}>
      <div className="title_section">
        {showLeadName()}
        <h1 className="stage_name">{title}</h1>
        {objectionTitle && <h2 className="objection_name">{objectionTitle}</h2>}
      </div>
      <div className="script_section">
        <TextEditorViewer value={text} onChange={onTextChange} ref={textEditorRef} />
      </div>
      <div className="navigation_section">
        {type === "objectionScript" ? (
          <div className="control_section">
            <Button type="orange-empty" onClick={backToScriptHandler}>
              Back to script
            </Button>
            <Button type="orange" onClick={handledObjectionHandler}>
              Handle objection
            </Button>
          </div>
        ) : (
          <div className="control_section">
            <Button disabled={prevDisabled} type="orange" onClick={previousStageHandler}>
              Back
            </Button>
            <Button disabled={nextDisabled} type="orange" onClick={nextStageHandler}>
              Next
            </Button>
          </div>
        )}
      </div>
    </Col>
  );
}

ScriptBlock.propTypes = {
  selectedObjection: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    priority: PropTypes.string,
    script: PropTypes.string,
    stageId: PropTypes.string,
    type: PropTypes.string,
  }),
  handledObjectionHandler: PropTypes.func,
  backToScriptHandler: PropTypes.func,
  currentStage: PropTypes.number,
  previousStageHandler: PropTypes.func,
  prevDisabled: PropTypes.bool,
  checkAllChosen: PropTypes.func,
  nextStageHandler: PropTypes.func,
  allScriptStages: PropTypes.arrayOf(
    PropTypes.shape({
      endReasons: PropTypes.array,
      id: PropTypes.string,
      name: PropTypes.string,
      objections: PropTypes.array,
      order: PropTypes.number,
      questions: PropTypes.array,
      script: PropTypes.string,
    })
  ),
};
