import React, { useState, useMemo } from "react";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  setChosenUserAction,
  createNewUserAction,
  deleteCurrentUserAction,
  editUserAction,
} from "../../../redux/actions/adminPageActions";
import { Switch } from "antd";
import API from "../../../services/apiService";
import notificationAlert from "../../../utils/notificationAlert";
import ModalGroup from "../../../components/ModalGroup/ModalGroup";
import PropTypes from "prop-types";
import UsersDropdown from "../../../components/UsersDropdown/UsersDropdown";
import "./_configUserPanel.less";

const ConfigUserPanel = React.memo(({ loggedUser, currentCompany, allCompanies }) => {
  const dispatch = useDispatch();
  const allUsers = useSelector((state) => state.setting.companyUsers, shallowEqual);
  const currentUser = useSelector((state) => state.setting.currentUser, shallowEqual);

  const [modalMode, setModalMode] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);

  const chooseUserHandler = (selectedUser) => {
    const chosenUser = allUsers.find((user) => user.id === selectedUser);
    dispatch(setChosenUserAction(chosenUser));
    localStorage.setItem("currentUserName", selectedUser);
  };

  const createNewUser = async ({
    email,
    name,
    password,
    role,
    welcomeEmail,
    disabled,
    salesPerWeek,
    isDefaultStats,
  }) => {
    const newUserData = await API.post("user/create", {
      email,
      name,
      password,
      companyId: currentCompany.id,
      role,
      welcomeEmail: !!welcomeEmail,
      disabled: !disabled,
      salesPerWeek,
      companyTimezone: currentCompany.timezoneName,
      isDefaultStats: isDefaultStats,
    });
    notificationAlert({ description: "User has been created" });
    dispatch(createNewUserAction({ newUserData }));
  };

  const editUser = async ({
    email,
    name,
    password,
    role,
    welcomeEmail,
    company,
    disabled,
    salesPerWeek,
    isDefaultStats,
  }) => {
    const selectedCompany = allCompanies.find((companyItem) => companyItem.name === company);
    const updatedUser = await API.patch(`user/${currentUser.id}`, {
      email,
      name,
      password,
      companyId: selectedCompany.id,
      role,
      welcomeEmail: !!welcomeEmail,
      disabled: !disabled,
      salesPerWeek,
      companyTimezone: currentCompany.timezoneName,
      isDefaultStats: isDefaultStats,
    });

    if (Number(updatedUser.companyId) === Number(currentCompany.id)) {
      dispatch(editUserAction({ updatedUser }));
    } else {
      dispatch(deleteCurrentUserAction({ userDeletedId: currentUser.id }));
    }
    notificationAlert({ description: "User has been edited" });
  };

  const deleteUser = async () => {
    await API.delete(`user/${currentUser.id}`);
    notificationAlert({
      description: "User has been deleted",
    });
    dispatch(deleteCurrentUserAction({ userDeletedId: currentUser.id }));
  };

  const resendEmailHandler = async () => {
    await API.post("user/welcome-message", currentUser);
    notificationAlert({
      description: `Notification has been sent to ${currentUser.name}`,
    });
  };

  const modalSubmitOk = async (value) => {
    if (modalMode === "add") {
      await createNewUser(value);
    } else if (modalMode === "edit") {
      await editUser(value);
    } else if (modalMode === "delete") {
      await deleteUser();
    }
    setModalVisible(false);
  };

  const onModalCancel = () => {
    setModalVisible(false);
    setModalMode("");
  };

  const getActiveUsers = (allUsers) => (allUsers && allUsers.length ? allUsers.filter((user) => !user.disabled) : []);

  const onModalOpen = (mode) => {
    setModalVisible(true);
    setModalMode(mode);
  };

  const disableUserHandler = async (value) => {
    if (value && getActiveUsers(allUsers).length >= currentCompany.licenseeCount) {
      return notificationAlert({
        description: "Your license does not allow you to have more active users",
        type: "warning",
      });
    }

    const updatedUser = await API.patch(`user/${currentUser.id}`, {
      ...currentUser,
      disabled: !value,
    });
    const updatedUserIndex = allUsers.findIndex((user) => user.name === currentUser.name);
    dispatch(editUserAction({ updatedUser, updatedUserIndex }));
  };

  const isBtnVisible = !!(currentUser && currentUser.name);

  const getButtons = () => {
    if (loggedUser.role !== "admin" && currentUser.role === "admin") {
      return [{ name: "Add user", action: () => onModalOpen("add"), visible: true }];
    }
    return [
      { name: "Add user", action: () => onModalOpen("add"), visible: true },
      { name: "Edit user", action: () => onModalOpen("edit"), visible: isBtnVisible },
      {
        name: "Delete user",
        action: () => onModalOpen("delete"),
        visible: isBtnVisible && loggedUser.id !== currentUser.id,
      },
      { name: "Resend email", action: resendEmailHandler, visible: isBtnVisible },
    ];
  };

  const modals = [
    {
      title: "Delete",
      text: `Delete «${currentUser && currentUser.name}» user?`,
      type: "delete",
      isVisible: isModalVisible,
    },
    { title: "Edit", text: "Edit user fields", type: "edit", isVisible: isModalVisible },
    { title: "Add", text: "Add new user", type: "add", isVisible: isModalVisible },
  ];

  const getRolesField = (loggedUser) => {
    if (loggedUser.id === currentUser.id) {
      return [{ name: loggedUser.role }];
    }
    if (loggedUser.role === "admin") {
      return [{ name: "user" }, { name: "admin" }];
    }
    return [{ name: "user" }];
  };

  const modalComponentsData = useMemo(
    () => () => {
      if (modalMode === "edit") {
        return [
          { name: "name", elementType: "input", placeholder: "User name" },
          {
            name: "email",
            elementType: "input",
            placeholder: "User email",
            email: true,
            checkDuplicate: true,
          },
          {
            name: "password",
            elementType: "input_password",
            placeholder: "User password",
            password: true,
            notRequired: true,
          },
          {
            name: "role",
            disabled: loggedUser.id === currentUser.id,
            placeholder: "Choose user role",
            elementType: "select",
            elementList: getRolesField(loggedUser),
          },
          {
            name: "company",
            elementType: "select",
            placeholder: "Company",
            elementList: allCompanies,
          },
          {
            name: "salesPerWeek",
            elementType: "input_number",
            placeholder: "Sales per week",
            notRequired: true,
          },
          { name: "disabled", elementType: "switcher", notRequired: true },
          {
            name: "welcomeEmail",
            placeholder: "Send welcome email",
            elementType: "checkbox",
            notRequired: true,
          },
          {
            name: "isDefaultStats",
            placeholder: "Set default stats",
            elementType: "checkbox",
            notRequired: true,
          },
        ];
      }
      return [
        { name: "name", elementType: "input", placeholder: "User name" },
        {
          name: "email",
          elementType: "input",
          placeholder: "User email",
          email: true,
          checkDuplicate: true,
        },
        {
          name: "password",
          elementType: "input_password",
          placeholder: "User password",
          password: true,
        },
        {
          name: "salesPerWeek",
          elementType: "input_number",
          placeholder: "Sales per week",
          notRequired: true,
        },
        {
          name: "role",
          placeholder: "Choose user role",
          elementType: "select",
          elementList: loggedUser.role === "admin" ? [{ name: "user" }, { name: "admin" }] : [{ name: "user" }],
        },
        { name: "disabled", placeholder: "Active", elementType: "switcher", notRequired: true },
        {
          name: "welcomeEmail",
          placeholder: "Send welcome email",
          elementType: "checkbox",
          notRequired: true,
        },
        {
          name: "isDefaultStats",
          placeholder: "Set default stats",
          elementType: "checkbox",
          notRequired: true,
        },
      ];
    },
    [modalMode, currentUser]
  );

  const getCurrentItem = (currentUser) => {
    return modalMode === "edit"
      ? { ...currentUser, company: currentCompany.name, disabled: !currentUser.disabled }
      : currentUser;
  };

  const checkCheckboxVisible = () => {
    if (currentUser && currentUser.role) {
      return currentUser.role !== "admin" && (loggedUser.role === "admin" || loggedUser.role === "consultant");
    }
    return false;
  };

  return (
    <div className="user-config">
      {isModalVisible ? (
        <ModalGroup
          editableItemIdx={""}
          allItems={allUsers}
          mode={modalMode}
          modals={modals}
          modalComponentsData={modalComponentsData()}
          onModalCancel={onModalCancel}
          modalSubmitOk={modalSubmitOk}
          currentItem={getCurrentItem(currentUser)}
        />
      ) : null}
      <div className="block__title">Company users:</div>
      <div className="select_container">
        <UsersDropdown
          optionField="name"
          valueField="id"
          onChange={chooseUserHandler}
          value={currentUser && currentUser.id}
          allData={allUsers}
        />
      </div>
      <ButtonGroup data={getButtons()} />
      {checkCheckboxVisible() && (
        <Switch
          checkedChildren={"Active"}
          unCheckedChildren={"Inactive"}
          onChange={disableUserHandler}
          checked={!currentUser.disabled}
        />
      )}
    </div>
  );
});

ConfigUserPanel.propTypes = {
  currentCompany: PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.number,
    name: PropTypes.string,
    attributes: PropTypes.array,
    consultants: PropTypes.array,
    funnels: PropTypes.array,
    performanceIndicators: PropTypes.array,
    questions: PropTypes.array,
    reportSettings: PropTypes.object,
    scripts: PropTypes.array,
    timezone: PropTypes.string,
    timezoneName: PropTypes.string,
    webhooks: PropTypes.array,
  }),
  allCompanies: PropTypes.array,
  loggedUser: PropTypes.shape({
    companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.number,
    email: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    salesPerWeek: PropTypes.number,
  }),
};

export default ConfigUserPanel;
