import React from "react";
import { Table } from "antd";
import tableConfig from "./tableConfig";
import PropTypes from "prop-types";

export default function StatsViewerTable({
  loading,
  data,
  companies,
  sortTable,
  sortSettings,
  filterByField,
  filterSetting,
}) {
  let { getColumns } = tableConfig({
    companies,
    sortTable,
    sortSettings,
    filterByField,
    filterSetting,
  });
  return (
    <div className="statsTable_wrapper">
      <Table
        data-testid="statsViewerTable"
        className="stats_table"
        size="small"
        sticky={data && data.length ? { offsetHeader: 60 } : false}
        onChange={sortTable}
        columns={getColumns}
        dataSource={data}
        bordered={true}
        loading={loading}
        scroll={{ x: "auto" }}
        pagination={false}
      />
    </div>
  );
}

StatsViewerTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      guidance: PropTypes.string,
      name: PropTypes.string,
      hoursDialing: PropTypes.number,
      sales: PropTypes.number,
      email: PropTypes.string,
      companyTimezone: PropTypes.string,
      //and other KPI
    })
  ),
  companies: PropTypes.arrayOf(PropTypes.object),
  sortTable: PropTypes.func,
  sortSettings: PropTypes.shape({
    sortedDirection: PropTypes.string,
    sortedField: PropTypes.string,
  }),
  filterByField: PropTypes.func,
  filterSetting: PropTypes.array,
};
