import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Title from "../../../../../components/Title/Title";
import { useDispatch, useSelector } from "react-redux";
import { getGroupedChartData } from "../../../../../redux/actions/dashboardPageActions/callBreakdownsActions";
import { getTimeLabels, getTransformedGroupedChartData } from "../../../../../redux/selectors/dashboardsPageSelectors";
import { Spin } from "antd";
import "./_callBreakdownsGroupedChart.less";

function CallBreakdownsGroupedChart() {
  const dispatch = useDispatch();
  const updateDashboardsKpi = useSelector((state) => state.dashboard.updateDashboardsKpi);
  const groupedChartData = useSelector((state) => getTransformedGroupedChartData(state));
  const timeLabels = useSelector((state) => getTimeLabels(state));
  const isGroupedChartDataLoading = useSelector((state) => state.callBreakdowns.isGroupedChartDataLoading);

  useEffect(() => {
    dispatch(getGroupedChartData());
  }, [updateDashboardsKpi]);

  if (isGroupedChartDataLoading) {
    return (
      <div className="block_spinner">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="grouped_chart">
      <Title tag="h5" position="left" weight="bold">
        Where are Employee calls ending?
      </Title>
      <Title tag="h5" position="left">
        This graph breaks up all company calls over 28 days into average duration and determines the number of times a
        response was received.
      </Title>
      {groupedChartData ? (
        <Bar
          /*redraw*/
          data={{
            labels: timeLabels.map((label) => label),
            datasets: groupedChartData.map((item, i) => {
              return {
                type: "bar",
                label: item.label,
                data: item.data,
                backgroundColor: item.backgroundColor,
                borderWidth: 1,
              };
            }),
          }}
          options={{
            scales: {
              x: {
                beginAtZero: true,
                stacked: true,
                ticks: { min: 0 },
              },
              y: {
                ticks: {
                  beginAtZero: true,
                  min: "00:00:10",
                },
                beginAtZero: true,
                stacked: true,
                grid: {
                  display: true,
                },
              },
            },
            layout: {
              padding: 5,
            },
            indexAxis: "y",
            elements: {
              bar: {
                borderWidth: 1,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                position: "top",
                /*   onClick: newLegendClickHandler,*/
              },
              title: {
                display: true,
                text: "",
              },
            },
          }}
        />
      ) : null}
    </div>
  );
}

export default CallBreakdownsGroupedChart;
