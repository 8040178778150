import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, List, Typography, Button } from "antd";
import { Link } from "react-router-dom";
import { stats_inputs } from "../../utils/mocks";
import API from "../../services/apiService";
import notificationAlert from "../../utils/notificationAlert";
import { useSelector } from "react-redux";
import { b64e, getDateInMilliseconds } from "../../utils/helpers";
import withCallClear from "../../hocks/withCallClear";
import "./_stats-page.less";

function StatsPage() {
  const user = useSelector((state) => state.common.user);
  const [guidance, setGuidance] = useState([]);
  const [showGuidance, setShowGuidance] = useState(false);
  const [guidanceLoading, setGuidanceLoading] = useState(false);
  const [endReasons, setEndReasons] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    let currentUserName = b64e(JSON.parse(localStorage.getItem("currentUser")).id);
    const currentUserStats = JSON.parse(localStorage.getItem("ironcage-stats"))[currentUserName];
    setEndReasons(currentUserStats.endReasons);
  }, []);

  const setStatsData = (stats) => {
    return {
      hoursDialling: 0,
      dials: stats && stats.dials ? stats.dials : 0,
      conversations: stats && stats.conversations ? stats.conversations : 0,
      pitches: stats && stats.pitches ? stats.pitches : 0,
      salesValue: 0,
      cash: 0,
      sales: stats && stats.sales ? stats.sales : 0,
    };
  };

  const submitStatsHandler = async (data) => {
    const { name, email, ...stats } = data;
    setGuidanceLoading(true);
    //user cannot submit stats every seconds
    setTimeout(async () => {
      const guidance = await API.post("stats", {
        ...stats,
        endReasons,
        date: getDateInMilliseconds(new Date()),
      });
      notificationAlert({ description: "Stats has been successfully updated" });
      if (guidance.text === "Stats Submitted Successfully") {
        let recommendations = guidance.guidance.guidance;
        setGuidance(recommendations);
        setShowGuidance(true);
      }
      setGuidanceLoading(false);
      form.resetFields();
    }, 1500);
  };

  const setInitialFormValue = () => {
    let currentUserName = b64e(JSON.parse(localStorage.getItem("currentUser")).id);
    const currentUserStats = JSON.parse(localStorage.getItem("ironcage-stats"))[currentUserName];
    return { email: user.email, name: user.name, ...setStatsData(currentUserStats) };
  };

  return (
    <div className="stats-page">
      <div className="container">
        <div className="main_content">
          <Typography.Title className="title" level={2}>
            Submit stats
          </Typography.Title>
          <div className="stats_form">
            <Form
              form={form}
              labelCol={{ span: 7 }}
              name="stats_form"
              onFinish={submitStatsHandler}
              initialValues={setInitialFormValue()}>
              <Form.Item label={"Email"} name="email" validateTrigger={["onBlur"]}>
                <Input disabled size="large" placeholder="Email" />
              </Form.Item>
              <Form.Item label={"Name"} name="name" validateTrigger={["onBlur"]}>
                <Input disabled size="large" placeholder="Name" />
              </Form.Item>
              {stats_inputs.map((stat, statIndex) => {
                return (
                  <Form.Item
                    label={stat.name}
                    key={statIndex}
                    name={stat.value}
                    validateTrigger={["onBlur"]}
                    rules={[{ required: true, message: "Field is required!" }]}>
                    <InputNumber min={0} max={10000000000000} size="large" placeholder={stat.name} />
                  </Form.Item>
                );
              })}
              <Form.Item>
                <Button
                  className="btn"
                  disabled={guidanceLoading}
                  htmlType="submit"
                  type="orange"
                  loading={guidanceLoading}>
                  Submit stats
                </Button>
              </Form.Item>
              <Form.Item>
                <Button className="btn" type="orange-empty">
                  <Link to={"/"}>Back to home</Link>
                </Button>
              </Form.Item>
            </Form>
          </div>
          {showGuidance ? (
            <div className="recommendation">
              <List
                size="small"
                header={<h3 className="title">Based on your stats, we recommend looking at the following areas:</h3>}
                bordered
                dataSource={guidance}
                renderItem={(assessment) => (
                  <List.Item>
                    {assessment && assessment.trainingUrl ? (
                      <a
                        className="training_link"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={assessment.trainingUrl}>
                        {assessment.guidance}
                      </a>
                    ) : (
                      <p>{assessment.guidance}</p>
                    )}
                  </List.Item>
                )}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default withCallClear(StatsPage);
