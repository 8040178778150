import React from "react";
import { Card } from "antd";
import PropTypes from "prop-types";

const DashboardItem = React.memo(({ dashboard }) => {
  return (
    <div className="dashboard_item" data-testid="kpi_item">
      <Card bordered={false}>
        <div className="title">{dashboard.title}</div>
        <div className="value">{dashboard.value}</div>
        <div className="stat">{dashboard.differenceValue}</div>
      </Card>
    </div>
  );
});

DashboardItem.propTypes = {
  dashboard: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    difference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default DashboardItem;
