import API from "../../services/apiService";
import { isHavePermission } from "../../utils/helpers";
import { applicationRoles } from "../../utils/mocks";
import { ExcelRenderer } from "react-excel-renderer";
import notificationAlert from "../../utils/notificationAlert";

const { ADMIN, CONSULTANT } = applicationRoles;

export const GET_CURRENT_PAGE_LEADS_REQUEST = "GET_CURRENT_PAGE_LEADS_REQUEST";
export const GET_CURRENT_PAGE_LEADS_SUCCESS = "GET_CURRENT_PAGE_LEADS_SUCCESS";
export const SET_SELECTED_LEADS = "SET_SELECTED_LEADS";
export const GET_ADDITIONAL_LEADS_DATA_SUCCESS = "GET_ADDITIONAL_LEADS_DATA_SUCCESS";
export const GET_ADDITIONAL_LEADS_DATA_REQUEST = "GET_ADDITIONAL_LEADS_DATA_REQUEST";
export const RESET_COMPANY = "RESET_COMPANY";
export const SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY";
export const GET_COMPANIES_REQUEST = "GET_COMPANIES_REQUEST";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const RESET_LEADS_FILTERS = "RESET_LEADS_FILTERS";
export const UPLOAD_LEADS_REQUEST = "UPLOAD_LEADS_REQUEST";
export const UPLOAD_LEADS_SUCCESS = "UPLOAD_LEADS_SUCCESS";
export const UPDATE_PAGINATION = "UPDATE_PAGINATION";
export const UPDATE_SORTER = "UPDATE_SORTER";
export const UPDATE_LEAD_TABLE = "UPDATE_LEAD_TABLE";
export const EDIT_LEAD_SUCCESS = "EDIT_LEAD_SUCCESS";
export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS";
export const DELETE_All_LEADS_SUCCESS = "DELETE_All_LEADS_SUCCESS";
export const UPDATE_LEAD_FILTERS = "UPDATE_LEAD_FILTERS";
export const CLEAR_LEADS_REDUCER = "CLEAR_LEADS_REDUCER";

const REQUIRED_TABLE_FIELDS = ["name", "email", "phone", "status", "leadInfo"];

const getCurrentPageLeadsRequest = () => {
  return {
    type: GET_CURRENT_PAGE_LEADS_REQUEST,
  };
};

const getCurrentPageLeadsSuccess = (payload) => {
  return {
    type: GET_CURRENT_PAGE_LEADS_SUCCESS,
    payload,
  };
};

export const getCurrentPageLeads = () => async (dispatch, getState) => {
  const { selectedCompany, sorter, pagination, filters } = getState().leads;

  dispatch(getCurrentPageLeadsRequest());
  const data = await API.get(`lead`, {
    params: {
      companyId: selectedCompany?.id || 1,
      filteredField: filters.filteredField,
      filterValue: filters.filteredValue,
      sortedField: sorter.field,
      sortedValue: sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : "",
      currentLeadsPage: pagination.current,
      pageSize: pagination.pageSize,
    },
  });

  dispatch(getCurrentPageLeadsSuccess({ currentPageLeads: data.leads, total: data.totalCount }));
};

export const setSelectedLeads = (payload) => {
  return {
    type: SET_SELECTED_LEADS,
    payload,
  };
};

export const getAdditionalLeadsData = (companyId) => async (dispatch, getState) => {
  const loggedUser = getState().common.user;

  dispatch(getAdditionalLeadsDataRequest());

  if (isHavePermission(loggedUser.role, [ADMIN, CONSULTANT])) {
    const allScripts = await API.get(`script/company`, { params: { companyId } });
    const funnels = await API.get(`funnel/company/${companyId}`);
    const allUsers = await API.get(`user/by-company/${companyId}`);

    dispatch(
      getAdditionalLeadsDataSuccess({
        allScripts,
        funnels,
        allUsers,
      })
    );
  } else {
    const allScripts = await API.get(`script/company`, {
      params: { companyId: loggedUser.companyId },
    });
    const funnels = await API.get(`funnel/company/${loggedUser.companyId}`);
    const selectedCompany = await API.get(`company/${loggedUser.companyId}`);

    dispatch(
      getAdditionalLeadsDataSuccess({
        allScripts,
        funnels,
        selectedCompany,
      })
    );
  }
};

export const getAdditionalLeadsDataRequest = () => {
  return {
    type: GET_ADDITIONAL_LEADS_DATA_REQUEST,
  };
};

export const getAdditionalLeadsDataSuccess = (payload) => {
  return {
    type: GET_ADDITIONAL_LEADS_DATA_SUCCESS,
    payload,
  };
};

export const resetCompany = (payload) => {
  return {
    type: RESET_COMPANY,
    payload,
  };
};

export const setSelectedCompany = (payload) => {
  return {
    type: SET_SELECTED_COMPANY,
    payload,
  };
};

export const getCompanies = () => async (dispatch) => {
  dispatch(getCompaniesRequest());
  const allCompanies = await API.get("company/all");
  const activeCompanies = allCompanies && allCompanies.length ? allCompanies.filter((company) => company.isActive) : [];
  dispatch(getCompaniesSuccess(activeCompanies));
};

export const getCompaniesRequest = () => {
  return {
    type: GET_COMPANIES_REQUEST,
  };
};

export const getCompaniesSuccess = (payload) => {
  return {
    type: GET_COMPANIES_SUCCESS,
    payload,
  };
};

export const resetLeadsFilters = () => {
  return {
    type: RESET_LEADS_FILTERS,
  };
};

const transformCsvToLeadsArray = (data, selectedCompanyId) => {
  let result = data.slice(1).map((a) => Object.fromEntries(data[0].map((k, i) => [k, a[i]])));
  return result.map((el) => ({ ...el, companyId: String(selectedCompanyId) }));
};

export const uploadLeads = (file, selectedCompany) => async (dispatch, getState) => {
  dispatch(uploadLeadsRequest());

  ExcelRenderer(file, async (_, resp) => {
    const isFileValid = REQUIRED_TABLE_FIELDS.every((item) => resp.rows[0].includes(item));
    let failureAlert = () =>
      notificationAlert({
        description:
          "File is invalid. Please make sure that 1st row does not contain  empty values and all required fields are specified. [name, email, phone, status, leadInfo]",
        type: "error",
      });

    if (isFileValid) {
      let leadsArray;
      try {
        leadsArray = transformCsvToLeadsArray(resp.rows, selectedCompany.id);
      } catch (e) {
        console.error(e);
        failureAlert();
        return;
      }
      await API.post("lead/create-many", { leads: leadsArray });

      dispatch(uploadLeadsSuccess(new Date()));
    } else {
      failureAlert();
    }
  });
};

export const uploadLeadsRequest = () => {
  return {
    type: UPLOAD_LEADS_REQUEST,
  };
};

export const uploadLeadsSuccess = () => {
  return {
    type: UPLOAD_LEADS_SUCCESS,
  };
};

export const updatePagination = (payload) => {
  return {
    type: UPDATE_PAGINATION,
    payload,
  };
};

export const updateSorter = (payload) => {
  return {
    type: UPDATE_SORTER,
    payload,
  };
};

export const updateLeadTable = (payload) => {
  return {
    type: UPDATE_LEAD_TABLE,
    payload,
  };
};

export const clearLeadsReducer = () => {
  return {
    type: CLEAR_LEADS_REDUCER,
  };
};

export const editLead = (data, currentLead) => async (dispatch, getState) => {
  const { allScripts, allUsers, funnels } = getState().leads;

  let leadScript = Array.isArray(data.leadsScripts) ? data.leadsScripts[0] : data.leadsScripts;
  let leadSalesPerson = Array.isArray(data.leadsSalesPersons) ? data.leadsSalesPersons[0] : data.leadsSalesPersons;

  leadScript = leadScript
    ? [
        {
          name: leadScript,
          salesPersonId: allScripts.find((el) => el.name === leadScript).id,
        },
      ]
    : "";

  leadSalesPerson = leadSalesPerson
    ? [
        {
          name: leadSalesPerson,
          salesPersonId: allUsers.find((el) => el.name === leadSalesPerson).id,
        },
      ]
    : "";
  const funnelObject = data.funnel ? funnels.find((item) => item.name === data.funnel) : {};
  const funnelIdx = data.funnel ? funnels.findIndex((item) => item.name === data.funnel) : null;
  const funnelStageObject = data.funnel
    ? funnels[funnelIdx].funnelStages.find((stage) => stage.name === data.funnelStage)
    : {};

  const updatedLead = {
    ...currentLead,
    status: data.status,
    name: data.name,
    email: data.email,
    phone: data.phone,
    leadInfo: data.leadInfo,
    leadsScripts: leadScript,
    leadsSalesPersons: leadSalesPerson,
    funnelId: funnelObject && funnelObject.id,
    funnelStageId: (funnelStageObject && funnelStageObject.id) || null,
  };

  await API.patch(`lead/${currentLead.id}`, updatedLead);
  notificationAlert({
    description: "Lead has been updated successfully",
  });
  dispatch(editLeadSuccess({ isUpdated: new Date() }));
};

export const editLeadSuccess = (payload) => {
  return {
    type: EDIT_LEAD_SUCCESS,
    payload,
  };
};

export const deleteLead = (leadId) => async (dispatch) => {
  await API.delete(`lead/${leadId}`);
  notificationAlert({
    description: "Lead has been deleted successfully",
  });
  dispatch(deleteLeadSuccess({ isUpdated: new Date() }));
};

export const deleteLeadSuccess = (payload) => {
  return {
    type: DELETE_LEAD_SUCCESS,
    payload,
  };
};

export const deleteAllLeads = () => async (dispatch, getState) => {
  const { selectedLeads } = getState().leads;

  await Promise.all(selectedLeads.map((el) => API.delete(`lead/${el}`)));
  notificationAlert({
    description: "Leads has been deleted successfully",
  });
  dispatch(deleteLeadSuccess({ isUpdated: new Date() }));
};

export const deleteAllLeadsSuccess = (payload) => {
  return {
    type: DELETE_All_LEADS_SUCCESS,
    payload,
  };
};

export const createLead = (newLeadData) => async (dispatch, getState) => {
  await API.post("lead/create", newLeadData);
  notificationAlert({
    description: "Lead has been created successfully",
  });
};

export const scriptLeadsAssign = (script) => async (dispatch, getState) => {
  const { selectedLeads, currentPageLeads } = getState().leads;

  await Promise.all(
    selectedLeads.map((el) => {
      const lead = currentPageLeads.find((lead) => lead.id === el);
      return API.patch(`lead/${el}`, {
        ...lead,
        leadsScripts: [{ name: script.name, scriptId: script.id }],
      });
    })
  );

  notificationAlert({
    description: "Script has been assigned successfully",
  });
};

export const userLeadsAssign = (user) => async (dispatch, getState) => {
  const { selectedLeads, currentPageLeads } = getState().leads;

  await Promise.all(
    selectedLeads.map((el) => {
      const lead = currentPageLeads.find((lead) => lead.id === el);
      return API.patch(`lead/${el}`, {
        ...lead,
        leadsSalesPersons: [{ name: user.name, salesPersonId: user.id }],
      });
    })
  );
  notificationAlert({
    description: "User has been assigned successfully",
  });
};

export const funnelLeadsAssign = (currentLead, funnel, funnelStage) => async (dispatch, getState) => {
  const { selectedLeads, currentPageLeads } = getState().leads;

  if (!selectedLeads.length) {
    await API.put(`lead/${currentLead.id}`, {
      funnelId: funnel && funnel.id,
      funnelStageId: funnelStage && funnelStage.id,
    });
    return;
  }

  await Promise.all(
    selectedLeads.map((el) => {
      const lead = currentPageLeads.find((lead) => lead.id === el);
      return API.patch(`lead/${el}`, {
        ...lead,
        funnelId: funnel.id,
        funnelStageId: funnelStage.id,
      });
    })
  );

  notificationAlert({
    description: "Funnel has been assigned successfully",
  });
};

export const updateLeadFilters = (payload) => {
  return {
    type: UPDATE_LEAD_FILTERS,
    payload,
  };
};
