import React, { useMemo, useState } from "react";
import Button from "antd/lib/button";
import ModalGroup from "../../../components/ModalGroup/ModalGroup";
import { useDispatch } from "react-redux";
import { editCompanyAction } from "../../../redux/actions/adminPageActions";
import ChipComponent from "../../../components/Chip/Chip";
import API from "../../../services/apiService";
import PropTypes from "prop-types";
import notificationAlert from "../../../utils/notificationAlert";
import "./_webhookPanel.less";

const WebhookPanel = React.memo(({ allCompanies, currentCompany }) => {
  const dispatch = useDispatch();
  const [selectedWebhook, setSelectedWebhook] = useState({});
  const [modalMode, setModalMode] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);

  const webhookCrud = async (updateCompanyObject, crudType) => {
    const updatedCompany = await API.patch(`company/${currentCompany.id}`, updateCompanyObject);
    const companyIndex = allCompanies.findIndex((company) => company.id === updatedCompany.id);
    notificationAlert({
      type: "success",
      description: `Webhook has been successfully ${crudType}`,
    });
    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
  };

  const createNewWebhook = async ({ name, url }) => {
    const updateCompanyObject = {
      ...currentCompany,
      webhooks: [...currentCompany.webhooks, { name: name + "_webhook", url, companyId: currentCompany.id }],
    };
    webhookCrud(updateCompanyObject, "created");
  };

  const editWebhook = async ({ name, url }) => {
    const updateCompanyObject = {
      ...currentCompany,
      webhooks: currentCompany.webhooks.map((webhook) => {
        if (webhook.name === selectedWebhook.name) {
          return { name, url, companyId: currentCompany.id };
        }
        return webhook;
      }),
    };
    await webhookCrud(updateCompanyObject, "updated");
  };

  const deleteWebhook = async () => {
    const updateCompanyObject = {
      ...currentCompany,
      webhooks: currentCompany.webhooks.filter((item) => item.name !== selectedWebhook.name),
    };
    await webhookCrud(updateCompanyObject, "deleted");
  };

  const onSelectWebhook = (item) => setSelectedWebhook(item);

  const modalSubmitOk = async (value) => {
    if (modalMode === "add") {
      await createNewWebhook(value);
    } else if (modalMode === "edit") {
      await editWebhook(value);
    } else if (modalMode === "delete") {
      await deleteWebhook();
    }
    setModalVisible(false);
  };

  const onModalCancel = () => {
    setModalVisible(false);
    setModalMode("");
  };

  const onModalOpen = (mode) => {
    setModalVisible(true);
    setModalMode(mode);
  };

  const modals = [
    {
      title: "Delete",
      text: `Delete «${selectedWebhook && selectedWebhook.name}» webhook`,
      type: "delete",
      isVisible: isModalVisible,
    },
    { title: "Edit", text: "Edit webhook", type: "edit", isVisible: isModalVisible },
    { title: "Add", text: "Add new webhook", type: "add", isVisible: isModalVisible },
  ];

  const modalComponentsData = useMemo(
    () => () => {
      return [
        {
          name: "name",
          placeholder: "Type webhook title",
          elementType: "input",
          checkDuplicate: true,
        },
        { name: "url", placeholder: "Type webhook link", elementType: "input" },
      ];
    },
    []
  );

  const transformWebhooksEnding = (webhooks) => {
    return webhooks.map((webhook) => {
      return { ...webhook, name: webhook.name.split("_webhook")[0] };
    });
  };

  return (
    <div className="config-webhooks">
      {isModalVisible ? (
        <ModalGroup
          editableItemIdx={""}
          allItems={currentCompany && transformWebhooksEnding(currentCompany.webhooks)}
          mode={modalMode}
          modals={modals}
          modalComponentsData={modalComponentsData()}
          onModalCancel={onModalCancel}
          modalSubmitOk={modalSubmitOk}
          currentItem={selectedWebhook}
        />
      ) : null}
      <div className="panel">
        <div className="block__title">Webhooks:</div>
        <Button className="btn" type="primary" onClick={() => onModalOpen("add")}>
          Add
        </Button>
      </div>
      <div className="attributes-block">
        {currentCompany && currentCompany.webhooks && currentCompany.webhooks.length
          ? currentCompany.webhooks.map((webhook, index) => {
              return (
                <ChipComponent
                  selectCurrentHandler={() => onSelectWebhook(webhook)}
                  editHandler={() => onModalOpen("edit")}
                  deleteHandler={() => onModalOpen("delete")}
                  text={webhook.name}
                  key={index}
                />
              );
            })
          : ""}
      </div>
    </div>
  );
});

WebhookPanel.propTypes = {
  currentCompany: PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.number,
    name: PropTypes.string,
    attributes: PropTypes.array,
    consultants: PropTypes.array,
    funnels: PropTypes.array,
    performanceIndicators: PropTypes.array,
    questions: PropTypes.array,
    reportSettings: PropTypes.object,
    scripts: PropTypes.array,
    timezone: PropTypes.string,
    timezoneName: PropTypes.string,
    webhooks: PropTypes.array,
  }),
  allCompanies: PropTypes.array,
};

export default WebhookPanel;
