import React, { useMemo, useState, useEffect } from "react";
import ModalGroup from "../../../components/ModalGroup/ModalGroup";
import { useDispatch } from "react-redux";
import { editCompanyAction } from "../../../redux/actions/adminPageActions";
import API from "../../../services/apiService";
import PropTypes from "prop-types";
import notificationAlert from "../../../utils/notificationAlert";
import SelectComponent from "../../../components/Select/Select";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import "./_funnelPanel.less";

const FunnelStagePanel = ({ allCompanies, currentCompany, currentFunnel }) => {
  const dispatch = useDispatch();
  const [selectedFunnelStage, setSelectedFunnelStage] = useState({});
  const [modalMode, setModalMode] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    setSelectedFunnelStage({});
  }, [currentFunnel.id]);

  const createNewFunnelStage = async ({ name }) => {
    const funeralIdx = currentCompany.funnels.findIndex((funnel) => funnel.id === currentFunnel.id);

    const newFuneralStage = await API.post(`funnel-stage`, { funnelId: currentFunnel.id, name });

    const updatedCompany = {
      ...currentCompany,
      funnels: [
        ...currentCompany.funnels.slice(0, funeralIdx),
        { ...currentFunnel, funnelStages: [...currentFunnel.funnelStages, newFuneralStage] },
        ...currentCompany.funnels.slice(funeralIdx + 1),
      ],
    };

    const companyIndex = allCompanies.findIndex((company) => company.id === currentCompany.id);

    notificationAlert({
      type: "success",
      description: "Funnel stage has been successfully created",
    });

    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
    setSelectedFunnelStage(newFuneralStage);
  };

  const editFunnelStage = async ({ name }) => {
    const funeralIdx = currentCompany.funnels.findIndex((funnel) => funnel.id === currentFunnel.id);
    const selectedFunnelStageIdx = currentFunnel.funnelStages.findIndex(
      (funnelStage) => funnelStage.id === selectedFunnelStage.id
    );

    const newFuneralStage = await API.put(`funnel-stage/${selectedFunnelStage.id}`, { name });

    setSelectedFunnelStage(newFuneralStage);

    const updatedCompany = {
      ...currentCompany,
      funnels: [
        ...currentCompany.funnels.slice(0, funeralIdx),
        {
          ...currentFunnel,
          funnelStages: [
            ...currentFunnel.funnelStages.slice(0, selectedFunnelStageIdx),
            newFuneralStage,
            ...currentFunnel.funnelStages.slice(selectedFunnelStageIdx + 1),
          ],
        },
        ...currentCompany.funnels.slice(funeralIdx + 1),
      ],
    };

    const companyIndex = allCompanies.findIndex((company) => company.id === currentCompany.id);

    notificationAlert({
      type: "success",
      description: "Funnel stage has been successfully updated",
    });

    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
  };

  const deleteFunnelStage = async () => {
    const funeralIdx = currentCompany.funnels.findIndex((funnel) => funnel.id === currentFunnel.id);

    await API.delete(`funnel-stage/${selectedFunnelStage.id}`);

    const updatedCompany = {
      ...currentCompany,
      funnels: [
        ...currentCompany.funnels.slice(0, funeralIdx),
        {
          ...currentFunnel,
          funnelStages: currentCompany.funnels[funeralIdx].funnelStages.filter((funnelStage) => {
            return funnelStage.id !== selectedFunnelStage.id;
          }),
        },
        ...currentCompany.funnels.slice(funeralIdx + 1),
      ],
    };

    setSelectedFunnelStage({});
    const companyIndex = allCompanies.findIndex((company) => company.id === currentCompany.id);

    notificationAlert({
      type: "success",
      description: "Funnel stage has been successfully deleted",
    });

    dispatch(editCompanyAction({ updatedCompany, companyIndex }));
  };

  const chooseFunnelObjectionHandler = (value) => {
    const funnelStageItem = currentFunnel.funnelStages.find((funnelStage) => funnelStage.name === value);
    setSelectedFunnelStage(funnelStageItem);
  };

  const modalSubmitOk = async (value) => {
    if (modalMode === "add") {
      await createNewFunnelStage(value);
    } else if (modalMode === "edit") {
      await editFunnelStage(value);
    } else if (modalMode === "delete") {
      await deleteFunnelStage();
    }
    setModalVisible(false);
  };

  const onModalCancel = () => {
    setModalVisible(false);
    setModalMode("");
  };

  const onModalOpen = (mode) => {
    setModalVisible(true);
    setModalMode(mode);
  };

  const modals = [
    {
      title: "Delete",
      text: `Delete «${selectedFunnelStage && selectedFunnelStage.name}» funnel stage`,
      type: "delete",
      isVisible: isModalVisible,
    },
    { title: "Edit", text: "Edit funnel stage", type: "edit", isVisible: isModalVisible },
    { title: "Add", text: "Add new funnel stage", type: "add", isVisible: isModalVisible },
  ];

  const modalComponentsData = useMemo(
    () => () => {
      return [
        {
          name: "name",
          placeholder: "Type funnel stage name",
          elementType: "input",
          checkDuplicate: true,
        },
      ];
    },
    []
  );

  const isBtnVisible = !!(selectedFunnelStage && selectedFunnelStage.name);

  const buttons = [
    { name: "Add stage to funnel", action: () => onModalOpen("add"), visible: true },
    { name: "Edit funnel stage", action: () => onModalOpen("edit"), visible: isBtnVisible },
    { name: "Delete funnel stage", action: () => onModalOpen("delete"), visible: isBtnVisible },
  ];

  return (
    <div className="funnel-config">
      {isModalVisible ? (
        <ModalGroup
          editableItemIdx={""}
          mainField={"name"}
          allItems={currentFunnel.funnelStages}
          mode={modalMode}
          modals={modals}
          modalComponentsData={modalComponentsData()}
          onModalCancel={onModalCancel}
          modalSubmitOk={modalSubmitOk}
          currentItem={selectedFunnelStage}
        />
      ) : null}
      <div className="block__title">Funnel stages:</div>
      <div className="select_container">
        <SelectComponent
          mainField="name"
          data={currentFunnel.funnelStages}
          value={selectedFunnelStage && selectedFunnelStage.name}
          handleChange={chooseFunnelObjectionHandler}
        />
      </div>
      <ButtonGroup data={buttons} />
    </div>
  );
};

FunnelStagePanel.propTypes = {
  currentCompany: PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.number,
    name: PropTypes.string,
    attributes: PropTypes.array,
    consultants: PropTypes.array,
    funnels: PropTypes.array,
    performanceIndicators: PropTypes.array,
    questions: PropTypes.array,
    reportSettings: PropTypes.object,
    scripts: PropTypes.array,
    timezone: PropTypes.string,
    timezoneName: PropTypes.string,
    webhooks: PropTypes.array,
  }),
  allCompanies: PropTypes.array,
  currentFunnel: PropTypes.object,
};

export default FunnelStagePanel;
