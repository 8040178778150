import { useState } from "react";
import { b64e } from "../utils/helpers";
import { useSelector } from "react-redux";

function usePersistedState(key, initialValue) {
  const loggedUser = useSelector((state) => state.common.user);

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const loggedUserHashedId = b64e([loggedUser.id]);
      const item = JSON.parse(localStorage.getItem(key))[loggedUserHashedId];
      return item ? item : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const prevData = JSON.parse(localStorage.getItem("timezoneStatsViewer"));
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      localStorage.setItem(
        key,
        JSON.stringify({
          ...prevData,
          [b64e(loggedUser.id)]: valueToStore,
        })
      );
    } catch (error) {}
  };

  return [storedValue, setValue];
}

export default usePersistedState;
