import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Table, Typography } from "antd";
import tableConfig from "./tableConfig";
import { setGuidanceAsyncAction } from "../../../redux/actions/meetingsPlanActions";
import PropTypes, { oneOfType } from "prop-types";
import moment from "moment";
import "./_meetingPlanPlannedGuidance.less";

function MeetingPlanPlannedGuidance({ guidance, selectedTeamMembers }) {
  const dispatch = useDispatch();
  const [firstLoad, setFirstLoad] = useState(true);
  const isGuidanceLoading = useSelector((state) => state.meetingPlan.isGuidanceLoading);
  const systemUsers = useSelector((state) => state.meetingPlan.systemUsers);
  const meetingDate = useSelector((state) => state.meetingPlan.date);

  useEffect(() => {
    if (!firstLoad) {
      dispatch(setGuidanceAsyncAction(selectedTeamMembers, systemUsers));
    }
    setFirstLoad(false);
  }, [selectedTeamMembers]);

  const getSearchUrl = ({ chosenCompanyId, sDate, eDate, usersId }) => {
    const company = chosenCompanyId ? "&companyId=" + chosenCompanyId : "";
    const dateStart = sDate ? "&startDate=" + sDate : "";
    const dateEnd = eDate ? "&endDate=" + eDate : "";
    const users = usersId.length ? usersId.map((user) => "&userId[]=" + user.id).join("") : "";
    let res = `?${company}${dateStart}${dateEnd}${users}`;
    return res[1] === "&" ? res.substring(0, 1) + res.substring(2, res.length) : res;
  };

  const getStartDate = (level) => {
    if (level === "new") {
      return moment(new Date(meetingDate) - 7 * 24 * 3600 * 1000).format("YYYY-MM-DD HH:mm:ss");
    }
    if (level === "ongoing") {
      return moment(new Date(meetingDate) - 14 * 24 * 3600 * 1000).format("YYYY-MM-DD HH:mm:ss");
    }

    if (level === "systemic") {
      return moment(new Date(meetingDate) - 30 * 24 * 3600 * 1000).format("YYYY-MM-DD HH:mm:ss");
    }
  };

  const redirectToDashboards = (record) => {
    const selectedUser = systemUsers.find((user) => user.id === record.userId);

    window.open(
      `/dashboard${getSearchUrl({
        chosenCompanyId: selectedUser?.companyId,
        sDate: getStartDate(record.level),
        eDate: meetingDate,
        usersId: [selectedUser],
      })}`,
      "_blank"
    );
  };

  const { getData, getColumns } = tableConfig(guidance, redirectToDashboards);

  return (
    <div className="guidance_table">
      <Typography.Title level={4}>Planned guidance</Typography.Title>
      <Table
        className="table"
        loading={isGuidanceLoading}
        pagination={false}
        size="small"
        columns={getColumns}
        dataSource={getData}
        bordered={true}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const { guidance, guidanceTableConfig, selectedTeamMembers } = state.meetingPlan;
  return { guidance, guidanceTableConfig, selectedTeamMembers };
}

MeetingPlanPlannedGuidance.propTypes = {
  guidance: oneOfType([PropTypes.array, PropTypes.object]), //type is array but when no data backend send empty object =)
  selectedTeamMembers: PropTypes.arrayOf(PropTypes.string),
};

export default connect(mapStateToProps)(MeetingPlanPlannedGuidance);
