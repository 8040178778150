import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../../../../components/Title/Title";
import { getCallBreakdownsKPI } from "../../../../../redux/actions/dashboardPageActions/callBreakdownsActions";
import DashboardsKpi from "../../../Components/DashboardKpi/DashboardsKpi";

function CallBreakdownKpi() {
  const dispatch = useDispatch();
  const dashboardsKPI = useSelector((state) => state.callBreakdowns.kpi);
  const updateDashboardsKpi = useSelector((state) => state.dashboard.updateDashboardsKpi);

  useEffect(() => {
    dispatch(getCallBreakdownsKPI());
  }, [updateDashboardsKpi]);

  return (
    <div className="stats_section">
      <Title tag="h5" position="left" weight="bold">
        How is the Employee going this period compared to last period?
      </Title>
      <DashboardsKpi dashboardsKPI={dashboardsKPI} />
    </div>
  );
}

export default CallBreakdownKpi;
