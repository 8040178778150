import { dashboardPageReducer } from "./dashboardPageReducer";
import { employeeSummaryReducer } from "./employeeSummartReducer";
import { callBreakdownReducer } from "./callBreakdownsReducer";

const dashboardPageReducers = {
  dashboard: dashboardPageReducer,
  employeeSummary: employeeSummaryReducer,
  callBreakdowns: callBreakdownReducer,
};

export default dashboardPageReducers;
