import React from "react";
import { chooseCurrentCompanyAction, chooseCurrentScriptAction } from "../../redux/actions/homePageActions";
import { useDispatch } from "react-redux";
import StartScriptButton from "./StartScriptButton";
import CompanyDropdown from "../../components/CompanyDropdown/CompanyDropdown";
import ScriptDropdown from "../../components/ScriptDropdown/ScriptDropdown";
import { isHavePermission } from "../../utils/helpers";
import { applicationRoles } from "../../utils/mocks";
import { useHistory } from "react-router";

const { ADMIN, CONSULTANT } = applicationRoles;

export default function StartScriptPanel({ allScripts, allCompanies, currentScript, currentCompany, currentUser }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const changeCompanyHandler = (value) => {
    const currentCompany = allCompanies.find((company) => company.name === value);
    dispatch(chooseCurrentCompanyAction({ currentCompany }));
  };

  const startScriptHandler = (value) => {
    const currentScript = allScripts.find((script) => script.name === value);
    localStorage.setItem("selectedScript", value);
    localStorage.setItem("selectedScriptId", currentScript.id);
    dispatch(chooseCurrentScriptAction({ currentScript }));
  };

  const startCallHandler = () => {
    history.push({ pathname: "/startScript", search: `?script_id=${currentScript.id}` });
  };

  return (
    <div className="config-panel">
      <div className="select_container">
        {isHavePermission(currentUser.role, [ADMIN, CONSULTANT]) ? (
          <>
            <div className="title">Please choose company</div>
            <CompanyDropdown
              className="basic"
              optionField="name"
              onSelect={(chosenCompany) =>
                currentCompany?.name !== chosenCompany && changeCompanyHandler(chosenCompany)
              }
              value={currentCompany && currentCompany.name}
              allData={allCompanies}
            />
          </>
        ) : (
          ""
        )}
      </div>
      <div className="select_container">
        <div className="title">Please choose your script</div>
        <ScriptDropdown
          className="basic"
          onSelect={(chosenScript) =>
            currentScript && currentScript.name !== chosenScript && startScriptHandler(chosenScript)
          }
          value={currentScript && currentScript.name}
          allData={allScripts}
          optionField="name"
        />
      </div>
      <StartScriptButton startCallHandler={startCallHandler} currentScript={currentScript} />
    </div>
  );
}
