import {
  SET_START_DATA_VIEWER_REQUEST,
  SET_START_DATA_VIEWER_SUCCESS,
  UPDATE_VIEWER_DATA,
  FINISH_CALL,
  UPDATE_CALL_ACTIVITY,
  START_NEW_CALL,
  CLEAR_VIEWER,
  UPDATE_SCRIPT_TEXT_LOCAL,
  UPDATE_SCRIPT_OBJECTION_LOCAL,
} from "../actions/scriptViewerPageActions";
import { b64e } from "../../utils/helpers";

const initialState = {
  allScriptStages: [],
  currentStage: 0,
  isLoading: true,
  prevDisabled: true,
  nextDisabled: false,
  currentCall: null, //тут записывать кол активити какой происходит
  categories: [],
  stats: { dials: 0, conversations: 0, pitches: 0, sales: 0, endReasons: [] },
  questions: [],
  currentScript: null,
};

export const scriptViewerPageReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case SET_START_DATA_VIEWER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SET_START_DATA_VIEWER_SUCCESS:
      return { ...state, ...payload, isLoading: false };

    case UPDATE_VIEWER_DATA:
      return { ...state, ...payload };

    case FINISH_CALL:
      let hashValueForUser = b64e(JSON.parse(localStorage.getItem("currentUser")).id);
      let prevStats = JSON.parse(localStorage.getItem("ironcage-stats"))[hashValueForUser];
      prevStats.dials++;
      const conversations =
        payload.endReason.category !== "dial" ? prevStats.conversations + 1 : prevStats.conversations;
      const pitches = payload.endReason.category.includes("pitch") ? prevStats.pitches + 1 : prevStats.pitches;
      const sales = payload.endReason.category.includes("yes") ? prevStats.sales + 1 : prevStats.sales;

      let prevStatsSession = JSON.parse(localStorage.getItem("ironcage-stats"));

      //Add endreason to stats.
      if (payload && payload.endReason && payload.endReason.category !== "dial") {
        let endReasonStat =
          prevStats &&
          prevStats.endReasons &&
          prevStats.endReasons.find((e) => e.category === payload.endReason.category);
        if (endReasonStat) {
          endReasonStat.count = endReasonStat.count + 1;
        }
      }

      let readyStats = {
        ...prevStatsSession,
        [hashValueForUser]: {
          ...prevStats,

          conversations: conversations,
          pitches: pitches,
          sales: sales,
        },
      };
      localStorage.setItem("ironcage-stats", JSON.stringify(readyStats));
      return {
        ...state,
        currentCall: { ...state.currentCall, ...payload },
      };

    case UPDATE_CALL_ACTIVITY:
      localStorage.setItem(
        "currentCall",
        JSON.stringify({
          ...state.currentCall,
          callActivities: [...state.currentCall.callActivities, payload],
        })
      );

      return {
        ...state,
        currentCall: {
          ...state.currentCall,
          callActivities: [...state.currentCall.callActivities, payload],
        },
      };

    case START_NEW_CALL:
      let hashedValueForUser = b64e(JSON.parse(localStorage.getItem("currentUser")).id);
      const statsObj =
        JSON.parse(localStorage.getItem("ironcage-stats")) &&
        JSON.parse(localStorage.getItem("ironcage-stats"))[hashedValueForUser];
      let prevSessionStats = JSON.parse(localStorage.getItem("ironcage-stats"));
      if (prevSessionStats !== null) {
        localStorage.setItem(
          "ironcage-stats",
          JSON.stringify({
            ...prevSessionStats,
            [hashedValueForUser]: {
              ...statsObj,
              dials: statsObj.dials + 1,
            },
          })
        );
      } else {
        localStorage.setItem(
          "ironcage-stats",
          JSON.stringify({
            [hashedValueForUser]: {
              ...statsObj,
              dials: statsObj.dials + 1,
            },
          })
        );
      }

      localStorage.setItem("currentCall", JSON.stringify({ ...payload }));

      return {
        ...state,
        currentCall: { ...payload },
      };

    case UPDATE_SCRIPT_TEXT_LOCAL:
      return {
        ...state,
        allScriptStages: state.allScriptStages.map((stage, i) => {
          if (i === payload.currentStage) {
            return { ...stage, script: payload.value };
          }
          return stage;
        }),
      };

    case UPDATE_SCRIPT_OBJECTION_LOCAL:
      return {
        ...state,
        allScriptStages: state.allScriptStages.map((stage, i) => {
          if (i === payload.currentStage) {
            return {
              ...stage,
              objections: stage.objections.map((objection) => {
                if (objection.name === payload.objectionTitle) {
                  return { ...objection, script: payload.value };
                }
                return objection;
              }),
            };
          }
          return stage;
        }),
      };

    case CLEAR_VIEWER:
      return initialState;

    default:
      return state;
  }
};
