import React, { useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import Button from "antd/lib/button";
import PropTypes from "prop-types";
import "./_rangePicker.less";

const { RangePicker: MiRangePicker } = DatePicker;

export default function RangePicker({ onChange, startDate, endDate, format, className, testId }) {
  const [isOpen, setOpen] = useState(false);

  const setRangeValue = () => {
    return startDate && endDate ? [moment(startDate, "YYYY-MM-DD"), moment(endDate, "YYYY-MM-DD")] : ["", ""];
  };

  const setYesterdayDate = () => {
    onChange(true, [
      moment().subtract(1, "days").format("YYYY-MM-DD"),
      moment().subtract(1, "days").format("YYYY-MM-DD"),
    ]);

    const antPicker = document.querySelector(".ant-picker");
    antPicker.classList.remove("ant-picker-focused");
    setOpen(false);
  };

  const setTodayDate = () => {
    onChange(true, [
      moment().subtract(0, "days").format("YYYY-MM-DD"),
      moment().subtract(0, "days").format("YYYY-MM-DD"),
    ]);

    setOpen(false);
  };

  const onChangePicker = (date, dateString) => {
    onChange(date, dateString);
    setOpen(false);
  };

  const onOpenHandler = (status) => setOpen(status);

  return (
    <MiRangePicker
      data-testid={testId}
      renderExtraFooter={() => {
        return (
          <div className="extra_footer">
            <Button key={"yesterday"} type="primary" onClick={setYesterdayDate}>
              Yesterday
            </Button>
            <Button key={"today"} type="primary" onClick={setTodayDate}>
              Today
            </Button>
          </div>
        );
      }}
      open={isOpen}
      onOpenChange={onOpenHandler}
      className={`datepicker ${className}`}
      onChange={onChangePicker}
      value={setRangeValue()}
      format={format}
    />
  );
}

RangePicker.defaultProps = {
  className: "",
  testId: "",
};

RangePicker.propTypes = {
  onChange: PropTypes.func,
  startDate: PropTypes.string || null,
  endDate: PropTypes.string || null,
  format: PropTypes.string,
  className: PropTypes.string,
  testId: PropTypes.string,
};
