import React, { useEffect } from "react";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import { useHistory } from "react-router-dom";
import notificationAlert from "../../utils/notificationAlert";
import { UserOutlined } from "@ant-design/icons";
import { validationAntService as validation } from "../../utils/validationAntService";
import API from "../../services/apiService";
import robot from "../../assets/robot.png";
import "./_password-reset.less";

const PasswordResetPage = () => {
  const history = useHistory();
  const { validateEmail } = validation();

  const backToLogin = () => history.push("/");

  const onFinish = async ({ email }) => {
    await API.post("auth/reset-password", { email });
    notificationAlert({
      description: `Thanks! Please check ${email} for a link to reset your password`,
    });
  };

  useEffect(() => {
    return () => notificationAlert({ destroy: true });
  }, []);

  return (
    <div className="reset_wrapper">
      <div className="reset__form_section">
        <h1 className="reset_preview_name">Script Sidekick</h1>
        <div className="reset-form">
          <h1 className="reset_message">Reset password</h1>
          <Form name="reset_form" initialValues={{ remember: true }} onFinish={onFinish}>
            <Form.Item
              name="email"
              validateTrigger={["onBlur"]}
              rules={[{ required: true, message: "This field is required" }, { validator: validateEmail }]}>
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email address"
              />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" className="btn submit_btn" type="orange">
                Submit
              </Button>
              <Button htmlType="submit" onClick={backToLogin} className="btn" type="orange-empty">
                Back
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="reset_img_section">
        <div className="reset_img">
          <img src={robot} alt="robot logo" />
        </div>
      </div>
    </div>
  );
};

export default PasswordResetPage;
