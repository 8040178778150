import API from "../../../services/apiService";
import moment from "moment";
import { getKpiWithDifferenceAndArrows } from "../../../pages/DashboardPage/DashboardTabPages/CallBreakdownsTab/CallBreakdownsKpi/kpiMapping";
import { kpiMapping } from "../../../pages/DashboardPage/DashboardTabPages/CallBreakdownsTab/CallBreakdownsKpi/kpiMapping";
import {
  END_REASONS_COLOR_MAPPING,
  VISIBLE_END_REASONS,
  OTHER_END_REASONS,
} from "../../../pages/DashboardPage/dashboardPageMappings";

export const GET_CALL_BREAKDOWNS_KPI_SUCCESS = "GET_CALL_BREAKDOWNS_KPI_SUCCESS";
export const GET_CALL_BREAKDOWNS_PIE_LEFT_SUCCESS = "GET_CALL_BREAKDOWNS_PIE_LEFT_SUCCESS";
export const GET_CALL_BREAKDOWNS_PIE_RIGHT_SUCCESS = "GET_CALL_BREAKDOWNS_PIE_RIGHT_SUCCESS";
export const GET_CALL_BREAKDOWNS_PIE_LEFT_REQUEST = "GET_CALL_BREAKDOWNS_PIE_LEFT_REQUEST";
export const GET_CALL_BREAKDOWNS_PIE_RIGHT_REQUEST = "GET_CALL_BREAKDOWNS_PIE_RIGHT_REQUEST";
export const GET_GROUPED_CHART_DATA_SUCCESS = "GET_GROUPED_CHART_DATA_SUCCESS";
export const GET_GROUPED_CHART_DATA_REQUEST = "GET_GROUPED_CHART_DATA_REQUEST";
export const UPDATE_GROUPED_CHART_DATA = "UPDATE_GROUPED_CHART_DATA";

const getUserIdArrayByName = (activeUsers) => {
  return activeUsers.length ? activeUsers.map((user) => user.id) : [];
};

export const getCallBreakdownsKPI = () => async (dispatch, getState) => {
  const { date, activeUsers, activeCompany, ignoreTimezone } = getState().dashboard;
  if (date.endDate && date.startDate && activeCompany) {
    const kpi = await API.get(`dashboards/call-stats`, {
      params: {
        f_userId: getUserIdArrayByName(activeUsers),
        f_companyId: [activeCompany.id],
        f_dateBefore: date.endDate,
        f_dateAfter: date.startDate,
        ignoreTimezone: ignoreTimezone,
      },
    });
    return dispatch(getCallBreakdownsKPISuccess({ kpi: getKpiWithDifferenceAndArrows(kpi) }));
  } else {
    return dispatch(getCallBreakdownsKPISuccess({ kpi: kpiMapping }));
  }
};

export const getCallBreakdownsKPISuccess = (payload) => {
  return {
    type: GET_CALL_BREAKDOWNS_KPI_SUCCESS,
    payload,
  };
};

const transformPieData = (data) => {
  const mainData = data
    .filter((item) => VISIBLE_END_REASONS.includes(item.name))
    .map((pieItem) => {
      return {
        ...pieItem,
        color: END_REASONS_COLOR_MAPPING[pieItem.name].color,
        labelColor: END_REASONS_COLOR_MAPPING[pieItem.name].labelColor,
      };
    });

  const other = data
    .filter((item) => OTHER_END_REASONS.includes(item.name))
    .map((pieItem) => {
      return {
        ...pieItem,
        color: END_REASONS_COLOR_MAPPING[pieItem.name].color,
        labelColor: END_REASONS_COLOR_MAPPING[pieItem.name].labelColor,
      };
    })
    .reduce((acc, el) => acc + el.value, 0);

  //TO SHOW ALL REASONS EVEN IF IT MISSING DATA
  const emptyReasons = VISIBLE_END_REASONS.filter(
    (endReasonName) => !mainData.some((item) => item.name === endReasonName)
  ).map((pieItem) => {
    return {
      value: 0,
      name: pieItem,
      color: END_REASONS_COLOR_MAPPING[pieItem].color,
      labelColor: END_REASONS_COLOR_MAPPING[pieItem].labelColor,
    };
  });

  let transformedData = [
    ...mainData,
    ...emptyReasons,
    {
      name: "Other",
      value: other,
      color: "#bfbcbb",
      labelColor: "black",
    },
  ];

  return transformedData.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};

const getTotalValue = (data) => {
  return data.reduce((acc, el) => {
    return acc + el.value;
  }, 0);
};

const getPrevDateRange = (startDateCurrent, endDateCurrent) => {
  const dateBefore = moment(startDateCurrent).subtract(1, "days").format("YYYY-MM-DD");
  const periodDifference = moment(startDateCurrent).diff(moment(endDateCurrent));
  const periodDifferenceInDays = Number(moment.utc(periodDifference).format("DD"));
  const dateAfter = moment(dateBefore).subtract(periodDifferenceInDays, "days").format("YYYY-MM-DD");

  return { dateBefore, dateAfter };
};

export const getCallBreakdownsPieLeft = () => async (dispatch, getState) => {
  const { date, activeUsers, activeCompany, ignoreTimezone } = getState().dashboard;

  if (date.endDate && date.startDate && activeCompany) {
    const { dateBefore, dateAfter } = getPrevDateRange(date.endDate, date.startDate);
    dispatch(getCallBreakdownsPieLeftRequest());
    const pieDashboard = await API.get(`dashboards/end-reasons-stats`, {
      params: {
        f_userId: getUserIdArrayByName(activeUsers),
        f_companyId: [activeCompany.id],
        f_dateBefore: dateBefore,
        f_dateAfter: dateAfter,
        ignoreTimezone: ignoreTimezone,
      },
    });
    return dispatch(
      getCallBreakdownsPieLeftSuccess({
        leftPieDashboard: transformPieData(pieDashboard),
        totalValue: getTotalValue(pieDashboard),
      })
    );
  } else {
    return dispatch(getCallBreakdownsPieLeftSuccess({ leftPieDashboard: null }));
  }
};

export const getCallBreakdownsPieLeftSuccess = (payload) => {
  return {
    type: GET_CALL_BREAKDOWNS_PIE_LEFT_SUCCESS,
    payload,
  };
};

export const getCallBreakdownsPieLeftRequest = (payload) => {
  return {
    type: GET_CALL_BREAKDOWNS_PIE_LEFT_REQUEST,
    payload,
  };
};

export const getCallBreakdownsPieRight = () => async (dispatch, getState) => {
  const { date, activeUsers, activeCompany, ignoreTimezone } = getState().dashboard;

  if (date.endDate && date.startDate && activeCompany) {
    dispatch(getCallBreakdownsPieRightRequest());
    const pieDashboard = await API.get(`dashboards/end-reasons-stats`, {
      params: {
        f_userId: getUserIdArrayByName(activeUsers),
        f_companyId: [activeCompany.id],
        f_dateBefore: date.endDate,
        f_dateAfter: date.startDate,
        ignoreTimezone: ignoreTimezone,
      },
    });

    return dispatch(
      getCallBreakdownsPieRightSuccess({
        rightPieDashboard: transformPieData(pieDashboard),
        totalValueRightPie: getTotalValue(pieDashboard),
      })
    );
  } else {
    return dispatch(getCallBreakdownsPieRightSuccess({ rightPieDashboard: null }));
  }
};

export const getCallBreakdownsPieRightSuccess = (payload) => {
  return {
    type: GET_CALL_BREAKDOWNS_PIE_RIGHT_SUCCESS,
    payload,
  };
};

export const getCallBreakdownsPieRightRequest = (payload) => {
  return {
    type: GET_CALL_BREAKDOWNS_PIE_RIGHT_REQUEST,
    payload,
  };
};

const getGroupedChartDataWithColors = (data) => {
  const mainData = data
    .filter((reason) => reason.duration)
    .map((pieItem) => {
      const stats = pieItem.stats
        .filter((item) => [...VISIBLE_END_REASONS, ...OTHER_END_REASONS].includes(item.endReason))
        .map((item) => {
          if (OTHER_END_REASONS.includes(item.endReason)) {
            return {
              ...item,
              endReason: "Other",
              color: END_REASONS_COLOR_MAPPING[item.endReason].color,
            };
          }
          return {
            ...item,
            color: END_REASONS_COLOR_MAPPING[item.endReason].color,
          };
        });
      return {
        ...pieItem,
        stats,
      };
    });

  function sortGroupedChartDataByDuration(field) {
    return function (a, b) {
      a = a[field].slice(0, 8); //to have ability convert at time value with 00:45:00+
      b = b[field].slice(0, 8);
      const first = moment.duration(a).asSeconds();
      const second = moment.duration(b).asSeconds();
      return first > second ? 1 : -1;
    };
  }

  return mainData.sort(sortGroupedChartDataByDuration("duration"));
};

export const getGroupedChartData = () => async (dispatch, getState) => {
  const { date, activeUsers, activeCompany, users, ignoreTimezone } = getState().dashboard;

  if (date.endDate && date.startDate && activeCompany) {
    dispatch(getGroupedChartDataRequest());
    const groupedChartData = await API.get(`dashboards/end-reasons-duration-stats`, {
      params: {
        f_userId: getUserIdArrayByName(activeUsers, users),
        f_companyId: [activeCompany.id],
        f_dateBefore: date.endDate,
        f_dateAfter: date.startDate,
        ignoreTimezone: ignoreTimezone,
      },
    });
    return dispatch(
      getGroupedChartDataSuccess({
        groupedChartData: getGroupedChartDataWithColors(groupedChartData),
      })
    );
  } else {
    return dispatch(
      getGroupedChartDataSuccess({
        groupedChartData: null,
      })
    );
  }
};

export const getGroupedChartDataSuccess = (payload) => {
  return {
    type: GET_GROUPED_CHART_DATA_SUCCESS,
    payload,
  };
};

export const getGroupedChartDataRequest = (payload) => {
  return {
    type: GET_GROUPED_CHART_DATA_REQUEST,
    payload,
  };
};

export const updateGroupedChartData = (payload) => {
  return {
    type: UPDATE_GROUPED_CHART_DATA,
    payload,
  };
};
