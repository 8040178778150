import React from "react";
import { Select } from "antd";
import { sortByName } from "../../utils/helpers";
import PropTypes from "prop-types";
import "./__scriptDropdown.less";

export default function ScriptDropdown({ loading, value, allData, onSelect, className, optionField }) {
  return (
    <Select
      placeholder={"Choose script"}
      showSearch
      className={"script_dropdown " + className}
      loading={loading}
      onSelect={(chosenScript) => value !== chosenScript && onSelect(chosenScript)}
      value={value}>
      {allData &&
        sortByName(allData, optionField).map((script, index) => {
          return (
            <Select.Option key={index} value={script[optionField]}>
              {script[optionField]}
            </Select.Option>
          );
        })}
    </Select>
  );
}

ScriptDropdown.defaultProps = {
  loading: false,
  className: "",
};

ScriptDropdown.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.string,
  allData: PropTypes.array,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  optionField: PropTypes.string,
};
