import React, { useEffect, useState } from "react";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import { useHistory } from "react-router";
import notificationAlert from "../../utils/notificationAlert";
import { LockOutlined } from "@ant-design/icons";
import { validationAntService as validation } from "../../utils/validationAntService";
import API from "../../services/apiService";
import "./_password-reset-confirm.less";

const PasswordResetConfirmPage = () => {
  const [linkValid, setLinkValid] = useState(false);
  const { validatePassword, validatePasswordDuplicate } = validation();
  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = async ({ password, passwordRepeat }) => {
    if (password !== passwordRepeat) {
      notificationAlert({
        type: "error",
        description: `Passwords different`,
      });
    }
    await API.post("auth/reset/password", {
      token: new URLSearchParams(window.location.search).get("uniqId"),
      password: password,
    });
    notificationAlert({ description: `Password has been changed` });

    setTimeout(() => {
      history.push("/login");
    }, 2000);
  };

  const checkResetToken = async (uniqId) => {
    let result = await API.post("auth/reset/check", { token: uniqId });
    return result.status;
  };

  useEffect(() => {
    const checkLinkValid = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const uniqId = urlParams.get("uniqId");
      const status = await checkResetToken(uniqId);

      if (status) {
        setLinkValid(true);
      }
    };

    checkLinkValid();
  }, []);

  if (!linkValid) {
    return (
      <div className="wrapper">
        <div className="forgot_link">This link is invalid</div>
      </div>
    );
  }

  return (
    <div className="reset_confirm_form block">
      <div className="container-fluid-sm">
        <h1>Set new password</h1>
        <Form form={form} name="reset-password" onFinish={onFinish} initialValues={{ remember: true }}>
          <Form.Item
            name="password"
            validateTrigger={["onBlur"]}
            rules={[{ required: true, message: "This field is required" }, { validator: validatePassword }]}>
            <Input.Password
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="passwordRepeat"
            validateTrigger={["onBlur"]}
            rules={[
              { required: true, message: "This field is required" },
              {
                validator: (rule, value) => {
                  return validatePasswordDuplicate(rule, value, form.getFieldsValue().password);
                },
              },
            ]}>
            <Input.Password
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Repeat password"
            />
          </Form.Item>
          <Form.Item>
            <Button type="orange" htmlType="submit" block>
              Set new password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default PasswordResetConfirmPage;
