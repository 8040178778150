import React from "react";
import { Card, Avatar, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import logo from "../../assets/logo.svg";
import PropTypes from "prop-types";
import "./_dashboard.less";

const { Meta } = Card;

export default function DashboardComponent({ onDelete, onEdit, title, value, onClick }) {
  return (
    <Card
      className="dashboard__item"
      onClick={onClick}
      hoverable
      actions={[
        <Tooltip placement="top" title="Edit dashboard">
          <EditOutlined key="edit" className="icon edit" onClick={onEdit} />
        </Tooltip>,
        <Tooltip placement="top" title="Delete dashboard">
          <DeleteOutlined key="delete" className="icon delete" onClick={onDelete} />
        </Tooltip>,
      ]}>
      <Meta avatar={<Avatar src={logo} />} title={title} description={value} />
    </Card>
  );
}

DashboardComponent.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.number,
  onClick: PropTypes.func,
};
