import { Form, Input, Select } from "antd";
import { validationAntService as validation } from "../../../utils/validationAntService";
import PhoneInput from "../../../components/PhoneInput/PhoneInput";
import { useSelector } from "react-redux";
import { sortByName } from "../../../utils/helpers";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const { Option } = Select;

const { validateEmail, validatePhone } = validation();

function LeadForm({ onSubmit, currentLead, onCancel }) {
  const [form] = Form.useForm();
  const allScripts = useSelector((state) => state.leads.allScripts);
  const allUsers = useSelector((state) => state.leads.allUsers);
  const funnels = useSelector((state) => state.leads.funnels);
  const [selectedFunnel, setSelectedFunnel] = useState([]);

  useEffect(() => {
    if (currentLead && currentLead.funnel) {
      setActiveFunnelStages(currentLead.funnel);
    }
  }, []);

  const setActiveFunnelStages = (funnelName) => {
    if (funnelName) {
      const selectedFunnel = funnels.find((funnel) => funnel.name === funnelName);
      if (selectedFunnel) {
        setSelectedFunnel(selectedFunnel.funnelStages);
      }
    }
  };

  const onFormLayoutChange = (data) => {
    //setActiveFunnelStages and reset active
    if (data.funnel) {
      const selectedFunnel = funnels.find((funnel) => funnel.name === data.funnel);
      if (selectedFunnel) {
        setSelectedFunnel(selectedFunnel.funnelStages);
        form.setFieldsValue({ funnelStage: [] });
      }
    }
  };

  const formSubmit = () =>
    form
      .validateFields()
      .then((data) => onSubmit(data))
      .then(() => form.resetFields());

  const getCurrentLead = (currentLead) => {
    if (currentLead) {
      return {
        ...currentLead,
        leadsScripts: currentLead.leadsScripts ? currentLead.leadsScripts : null,
        leadsSalesPersons: currentLead.leadsSalesPersons ? currentLead.leadsSalesPersons : null,
        funnel: currentLead.funnel ? currentLead.funnel : null,
        funnelStage: currentLead.funnelStage ? currentLead.funnelStage : null,
      };
    }
    return null;
  };

  return (
    <Form
      form={form}
      name="basic"
      onValuesChange={onFormLayoutChange}
      initialValues={getCurrentLead(currentLead)}
      onFinish={onSubmit}
      autoComplete="off">
      <Form.Item name="name" rules={[{ required: true, message: "This field is required" }]}>
        <Input placeholder="Lead name" />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          { required: true, message: "This field is required" },
          { validator: (rule, value) => validateEmail(rule, value) },
        ]}>
        <Input placeholder="Lead email" />
      </Form.Item>

      <Form.Item
        name="phone"
        rules={[
          { required: true, message: "This field is required" },
          { validator: (rule, value) => validatePhone(rule, value) },
        ]}>
        <PhoneInput form={form} placeholder="Enter phone number" />
      </Form.Item>

      <Form.Item name="leadInfo" rules={[{ required: true, message: "This field is required" }]}>
        <Input placeholder="Lead info" />
      </Form.Item>

      <Form.Item name="status" rules={[{ required: true, message: "This field is required" }]}>
        <Input placeholder="Lead status" />
      </Form.Item>

      <Form.Item name="leadsScripts">
        <Select placeholder={"Choose script"}>
          {allScripts.length &&
            sortByName(allScripts, "name").map((script, index) => {
              return (
                <Option key={index} value={script.name}>
                  {script.name}
                </Option>
              );
            })}
        </Select>
      </Form.Item>

      <Form.Item name="leadsSalesPersons">
        <Select placeholder={"Choose salesman"}>
          {allUsers.length &&
            sortByName(allUsers, "name").map((script, index) => {
              return (
                <Option key={index} value={script.name}>
                  {script.name}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item name="funnel">
        <Select placeholder={"Choose funnel"}>
          {funnels.length &&
            sortByName(funnels, "name").map((script, index) => {
              return (
                <Option key={index} value={script.name}>
                  {script.name}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item name="funnelStage">
        <Select placeholder={"Choose funnel stage"}>
          {selectedFunnel.length &&
            sortByName(selectedFunnel, "name").map((script, index) => {
              return (
                <Option key={index} value={script.name}>
                  {script.name}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
      <div className="ant-modal-footer" style={{ marginLeft: "-22px", marginRight: "-22px", paddingRight: "22px" }}>
        <button type="button" className="ant-btn" onClick={onCancel}>
          <span>Cancel</span>
        </button>
        <button type="button" className="ant-btn ant-btn-primary" onClick={formSubmit}>
          <span>OK</span>
        </button>
      </div>
    </Form>
  );
}

LeadForm.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {},
  currentLead: null,
};

LeadForm.propTypes = {
  onSubmit: PropTypes.func,
  currentLead: PropTypes.object,
  onCancel: PropTypes.func,
};

export default LeadForm;
