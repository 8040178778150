import API from "../../services/apiService";
import {
  setPrevDisabled,
  setNextDisabled,
  getUrlParam,
  isHavePermission,
  FORMAT_DATE,
  getDateInMilliseconds,
  transformAndGetDataWithOffset,
} from "../../utils/helpers";
import { applicationRoles } from "../../utils/mocks";

const { CONSULTANT } = applicationRoles;

export const SET_START_DATA_VIEWER_REQUEST = "SET_START_DATA_VIEWER_REQUEST";
export const SET_START_DATA_VIEWER_SUCCESS = "SET_START_DATA_VIEWER_SUCCESS";
export const UPDATE_VIEWER_DATA = "UPDATE_VIEWER_DATA";
export const UPDATE_CALL_ACTIVITY = "UPDATE_CALL_ACTIVITY";
export const START_NEW_CALL = "START_NEW_CALL";
export const FINISH_CALL = "FINISH_CALL";
export const CLEAR_VIEWER = "CLEAR_VIEWER";
export const UPDATE_SCRIPT_TEXT_LOCAL = "UPDATE_SCRIPT_TEXT_LOCAL";
export const UPDATE_SCRIPT_OBJECTION_LOCAL = "UPDATE_SCRIPT_OBJECTION_LOCAL";

export const setStartDataViewerAction = (currentScriptPage, loggedUser, history) => async (dispatch) => {
  dispatch(setStartDataViewerRequest());

  const selectedScriptId = getUrlParam(window.location.search, "script_id");
  let currentScript; //current started script
  let userCompany;

  if (isHavePermission(loggedUser.role, CONSULTANT) || isHavePermission(loggedUser.role, "admin")) {
    const allCompanies = await API.get(`company/all`);
    userCompany = allCompanies.find((company) => company.name === localStorage.getItem("currentCompanyName"));
    currentScript = userCompany.scripts.find((script) => script.id === selectedScriptId);
  } else {
    userCompany = await API.get(`company/${loggedUser.companyId}`);
    currentScript = userCompany.scripts.find((script) => script.id === selectedScriptId);
  }

  if (loggedUser.role !== "admin" && !(currentScript && Object.entries(currentScript).length)) {
    return history.push("/forbidden-page");
  }

  const categories = await API.get("category/all");
  const allScriptStages = (await API.get(`stage/by-script/${selectedScriptId}`)) || [];
  const questions = allScriptStages[currentScriptPage] && allScriptStages[currentScriptPage].questions;
  const prevDisabled = setPrevDisabled(currentScriptPage);
  const nextDisabled = setNextDisabled(currentScriptPage, allScriptStages);

  const isCallActive = localStorage.getItem("currentCall");
  let currentCall;
  //check if call have already started
  if (!isCallActive) {
    currentCall = await API.post("sales-call/create", {
      companyId: loggedUser.companyId || userCompany.id,
      startDateTime: getDateInMilliseconds(new Date()),
      userId: loggedUser.id,
    });
    currentCall.startDateTime = transformAndGetDataWithOffset(currentCall.startDateTime, FORMAT_DATE);
  } else {
    currentCall = JSON.parse(localStorage.getItem("currentCall"));
  }

  let lead = null;
  let leadFunnel = null;
  let leadStage = null;
  let listStages = [];
  const lead_id = getUrlParam(window.location.search, "lead_id");
  //set lead info if call started with lead or set null
  if (lead_id) {
    lead = await API.get(`lead/${lead_id}`);
    leadFunnel = lead.funnelId ? await API.get(`funnel/${lead.funnelId}`) : null;
    listStages = leadFunnel ? leadFunnel.funnelStages : [];
    leadStage =
      leadFunnel && leadFunnel.funnelStages
        ? leadFunnel.funnelStages.find((el) => {
            return el.id === lead.funnelStageId;
          })
        : {};
  }

  //leadStage - current lead stage if it exists
  //listStages - all lead stages if it exists
  //lead - selected lead
  //currentCall - call info from request above
  const callInfo = { ...currentCall, lead, listStages, currentStage: leadStage };
  localStorage.setItem("currentCall", JSON.stringify(callInfo));

  dispatch(
    setStartDataViewerSuccess({
      allScriptStages,
      prevDisabled,
      nextDisabled,
      categories,
      currentCall: callInfo,
      questions,
      currentScript,
    })
  );
};

const setStartDataViewerRequest = () => {
  return {
    type: SET_START_DATA_VIEWER_REQUEST,
  };
};

const setStartDataViewerSuccess = (payload) => {
  return {
    type: SET_START_DATA_VIEWER_SUCCESS,
    payload,
  };
};

export const updateViwerDataAction = (payload) => {
  return {
    type: UPDATE_VIEWER_DATA,
    payload,
  };
};

export const finishCallAction = (payload) => {
  return {
    type: FINISH_CALL,
    payload,
  };
};

export const clearViewerAction = (payload) => {
  return {
    type: CLEAR_VIEWER,
    payload,
  };
};

export const startNewCallAction = (payload) => {
  return {
    type: START_NEW_CALL,
    payload,
  };
};

export const updateCallActivityAction = (payload) => {
  return {
    type: UPDATE_CALL_ACTIVITY,
    payload,
  };
};

export const updateScriptTextLocal = (payload) => {
  return {
    type: UPDATE_SCRIPT_TEXT_LOCAL,
    payload,
  };
};

export const updateScriptObjectionLocal = (payload) => {
  return {
    type: UPDATE_SCRIPT_OBJECTION_LOCAL,
    payload,
  };
};
