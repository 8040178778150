import React from "react";
import { Space, Tooltip } from "antd";
import { changeSortDirectionTitle, sortByName } from "../../../utils/helpers";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import DateFilterComponent from "../../../components/RangePickerFilter/RangePickerFilter";
import CheckboxFilter from "../../../components/CheckboxFIlter/CheckboxFilter";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

function tableConfig(
  meetings,
  sortedField,
  sortedDirection,
  setMeetingAction,
  filters,
  updateFilters,
  updateUserFilter,
  teamMembers
) {
  function getData() {
    return (
      meetings &&
      meetings.map(({ date, teamMembers, completedGuidances, id }) => {
        return { key: id, date, teamMembers, completedGuidances };
      })
    );
  }

  function getColumns() {
    return [
      {
        title: "Date",
        dataIndex: "date",
        className: "date_column",
        key: "date",
        ellipsis: true,
        width: "160px",
        align: "center",
        render: (text) => <span>{text}</span>,
        sorter: () => null,
        sortOrder: sortedField === "date" ? changeSortDirectionTitle(sortedDirection) : "",
        filterDropdown: ({ confirm, clearFilters }) => (
          <DateFilterComponent
            confirm={confirm}
            clearFilters={clearFilters}
            filters={filters}
            onSearch={updateFilters}
            field={"date"}
          />
        ),
        filterIcon: () => (
          <SearchOutlined style={{ color: filters.dateAfter || filters.dateBefore ? "#1890ff" : undefined }} />
        ),
      },
      {
        title: "Members",
        className: "teamMembers_column",
        width: "300px",
        dataIndex: "teamMembers",
        ellipsis: true,
        key: "teamMembers",
        align: "left",
        render: (data) => {
          const allTeamMembers = data && data.split(", ");
          return (
            <>
              <span className="dropdown-text">
                {allTeamMembers.length
                  ? sortByName(allTeamMembers).map((member, i) => (
                      <p key={i} className="member_text">
                        &nbsp;{member}
                      </p>
                    ))
                  : ""}
              </span>
            </>
          );
        },
        filterIcon: () => {
          return <FilterFilled style={{ color: filters.userId.length ? "#1890ff" : undefined }} />;
        },
        filterDropdown: ({ confirm, clearFilters }) => {
          return (
            <CheckboxFilter
              clearFilters={clearFilters}
              defaultValue={filters.userId}
              data={teamMembers}
              search={updateUserFilter}
              confirm={confirm}
              dataIndex={"teamMembers"}
            />
          );
        },
      },
      {
        title: "Completed guidance",
        dataIndex: "completedGuidances",
        className: "completedGuidance",
        key: "completedGuidances",
        align: "left",
        render: (guidanceArea) => {
          const allGuidance = guidanceArea;
          return (
            <>
              <span className="dropdown-text">
                {allGuidance.length
                  ? allGuidance.map((visibleGuidanceItem, index) => {
                      return <p key={index}>- {visibleGuidanceItem.guidanceArea.text}</p>;
                    })
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        title: "Actions",
        key: "action",
        className: "action_column",
        width: "95px",
        align: "center",
        render: (record) => (
          <Space size="middle">
            <Tooltip placement="topLeft" title={"Edit meeting"}>
              <span className="action_link" onClick={() => setMeetingAction("edit", record.key)} data-testid="edit">
                <EditOutlined style={{ fontSize: "18px" }} className="icon icon_edit" />
              </span>
            </Tooltip>
            <Tooltip placement="topLeft" title={"Delete meeting"}>
              <span className="action_link" data-testid="delete" onClick={() => setMeetingAction("delete", record.key)}>
                <DeleteOutlined style={{ fontSize: "18px" }} className="icon icon_delete" />
              </span>
            </Tooltip>
          </Space>
        ),
      },
    ];
  }

  return { getData: getData(), getColumns: getColumns() };
}

export default tableConfig;
