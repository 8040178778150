import { Table } from "antd";
import employeeGuidanceTableConfig from "./employeeSummaryGuidanceTableConfig";
import React, { useEffect } from "react";
import {
  getGuidanceKPI,
  updateGuidancePagination,
  updateGuidanceSort,
  updateGuidanceTableAction,
} from "../../../../../redux/actions/dashboardPageActions/employeeSummaryActions";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../../../../components/Title/Title";
import "./_employeeSummaryGuidanceTable.less";

function EmployeeSummaryGuidanceTable() {
  const dispatch = useDispatch();
  const updateDashboardsKpi = useSelector((state) => state.dashboard.updateDashboardsKpi);
  const updateGuidanceTable = useSelector((state) => state.employeeSummary.updateGuidanceTable);

  const guidanceKPI = useSelector((state) => state.employeeSummary.guidanceKPI);
  const { pageSize, page, totalCount, sortOrder, sortedField } = useSelector(
    (state) => state.employeeSummary.guidanceKpiSettings
  );

  const { getData, getColumns } = employeeGuidanceTableConfig({ guidanceKPI, sortOrder, sortedField });

  useEffect(() => {
    dispatch(getGuidanceKPI());
  }, [updateDashboardsKpi, updateGuidanceTable]);

  const onTableChange = (pagination, filters, sorter, { action }) => {
    if (action === "paginate") {
      dispatch(updateGuidancePagination({ page: pagination.current, pageSize: pagination.pageSize }));
      dispatch(updateGuidanceTableAction(new Date()));
    }
    if (action === "sort") {
      dispatch(updateGuidanceSort({ sortOrder: sorter.order, sortedField: sorter.field }));
      dispatch(updateGuidanceTableAction(new Date()));
    }
  };

  return (
    <>
      <Title tag="h5" position="left" weight="bold">
        What guidance has the Employee received, and how are their KPIs?
      </Title>
      <Table
        className="employeeGuidanceTable"
        scroll={{ x: "auto" }}
        pagination={{
          pageSize: pageSize,
          current: page,
          pageSizeOptions: [10, 20, 50, 100],
          total: totalCount,
          showSizeChanger: true,
          position: ["bottomCenter"],
        }}
        size="small"
        columns={getColumns}
        dataSource={getData}
        bordered={true}
        onChange={onTableChange}
      />
    </>
  );
}

export default EmployeeSummaryGuidanceTable;
