import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setGridDataAction,
  replaceStageWithCurrentAction,
  closeOverrideModalAction,
  replaceStageWithCurrentSuccess,
  cancelOverrideModalAction,
} from "../../../redux/actions/scriptLoaderActions";
import { Modal, Spin, Input, Button } from "antd";
import ScriptLoaderTable from "./ScriptLoaderTable";
import ModalComponent from "../../../components/Modal/Modal";
import PropTypes from "prop-types";
import "./_script-loader.less";

const { TextArea } = Input;

export default function ScriptLoader({ title, isModalVisible, onModalCancelHandler, currentPage, scriptLoaderToggle }) {
  const dispatch = useDispatch();
  const isGridDataLoading = useSelector((state) => state.scriptLoader.isGridDataLoading);
  const gridData = useSelector((state) => state.scriptLoader.gridData);
  const [selectedObjection, setSelectedObjection] = useState({});
  const isOverrideModalOpen = useSelector((state) => state.scriptLoader.isOverrideModalOpen);
  const gridOverrideData = useSelector((state) => state.scriptLoader.gridOverrideData);

  useEffect(() => {
    if (isModalVisible) {
      dispatch(setGridDataAction());
    }
  }, [isModalVisible]);

  const loadToScript = async () => {
    if (Object.entries(selectedObjection).length) {
      dispatch(replaceStageWithCurrentAction(selectedObjection.id, currentPage)); //show override popup
    }
  };

  const overrideLoad = async (override) => {
    if (override) {
      scriptLoaderToggle();
      dispatch(replaceStageWithCurrentSuccess({ overrideData: { ...gridOverrideData }, currentPage }));
    } else {
      scriptLoaderToggle();
      dispatch(
        replaceStageWithCurrentSuccess({
          overrideData: { script: gridOverrideData.script },
          currentPage,
        })
      );
    }
    dispatch(closeOverrideModalAction());
  };

  const setCurrentStage = (stage) => setSelectedObjection(stage);

  const cancelOverrideModal = () => dispatch(cancelOverrideModalAction());

  return (
    <>
      <Modal
        className="loader_modal"
        centered
        width={"90%"}
        title={title}
        footer={[
          <Button
            key="back"
            className="btn"
            onClick={() => {
              onModalCancelHandler();
              setSelectedObjection({});
            }}>
            Cancel
          </Button>,
          <Button
            key="submit"
            className="btn"
            type="primary"
            disabled={!Object.entries(selectedObjection).length}
            onClick={loadToScript}>
            OK
          </Button>,
        ]}
        visible={isModalVisible}
        onCancel={() => {
          onModalCancelHandler();
          setSelectedObjection({});
        }}>
        {isGridDataLoading ? (
          <div className="spinner">
            <Spin size="large" />
          </div>
        ) : (
          <>
            <ScriptLoaderTable gridData={gridData} setCurrentStage={setCurrentStage} />
            {selectedObjection && selectedObjection.script ? (
              <TextArea className="textarea" rows={8} value={selectedObjection.script} disabled />
            ) : (
              ""
            )}
          </>
        )}
      </Modal>
      <ModalComponent
        title={"Override modal"}
        text={"Override objections and reasons of current stage?"}
        isModalVisible={isOverrideModalOpen}
        onModalCancelHandler={cancelOverrideModal}
        footer={[
          <Button key="back" onClick={() => overrideLoad(false)}>
            No override
          </Button>,
          <Button key="submit" type="primary" onClick={() => overrideLoad(true)}>
            Override
          </Button>,
        ]}
      />
    </>
  );
}

ScriptLoader.propTypes = {
  title: PropTypes.string,
  isModalVisible: PropTypes.bool,
  onModalCancelHandler: PropTypes.func,
  currentPage: PropTypes.number,
  scriptLoaderToggle: PropTypes.func,
};
