import React, { useEffect, useMemo, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import ReactDOMServer from "react-dom/server";
import { ReactComponent as UndoIcon } from "../../../../assets/undo.svg";
import { ReactComponent as RedoIcon } from "../../../../assets/redo.svg";
import { ReactComponent as Highlight } from "../../../../assets/highlight_icon.svg";
import "react-quill/dist/quill.snow.css";
import "./_textEditor.less";

//Override font sizes
let fontSizeStyle = Quill.import("attributors/style/size");
const fontsList = ["15px", "16px", "17px", "18px", "20px"];
fontSizeStyle.whitelist = fontsList;
Quill.register(fontSizeStyle, true);

//Override custom button's style
let icons = Quill.import("ui/icons");
icons["undo"] = ReactDOMServer.renderToString(<UndoIcon />);
icons["redo"] = ReactDOMServer.renderToString(<RedoIcon />);
icons["background"] = ReactDOMServer.renderToString(<Highlight />);

export default function EditorToolbar({ value, onChange }) {
  const ref = useRef(null);

  useEffect(() => {
    ref.current.getEditor().history.clear();
  }, []);

  function undoChange() {
    this.quill.history.undo();
  }
  function redoChange() {
    this.quill.history.redo();
  }
  //To prevent render config each time and warnings we just use useMemo
  let modules = useMemo(() => {
    return {
      toolbar: {
        history: [{ delay: 500 }, { maxStack: 100 }, { userOnly: false }],
        container: [
          [{ header: [1, 2, 3, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ size: fontsList }],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["undo", "redo"],
        ],
        handlers: {
          undo: undoChange,
          redo: redoChange,
        },
      },
    };
  }, []);

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "align",
    "indent",
    "color",
    "size",
    "undo",
    "redo",
    "background",
  ];

  return (
    <ReactQuill
      ref={ref}
      className="text-editor"
      value={value}
      onChange={onChange}
      theme="snow"
      modules={modules}
      formats={formats}
    />
  );
}
