import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeadPageTable from "./LeadPageTable/LeadPageTable";
import {
  getCurrentPageLeads,
  getCompanies,
  getAdditionalLeadsData,
  updateLeadTable,
  clearLeadsReducer,
} from "../../redux/actions/leadsActions";
import { Typography } from "antd";
import LeadsCompanySection from "./LeadsCompanySection/LeadsCompanySection";
import LeadsPageConfigSection from "./LeadPageConfigPanel/LeadPageConfigPanel";
import withPermission from "../../hocks/withPermission";
import withCallClear from "../../hocks/withCallClear";
import useActivePage from "../../hooks/useActivePage";
import { applicationRoles, routes } from "../../utils/mocks";
import "./_leadPage.less";

const { ADMIN, USER, CONSULTANT } = applicationRoles;

const WithPermissionLeadsCompanySection = withPermission(LeadsCompanySection);
const WithPermissionLeadsPageConfigSection = withPermission(LeadsPageConfigSection);

function LeadPage() {
  const dispatch = useDispatch();
  useActivePage(routes.LEADS);
  const isUpdated = useSelector((state) => state.leads.isUpdated);
  const selectedCompany = useSelector((state) => state.leads.selectedCompany);
  const loggedUser = useSelector((state) => state.common.user);

  useEffect(() => {
    if (loggedUser.role === USER) {
      return dispatch(getAdditionalLeadsData()).then(() => {
        dispatch(updateLeadTable({ isUpdated: new Date() }));
      });
    }
    dispatch(getCompanies());
  }, []);

  useEffect(() => {
    if (selectedCompany) dispatch(getCurrentPageLeads());
  }, [isUpdated]);

  useEffect(() => {
    return () => dispatch(clearLeadsReducer());
  }, []);

  return (
    <div className="leads-page">
      <div className="fluid_container">
        <div className="leads-page__config">
          <Typography.Title className="title" level={2}>
            Leads
          </Typography.Title>
          <div className="lead_config_section">
            {selectedCompany && <WithPermissionLeadsPageConfigSection roles={[ADMIN, CONSULTANT]} />}
            <WithPermissionLeadsCompanySection roles={[ADMIN, CONSULTANT]} />
          </div>
        </div>
        <LeadPageTable />
      </div>
    </div>
  );
}

export default withCallClear(LeadPage);
