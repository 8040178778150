import React, { useEffect, useMemo } from "react";
import ReactQuill, { Quill } from "react-quill";
import ReactDOMServer from "react-dom/server";
import { ReactComponent as UndoIcon } from "../../../assets/undo.svg";
import { ReactComponent as RedoIcon } from "../../../assets/redo.svg";
import "react-quill/dist/quill.snow.css";
import "./_textEditor.less";

let icons = Quill.import("ui/icons");
icons["undo"] = ReactDOMServer.renderToString(<UndoIcon />);
icons["redo"] = ReactDOMServer.renderToString(<RedoIcon />);

const TextEditorViewer = React.forwardRef(({ value, onChange }, ref) => {
  let modules = useMemo(() => {
    return {
      toolbar: {
        history: [{ delay: 5000 }, { maxStack: 10 }, { userOnly: true }],
        container: [[{ background: [] }], ["undo", "redo"]],
        handlers: {
          undo: undoChange,
          redo: redoChange,
        },
      },
    };
  }, []);

  useEffect(() => {
    ref.current.getEditor().history.clear();
  }, []);

  function undoChange() {
    this.quill.history.undo();
  }
  function redoChange() {
    this.quill.history.redo();
  }

  return (
    <ReactQuill
      ref={ref}
      id={"toolbar"}
      className="text-editor-viewer"
      modules={modules}
      value={value}
      defaultValue={value}
      onChange={onChange}
    />
  );
});

export default TextEditorViewer;
