import React from "react";
import { DatePicker as MiPicker } from "antd";
import locale from "antd/es/date-picker/locale/de_DE";
import PropTypes from "prop-types";
import "./_datePicker.less";

export default function DatePicker({ onChange, value, showTime, format, className }) {
  return (
    <MiPicker
      value={value}
      className={`datepicker ${className}`}
      onChange={onChange}
      placeholder="Select date"
      showTime={showTime}
      format={format}
      locale={{
        ...locale,
        lang: {
          ...locale.lang,
          now: "Now",
          ok: "OK",
        },
      }}
    />
  );
}

DatePicker.defaultProps = {
  className: "",
};

DatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showTime: PropTypes.object,
  format: PropTypes.string,
  className: PropTypes.string,
};
