import React, { useState } from "react";
import { Button, Modal, Typography, InputNumber } from "antd";
import Form from "antd/lib/form";
import PropTypes from "prop-types";
import "./_script-viewer-page.less";

const { Text } = Typography;

const MAX_VALUE = 100000000;

export default function EndCallModal({ title, isVisible, finish, closeModal, endReasonItems }) {
  const [endReason, setEndReason] = useState(null);
  const [isYesModalOpen, setYesModalOpen] = useState(false);
  const [form] = Form.useForm();

  const submitHandler = (okModalItems) => {
    if (okModalItems) {
      finish({ ...endReason, ...okModalItems });
    } else {
      finish({ ...endReason });
    }

    setEndReason({});
  };

  const closeModalHandler = () => {
    setEndReason({});
    closeModal();
  };

  const selectEndReasonHandler = (value) => {
    if (value.name === "Yes" || value.category === "pitch_yes") {
      setYesModalOpen(true);
      setEndReason(value);
    } else {
      setEndReason(value);
    }
  };

  const checkItemChosen = () => !endReason;

  const closeOkModal = () => {
    setYesModalOpen(false);
    setEndReason(null);
    form.setFieldsValue({ salesValue: null, cash: null });
  };

  const submitOkModal = () => {
    form.validateFields().then(({ salesValue, cash }) => {
      setYesModalOpen(false);
      submitHandler({ salesValue, cash });
    });
  };

  const endCallFooter = [
    <Button key="1" onClick={closeModalHandler} type="orange-empty">
      Back to script
    </Button>,
    <Button key="2" disabled={checkItemChosen()} onClick={() => submitHandler()} type="orange">
      Submit
    </Button>,
  ];

  const okReasonFooter = [
    <Button key="1" onClick={closeOkModal} type="orange-empty">
      Back to reasons
    </Button>,
    <Button key="2" onClick={submitOkModal} type="orange">
      Submit
    </Button>,
  ];

  const getElementType = (endReasonItem) =>
    endReason && endReason.name === endReasonItem.name ? "blue-empty" : "blue";

  const getEndCallButtons = () => {
    return endReasonItems.length
      ? endReasonItems.map((endReasonItem) => {
          return (
            <Button
              className={`btn`}
              key={endReasonItem.name}
              type={getElementType(endReasonItem)}
              onClick={() => selectEndReasonHandler(endReasonItem)}>
              <Text className="btn_text">{endReasonItem.name}</Text>
            </Button>
          );
        })
      : null;
  };

  return (
    <Modal
      onCancel={closeModalHandler}
      title={title}
      centered
      maskClosable={false}
      className="end-call-modal"
      visible={isVisible}
      footer={endCallFooter}>
      <div className="reason__viewer">
        <div className="reason__section">{getEndCallButtons()}</div>
      </div>
      <Modal
        title="Enter sales data"
        className="endCallModal"
        footer={okReasonFooter}
        maskClosable={false}
        centered
        visible={isYesModalOpen}
        onCancel={closeOkModal}>
        <Form form={form} labelCol={{ span: 5 }} name="yes_purchase_form">
          <Form.Item label={"Sales value"} name="salesValue" rules={[{ required: true, message: "Field is required" }]}>
            <InputNumber min={0} max={MAX_VALUE} placeholder="Total sales value, 0 for appt" />
          </Form.Item>
          <Form.Item label={"Cash"} name="cash" rules={[{ required: true, message: "Field is required" }]}>
            <InputNumber min={0} max={MAX_VALUE} placeholder="Cash taken on this call, 0 for appt" />
          </Form.Item>
        </Form>
      </Modal>
    </Modal>
  );
}

EndCallModal.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  finish: PropTypes.func,
  closeModal: PropTypes.func,
  endReasonItems: PropTypes.arrayOf(PropTypes.object),
};
