import React, { useEffect } from "react";
import { Typography } from "antd";
import DashboardFiltersSection from "./DashboardFiltersSection/DashboardFiltersSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanies,
  getUsersByCompany,
  clearDashboardPage,
} from "../../redux/actions/dashboardPageActions/dashboardPageActions";
import useActivePage from "../../hooks/useActivePage";
import { routes } from "../../utils/mocks";
import DashboardTabs from "./DashboardTabs/DashboardTabs";
import "./_dashboardPage.less";

function DashboardPage() {
  const dispatch = useDispatch();
  const activeCompany = useSelector((state) => state.dashboard.activeCompany);
  useActivePage(routes.DASHBOARD);

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  useEffect(() => {
    if (activeCompany) {
      dispatch(getUsersByCompany(activeCompany));
    }
  }, [activeCompany]);

  useEffect(() => {
    return () => {
      dispatch(clearDashboardPage());
    };
  }, []);

  return (
    <div className="employeeSummary_wrapper">
      <div className="fluid_container">
        <div className="employeeSummary">
          <Typography.Title className="title" level={2}>
            Dashboard
          </Typography.Title>
          <div className="content">
            <DashboardFiltersSection />
            <DashboardTabs />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
