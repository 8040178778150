import React from "react";
import DashboardItem from "./DashboardItem";
import PropTypes from "prop-types";
import "./_dashboardKpi.less";

function DashboardsKpi({ dashboardsKPI }) {
  return (
    <div className="dashboardsCard_section">
      {dashboardsKPI &&
        dashboardsKPI.map((dashboard) => {
          return <DashboardItem key={dashboard.name} dashboard={dashboard} />;
        })}
    </div>
  );
}

DashboardsKpi.propTypes = {
  dashboardsKPI: PropTypes.arrayOf(PropTypes.object),
};

export default DashboardsKpi;
