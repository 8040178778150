import React, { useState } from "react";
import { Form, Select } from "antd";
import { useSelector } from "react-redux";
import { sortByName } from "../../../utils/helpers";
import PropTypes from "prop-types";

const { Option } = Select;

function FunnelAssignForm({ onSubmit, buttonTitle, onCancel }) {
  const [form] = Form.useForm();
  const funnels = useSelector((state) => state.leads.funnels);
  const [selectedFunnel, setSelectedFunnel] = useState([]);

  const formSubmit = () =>
    form.validateFields().then((data) => {
      return onSubmit(data);
    });

  const onFormLayoutChange = (data) => {
    if (data.funnel) {
      const selectedFunnel = funnels.find((funnel) => funnel.name === data.funnel);
      if (selectedFunnel) return setSelectedFunnel(selectedFunnel.funnelStages);
    }
  };

  return (
    <Form form={form} name="basic" onValuesChange={onFormLayoutChange} onFinish={onSubmit} autoComplete="off">
      <Form.Item name="funnel" rules={[{ required: true, message: "This field is required" }]}>
        <Select placeholder={"Choose funnel"}>
          {funnels.length &&
            sortByName(funnels, "name").map((script, index) => {
              return (
                <Option key={index} value={script.name}>
                  {script.name}
                </Option>
              );
            })}
        </Select>
      </Form.Item>

      <Form.Item name="funnelStage" rules={[{ required: true, message: "This field is required" }]}>
        <Select placeholder={"Choose funnel stage"}>
          {selectedFunnel.length &&
            sortByName(selectedFunnel, "name").map((script, index) => {
              return (
                <Option key={index} value={script.name}>
                  {script.name}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
      <div className="ant-modal-footer" style={{ marginLeft: "-22px", marginRight: "-22px", paddingRight: "22px" }}>
        <button type="button" className="ant-btn" onClick={onCancel}>
          <span>Cancel</span>
        </button>
        <button type="button" className="ant-btn ant-btn-primary" onClick={formSubmit}>
          <span>{buttonTitle ? buttonTitle : "OK"}</span>
        </button>
      </div>
    </Form>
  );
}

FunnelAssignForm.propTypes = {
  onSubmit: PropTypes.func,
  buttonTitle: PropTypes.string,
  onCancel: PropTypes.func,
};

export default FunnelAssignForm;
