import axios from "axios";
import notificationAlert from "../utils/notificationAlert";
import { clearStorageExept, getCurrentTimezone } from "../utils/helpers";

/**
 * Setting up the axios object
 */
const API = axios.create({
  baseURL: "/api/",
  responseType: "json",
  headers: {
    cache: "no-store",
  },
});

/**
 * Callback that is executed in case of a successful request
 */
const onFullfiled = (response) => response.data;

/**
 * Callback that is executed in case of an error
 */
const onRejected = (error) => {
  if (error.response && error.response.status === 401) {
    clearStorageExept("ironcage-stats");
    window.location.href = "/login";
  } else if (error.response && error.response.status === 403) {
    window.location.href = "/forbidden-page";
  } else if (error.response && error.response.status === 500) {
    notificationAlert({
      type: "error",
      description: error.response.statusText || " Internal server error",
    });
    setTimeout(function () {
      window.location.href = "/server-error";
    }, 1000);
  } else if (error.response && error.response?.data?.message) {
    notificationAlert({
      type: "error",
      description: error.response.data.message,
    });
  } else {
    notificationAlert({
      type: "error",
      description: "Oops something went wrong",
    });
  }

  return Promise.reject(error);
};

API.interceptors.response.use(onFullfiled, onRejected);

API.interceptors.request.use(function (config) {
  config.headers["Timezone-Offset"] = getCurrentTimezone();
  return config;
});

export default API;
