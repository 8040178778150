import React from "react";
import "antd/dist/antd.css";
import { Layout } from "antd";
import { Switch, Route } from "react-router-dom";
import HeaderComponent from "../../components/Header/Header";
import SideMenu from "../../components/SideMenu/SideMenu";
import {
  DashboardPage,
  AdminPage,
  ScriptViewerPage,
  HomePage,
  ForbiddenPage,
  NotFoundPage,
  StatsPage,
  ConsultantsPage,
  MeetingsPage,
  MeetingPlanPage,
  ServerErrorPage,
  StatsInfoPage,
  StaffDetail,
  StatsViewerPage,
  Guidance,
  LeadPage,
} from "../index";
import withAuthLogout from "../../hocks/withAuthLogout";
import { routesPaths } from "../../utils/mocks";
import "./_main.less";

const { Content } = Layout;

function Main() {
  return (
    <Layout>
      <HeaderComponent />
      <Layout className="content_layout">
        <SideMenu />
        <Content className="site-layout-background">
          <Switch>
            <Route path={routesPaths.HOME} component={HomePage} exact />
            <Route path={routesPaths.STATS_INFO} component={StatsInfoPage} exact />
            <Route path={routesPaths.START_SCRIPT} component={ScriptViewerPage} exact />
            <Route path={routesPaths.ADMIN} component={AdminPage} exact />
            <Route path={routesPaths.STATS_VIEWER} component={StatsViewerPage} exact />
            <Route path={routesPaths.STATS} component={StatsPage} exact />
            <Route path={routesPaths.LEADS} component={LeadPage} exact />
            <Route path={routesPaths.MEETINGS} component={MeetingsPage} exact />
            <Route path={routesPaths.CALLS_DETAIL} component={StaffDetail} exact />
            <Route path={routesPaths.DASHBOARD} component={DashboardPage} exact />
            <Route path={routesPaths.RULES} component={Guidance} exact />
            <Route path={routesPaths.MEETING_PLAN} component={MeetingPlanPage} exact />
            <Route path={routesPaths.CONSULTANTS} component={ConsultantsPage} exact />
            <Route path={routesPaths.FORBIDDEN} component={ForbiddenPage} exact />
            <Route path={routesPaths.SERVER_ERROR} component={ServerErrorPage} exact />
            <Route path={routesPaths.NOT_FOUNT} component={NotFoundPage} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}

export default withAuthLogout(Main);
