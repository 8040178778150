import {
  SET_ALL_INITIAL_DATA_SUCCESS,
  CHOOSE_CURRENT_COMPANY,
  CHOOSE_CURRENT_SCRIPT,
  SET_ALL_INITIAL_DATA_REQUEST,
  SET_STATS,
  RESET_STATS,
  SET_PREV_DAY_REQUEST,
  SET_PREV_DAY_SUCCESS,
} from "../actions/homePageActions";
import { end_categories } from "../../utils/mocks";
import { b64e } from "../../utils/helpers";

const initialState = {
  currentScript: {},
  currentCompany: {},
  currentUser: {},
  allScripts: [],
  allCompanies: [],
  isLoading: true,
  stats: { dials: 0, conversations: 0, pitches: 0, sales: 0, endReasons: [] },
  prevDaysStats: [],
  isPrevDayStatsLoading: false,
};

export const homePageReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case SET_ALL_INITIAL_DATA_REQUEST:
      return { ...state, isLoading: false };

    case SET_ALL_INITIAL_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentScript: payload.currentScript,
        currentCompany: payload.currentCompany,
        currentUser: payload.currentUser,
        allScripts: payload.allScripts,
        allCompanies: payload.allCompanies,
      };

    case SET_PREV_DAY_REQUEST:
      return { ...state, isPrevDayStatsLoading: false };

    case SET_PREV_DAY_SUCCESS:
      return { ...state, isPrevDayStatsLoading: true, prevDaysStats: payload };

    case CHOOSE_CURRENT_COMPANY:
      localStorage.removeItem("selectedScript");
      localStorage.setItem("currentCompanyName", payload.currentCompany.name);
      return {
        ...state,
        currentCompany: payload.currentCompany,
        allScripts: payload.currentCompany.scripts,
        currentScript: {},
      };

    case CHOOSE_CURRENT_SCRIPT:
      return { ...state, currentScript: payload.currentScript };

    case SET_STATS:
      let hashedValueForUser = b64e(JSON.parse(localStorage.getItem("currentUser")).id);

      let stats =
        (JSON.parse(localStorage.getItem("ironcage-stats")) &&
          JSON.parse(localStorage.getItem("ironcage-stats"))[hashedValueForUser]) ||
        null;

      let newStatsObject = {
        [hashedValueForUser]: {
          dials: 0,
          conversations: 0,
          pitches: 0,
          sales: 0,
          endReasons: [],
        },
      };

      if (stats === null) {
        end_categories.map((category) =>
          state.stats.endReasons.push({
            category: category.cat,
            name: category.name,
            count: 0,
          })
        );
        let prevSessionStats = JSON.parse(localStorage.getItem("ironcage-stats"));
        let stats;
        if (prevSessionStats !== null) {
          stats = { ...prevSessionStats, [hashedValueForUser]: state.stats };
        } else {
          stats = { [hashedValueForUser]: state.stats };
        }
        localStorage.setItem("ironcage-stats", JSON.stringify(stats));
      } else {
        newStatsObject = { [hashedValueForUser]: stats };
      }
      return { ...state, stats: newStatsObject[hashedValueForUser] };

    case RESET_STATS:
      let hashValueForUser = b64e(JSON.parse(localStorage.getItem("currentUser")).id);
      let prevSessionStats = JSON.parse(localStorage.getItem("ironcage-stats")) || {};
      let endReasons = [
        { category: "dial", name: "Dials", count: 0 },
        { category: "intro_callDrop", name: "Intro - Call Drop", count: 0 },
        { category: "intro_notInterested", name: "Not Interested", count: 0 },
        { category: "intro_reschedule", name: "Reschedule", count: 0 },
        { category: "intro_disconnect", name: "Disconnect", count: 0 },
        { category: "probe_notInterested", name: "Probe - Not Interested", count: 0 },
        { category: "probe_reschedule", name: "Reschedule", count: 0 },
        { category: "probe_notQualified", name: "Not Qualified", count: 0 },
        { category: "pitch_yes", name: "Pitch - Yes", count: 0 },
        { category: "pitch_no", name: "No", count: 0 },
        { category: "pitch_reschedule", name: "Reschedule", count: 0 },
        { category: "pitch_takeaway", name: "Takeaway", count: 0 },
        { category: "pitch_hangup", name: "Hang Up", count: 0 },
        { category: "pitch_time", name: "Objection - Time", count: 0 },
        { category: "pitch_money", name: "Objection - Money", count: 0 },
        { category: "pitch_partner", name: "Objection - Partner", count: 0 },
        { category: "pitch_info", name: "Objection - Info", count: 0 },
        { category: "pitch_trust", name: "Objection - Trust", count: 0 },
      ];

      const updatedStatsObj = {
        ...prevSessionStats,
        [hashValueForUser]: {
          dials: 0,
          conversations: 0,
          pitches: 0,
          sales: 0,
          endReasons: endReasons,
        },
      };
      localStorage.setItem("ironcage-stats", JSON.stringify(updatedStatsObj));

      return {
        ...state,
        stats: { ...state.stats, dials: 0, conversations: 0, pitches: 0, sales: 0 },
      };

    default:
      return state;
  }
};
