import PropTypes from "prop-types";

function DeleteForm({ onSubmit, title, onCancel }) {
  return (
    <>
      <div style={{ marginBottom: "20px" }}>{title}</div>
      <div className="ant-modal-footer" style={{ marginLeft: "-22px", marginRight: "-22px", paddingRight: "22px" }}>
        <button type="button" className="ant-btn" onClick={onCancel} data-testid="cancel_btn">
          <span>Cancel</span>
        </button>
        <button type="button" data-testid="ok_btn" className="ant-btn ant-btn-primary" onClick={onSubmit}>
          <span>OK</span>
        </button>
      </div>
    </>
  );
}

DeleteForm.propTypes = {
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  onCancel: PropTypes.func,
};

export default DeleteForm;
