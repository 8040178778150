import API from "../../services/apiService";
import { getAllUrlParam, getUrlParam } from "../../utils/helpers";

export const SET_ALL_MEETING_PLAN_REQUEST = "SET_ALL_MEETING_PLAN_REQUEST";
export const SET_ALL_MEETING_PLAN_SUCCESS = "SET_ALL_MEETING_PLAN_SUCCESS";

export const SET_MEETING_DATE = "SET_MEETING_DATE";
export const SET_TEAM_MEMBERS = "SET_TEAM_MEMBERS";
export const SET_GUIDANCE_ACTION = "SET_GUIDANCE_ACTION";
export const SET_GUIDANCE_REQUEST = "SET_GUIDANCE_REQUEST";

export const ADD_ACTUAL_GUIDANCE = "ADD_ACTUAL_GUIDANCE";
export const EDIT_ACTUAL_GUIDANCE = "EDIT_ACTUAL_GUIDANCE";
export const DELETE_ACTUAL_GUIDANCE = "DELETE_ACTUAL_GUIDANCE";

export const UPDATE_PLANNING_NOTES = "UPDATE_PLANNING_NOTES";
export const UPDATE_MEETING_NOTES = "UPDATE_MEETING_NOTES";
export const CLEAR_MEETING_PLAN = "CLEAR_MEETING_PLAN";

export const UPDATE_DONE_STATUS_ACTUAL_GUIDANCE = "UPDATE_DONE_STATUS_ACTUAL_GUIDANCE";
export const UPDATE_SENTIMENT_ACTUAL_GUIDANCE = "UPDATE_SENTIMENT_ACTUAL_GUIDANCE";

export const GET_AUTO_GUIDANCES_SUCCESS = "GET_AUTO_GUIDANCES_SUCCESS";
export const UNSET_TEAM_MEMBERS = "UNSET_TEAM_MEMBERS";

export const SET_ALL_USERS_SUCCESS = "SET_ALL_USERS_SUCCESS";

export const setGuidanceAsyncAction = (selectedTeamMembers, systemUsers) => async (dispatch) => {
  dispatch(setGuidanceRequest());

  const selectedTeamMembersIdName = systemUsers
    .filter((user) => {
      return selectedTeamMembers.some((member) => member === user.id);
    })
    .map((user) => user.name);

  const guidance = await API.get("guidances/planned", {
    params: {
      f_user: selectedTeamMembersIdName,
    },
  });
  dispatch(setGuidanceAction({ guidance: guidance }));
};

export const setAllMeetingPlanDataAsyncAction = (meetingId) => async (dispatch) => {
  dispatch(setAllMeetingPlanDataRequest());
  let meetingPlanData = await API(`meetings/${meetingId}`);
  dispatch(setAllMeetingPlanDataSuccess({ meetingPlanData }));
};

export const setAllMeetingPlanDataRequest = (payload) => ({
  type: SET_ALL_MEETING_PLAN_REQUEST,
  payload,
});
export const setAllMeetingPlanDataSuccess = (payload) => ({
  type: SET_ALL_MEETING_PLAN_SUCCESS,
  payload,
});

export const addActualGuidanceAction = (payload) => ({ type: ADD_ACTUAL_GUIDANCE, payload });
export const editActualGuidanceAction = (payload) => ({ type: EDIT_ACTUAL_GUIDANCE, payload });
export const deleteActualGuidanceAction = (payload) => ({ type: DELETE_ACTUAL_GUIDANCE, payload });

export const setGuidanceRequest = () => ({ type: SET_GUIDANCE_REQUEST });
export const setGuidanceAction = (payload) => ({ type: SET_GUIDANCE_ACTION, payload });

export const setMeetingDateAction = (payload) => ({ type: SET_MEETING_DATE, payload });
export const setTeamMembersAction = (payload) => ({ type: SET_TEAM_MEMBERS, payload });
export const unsetTeamMemberAction = (payload) => ({ type: UNSET_TEAM_MEMBERS, payload });

export const updatePlanningNotesAction = (payload) => ({ type: UPDATE_PLANNING_NOTES, payload });
export const updateMeetingNotesAction = (payload) => ({ type: UPDATE_MEETING_NOTES, payload });
export const clearMeetingPlanReducerAction = () => ({ type: CLEAR_MEETING_PLAN });

export const updateDoneStatus = (payload) => ({ type: UPDATE_DONE_STATUS_ACTUAL_GUIDANCE, payload });
export const updateSentiment = (payload) => ({ type: UPDATE_SENTIMENT_ACTUAL_GUIDANCE, payload });

const transformAutoGuidances = (guidances) => {
  if (!guidances.length) {
    return [];
  }
  let guidancesResult = [];
  guidances.forEach((guidance) => {
    if (guidance.newGuidanceList?.length) {
      guidancesResult.push({
        data: null,
        done: null,
        level: "new",
        notes: null,
        sentiment: null,
        type: "Consultant guidance",
        guidanceArea: { text: guidance.newGuidanceList.join(";") },
        userId: [guidance.user.id],
      });
    }
    if (guidance.ongoingGuidanceList?.length) {
      guidancesResult.push({
        data: null,
        done: null,
        level: "ongoing",
        notes: null,
        sentiment: null,
        type: "Consultant guidance",
        guidanceArea: { text: guidance.ongoingGuidanceList.join("/") },
        userId: [guidance.user.id],
      });
    }
    if (guidance.repeatedGuidanceList?.length) {
      guidancesResult.push({
        data: null,
        done: null,
        level: "repeated",
        notes: null,
        sentiment: null,
        type: "Consultant guidance",
        guidanceArea: { text: guidance.repeatedGuidanceList.join("/") },
        userId: [guidance.user.id],
      });
    }
  });

  return guidancesResult;
};

export const getAutoGuidances = (selectedTeamMembersIdArray) => async (dispatch) => {
  const autoGuidances = await API.get("guidances", {
    params: {
      f_userId: selectedTeamMembersIdArray,
    },
  });
  dispatch(getAutoGuidancesSuccess(transformAutoGuidances(autoGuidances)));
};

export const setAllUsers = () => async (dispatch) => {
  const systemUsers = await API.get("user/all");
  dispatch(setAllUserSuccess(systemUsers));
};

export const setAllUserSuccess = (payload) => ({ type: SET_ALL_USERS_SUCCESS, payload });

export const getAutoGuidancesSuccess = (payload) => ({ type: GET_AUTO_GUIDANCES_SUCCESS, payload });

export const updateFiltersFromParamMeetingPlan = (params) => async (dispatch) => {
  const meetingDate = getUrlParam(params, "date");
  const selectedTeamMembers = getAllUrlParam(params, "userId[]");
  dispatch(setMeetingDateAction(meetingDate));
  dispatch(setTeamMembersAction(selectedTeamMembers));
};
