import React from "react";
import PropTypes from "prop-types";
import "./_title.less";

function Title({ tag, children, position, weight }) {
  switch (tag) {
    case "h1":
      return (
        <div className={`block_title ${position}`}>
          <h1>{children}</h1>
        </div>
      );
    case "h2":
      return (
        <div className={`block_title ${position}`}>
          <h2>{children}</h2>
        </div>
      );
    case "h3":
      return (
        <div className={`block_title ${position}`}>
          <h3>{children}</h3>
        </div>
      );
    case "h4":
      return (
        <div className={`block_title ${position}`}>
          <h4 className={`${weight}`}>{children}</h4>
        </div>
      );
    case "h5":
      return (
        <div className={`block_title ${position}`}>
          <h5 className={`${weight}`}>{children}</h5>
        </div>
      );
    default:
      return <></>;
  }
}

Title.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.string,
  position: PropTypes.string,
  weight: PropTypes.string,
};

export default Title;
